import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ExportEmployerJobsService } from 'src/app/services/export-employer-jobs.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult';
//import { AppliedJobDialog } from './applied_dialog';
// @Injectable()
// export class JobServices {
//   constructor(
//      private http: HttpClient
//      ) {}

//   async commonService(rawData: any, token: string, path: string) {
//     try {
//       let headers = new HttpHeaders({
//         'Content-Type': 'application/json',
//         Authorization: token,
//       });
//       let options = { headers: headers };
//       const body = rawData;
//       const data = await this.http
//         .post(`${environment.baseUrl}${path}`, body, options)
//         .toPromise();
//       return data;
//     } catch (error) {
//       console.error(`Error occurred: ${error}`);
//     }
//     return 'Somthing wrong ';
//   }
// }

@Component({
  selector: 'app-emp-jobs',
  // providers: [JobServices],
  templateUrl: './emp-jobs.component.html',
  styleUrls: ['./emp-jobs.component.scss'],
})
export class EmpJobsComponent implements OnInit {
  paths: any = {
    list: '/job/employer-wise-job-list',
    appliedJobs: '/job/applied-jobs',
  };
  dashBoardDetails: UntypedFormGroup;
  jobsList: any = [];
  userToken: string;
  userDetails: any;
  finalJobList: any = [];
  statusList: any = ['Active', 'Inactive'];
  userDeatils: any;
  companyData: any;
  show: boolean = false;
  selectedJob: any;
  appliedJobseekerDetails: any;
  postedDate: Date = new Date();
  searchText: any = '';
  postedByList: any;

  jobTypeList: any = ['active', 'inactive'];
  filtered: any = {};
  clearButton: boolean = false;
  selectedJobByEmployer: any;
  filteredOptions: Observable<string[]>;
  CompaniesResult: any = [];

  selectCompany: any;

  startJobIndex: any = 0;
  nextButtonflag: boolean = true;
  constructor(
    // private jobService: JobServices,
    public dialog: MatDialog,
    private router: Router,
    private helper: Helper,
    private auth: AuthService,
    private io: HttpIOService,
    private EmployerService: EmployerService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private employerService: EmployerService,
    private snackBar: MatSnackBar,
    private exportEmpJobsService: ExportEmployerJobsService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    // console.log(this.userDeatils)
    this.validationForm();
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
      this.getEmployerByCompany();
    } else {
      this.getAllCompaniesList();
      this.searchByDependency();
    }

    this.filteredOptions =
      this.dashBoardDetails.controls.companies.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CompaniesResult.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  // Insilize Form //
  validationForm() {
    this.dashBoardDetails = this.fb.group({
      created_at: [''],
      title: [''],
      job_status: [''],
      provider_id: [''],
      companies: [''],
    });
  }

  async getAllCompaniesList() {
    let jsonData = {
      path: 'get-company-list',
    };
    await this.employerService
      ._getAllCompaniesList(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res['result']);
          this.CompaniesResult = res['result'].companyList;
        }
      });
  }

  async getEmployerJobs() {
    if (this.userDeatils.userRole == 'company_admin') {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        //  "provider_id": this.userDeatils.id,
        company_id: this.userDeatils.companyId,
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.jobsList = res['result'].job;
          }
        });
    }
    if (this.userDeatils.userRole == 'company_basic') {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        provider_id: this.userDeatils.id,
        company_id: this.userDeatils.companyId,
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.jobsList = res['result'].job;
          }
        });
    }
  }

  getEmployerByCompany() {
    let jsonData = {
      path: 'get-employer-by-company',
      companyId: this.userDeatils.companyId,
    };
    this.employerService
      ._getEmployerByCompany(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.postedByList = res['result'].userData;
        }
      });
  }

  async getAppliedJobseekerDetails(job) {
    // console.log(job)
    this.selectedJob = '';
    this.selectedJob = job;
    let jsonData = {
      jobId: job.id,
      path: 'get-applied-job-seeker',
    };
    await this.employerService
      ._getAppliedJobseeker(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          //  console.log(res)
          this.appliedJobseekerDetails = res['result'].userData;
          this.selectedJobByEmployer = job.id;
          // console.log(this.appliedJobseekerDetails)
          this.show = true;
          this.clearButton = false;
        }

        if (res.serverResponse.code == 400) {
          this.snackBar
            .openFromComponent(DynamicSnacbarSkipComponent, {
              data: 'Notice: no one applied yet',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  async routeToAddJob() {
    let checkEmailValid = await this.io._checkEmailValid();
    // console.log(checkEmailValid)
    if (checkEmailValid == true) {
      this.router.navigate(['post-job']);
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }
  onclick() {
    this.show = false;
    if (this.userDeatils.userType == 'super_admin') {
      this.clearButton = true;
    }
  }

  onKey(event) {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      this.searchByDependency();
    }
    this.clearButton = true;
  }

  filterFrom(e: MatDatepickerInputEvent<Date>) {
    // console.log(e.value);
    this.searchByDependency();
  }

  changeJobType(event) {
    // console.log(event.value);
    this.searchByDependency();
    this.clearButton = true;
  }

  changePostedBy(event) {
    // console.log(event);
    if (this.selectCompany) {
      this.searchByDependency();
    }
    if (this.userDeatils.userType != 'super_admin') {
      this.searchByDependency();
    }
    this.clearButton = true;
    this.startJobIndex = 0;
    // this.nextButtonflag = false
  }

  changeCompany(event) {
    // console.log(event);
    this.selectCompany = '';
    this.selectCompany = this.CompaniesResult.find(
      (x) => x.companyName == event
    );
    // console.log(this.selectCompany);
    this.searchByDependency();
    let jsonData = {
      path: 'get-employer-by-company',
      companyId: this.selectCompany.id,
    };
    this.employerService
      ._getEmployerByCompany(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.postedByList = res['result'].userData;
          this.clearButton = true;
        }
      });
  }

  transformTreeDateTime(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  searchByDependency() {
    if (this.userDeatils.userType != 'super_admin') {
      this.filtered = {
        path: 'get-job-by-provider-or-company',
        company_id: this.userDeatils.companyId,
      };
    } else {
      this.filtered = {
        path: 'get-job-by-provider-or-company',
        company_id: this.selectCompany ? this.selectCompany.id : 'all',
        start: 0,
        size: 10,
      };
    }
    if (this.dashBoardDetails) {
      for (let key in this.dashBoardDetails.value) {
        if (this.dashBoardDetails.value[key]) {
          if (key == 'created_at' && this.dashBoardDetails.value[key] != '') {
            this.filtered[key] = this.transformTreeDateTime(
              this.dashBoardDetails.value[key]
            );
          } else {
            this.filtered[key] = this.dashBoardDetails.value[key];
          }
        }
      }
    }
    console.log(this.filtered);
    this.employerService
      ._getJobByproviderAndCompany(this.filtered)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res['result'].job);
          if (res['result']) {
            this.jobsList = res['result'].job;
          }
          if (this.jobsList.length > 0) {
            if (this.userDeatils.userType != 'super_admin') {
              this.clearButton = true;
            }
            if (
              this.userDeatils.userType == 'super_admin' &&
              this.selectCompany
            ) {
              this.clearButton = true;
            }
          }
          // if (this.selectCompany == undefined) {
          //   // console.log(this.selectCompany)
          //   this.snackBar.openFromComponent(DynamicSnacbarSkipComponent, {
          //     data: res.serverResponse.message,
          //     verticalPosition: 'top', // 'top' | 'bottom'
          //     horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          //   }).afterOpened().subscribe(this.helper.setOnTop);
          // }
        }
      });
  }

  loadData(data) {
    if (data == 'previous' && this.startJobIndex != 0) {
      this.startJobIndex = this.startJobIndex - 10;
      this.nextButtonflag = true;
      this.filtered['start'] = this.startJobIndex;
      if (this.userDeatils.userType != 'super_admin') {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.userDeatils.companyId;
      } else {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.selectCompany
          ? this.selectCompany.id
          : 'all';
      }
      console.log(this.filtered);
      this.employerService
        ._getJobByproviderAndCompany(this.filtered)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res['result'].job);
            if (res['result']) {
              this.jobsList = res['result'].job;
            }
            if (this.jobsList.length > 0) {
              if (this.userDeatils.userType != 'super_admin') {
                this.clearButton = true;
              }
              if (
                this.userDeatils.userType == 'super_admin' &&
                this.selectCompany
              ) {
                this.clearButton = true;
              }
            }
            if (this.selectCompany == undefined) {
              // console.log(this.selectCompany)
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          }
        });
    }
    if (data == 'next') {
      this.startJobIndex = this.startJobIndex + 10;
      this.filtered['start'] = this.startJobIndex;
      if (this.userDeatils.userType != 'super_admin') {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.userDeatils.companyId;
      } else {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.selectCompany
          ? this.selectCompany.id
          : 'all';
      }
      this.employerService
        ._getJobByproviderAndCompany(this.filtered)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res['result'].job);
            if (res['result']) {
              this.jobsList = res['result'].job;
            }

            if (this.selectCompany == undefined) {
              // console.log(this.selectCompany)
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          }
          if (res.serverResponse.code == 404) {
            if (typeof res['result'] === 'object' && res['result'] !== null) {
              console.log(res['result']);
              this.nextButtonflag = false;
            }
          }
        });
    }
  }

  //ACTIVE OR INACTIVE SLIDE //
  activeInactive(job, index) {
    console.log(job, index);
    let jobStatus = job.job_status;
    if (jobStatus === 'active') {
      let jsonData = {
        path: 'inactive-job',
        id: job.id,
      };
      this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => {
        // console.log(this.jobsList[index]);
        this.jobsList[index].job_status = res['result'].job.job_status;
      });
    } else {
      let jsonData = {
        path: 'active-job',
        id: job.id,
      };
      this.io._activeForEmpManage(jsonData).subscribe((res: any) => {
        // console.log(res);
        this.jobsList[index].job_status = res['result'].job.job_status;
      });
    }
  }

  resetFrom() {
    this.dashBoardDetails.reset({ companies: '' });
    this.clearButton = false;
    if (this.userDeatils.userType == 'super_admin') {
      this.selectCompany = '';
      this.jobsList = [];
      this.startJobIndex = 0;
      this.searchByDependency();
    }
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
    }
  }

  exportAllJobs() {
    if (this.userDeatils.userRole == 'company_admin') {
      this.exportEmpJobsService.genExcel(this.jobsList);
    } else {
      this.exportEmpJobsService.genExcelCompanyBasic(this.jobsList);
    }
  }

  feature(job) {
    console.log(job);
    if (job.is_featured == true) {
      let jsonData = {
        path: 'update-featured-status',
        is_featured: false,
        id: job.id,
      };
      this.EmployerService._FeatureJob(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.searchByDependency();
        }
      });
    } else {
      let jsonData = {
        path: 'update-featured-status',
        is_featured: true,
        id: job.id,
      };
      this.EmployerService._FeatureJob(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.searchByDependency();
        }
      });
    }
  }
}

// jobApplied(job: any) {
//   var jobData = {
//     path: 'applied-job-apps',
//     jobId: job.id,
//   };
//   this.jobService
//     .commonService(jobData, this.userToken, this.paths.appliedJobs)
//     .then((resStr) => {
//       var appliedUsers = [];
//       appliedUsers = resStr['result']['appliedJobApps'];
//       this.openDialog(appliedUsers);
//     })
//     .catch((e) => {
//       console.log(e);
//     });
// }

// openDialog(job): void {
// alert('er');

//   console.log(job.id);
//  this.router.navigate(['/edit-job'], { queryParams: { id: job.id } })

// [routerLink]="['/edit-job?id=f444040d-b7b5-4ceb-8403-65ea058f1231']"
// const dialogRef = this.dialog.open(AppliedJobDialog, {
//   width: '70%',
//   data: users,
// });

// dialogRef.afterClosed().subscribe((result) => {
//   console.log('The dialog was closed');
// });
// }

// filterJobs(status: any) {
//   this.jobsList = this.finalJobList;
//   var fliterJob = [];
//   this.jobsList.map((job) => {
//     if (job.job_status.toLowerCase() === status.toLowerCase()) {
//       fliterJob.push(job);
//     } else if (status == 'All') {
//       fliterJob.push(job);
//     }
//   });
//   this.jobsList = [];
//   this.jobsList = fliterJob;
// }

// onKeypressEvent(event: any) {
//   this.jobsList = this.finalJobList;
//   if (event.target.value != '') {
//     var data = this.jobsList.filter(function (job) {
//       return job.title.includes(event.target.value);
//     });
//     this.jobsList = data;
//   }
// }
