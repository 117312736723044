import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../container/elements/header/header.component';
import { FooterComponent } from '../../container/elements/footer/footer.component';
import { WithNavComponent } from '../../container/layouts/with-nav/with-nav.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material/material.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { AgmCoreModule } from '@agm/core';

// import { MenuComponent } from '../container/elements/menu/menu.component';
// import { ModalDilogComponent } from '../container/layouts/modal-dilog/modal-dilog.component';
// import { AgmCoreModule } from '@agm/core';
// import { MatCarouselModule } from '@ngmodule/material-carousel';
// import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
// import { PickerModule } from '@ctrl/ngx-emoji-mart'
// import { MentionModule } from 'angular-mentions';
// import { HashtagMentionColLibModule } from 'hashtag-mention-colorizer';
// import { ShareButtonModule } from 'ngx-sharebuttons/button';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { AngularEditorModule } from '@kolkov/angular-editor';
// import { Footer2Component } from 'src/app/container/elements/footer2/footer2.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    WithNavComponent,
    // MenuComponent,
    // ModalDilogComponent
    // Footer2Component,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    // AgmCoreModule,
    AngularEditorModule,
    // AgmCoreModule,
    // MatCarouselModule,
    // EmojiModule,
    // PickerModule,
    // MentionModule,
    // HashtagMentionColLibModule,
    // ShareButtonModule,
    // ShareIconsModule
  ],
  exports: [
    WithNavComponent,
    RouterModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    // AgmCoreModule,
    AngularEditorModule,
    // MenuComponent,
    // ModalDilogComponent,
    // AgmCoreModule,
    // MatCarouselModule,
    // EmojiModule,
    // PickerModule,
    // MentionModule,
    // HashtagMentionColLibModule,
    // ShareButtonModule,
    // ShareIconsModule
  ],
})
export class SharedModule {}
