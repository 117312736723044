<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="log-lt">
                <h3>We emailed you a code</h3>
                <p class="sub-heading">
                  Enter the 6 digit verification code sent to<br /><strong>{{
                    this.emailId
                  }}</strong>
                  <a href="javascript:void(0);" routerLink="/forgot-password">
                    Change</a
                  >
                </p>
                <div class="form-wrap">
                  <form [formGroup]="codeEnter">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="6 digit code"
                          required="required"
                          formControlName="otp"
                          [pattern]="otpPattern"
                          maxlength="6"
                        />
                        <div *ngIf="f.otp.touched && f.otp.invalid">
                          <span
                            class="text-left text-danger pb-1"
                            *ngIf="f.otp.errors.required"
                            >Otp is required
                          </span>
                          <span
                            class="text-left text-danger pb-1"
                            *ngIf="f.otp.errors?.pattern"
                          >
                            Otp is not valid.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          value="Submit"
                          class="btn btn-green w-100"
                          (click)="newPassword()"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 forgot-section">
                      <p>
                        If you don’t see the email in your inbox, kindly check
                        your spam folder
                      </p>
                      <p>
                        Did not get the email?
                        <a href="javascript:void(0);" (click)="resendPassword()"
                          >Resend OTP</a
                        >
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img
                    src="../../../../assets/images/Forgot-password.png"
                    alt=""
                  />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="custom-wrap-box">
  <h2 class="modal-heading mb-0"></h2>

  <div class="box-wrapper mw-100">
    <div class="login-form mt-4"></div>
  </div>
</div>
