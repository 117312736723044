<div>
  <div class="img-container">
    <img src="../../../assets/images/bell-notif.svg"/>
  </div>
  <div class="main-container">
    <p class="txt">
      Only fellows who have completed the 3MTT program can view this job. You can find more jobs like this on worknigeria.
    </p>
    <button class="main">
      <a href="https://worknigeria.com/job-search">Search on WorkNigeria</a>
    </button>
    <button class="learn-more">
      <a href="https://3mtt.nitda.gov.ng/">Learn more about 3MTT</a>
    </button>
  </div>
</div>