<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<div class="dell-area">
  <div>
    <h4 style="text-align: center">Are you sure you want to subscribe?</h4>
    <p style="text-align: center">
      <button class="btn-green" mat-flat-button (click)="submitForm()">
        YES</button
      >&nbsp;
      <button
        class="btn-green wh-bg"
        mat-raised-button
        color="warn"
        (click)="onNoClick()"
      >
        NO
      </button>
    </p>
  </div>
</div>
