import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from '../auth/auth.module';
import { JobSearchModule } from '../job-search/job-search.module';
import { EmployerModule } from '../employer/employer.module';
import { JobseekerModule } from '../jobseeker/jobseeker.module';
// import { CurrencyMaskModule } from '@mvpproperties/ng2-currency-mask';
import { ScrollspyDirective } from '../job-search/scrollspy.directive';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule,
    // InfiniteScrollModule,
    // CurrencyMaskModule,
    JobSearchModule,
    EmployerModule,
    JobseekerModule,
    // ScrollspyDirective
  ],
})
export class GeneralModuleModule {}
