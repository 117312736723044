import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
@Component({
  selector: 'app-js-education',
  templateUrl: './js-education.component.html',
  styleUrls: ['./js-education.component.scss'],
})
export class JsEducationComponent implements OnInit {
  educationForm: UntypedFormGroup;
  userDeatils: any;
  companyData: any;
  show: boolean = false;
  educationWholeRecord: any = [];
  index: any;
  toDay: any = new Date();
  Year = [
    '1980',
    '1981',
    '1982',
    '1983',
    '1984',
    '1985',
    '1986',
    '1987',
    '1988',
    '1989',
    '1990',
    '1991',
    '1992',
    '1993',
    '1994',
    '1995',
    '1996',
    '1997',
    '1998',
    '1999',
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
  ];
  uuidValue: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<JsEducationComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    // console.log(this.data)
    this.validationForm();
    if (this.data['data'] != 'NEW' && this.data['data'] != 'confirm') {
      this.patchValue();
      this.show = true;
      this.educationWholeRecord = this.data['wholeData'];
      this.index = this.educationWholeRecord.findIndex(
        (id) => id.id == this.data['data'].id
      );
      // console.log(this.index);
    }
    if (this.data['data'] == 'confirm') {
      this.uuidValue = UUID.UUID();
      this.educationWholeRecord = this.data['wholeData'];
    }
  }
  // Validation Controls //
  get f() {
    return this.educationForm.controls;
  }

  validationForm() {
    this.educationForm = this.fb.group({
      DegreeCourseName: ['', Validators.required],
      Universityinstitute: ['', Validators.required],
      Endyear: ['', Validators.required],
    });
  }

  patchValue() {
    let patchData = {
      DegreeCourseName: this.data['data'].courseName,
      Universityinstitute: this.data['data'].universityName,
      Endyear: String(this.data['data'].endYear),
    };
    this.educationForm.patchValue(patchData);
  }

  saveEducation() {
    if (this.educationForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.educationForm);
      return false;
    }
    if (this.data['data'] == 'confirm') {
      this.educationWholeRecord.push({
        id: this.uuidValue,
        createdAt: this.toDay.toISOString(),
        courseName: this.educationForm.get('DegreeCourseName').value,
        universityName: this.educationForm.get('Universityinstitute').value,
        endYear: Number(this.educationForm.get('Endyear').value),
      });
      // console.log(this.educationWholeRecord)
      this.dialogRef.close(this.educationWholeRecord);
    } else {
      let jsonData = {
        path: 'add-education',
        id: this.userDeatils.id,
        courseName: this.educationForm.get('DegreeCourseName').value,
        universityName: this.educationForm.get('Universityinstitute').value,
        endYear: Number(this.educationForm.get('Endyear').value),
      };
      this.io._JSaddEducation(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }
  }

  updateEducation() {
    if (this.educationForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.educationForm);
      return false;
    }
    if (this.data['confirm'] == 'confirmEducation') {
      this.educationWholeRecord[this.index].courseName =
        this.educationForm.get('DegreeCourseName').value;
      this.educationWholeRecord[this.index].universityName =
        this.educationForm.get('Universityinstitute').value;
      this.educationWholeRecord[this.index].endYear = Number(
        this.educationForm.get('Endyear').value
      );
      this.dialogRef.close(this.educationWholeRecord);
    } else {
      this.educationWholeRecord[this.index].courseName =
        this.educationForm.get('DegreeCourseName').value;
      this.educationWholeRecord[this.index].universityName =
        this.educationForm.get('Universityinstitute').value;
      this.educationWholeRecord[this.index].endYear = Number(
        this.educationForm.get('Endyear').value
      );
      // this.educationWholeRecord.splice(this.index, 1);
      let jsonData = {
        path: 'edit-education',
        id: this.userDeatils.id,
        educations: this.educationWholeRecord,
      };
      // console.log(jsonData);
      this.io._JSeditEducation(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }
  }

  removeEducation() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      //height: '400px',
      width: '400px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        if (this.data['confirm'] == 'confirmEducation') {
          this.educationWholeRecord.splice(this.index, 1);
          this.dialogRef.close(this.educationWholeRecord);
        } else {
          this.educationWholeRecord.splice(this.index, 1);
          let jsonData = {
            path: 'edit-education',
            id: this.userDeatils.id,
            educations: this.educationWholeRecord,
          };
          // console.log(jsonData);
          this.io._JSeditEducation(jsonData).subscribe((res: any) => {
            if (res.serverResponse.code == 200) {
              // console.log(res);
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
              this.dialogRef.close('save');
            }
          });
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
