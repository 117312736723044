<div class="sign-lt">
  <!-- <p class="sub-heading">Provide your RC number to become a verified employer on 
        WorkNigeria. <a href="javascript:void(0);" >What’s this?</a> </p> -->
  <div class="form-wrap">
    <form [formGroup]="completeRegiEmplyer" autocomplete="off">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>First Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="First Name"
            required="required"
            formControlName="firstName"
          />
        </mat-form-field>
        <div *ngIf="f.firstName.touched && f.firstName.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.firstName.errors.required"
            >First Name is required
          </span>
          <!-- <span class="text-left text-danger pb-1" *ngIf="f.password.errors?.pattern">You must use
                    upper
                    or lower case text,numbers & The special characters ~ ! % * _ - [ ] ? , .
                </span> -->
        </div>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Last Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Last Name"
            required="required"
            formControlName="lastName"
          />
        </mat-form-field>
        <div *ngIf="f.lastName.touched && f.lastName.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.lastName.errors.required"
            >Last Name is required
          </span>
          <!-- <span class="text-left text-danger pb-1" *ngIf="f.password.errors?.pattern">You must use
                    upper
                    or lower case text,numbers & The special characters ~ ! % * _ - [ ] ? , .
                </span> -->
        </div>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Company name </mat-label>
          <input
            type="text"
            matInput
            placeholder="Company name "
            required="required"
            formControlName="companyName"
          />
        </mat-form-field>
        <div *ngIf="f.companyName.touched && f.companyName.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.companyName.errors.required"
            >Company name is required
          </span>
          <!-- <span class="text-left text-danger pb-1" *ngIf="f.password.errors?.pattern">You must use
                    upper
                    or lower case text,numbers & The special characters ~ ! % * _ - [ ] ? , .
                </span> -->
        </div>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Password</mat-label>
          <input
            type="password"
            matInput
            placeholder="Password"
            required="required"
            formControlName="password"
            [pattern]="passwordPattern"
            [type]="showPassword ? 'text' : 'password'"
          />
          <mat-icon
            matSuffix
            (click)="togglePasswordVisibility()"
            style="cursor: pointer"
            >{{ showPassword ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <div *ngIf="f.password.touched && f.password.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.password.errors.required"
            >Password is required
          </span>
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.password.errors?.pattern"
            >You must use at least one upper case letter, one lower case letter, one number, and one special character. The password should be at least 8 characters long
          </span>
        </div>
      </div>

      <div class="form-group">
        <input
          type="submit"
          value="Sign up"
          class="btn btn-green w-100"
          (click)="completeRegistrasion()"
        />
      </div>
      <div class="form-group text-center">
        <button class="btn-back" (click)="signUP()">Back</button>
      </div>
    </form>
  </div>
</div>
