<span *ngIf="this.userDetails.userRole == 'company_admin'">
  <span
    *ngIf="!this.companyData.cacVerified"
  >
    <div class="banner-notification" style="margin-top: 47px">
      <div class="banner-notify">
        <p>
          <b
            >Get verified to start posting Jobs for free. Click here
            to get</b
          >&nbsp;<a href="javascript:void(0);" class="verified" (click)="verification()">verified.</a>
        </p>
      </div>
    </div>
  </span>
  <span *ngIf="this.companyData.approvedStatus == 'pending'">
    <div class="banner-notification">
      <div class="banner-notify">
        <p><b> Verfication request has been submitted !</b></p>
      </div>
    </div>
  </span>
</span>
<main
  class="new-screen emp-dashboard-outer common-padd-small pt-0 common-padd-lt-rt"
>
  <!-- Tabs -->
  <section id="tabs" class="emp-dashboard common-padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 ten">
          <div class="emp-dashboard-bnr">
            <img src="../../../../assets/images/dashboard-banner.webp" />
          </div>
          <!-- <span *ngIf="this.userDetails.userRole == 'company_admin'">
                        <span
                            *ngIf=" this.companyData.approvedStatus == 'notApply' || this.companyData.approvedStatus == 'rejected'">
                            <div class="Verified-employers">
                                <h4> Companies that get verified receive more applications. Click here to get<a
                                        href="javascript:void(0);" style="color:#FC6748"
                                        (click)="verification()"> Verified!</a>
                                </h4>
                            </div>
                        </span>
                    </span> -->
        </div>
        <div class="col-lg-12">
          <div class="emp-dashboard-box">
            <mat-tab-group
              #tabs
              [(selectedIndex)]="selectedTabIndex"
              mat-align-tabs="center"
            >
            <mat-tab label="DASHBOARD">
              <app-emp-dashboard-dash></app-emp-dashboard-dash>
            </mat-tab>
            <!-- <mat-tab label="JOBS">
                <app-emp-jobs></app-emp-jobs>
              </mat-tab> -->

              <mat-tab *ngIf="this.userDetails.userRole == 'company_admin'">
                <!-- <app-emp-company></app-emp-company>*ngIf="this.companyData.approvedStatus == 'approved'" -->
                <!-- *ngIf="job.companyApprovedStatus =='approved'" class="verifi-icon"><img src="../../../../assets/images/Verification-icon.png"></span> -->
                <ng-template mat-tab-label>
                  COMPANY
                  <mat-icon
                    class="verifi-icon"
                    *ngIf="this.companyData.approvedStatus == 'approved'"
                    ><img
                      src="../../../../assets/images/Verification-icon.png"
                    />
                  </mat-icon>
                </ng-template>
                <app-emp-company></app-emp-company>
              </mat-tab>

              <mat-tab
                *ngIf="this.userDetails.userType == 'super_admin'"
                label="COMPANY"
              >
                <app-companies-for-super-admin></app-companies-for-super-admin>
              </mat-tab>

              <mat-tab
                *ngIf="
                  this.userDetails.userRole == 'company_admin' ||
                  this.userDetails.userType == 'super_admin'
                "
                label="USER MANAGEMENT"
              >
                <app-emp-user-management></app-emp-user-management>
              </mat-tab>
              <mat-tab [disabled]="true">
                <ng-template mat-tab-label>
                  <div>
                    <img src="../../../../assets/images/wallet-ic.svg"/>
                    <span class="wallet-amount">&nbsp;NGN {{ this.auth.getUserBalance() | number : "1.1-1" }}&nbsp;&nbsp;&nbsp;</span>
                    <button class="btn-fund-wallet" (click)="navigateToFundWallet()" *ngIf="!userDetails.isSuperAdmin">Fund Wallet</button>
                    <img style="margin-left: 20px;" src="../../../../assets/images/campaign-top.svg"/>
                    <span class="wallet-amount">&nbsp;{{ this.cvBalance }}&nbsp;&nbsp;&nbsp;</span>
                    <img style="margin-left: 20px;" src="../../../../assets/images/cv-top.svg"/>
                    <span class="wallet-amount">&nbsp;{{ this.campaignBalance }}&nbsp;&nbsp;&nbsp;</span>
                  </div>
                    <!-- <span class="wallet-title">Available Balance</span> -->
                </ng-template>
              </mat-tab>

            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ./Tabs -->
</main>
