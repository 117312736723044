<div class="job-overview-container">
<div class="overview-job-row" *ngIf="!show">
    <form [formGroup]="dashBoardDetails">
        <div class="select-wrap">
            <div class="select-row">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label class="search-jobs">Search Jobs <i class="fas fa-search mt-1"></i></mat-label>
                    <input type="text" matInput placeholder="" formControlName="title"
                        (keyup)="onKey($event)" />
                </mat-form-field>
            </div>
            <div class="btn-set">
                <a href="javascript:void(0);" *ngIf="clearButton" class="gray-btn" (click)="resetFrom()">Clear</a>&nbsp;
                <a href="javascript:void(0);" *ngIf="!show" class="btn border" (click)="exportAllJobs()"><img
                        src="../assets/images/el.png" alt="" /></a>
            </div>
        </div>
    </form>

    <button class="btn-post-job" [ngClass]="userDeatils.isSuperAdmin ? 'btn-post-job-not': 'btn-post-job'" (click)="routeToAddJob()"> <img src="../../../../../../../assets/images/plus-icon.png" 
            class="plus-icon" /> Post a Job</button>
</div>

<div *ngIf="!show" class="job-overview-div">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z8">

        <!-- VIEWS -->
        <ng-container matColumnDef="viewed">
            <th mat-header-cell *matHeaderCellDef>
                VIEW
            </th>
            <td mat-cell *matCellDef="let element"> {{element.jobViewData?.viewed || element?.viewed || 0}} </td>
        </ng-container>

        <!-- APPLIED -->
        <ng-container matColumnDef="applied">
            <th mat-header-cell *matHeaderCellDef>
                APPLY
            </th>
            <td mat-cell *matCellDef="let element"> {{element.jobViewData?.applied || element?.applied || 0}} </td>
        </ng-container>

        <!-- JOB TITLE -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                JOB TITLE
            </th>
            <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <!-- JOB TYPE -->
        <ng-container matColumnDef="location_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                JOB TYPE
            </th>
            <td mat-cell *matCellDef="let element"> {{transformData(element.location_type)}} </td>
        </ng-container>

        <!-- APPLY VIA -->
        <ng-container matColumnDef="apply_via">
            <th mat-header-cell *matHeaderCellDef>
                CHANNEL
            </th>
            <td mat-cell *matCellDef="let element"> {{applyViaText(element.apply_via)}} </td>
        </ng-container>

        <!-- DATE CREATED -->
        <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                DATE
            </th>
            <td mat-cell *matCellDef="let element"> {{formatDateString(element.created_at)}} </td>
        </ng-container>

        <!-- POSTED BY -->
        <ng-container matColumnDef="providerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                POSTED BY
            </th>
            <td mat-cell *matCellDef="let element"> {{element.providerName}} </td>
        </ng-container>

        <!-- ACTIONS -->
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>
                ACTION
            </th>
            <td mat-cell *matCellDef="let element">
                <ul>
                    <li>
                        <a class="action-clicks" matTooltip="View Job" matTooltipPosition="above"
                            matTooltipShowDelay="100" (click)="getAppliedJobseekerDetails(element)"><img
                                class="action-icons" src="../../../../../../../assets/images/viewJob.png" /></a>
                    </li>
                    <li>
                        <a class="action-clicks" matTooltip="Share Job" matTooltipPosition="above"
                            matTooltipShowDelay="100" (click)="shareJob(element)"><img class="action-icons"
                                src="../../../../../../../assets/images/shareJob.png" /></a>
                    </li>
                    <li>
                        <a class="action-clicks" matTooltip="Promote Job" matTooltipPosition="above"
                            matTooltipShowDelay="100" (click)="promoteJob()"><img class="action-icons"
                                src="../../../../../../../assets/images/promoteJob.png" /></a>
                    </li>
                    <li>
                        <a class="action-clicks" matTooltip="Edit Job" matTooltipPosition="above"
                            matTooltipShowDelay="100" (click)="routeToEditJob(element.id)"><img class="action-icons"
                                src="../../../../../../../assets/images/editJob.png" /></a>
                    </li>
                </ul>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator 
        *ngIf="this.jobsList?.length > 0" 
        [length]="this.pagination?.total"
        (page)="onPageChanged($event)"
        [pageIndex]="this.currentPage"
        [pageSize]="10"
        showFirstLastButtons>
    </mat-paginator>
    <div class="no-job-found" *ngIf="jobsList?.length == '0'">
        <h4>No job found</h4>
    </div>
</div>

<div *ngIf="show">
    <app-applied-jobseeker [appliedJobseeker]="this.appliedJobseekerDetails"
        [selectedJobId]="this.selectedJobByEmployer" [selectedJob]="this.selectedJob"></app-applied-jobseeker>
</div>
</div>