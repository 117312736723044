<div class="container">
  <div class="glow-card">
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-3 my-auto">
            <img class="wallet-image" src="assets/images/gold-image.png" width="92px" />
          </div>
          <div class="col-lg-6 my-auto">
            <div class="wallet-title">Available Balance</div>
            <div class="wallet-amount">N{{ currentBalance || 0 | number : "1.1-1" }}</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 my-auto">
        <div class="top-row">
          <button class="float-right">Fund Wallet</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="transactionHistory.length !== 0">
  <div class="top-row-2">
    <div class="float-left">Payment History</div>

    <div class="inputs">
      <div class="sort-text">Sort by Date</div>
      <mat-form-field appearance="outline" class="custom-form-field">
        <mat-label>Label</mat-label>
        <input matInput placeholder="Placeholder" />
      </mat-form-field>
    </div>
  </div>
  <div class="bottom-row-2">
    <div class="well-views table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Price</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let campaign of transactionHistory">
            <td class="table-cols">
              <div class="card-type-name">
                <img *ngIf="campaign.cardType === 'Visa'" src="../../../../../../assets/images/visa-icon.png"
                  class="card-type" />
                <img *ngIf="campaign.cardType === 'Mastercard'"
                  src="../../../../../../assets/images/mastercard-icon.png" class="card-type" />
                {{campaign.cardName}}
              </div>
            </td>
            <td class="table-cols">{{correctDayDate(campaign.createdAt)}}</td>
            <td class="table-cols">{{campaign.currency}}{{campaign.paymentAmount}}</td>
            <td>
              <div class="curved-pill" *ngIf="campaign.status === 'Paid'">
                <span class="pill-text">successful</span>
              </div>
              <div class="curved-pill" *ngIf="campaign.status === 'Unpaid'">
                <span class="pill-text">unsuccessful</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" *ngIf="transactionHistory.length !== 0">
        <span class="pagination-text">
          Showing
          {{ walletPagination.start + 1 }} to
          {{ walletPagination.start + transactionHistory.length }}
          of {{ walletPagination.total }} results
        </span>
        <ngb-pagination
          [page]="currentWalletPage"
          (pageChange)="onWalletPageChanged($event)"
          [pageSize]="walletPagination.size"
          [ellipses]="true"
          [maxSize]="5"
          [rotate]="true"
          [collectionSize]="walletPagination.total"
        ></ngb-pagination>
      </div>
      <div class="container" *ngIf="transactionHistory.length === 0">
        <div class="bottom-row">
          <div class="row">
            <img src="assets/images/no-campaign-icon.svg" alt="" />
          </div>
          <div class="row">
            <p class="no-campaign-text">
              You have no transaction history, click “Fund Wallet” to put money in
              your wallet
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>