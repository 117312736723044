<div class="tab-content py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active" id="jobs">
    <!-- <button *ngIf="show" (click)="onclick()">back</button> -->
    <div class="btnrightadd">
      <div class="brd-crmb">
        <a
          href="javascript:void(0);"
          class="job-oberview-back"
          (click)="onclick()"
          *ngIf="show"
          >Job overview >> <span>{{ selectedJob.title }}</span>
        </a>
      </div>
      <div class="btn-set">
        <a
          href="javascript:void(0);"
          *ngIf="clearButton"
          class="gray-btn"
          (click)="resetFrom()"
          >Clear</a
        >&nbsp;
        <a
          href="javascript:void(0);"
          *ngIf="!show"
          class="btn border"
          (click)="exportAllJobs()"
          ><img src="../assets/images/el.png" alt=""
        /></a>
        <a
          href="javascript:void(0);"
          (click)="routeToAddJob()"
          class="btn btn-green"
        >
          Add job
        </a>
      </div>
    </div>

    <div class="overview-job-row">
      <h3 *ngIf="!show">Job overview</h3>

      <form [formGroup]="dashBoardDetails">
        <div *ngIf="!show" class="select-wrap">
          <div
            class="select-row"
            *ngIf="this.userDeatils.userType == 'super_admin'"
          >
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label style="color: black"
                ><i class="fas fa-search"></i> Companies</mat-label
              >
              <input
                type="text"
                aria-label="Number"
                matInput
                formControlName="companies"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                (optionSelected)="changeCompany($event.option.value)"
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option.companyName"
                >
                  {{ option.companyName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="select-row">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label><i class="fas fa-search"></i> What</mat-label>
              <input
                type="text"
                matInput
                placeholder="Search text"
                formControlName="title"
                (keyup)="onKey($event)"
              />
            </mat-form-field>
          </div>

          <!-- <div class="select-row">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Date posted</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="created_at" (dateChange)="filterFrom($event)">
              <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div> -->

          <div class="select-row">
            <mat-form-field
              class="example-full-width selectbox"
              appearance="fill"
            >
              <mat-label>Status</mat-label>
              <mat-select
                formControlName="job_status"
                (selectionChange)="changeJobType($event)"
              >
                <mat-option value="{{ row }}" *ngFor="let row of jobTypeList"
                  >{{ row[0].toUpperCase() + row.slice(1) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            class="select-row"
            *ngIf="
              this.userDeatils.userRole == 'company_admin' ||
              this.userDeatils.userType == 'super_admin'
            "
          >
            <mat-form-field
              class="example-full-width selectbox"
              appearance="fill"
            >
              <mat-label>Posted By</mat-label>
              <mat-select
                formControlName="provider_id"
                (selectionChange)="changePostedBy($event)"
              >
                <mat-option
                  value="{{ row.id }}"
                  *ngFor="let row of postedByList"
                  >{{
                    row.firstName[0].toUpperCase() + row.firstName.slice(1)
                  }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>

    <!-- <button mat-raised-button (click)="openDialog()">Pick one</button> -->
    <!-- <input type="text" (keyup)="onKeypressEvent($event)"> -->
    <!-- <select class="selectbox" name="demo" id="status_dropdown" label="status" #status_dropdown
        (change)="filterJobs(status_dropdown.value)">
        <option value="All">All</option>
        <option *ngFor="let status of statusList" [value]="status">
          {{status}}
        </option>
      </select> -->

    <div *ngIf="!show" class="well-views table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Views</th>
            <th>Applications</th>
            <!-- <th>Job title <i class="fas fa-arrow-down"></i></th> -->
            <th>Job title</th>
            <th>Job type</th>
            <th>Channel of application</th>
            <th>Posted on</th>
            <th
              *ngIf="
                this.userDeatils.userRole == 'company_admin' ||
                this.userDeatils.userType == 'super_admin'
              "
            >
              Posted by
            </th>
            <th>Status</th>
            <th *ngIf="this.userDeatils.userType == 'super_admin'">
              Is Feature
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngFor="let job of jobsList" (click)="openDialog(job)"> -->
          <tr *ngFor="let job of jobsList; let i = index">
            <td
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{ job.jobViewData.viewed }}
            </td>
            <td
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{ job.jobViewData.applied }}
            </td>
            <td
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{ job.title }}
            </td>
            <td
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{ job.job_type }}
            </td>
            <td
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{
                job.apply_via == "1"
                  ? "applied on
              WorkNigeria"
                  : job.apply_via == "2"
                  ? "applied on external"
                  : "applied
              via email"
              }}
            </td>
            <td
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{ job.created_at | date : "short" }}
            </td>
            <td
              *ngIf="
                this.userDeatils.userRole == 'company_admin' ||
                this.userDeatils.userType == 'super_admin'
              "
              (click)="getAppliedJobseekerDetails(job)"
              style="cursor: pointer"
            >
              {{ job.providerName }}
            </td>
            <td style="cursor: pointer">
              <mat-slide-toggle
                [checked]="job.job_status == 'active' ? true : false"
                (click)="activeInactive(job, i)"
              >
                {{ job.job_status == "active" ? "Active" : "Deactive" }}
              </mat-slide-toggle>
            </td>
            <td *ngIf="this.userDeatils.userType == 'super_admin'">
              <mat-slide-toggle
                [checked]="job.is_featured && job.is_featured == true"
                (click)="feature(job)"
              >
              </mat-slide-toggle>
            </td>
          </tr>
        </tbody>

        <!-- <tbody *ngIf="jobsList.length == '0'">
          
        </tbody> -->
      </table>
      <!-- <p style="text-align:right ; font-size: 20px; color: #3EAB70;cursor: pointer;" >load more ...</p> -->

      <div *ngIf="this.jobsList.length > '0'" style="text-align: right">
        <button
          class="btn btn-green"
          *ngIf="startJobIndex != '0'"
          mat-raised-button
          (click)="loadData('previous')"
        >
          previous</button
        >&nbsp;
        <button
          class="btn btn-green"
          *ngIf="this.nextButtonflag"
          mat-raised-button
          (click)="loadData('next')"
        >
          next
        </button>
      </div>
      <!-- <div  style="text-align:right ">
        <button  class="btn btn-green"  mat-raised-button (click)="loadData('previous')">previous</button>&nbsp;
        <button class="btn btn-green"  mat-raised-button (click)="loadData('next')">next</button>
        
      </div> -->
    </div>
    <div class="no-job-found" *ngIf="jobsList.length == '0'">
      <h4>No job found</h4>
    </div>
  </div>
</div>

<div *ngIf="show">
  <app-applied-jobseeker
    [appliedJobseeker]="this.appliedJobseekerDetails"
    [selectedJobId]="this.selectedJobByEmployer"
    [selectedJob]="this.selectedJob"
  ></app-applied-jobseeker>
</div>
