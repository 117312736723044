import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { NewUserDialog } from './new-user-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EmployerService } from 'src/app/services/employer.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
@Injectable()
export class EmpService {
  constructor(private http: HttpClient) {}
  async commonService(rawData: any, token: string, path: string) {
    try {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });
      let options = { headers: headers };
      const body = rawData;
      const data = await this.http
        .post(`${environment.baseUrl}${path}`, body, options)
        .toPromise();
      return data;
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
    return 'Somthing wrong ';
  }
}

@Component({
  selector: 'app-emp-user-management',
  templateUrl: './emp-user-management.component.html',
  styleUrls: ['./emp-user-management.component.scss'],
  providers: [EmpService],
})
export class EmpUserManagementComponent implements OnInit {
  userToken: string;
  path: any = {
    getUserUrl: 'user/manage-users',
    deleteUserUrl: 'user/manage-users',
    createUser: 'user/manage-users',
    editUser: 'user/manage-users',
  };
  empList: any = [];
  companyDetails: any;
  loggedInUser: any;
  viewOnly: boolean = false;
  body: any = {};
  companies: any = '';
  CompaniesResult: any = [];
  selectCompany: any;
  filteredOptions: Observable<string[]>;
  userManagement: UntypedFormGroup;
  sortfuction: boolean = true;
  constructor(
    private EmpService: EmpService,
    private EmployerService: EmployerService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private helper: Helper
  ) {
    this.userToken = sessionStorage.getItem('workNigeriaToken');
    this.companyDetails = JSON.parse(
      atob(sessionStorage.getItem('workNigeriacompanyData'))
    );
    this.loggedInUser = JSON.parse(
      this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
    );
    if (this.loggedInUser.userRole == 'company_basic') {
      this.viewOnly = true;
    }
  }

  ngOnInit(): void {
    // console.log(this.loggedInUser.id)
    if (this.loggedInUser.userType != 'super_admin') {
      this.getUserList();
    } else {
      this.validationForm();
      this.getAllCompaniesList();
      this.filteredOptions =
        this.userManagement.controls.companies.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CompaniesResult.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  // Insilize Form //
  validationForm() {
    this.userManagement = this.fb.group({
      companies: [''],
    });
  }

  openDialog(employers, type) {
    if (this.loggedInUser.userType == 'super_admin') {
      if (employers === '' && this.selectCompany == undefined) {
        // this.snackBar.open("Please select a company first", "OK", {
        // });
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: 'Please select a company first',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        return false;
      }
    }

    const dialogRef = this.dialog.open(NewUserDialog, {
      width: '70%',
      autoFocus: false,
      data: {
        users: employers,
        type: type,
        superAdminSelectedCompany: this.selectCompany,
        autoFocus: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined && result.event == 'create') {
        this.createUser(result);
      }
      if (result != undefined && result.event == 'edit') {
        this.editUser(result);
        console.log(result);
      }
    });
  }

  viewUserDetails(employer) {
    this.openDialog(employer, 'view');
  }

  async getAllCompaniesList() {
    let jsonData = {
      path: 'get-company-list',
    };
    await this.EmployerService._getAllCompaniesList(jsonData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res['result']);
          this.CompaniesResult = res['result'].companyList;
        }
      }
    );
  }

  createUser(result) {
    this.empList.push(result.body);
    this.EmpService.commonService(
      result.body,
      this.userToken,
      this.path.createUser
    )
      .then((res) => {
        console.log(res);
        this.getUserList();
        this.toastMessage(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  toastMessage(res) {
    if (res['serverResponse']['code'] == 200) {
      // this.snackBar.open(`${res['serverResponse']['message']}`, 'OK', {});
      this.snackBar
        .openFromComponent(DynamicSnacbarComponent, {
          data: res['serverResponse']['message'],
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    } else {
      // this.snackBar.open(`${res['serverResponse']['message']}`, 'OK', {});
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: res['serverResponse']['message'],
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  changeCompany(event) {
    console.log(event);
    // this.selectCompany = '';
    // this.selectCompany = event.value;
    // this.getUserList()
    this.selectCompany = '';
    this.selectCompany = this.CompaniesResult.find(
      (x) => x.companyName == event
    );
    this.getUserList();
  }

  getUserList() {
    if (this.loggedInUser.userType != 'super_admin') {
      this.body = {
        path: 'get-users',
        companyId: this.loggedInUser.companyId,
      };
    } else {
      this.body = {
        path: 'get-users',
        companyId: this.selectCompany.id,
      };
    }
    this.EmpService.commonService(
      this.body,
      this.userToken,
      this.path.getUserUrl
    )
      .then((res) => {
        this.empList = res['userData'];
        // console.log(res['userData'])
        this.empList = this.empList.filter(
          (item) => item.id !== this.loggedInUser.id
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }

  sortName() {
    // this.empList = this.empList.sort((a,c)=>a>c?1:-1);
    //     this.empList.forEach(element => {
    //       console.log(element);
    //       this.empList.sort((a, c) => a > c ? 1 : -1);
    //     });
    //     // console.log(  this.empList);
    //     for (let i = 0; i < this.empList.length; i++) {
    //       this.empList =this.empList.sort((this.empList[i].firstName, this.empList[i+1].firstName) => this.empList[i].firstName > this.empList[i+1].firstName ? 1 : -1);
    // }
    // this.sortfuction = true

    if (this.sortfuction == true) {
      this.empList = this.empList.sort((a, b) =>
        a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
      );
      this.sortfuction = !this.sortfuction;
    } else {
      this.empList = this.empList.sort((a, b) =>
        b.firstName > a.firstName ? 1 : a.firstName > b.firstName ? -1 : 0
      );
      this.sortfuction = !this.sortfuction;
    }

    // console.log( this.sortfuction);
  }

  editUser(result) {
    this.empList = this.empList.filter((user) => {
      return user.id != result.body.id;
    });
    this.empList.push(result.body);
    this.EmpService.commonService(
      result.body,
      this.userToken,
      this.path.editUser
    )
      .then((res) => {
        console.log(res);
        this.getUserList();
        this.toastMessage(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  deleteUser(emp: any) {
    if (confirm(`Are you sure to delete  (${emp.firstName} ${emp.lastName})`)) {
      this.confirmDeleteUser(emp);
    }
  }

  confirmDeleteUser(employer) {
    this.empList = this.empList.filter((user) => {
      return user.id != employer.id;
    });
    console.log(this.empList);
    var body = {
      path: 'delete-user',
      id: employer.id,
    };
    this.EmpService.commonService(body, this.userToken, this.path.deleteUserUrl)
      .then((res) => {
        console.log(res);
        this.toastMessage(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  activeUser(data) {
    let jsonData = {
      path: 'user-active-deactive',
      action: 'active',
      userIds: [data.id],
    };
    this.EmployerService._activeAndDeactiveUsers(jsonData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          // this.snackBar.open(res.serverResponse.message, "OK", {
          // })
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.getUserList();
        }
      }
    );
  }

  deactiveUser(data) {
    let jsonData = {
      path: 'user-active-deactive',
      action: 'deactive',
      userIds: [data.id],
    };
    this.EmployerService._activeAndDeactiveUsers(jsonData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.getUserList();
        }
      }
    );
  }
}
