import { Component, OnInit, ɵɵqueryRefresh } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SavedjobComponent } from '../../job-search/savedjob/savedjob.component';
import { SharejobComponent } from '../../job-search/sharejob/sharejob.component';
import { Title } from '@angular/platform-browser';
import { stringify } from 'querystring';
import { Subject } from 'rxjs';
import { Observable, Subscription, interval } from 'rxjs';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { RenameCollectionComponent } from '../rename-collection/rename-collection.component';
import { ReportJobComponent } from '../../job-search/report-job/report-job.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';

@Component({
  selector: 'app-jobseeker-applied',
  templateUrl: './jobseeker-applied.component.html',
  styleUrls: ['./jobseeker-applied.component.scss'],
})
export class JobseekerAppliedComponent implements OnInit {
  status: boolean = false;
  nojob: boolean = true;
  jobLists: any;
  detailsOfJob: any;
  shareJobId: any = '';
  shareJobCollection: any;
  searchDipendency: any;
  xxxxx: boolean = false;
  userDeatils: any;
  companyData: any;
  filterData: any;
  refresh: Subject<any> = new Subject();
  collectionRecords: any;
  selectedCollectionID: any;
  selectedCollectionName: any;
  ByDefaultSelectedCollection: any;
  payload: any = {};
  buttonDisable: boolean = false;
  showRightSectionInMobile: boolean = false;
  isExpriedButton: boolean;
  dateDiff: number;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private dialog: MatDialog,
    private titleService: Title
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.appliedJOb();
  }

  // CLICK ON JOB LIST //
  jobDetails(i, job) {
    console.log(job);
    this.showRightSectionInMobile = true;
    this.status = job.id;
    this.detailsOfJob = this.jobLists[i];
    this.jobExpried(this.detailsOfJob);
  }

  jobExpried(job) {
    if (job.expired_at) {
      this.dateDiff = this.helper.calculateDiff(
        new Date(job.expired_at),
        new Date()
      );
      // console.log(dateDiff)
      if (this.dateDiff >= 0) {
        this.isExpriedButton = false;
      } else {
        this.isExpriedButton = true;
      }
    }

    // var hours = Math.abs(new Date(job.expired_at).getTime() - new Date().getTime()) / 36e5;
    // console.log(hours)
  }

  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    if (dateDiff === 0) {
      return 'today';
    } else if (dateDiff === 1) {
      return 'yesterday';
    } else {
      return `${dateDiff} days ago`;
    }
  }
  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }
  // Saved job //
  savedJob(job) {
    if (job.isSaved == true) {
      let jsonData = {
        path: 'unsaved-job',
        jobId: job.id,
        id: this.userDeatils.id,
      };
      //  console.log(jsonData);
      this.io._unSaveJob(jsonData).subscribe((res: any) => {
        //  console.log(res);
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);

          this.appliedJOb();
        }
      });
    } else {
      const dialogRef = this.dialog.open(SavedjobComponent, {
        height: '450px',
        width: '450px',
        data: { data: job },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          this.appliedJOb();
        }
      });
    }
  }

  // Share job //
  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: '450px',
      width: '480px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  alreadyApply() {
    // this.snackBar.open("You have Already Applied", "OK")
    this.snackBar
      .openFromComponent(DynamicSnacbarSkipComponent, {
        data: 'You have Already Applied',
        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);
  }

  appliedJOb() {
    let data = {
      path: 'applied-jobs',
      userId: this.userDeatils.id,
    };
    this.io._appliedJobs(data).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.jobLists = res['result'].jobList;
        // console.log(this.jobLists)
        if (this.jobLists.length == 0) {
          this.nojob = false;
        }
        if (this.jobLists.length > 0) {
          this.detailsOfJob = this.jobLists[0];
          this.status = this.jobLists[0].id;
          this.jobExpried(this.detailsOfJob);
        }
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  reportModel(Perticularjob: any) {
    const dialogRef = this.dialog.open(ReportJobComponent, {
      height: 'auto',
      width: '450px',
      data: { Perticularjob },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        // this.ngOnInit();
      }
    });
  }
}
