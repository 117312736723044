import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CompleteRegistrationComponent } from '../../complete-registration/complete-registration.component';
import { LoginComponent } from '../../login/login.component';
import { HttpIOService } from '../../../../services/http-io.service';
import { Helper } from '../../../../helper/defult';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { SocialsignUp } from 'src/app/models/signUpsocial/signUpSocial';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { EmailNotificationComponent } from '../../email-notification/email-notification.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sign-up-jon-seeker',
  templateUrl: './sign-up-jon-seeker.component.html',
  styleUrls: ['./sign-up-jon-seeker.component.scss'],
})
export class SignUPJonSeekerComponent implements OnInit, OnDestroy {
  authSubscription!: Subscription;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  jobSeekerSignup: UntypedFormGroup;
  timeZone: any;
  user: SocialUser;
  // userSocialLogin: SocialsignUp;
  userSocialLogin: any;

  email: any;
  role: any;
  emailValue: any;
  userDataForTwitter: any;
  twitteroauthtoken: any;
  twitteroauthverifier: any;
  googleValue: any;
  fbValue: any;
  GuestUserjobId: any;
  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    @Inject(MatSnackBar) private snackBar: MatSnackBar,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    private socialauthService: SocialAuthService,
    private _authService: AuthService
  ) {
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.GuestUserjobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.role = params['_role'];
    });

    this.twitteroauthtoken = this.route.snapshot.queryParams['oauth_token'];
    this.twitteroauthverifier =
      this.route.snapshot.queryParams['oauth_verifier'];

    if (this.twitteroauthtoken && this.twitteroauthverifier) {
      this.fetchDataFromtwitter();
    }
  }

  ngOnInit(): void {
    this.formInsli();
    this.authSubscription = this.socialauthService.authState.subscribe((user) => {
      if(user){
        this.user = user;
        switch(user.provider){
          case 'GOOGLE':
            this.signInWithgoogle()
            break
        }
      }else{
        this.user = null
      }
    });
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }

  googleSignup(googleWrapper: any) {
    googleWrapper.click();
  }

  // Create Modal Driven Form //
  formInsli() {
    this.jobSeekerSignup = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });

    if (this.email && this.role == 'jobseeker') {
      this.jobSeekerSignup.patchValue({
        email: this.email,
      });
    }
  }

  // Validation Controls //
  get f() {
    return this.jobSeekerSignup.controls;
  }

  // Next Step Of SignUp //
  async completeRegistration() {
    if (this.jobSeekerSignup.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.jobSeekerSignup);
      return false;
    }
    var emailVeryfy: any = await this.helper.emailVerify(
      this.jobSeekerSignup.value.email
    );
    // console.log(emailVeryfy)
    if (
      emailVeryfy.serverResponse.code == 200 &&
      emailVeryfy.serverResponse.message == 'Email available to use.'
    ) {
      this.router.navigate(['/complete-registration'], {
        queryParams: {
          role: 'jobseeker',
          email: this.jobSeekerSignup.value.email,
        },
      });

      // this.snackBar.open(emailVeryfy.serverResponse.message, "OK", {
      // })
    } else {
      // this.snackBar.open(emailVeryfy.serverResponse.message, "OK", {
      // })
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: emailVeryfy.serverResponse.message,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  login() {
    this.router.navigate(['/login']);
  }

  // SIGNIN WITH FACEBOOK //
  signInWithFB(): void {
    this.socialauthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((fbResponse: any) => {
        if (!fbResponse.email) {
          const dialogRef = this.dialog.open(EmailNotificationComponent, {
            height: 'auto',
            width: '450px',
            hasBackdrop: false,
            data: {},
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res) {
              // console.log(res);
              this.fbValue = res;
              // this.loginWithSocialTwitter();
            }
          });
          return false;
        }
        this.userSocialLogin = {
          socialId: fbResponse.id,
          email: fbResponse.email ? fbResponse.email : this.fbValue,
          socialType: 'facebook',
          userType: 'jobseeker',
          path: 'social-signup',
          firstName: fbResponse.firstName,
          lastName: fbResponse.lastName,
          profileImage: fbResponse.response.picture.data.url
            ? fbResponse.response.picture.data.url
            : '',
        };
        this.io
          .apicall(this.userSocialLogin, 'auth/social-signup')
          .subscribe((res: any) => {
            if (res.serverResponse.code == 200) {
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: 'Your account has successfully been created.',
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
              this.router.navigate(['/login']);
              // this.dialogRef.close();
              // let userdata = res['result'].userData
              // let token = res['result'].token
              // let companyData = res['result'].companyData

              // this._authService.sendToken(token);
              // this._authService.setCurrentUserDetails(userdata);
              // this._authService.setCurrentCompanyData(companyData);
              // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
              //   height: 'auto',
              //   width: '450px',
              //   data: { message: res.serverResponse.message }
              // });
              // dialogRef.afterClosed().subscribe(res => {
              //   if (res === true) {
              //   }
              // });
              // this.snackBar.open(res.serverResponse.message, "OK", {
              // })
              // this.snackBar.openFromComponent(DynamicSnacbarComponent, {
              //   data: res.serverResponse.message
              // });
              // if (this.GuestUserjobId) {
              //   this.router.navigate(['/job-search'])
              // }else{
              //   this.router.navigate(['/home'])

              // }
            } else {
              // this.dialogRef.close();
              // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
              //   height: 'auto',
              //   width: '450px',
              //   data: { message: res.serverResponse.message }
              // });
              // dialogRef.afterClosed().subscribe(res => {
              //   if (res === true) {
              //   }
              // });
              this.snackBar
                .openFromComponent(DynamicSnacbarErrorComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          });
        // .catch(err => {
        //   if (!err.error.serverResponse) {
        //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
        //     //   height: 'auto',
        //     //   width: '450px',
        //     //   data: { message: 'Please check connectivity' }
        //     // });
        //     // dialogRef.afterClosed().subscribe(res => {
        //     //   if (res === true) {
        //     //   }
        //     // });
        //     this.snackBar.open('Please check connectivity', "OK", {
        //     })
        //   }
        //   else {
        //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
        //     //   height: 'auto',
        //     //   width: '450px',
        //     //   data: { message: err.error.serverResponse.message }
        //     // });
        //     // dialogRef.afterClosed().subscribe(res => {
        //     //   if (res === true) {
        //     //   }
        //     // });
        //     this.snackBar.open(err.error.serverResponse.message, "OK", {
        //     })
        //   }
        // })
      });
  }

  // SIGNIN WITH GOOGLE //
  signInWithgoogle(): void {
    this.userSocialLogin = {
      socialId: this.user.id,
      email: this.user.email ? this.user.email : this.googleValue,
      socialType: 'google',
      userType: 'jobseeker',
      path: 'social-signup',
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      profileImage: this.user.photoUrl
    };

    this.io
      .apicall(this.userSocialLogin, 'auth/social-signup')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your account has successfully been created.',
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/login']);
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  // Login With Linkedin //
  loginWithLinkedin() {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.linkedInCredentials.clientId}&redirect_uri=${environment.linkedInCredentials.redirectUrl}&state=signup&scope=profile,email,openid`;
  }


  // Login With Twitter //
  loginWithTwittwer() {
    this.io
      .apicall(
        {
          path: 'get-twitter-oauth-token',
          callbackUrl: `${environment.twitterDetails.callBackUrlForSignUp}`,
        },
        'auth/get-twitter-oauth-token'
      )
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          window.open(
            `https://api.twitter.com/oauth/authenticate?${res['result'].tokenBody.token}`,
            '_blank'
          );
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }

  fetchDataFromtwitter() {
    this.io
      .apicall(
        {
          path: 'user-data-from-twitter',
          oauthToken: this.twitteroauthtoken,
          oauthVerifier: this.twitteroauthverifier,
          processFollowed: 'signup',
        },
        'auth/user-data-from-twitter'
      )
      .subscribe((auth: any) => {
        if (auth.serverResponse.code == 200) {
          // this.loginWithSocialTwitter(res['result'].userData);
          // let userdata = auth['result'].userData
          // let token = auth['result'].token
          // let companyData = auth['result'].companyData

          //   this._authService.sendToken(token);
          //   this._authService.setCurrentUserDetails(userdata);
          //   this._authService.setCurrentCompanyData(companyData);
          if (this.userDataForTwitter.email) {
            this.loginWithSocialTwitter();
          }
          if (!this.userDataForTwitter.email) {
            const dialogRef = this.dialog.open(EmailNotificationComponent, {
              height: 'auto',
              width: '450px',
              hasBackdrop: false,
              data: {},
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res) {
                this.emailValue = res;
                this.loginWithSocialTwitter();
              } else {
                // this.snackBar.open('Please Enter Email Address','OK',{});
                this.snackBar
                  .openFromComponent(DynamicSnacbarSkipComponent, {
                    data: 'Please Enter Email Address',
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
                this.router.navigate(['/login']);
              }
            });
          }

          // console.log(res)
        } else {
          // this.snackBar.open(auth.serverResponse.message, "OK", {
          // })
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: auth.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }

  loginWithSocialTwitter() {
    // if(!this.emailValue) {
    //   const dialogRef = this.dialog.open(EmailNotificationComponent, {
    //     height: 'auto',
    //     width: '450px',
    //     data: {}
    //   });
    //   dialogRef.afterClosed().subscribe(res => {
    //     if (res) {
    //       console.log(res);
    //       this.emailValue = res
    //       this.loginWithSocialTwitter();

    //     }

    //   });
    //   return false;
    // }

    let payload = {
      socialId: this.userDataForTwitter.id,
      email: this.userDataForTwitter.email
        ? this.userDataForTwitter.email
        : this.emailValue,
      socialType: 'twitter',
      path: 'social-signup',
    };

    this.io.apicall(payload, 'auth/social-signup').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.router.navigate(['/login']);
        // let userdata = res['result'].userData
        // let token = res['result'].token
        // let companyData = res['result'].companyData

        //   this._authService.sendToken(token);
        //   this._authService.setCurrentUserDetails(userdata);
        //   this._authService.setCurrentCompanyData(companyData);

        // if (this.GuestUserjobId) {
        //   this.router.navigate(['/job-search'])
        // }else{
        //   this.router.navigate(['/home'])

        // }
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
    // .catch(err => {
    //   if (!err.error.serverResponse) {

    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {

    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //   }
    // })
  }
}
