import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { EmpDashPreviewComponent } from '../../employer/emp-dashboard/emp-dashboard-dash/emp-dash-preview/emp-dash-preview.component';
import { EmployerService } from 'src/app/services/employer.service';
import { DataService } from 'src/app/services/data.service';
import { IntroJSService } from 'src/app/services/introjs.service';



@Component({
  selector: 'app-employer-subscriptions',
  templateUrl: './employer-subscriptions.component.html',
  styleUrls: ['./employer-subscriptions.component.scss']
})
export class EmployerSubscriptionsComponent {
  transactionRef = uuidv4();
  userDetails: any;
  goldPlan: any;
  silverPlan: any;
  userPlan: any;
  purchasingPlan: string;
  paystackKey: string;
  campaignDetails: any;

  constructor(
    private helper: Helper,
    private auth: AuthService,
    private io: HttpIOService,
    @Inject(MatDialog) public dialog: MatDialog,
    private router: Router,
    private location: Location,
    private es: EmployerService,
    private dataService: DataService,
    private introService: IntroJSService
  ) {
    this.paystackKey = environment.paystack_key
    this.userPlan = {subscriptionPlan: ''}
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
      }
    });
    this.campaignDetails = this.location.getState()
  }

  ngOnInit(): void {
    this.dataService.getUserData().subscribe((data: any) => {
      if(data){
        this.userPlan = data.subscription.subscriptionPlan ? data.subscription : { subscriptionPlan: 'Bronze' };
      }else{
        this.loadUpdatedAssets()
      }
    });
    this.getAllPlans();
  }

  ngAfterViewInit(): void {
    if((localStorage.getItem('subscriptionsTour') ?? '0') === '0'){
      setTimeout(() => {
        this.introService.subscriptionAndBundles()
      }, 1000)
    }
  }

  private getPlanByName(plans: any[], name: string): any {
    return plans.find((plan: any) => plan.name === name);
  }

  getAllPlans(): void {
    this.io
      ._getAllPlans({})
      .subscribe(
        (res: any) => {
          if (res.serverResponse.code === 200) {
            this.goldPlan = this.getPlanByName(res.result, 'Gold');
            this.silverPlan = this.getPlanByName(res.result, 'Silver');
          }
        },
        (error: any) => {
          console.error('An error occurred:', error);
        }
      );
  }

  getUserPlan(): void {
    this.io
      ._getUserPlan()
      .subscribe(
        (res: any) => {
          console.log(res)
          if (res.serverResponse.code === 200) {
            this.userPlan = res.result ? res.result : { subscriptionPlan: 'Bronze' };
          } else {
            console.log(res);
          }
        },
        (error: any) => {
          console.error('An error occurred:', error);
        }
      );
  }

  cancelSubscription(): void {
    this.io
      ._cancelUserSubscription()
      .subscribe(
        (res: any) => {
          if (res.serverResponse.code === 200) {
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: 'auto',
              autoFocus: false,
              data: {
                data: 'Bronze',
              },
            })
            dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
          } else {
            console.log(res);
          }
        },
        (error: any) => {
          console.error('An error occurred:', error);
        }
      );
  }

  paymentCancel(): void {
    console.log('Payment Cancelled');
    this.purchasingPlan = ''
  }

  paymentDone(event: any): void {
    if(event.status === 'success'){
      if(this.campaignDetails.path === 'create-campaign'){
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Campaign Payment Successful',
            from: 'subscription',
            subscription: this.purchasingPlan,
            campaignDetails: {...this.campaignDetails, deductFromWallet: true}
          },
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result === 'job-overview'){
            this.es._getCreditUtilization({numOfJobs: this.campaignDetails?.jobIds?.length}).subscribe((res) => {
              const dialogRef = this.dialog.open(EmpDashPreviewComponent, {
                width: '880px',
                height: '850px',
                autoFocus: false,
                data: {
                  ...this.campaignDetails,
                  jobOverview: true, 
                  filteredJobs: this.campaignDetails?.jobs, 
                  creditBreakup: res['result']?.creditBreakup,
                  subscriptionPlan: res['result']?.subscriptionPlan,
                  jobIds: this.campaignDetails?.jobIds
                  },
              });
              dialogRef.afterClosed().subscribe(result => {
                if(result === 'success'){
                  const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                    height: 'auto',
                    width: 'auto',
                    autoFocus: false,
                    data: {
                      data: 'Campaign Successful',
                      campaignDetails: this.campaignDetails
                    },
                  })
                  dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() });
                }
              })
            })
          }else{
            this.loadUpdatedAssets()
          }
        });
      }else if(this.campaignDetails.path === 'unlock-cv'){
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Campaign Payment Successful',
            from: 'subscription-cv',
            plan: this.purchasingPlan,
            campaignDetails: this.campaignDetails
          },
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result === 'success'){
            this.loadUpdatedAssets()
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: 'auto',
              autoFocus: false,
              data: {
                data: 'CV Unlocked Successful',
                campaignDetails: this.campaignDetails
              },
            })
            dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() });
          }else{
            this.loadUpdatedAssets()
          }
        });
      }else{
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: this.purchasingPlan,
          },
        })
        dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() });
      }
    }
  }

  updateTransactionId(event: any): void {
    this.transactionRef = uuidv4();
  }

  setPurchasingPlan(plan: string): void {
    this.purchasingPlan = plan
  }

  navigateToBundles(bundleID: string): void {
    this.router.navigate([`bundles/${bundleID}`]);
  }
  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets()
    .subscribe(() => {
      this.dataService.getUserData().subscribe((data: any) => {
        this.userPlan = data.subscription.subscriptionPlan ? data.subscription : { subscriptionPlan: 'Bronze' };
      });
    });
  }
}
