import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  userDeatils: any;
  companyData: any;
  allJobSeekerList: any;

  constructor(
    // private jobService: JobServices,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private employerService: EmployerService,

    private helper: Helper
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    let jsonData = {
      path: 'get-all-users',
      userType: 'jobseeker',
      size: 20,
      // "lastKey":"0f0d89d7-97d8-4c4f-87ea-795305365499"
    };
    this.employerService._AllJobSeekerList(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.allJobSeekerList = res.userData;
        //  console.log(this.allJobSeekerList);
      }
    });
  }

  sendReport() {
    let jsonData = {
      path: 'export-data',
    };
    this.employerService._AllEmployeeDetails(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        console.log(res);
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: 'You get all records in your email',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }
}
