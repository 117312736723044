import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { UUID } from 'angular2-uuid';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
@Component({
  selector: 'app-js-experiance',
  templateUrl: './js-experiance.component.html',
  styleUrls: ['./js-experiance.component.scss'],
})
export class JsExperianceComponent implements OnInit {
  declrationCheckBox: any;
  checked: boolean = false;
  disabled = false;
  userDeatils: any;
  companyData: any;
  experienceForm: UntypedFormGroup;
  show: boolean = false;
  educationWholeRecord: any;
  index: any;
  WholeRecord: any;
  uuidValue: any;
  toDay: any = new Date();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    public datepipe: DatePipe,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<JsExperianceComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    // console.log(this.data)
    this.validationForm();
    if (this.data['data'] != 'NEW' && this.data['data'] != 'confirm') {
      this.patchValue();
      this.show = true;
      this.WholeRecord = this.data['wholeData'];
      this.index = this.WholeRecord.findIndex(
        (id) => id.id == this.data['data'].id
      );
      // console.log(this.index);
    }
    if (this.data['data'].stillWorking == true) {
      this.checkDeclaration(true);
    }
    if (this.data['data'] == 'confirm') {
      this.uuidValue = UUID.UUID();
      this.WholeRecord = this.data['wholeData'];
    }
  }
  // Validation Controls //
  get f() {
    return this.experienceForm.controls;
  }

  validationForm() {
    this.experienceForm = this.fb.group({
      title: ['', Validators.required],
      company: ['', Validators.required],
      industry: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      description: ['', Validators.required],
    });
  }

  patchValue() {
    this.checked = this.data['data'].stillWorking;
    let patchData = {
      title: this.data['data'].title,
      company: this.data['data'].company,
      industry: this.data['data'].industry,
      startDate: this.transformTreeDateTime(this.data['data'].startDate),
      endDate: this.transformTreeDateTime(this.data['data'].endDate),
      description: this.data['data'].description,
    };
    this.experienceForm.patchValue(patchData);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkDeclaration(event) {
    // console.log(event)
    this.declrationCheckBox = event;
    if (this.declrationCheckBox == true) {
      this.experienceForm.controls['endDate'].disable();
      this.experienceForm.patchValue({ endDate: '' });
    } else {
      this.experienceForm.controls['endDate'].enable();
    }
  }

  transformTreeDateTime(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  transformTreeDateTimeUrl(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  saveExperiance() {
    if (this.experienceForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.experienceForm);
      return false;
    }
    if (this.data['data'] == 'confirm') {
      this.WholeRecord.push({
        id: this.uuidValue,
        createdAt: this.toDay.toISOString(),
        title: this.experienceForm.get('title').value,
        company: this.experienceForm.get('company').value,
        industry: this.experienceForm.get('industry').value,
        startDate: this.transformTreeDateTime(
          this.experienceForm.get('startDate').value
        ),
        endDate: this.transformTreeDateTime(
          this.experienceForm.get('endDate').value
        ),
        description: this.experienceForm.get('description').value,
        stillWorking:
          this.declrationCheckBox == undefined ? '' : this.declrationCheckBox,
      });
      // console.log(this.WholeRecord)
      this.dialogRef.close(this.WholeRecord);
    } else {
      let jsonData = {
        path: 'add-experience',
        id: this.userDeatils.id,
        title: this.experienceForm.get('title').value,
        company: this.experienceForm.get('company').value,
        industry: this.experienceForm.get('industry').value,
        startDate: this.transformTreeDateTime(
          this.experienceForm.get('startDate').value
        ),
        endDate: this.transformTreeDateTime(
          this.experienceForm.get('endDate').value
        ),
        description: this.experienceForm.get('description').value,
        stillWorking:
          this.declrationCheckBox == undefined ? '' : this.declrationCheckBox,
      };
      console.log(jsonData);
      this.io._JSaddExperiance(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }
  }

  updateExperiance() {
    if (this.experienceForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.experienceForm);
      return false;
    }
    if (this.data['confirm'] == 'confirmExperiance') {
      (this.WholeRecord[this.index].title =
        this.experienceForm.get('title').value),
        (this.WholeRecord[this.index].company =
          this.experienceForm.get('company').value),
        (this.WholeRecord[this.index].industry =
          this.experienceForm.get('industry').value),
        (this.WholeRecord[this.index].startDate = this.transformTreeDateTime(
          this.experienceForm.get('startDate').value
        )),
        (this.WholeRecord[this.index].endDate = this.transformTreeDateTime(
          this.experienceForm.get('endDate').value
        )),
        (this.WholeRecord[this.index].description =
          this.experienceForm.get('description').value),
        (this.WholeRecord[this.index].stillWorking =
          this.declrationCheckBox == undefined ? '' : this.declrationCheckBox);
      this.dialogRef.close(this.WholeRecord);
    } else {
      (this.WholeRecord[this.index].title =
        this.experienceForm.get('title').value),
        (this.WholeRecord[this.index].company =
          this.experienceForm.get('company').value),
        (this.WholeRecord[this.index].industry =
          this.experienceForm.get('industry').value),
        (this.WholeRecord[this.index].startDate = this.transformTreeDateTime(
          this.experienceForm.get('startDate').value
        )),
        (this.WholeRecord[this.index].endDate = this.transformTreeDateTime(
          this.experienceForm.get('endDate').value
        )),
        (this.WholeRecord[this.index].description =
          this.experienceForm.get('description').value),
        (this.WholeRecord[this.index].stillWorking =
          this.declrationCheckBox == undefined ? '' : this.declrationCheckBox);
      let jsonData = {
        path: 'edit-experience',
        id: this.userDeatils.id,
        experience: this.WholeRecord,
      };
      console.log(jsonData);
      this.io._JSeditExperiance(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }
  }

  removeExperiance() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      //height: '400px',
      width: '400px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        if (this.data['confirm'] == 'confirmExperiance') {
          this.WholeRecord.splice(this.index, 1);
          this.dialogRef.close(this.WholeRecord);
        } else {
          this.WholeRecord.splice(this.index, 1);
          let jsonData = {
            path: 'edit-experience',
            id: this.userDeatils.id,
            experience: this.WholeRecord,
          };
          // console.log(jsonData)
          this.io._JSeditExperiance(jsonData).subscribe((res: any) => {
            if (res.serverResponse.code == 200) {
              // console.log(res);
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
              this.dialogRef.close('save');
            }
          });
        }
      }
    });
  }
}
