<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="log-lt">
                <div class="form-wrap">
                  <div class="limiter" style="display: none">
                    <div class="container-login100">
                      <div class="wrap-login100 p-t-30 p-b-50 login-form">
                        <form
                          [formGroup]="verifyemail"
                          autocomplete="off"
                          class="login100-form validate-form p-b-33 p-t-5"
                        >
                          <div class="form-group">
                            <h2 class="text-center">Verify Email</h2>
                            <div class="form-group login-group">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control inputsize"
                                  placeholder="code"
                                  required="required"
                                  id="email"
                                  formControlName="text"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div class="container-login100-form-btn m-t-32">
                            <button
                              class="btn btn-green w-100"
                              type="submit"
                              id="submit"
                              (click)="veriFyEmail()"
                            >
                              Verify
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img src="../../../../assets/images/auth-rt.png" alt="" />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>