<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<div class="updatename">
  <div class="createbookmark" style="padding-top: 50px">
    <form [formGroup]="renameCollection">
      <input
        type="text"
        formControlName="Name"
        class="form-control"
        placeholder="New Collection name"
        required
      />
      <div *ngIf="f.Name.touched && f.Name.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.Name.errors.required">
          Collection Name is required
        </span>
      </div>
      <br />
      <input
        type="submit"
        value="UPDATE NAME"
        (click)="saveRenameCollection()"
        class="sendcreate"
        class="btn-green"
      />
    </form>
  </div>
</div>
