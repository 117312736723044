import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DynamicSnacbarErrorComponent } from '../dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from '../dynamic-snacbar/dynamic-snacbar.component';

@Component({
  selector: 'app-unsebscribe',
  templateUrl: './unsebscribe.component.html',
  styleUrls: ['./unsebscribe.component.scss'],
})
export class UnsebscribeComponent implements OnInit {
  unsubscribeId: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private helper: Helper,
    private router: Router
  ) {}

  ngOnInit(): void {
    // console.log('0000', this.route.snapshot.url)
    this.unsubscribeId = this.route.snapshot.paramMap.get('subscriptionId');
    console.log('0000', this.unsubscribeId);
    if (this.unsubscribeId !== '' || this.unsubscribeId !== undefined) {
      this.unsubscribe(this.unsubscribeId);
    } else {
      this.router.navigate(['/home']);
    }
  }

  unsubscribe(id: string) {
    let payload = {
      id: id,
      path: 'remove-user-subscription',
    };

    this.io
      .apicall(payload, 'subscription/remove-user-subscription')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/home']);
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/home']);
        }
      });
  }
}
