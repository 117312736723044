import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-report-job',
  templateUrl: './report-job.component.html',
  styleUrls: ['./report-job.component.scss'],
})
export class ReportJobComponent implements OnInit {
  chooseReason: string = '';
  additionalWrite: string = '';

  reasons: string[] = [
    'It is offensive, discriminatory',
    'It seems like a fake job',
    'It is inaccurate',
    'It is an advertisement',
    'Other',
  ];
  userDeatils: any;
  companyData: any;
  show: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReportJobComponent>,
    private auth: AuthService,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    // console.log(this.data['Perticularjob'])
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    // console.log(this.chooseReason)
    if (this.chooseReason == '') {
      this.show = true;
      return false;
    }
    let jsonData = {
      path: 'report-job',
      jobId: this.data['Perticularjob'].id,
      userId: this.userDeatils.id,
      jobProviderId: this.data['Perticularjob'].job_provider_id,
      title: this.data['Perticularjob'].title,
      reason: this.chooseReason,
      additionalInformation: this.additionalWrite,
    };
    // console.log(jsonData);
    this.io._reportJob(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // });
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('save');
      }
    });
  }
}
