<div class="row">
    <!-- Payment Table Side -->
    <div class="col-6">
        <div class="container">
            <div class="row">
                <p class="heading">What would you like to ask</p>
                <p class="sub-heading">our support team will contact you </p>
                <div class="break-line">
                    <div style="width: 86.94px; height: 4.58px; background: #DE6E4B"></div>
                    <div style="width: 215.06px; height: 0.92px; background: #DE6E4B"></div>
                </div>
                <div class="contact-frm-section">
                    <div class="cntct-frm">
                        <form [formGroup]="contactUsFrom">
                            <div class="form-group">
                                <input type="text" class="form-control" id="" aria-describedby="" placeholder="Name"
                                    required="required" formControlName="Name" />
                                <div *ngIf="f.Name.touched && f.Name.invalid">
                                    <span class="text-left text-danger pb-1" *ngIf="f.Name.errors.required">
                                        Name is required
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" id="" aria-describedby="" placeholder="Email"
                                    required="required" formControlName="email" [pattern]="emailPattern" />
                                <div *ngIf="f.email.touched && f.email.invalid">
                                    <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is
                                        required
                                    </span>
                                    <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">
                                        Email is not valid.
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" id="" aria-describedby=""
                                    placeholder="Phone Number" required="required" formControlName="number"
                                    [pattern]="numberPattern" />
                                <div *ngIf="f.number.touched && f.number.invalid">
                                    <span class="text-left text-danger pb-1" *ngIf="f.number.errors.required">Number is
                                        required
                                    </span>
                                    <span class="text-left text-danger pb-1" *ngIf="f.number.errors?.pattern">
                                        Number is not valid
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" id="" rows="3" placeholder="Message"
                                    formControlName="Message"></textarea>
                            </div>
                            <div style="margin-bottom: 25px;">
                                <label class="acknowledgement">
                                    <input type="checkbox" formControlName="isChecked"> I would like to receive the
                                    newsletter.
                                </label>
                            </div>
                            <input type="submit" value="Submit" class="send-btn" (click)="submitContactFrom()" />
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="container-sec">
            <div class="row">
                <img src="../../../../../../assets/images/wn-location.webp" />
            </div>
            <div class="row">
                <div class="contact-group">
                    <img src="../../../../../../assets/images/loc-icon-black.svg" />
                    <p class="contact-details"><a
                            href="https://www.google.com/maps/search/?api=1&query=11b,+Rev+Ogunbiyi+Street,+Ikeja+GRA,+Ikeja,+Lagos"
                            target="_blank">
                            11b, Rev Ogunbiyi Street, Ikeja GRA, Ikeja, Lagos
                        </a></p>
                </div>
                <div class="contact-group">
                    <img src="../../../../../../assets/images/phn-icon-black.svg" />
                    <p class="contact-details"><a href="tel:09137000206">09137000206</a></p>
                </div>
                <div class="contact-group">
                    <img src="../../../../../../assets/images/em-icon-black.svg" />
                    <p class="contact-details"><a href="mailto:helpdesk@worknigeria.com">helpdesk@worknigeria.com</a>
                    </p>
                </div>

            </div>
            <div class="row">
                <div class="socials">
                    <a href="https://www.instagram.com/worknigeria/" target="_blank"><img
                            src="../../../../../../assets/images/Instagram.svg" /></a>
                    <a href="https://twitter.com/worknigerialtd" target="_blank"><img
                            src="../../../../../../assets/images/Twitter.svg" /></a>
                    <a href="https://www.facebook.com/WorkNigeria-107228115288361" target="_blank"><img
                            src="../../../../../../assets/images/Facebook.svg" /></a>
                    <a href="https://www.youtube.com/@worknigeria" target="_blank"><img
                            src="../../../../../../assets/images/Youtube.svg" /></a>
                </div>
            </div>
        </div>
    </div>
</div>