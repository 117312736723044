import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';

import { map, startWith } from 'rxjs/operators';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-js-skill',
  templateUrl: './js-skill.component.html',
  styleUrls: ['./js-skill.component.scss'],
})
export class JsSkillComponent implements OnInit {
  userDeatils: any;
  companyData: any;
  skillForm: UntypedFormGroup;
  visible: boolean = true;
  addOnBlur: boolean = true;
  keywords = [];
  //today
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new UntypedFormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  allFruits: any[] = [];

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  show: boolean = false;
  newSkills: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<JsSkillComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }

    this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allFruits.slice()
      )
    );
  }

  ngOnInit(): void {
    // console.log(this.data)
    this.getSkills();
    if (this.data['data'].length > 0) {
      this.fruits = this.data['data'];
      // console.log(this.fruits)
      this.show = true;
    } else {
      this.fruits = [];
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get f() {
    return this.skillForm.controls;
  }

  getSkills() {
    let jsonData = {
      path: 'get-skills',
    };
    // console.log(jsonData)

    this.io._JSgetSkill(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        //  console.log(res)
        this.allFruits = this._removeDuplicates(res['result'].skills);
        // console.log( this.allFruits);
      }
    });
  }

  saveSkills() {
    if (this.data['confirm'] == 'confirmSkill') {
      this.dialogRef.close(this.fruits);
    } else {
      let jsonData = {
        path: 'add-skills',
        id: this.userDeatils.id,
        skills: this.fruits,
        newSkills: this.newSkills,
      };
      // console.log(jsonData)

      this.io._JSaddSkill(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }
  }

  updateSkills() {
    if (this.data['confirm'] == 'confirmSkill') {
      this.dialogRef.close(this.fruits);
    } else {
      let jsonData = {
        path: 'removed-skills',
        id: this.userDeatils.id,
        skills: this.fruits,
        newSkills: this.newSkills,
      };
      // console.log(jsonData)

      this.io._JSupdateSkill(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.dialogRef.close('save');
        }
      });
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // console.log(value)
    let input = event.input;
    // Add our fruit
    if (
      value &&
      this.fruits.find((x) => x === value.toUpperCase()) === undefined &&
      this.newSkills.find((x) => x === value.toUpperCase()) === undefined
    ) {
      this.fruits.push(value.toUpperCase());
      this.newSkills.push(value.toUpperCase());
    }

    // Clear the input value
    if (input) {
      input.value = '';
    }

    this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (
      this.fruits.find((x) => x === event.option.viewValue.toUpperCase()) ===
        undefined &&
      this.newSkills.find((x) => x === event.option.viewValue.toUpperCase()) ===
        undefined
    ) {
      this.fruits.push(event.option.viewValue.toUpperCase());
    }
    this.fruitInput.nativeElement.value = '';
    this.fruitCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allFruits.filter((fruit) =>
      fruit.skillName.toLowerCase().includes(filterValue)
    );
  }

  private _removeDuplicates(arr: any[]) {
    let unique_array: any = [];
    let uniqueSet = new Set();
    for (let i = 0; i < arr.length; i++) {
      if (!uniqueSet.has(arr[i].skillName)) {
        unique_array.push(arr[i]);
        uniqueSet.add(arr[i].skillName);
      }
    }
    return unique_array;
  }
}
