import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  selectedTabIndex: number = 0;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  role: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.role = params['_role'];
    });
  }

  ngOnInit(): void {
    this.tabArrange();
  }
  // Tap Disablesd by Its Role Name //
  tabArrange() {
    if (this.role == 'jobseeker') {
      this.selectedTabIndex = 0;
    }
    if (this.role == 'employer') {
      this.selectedTabIndex = 1;
    }
  }
}
