<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<h4>You are logged in as a job seeker</h4>
<!-- <br />
<div>You are logged in as a job seeker .
</div> -->

<br />
<p>You are about to be redirected to the employer homepage, proceed?</p>
<a href="javascript:void(0);" (click)="gotologout()" class="btn btn-green">
  Ok
</a>
&nbsp; &nbsp;

<a href="javascript:void(0);" (click)="onNoClick()" class="btn btn-green">
  cancel
</a>
