import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-job-provider-profile',
  templateUrl: './job-provider-profile.component.html',
  styleUrls: ['./job-provider-profile.component.scss'],
})
export class JobProviderProfileComponent implements OnInit {
  profileForm: UntypedFormGroup;
  show: boolean = false;
  userDeatils: any;
  companyData: any;
  description: any = '';
  fileName: any;
  fileToUpload: any;
  uploadFileResult: any;
  imagePath: any;
  imgURL: string | ArrayBuffer = '';
  uploadMethodCall: boolean;
  formData: FormData;
  profileImage: any;
  Name: any;
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    public loaderService: NgxSpinnerService,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    // public dialog: MatDialog,
    private snackBar: MatSnackBar,
    // private dialogRef: MatDialogRef<JobProviderProfileComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.validationForm();
    console.log(this.userDeatils);
    console.log(this.companyData);
    // if (this.data != undefined) {
    //   this.Name = this.data['data'].firstName + " " + this.data['data'].lastName;
    //   this.description = this.data['data'].about;
    this.profileImage =
      this.userDeatils == '' || this.userDeatils.profileImage == undefined
        ? '../assets/images/defaultuser.png'
        : this.userDeatils.profileImage;
    // }
  }
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
  // Validation Controls //
  get f() {
    return this.profileForm.controls;
  }

  validationForm() {
    this.profileForm = this.fb.group({
      firstName: [this.userDeatils.firstName, Validators.required],
      lastName: [this.userDeatils.lastName, Validators.required],
      email: [this.userDeatils.email],
    });
  }

  onSelectFile(event) {
    this.uploadMethodCall = true;
    var reader = new FileReader();
    this.imagePath = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.fileName = event.target.files[0].name;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUpload = event.target.files[i];
      }
    }
  }

  async updateProfile() {
    if (this.uploadMethodCall == true) {
      const formData = new FormData();
      formData.append('userId', this.userDeatils?.id);
      formData.append('path', 'upload-file');
      formData.append('file', this.fileToUpload);
      formData.append('purpose', 'profileImageUpload');
      await this.io._uploadFile(formData).subscribe(
        (res: any) => {
          if (res.serverResponse.code == 200) {
            this.uploadFileResult = res['result'];
            // console.log(this.uploadFileResult);
            this.io.setfordisplayPicture(this.uploadFileResult.documentUrl);
            this.withPic();
            //  setTimeout(() => {
            //   this.withPic();
            //  }, 1000);
            // console.log(this.uploadFileResult);
          }
        },
        (err) => console.log(err)
      );
    } else {
      this.withOutPic();
    }
    //  await this.h();
  }

  withPic() {
    if (this.profileForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.profileForm);
      return false;
    }
    this.loaderService.show();
    let jsonData = {
      path: 'update-employer-profile',
      id: this.userDeatils.id,
      firstName: this.profileForm.get('firstName').value,
      lastName: this.profileForm.get('lastName').value,
      profileImage: this.uploadFileResult.documentUrl,

      email: this.userDeatils.email,
    };
    console.log(jsonData);
    this.io._JSupdateProfile(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        if (localStorage.getItem('workNigeriaUser') === null) {
          this.auth.setCurrentUserDetailsInSessoin(res['result'].user);
        } else {
          this.auth.setCurrentUserDetails(res['result'].user);
        }
        this.loaderService.hide();
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  withOutPic() {
    if (this.profileForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.profileForm);
      return false;
    }
    this.loaderService.show();
    let jsonData = {
      path: 'update-employer-profile',
      id: this.userDeatils.id,
      firstName: this.profileForm.get('firstName').value,
      lastName: this.profileForm.get('lastName').value,
      profileImage: this.profileImage,

      email: this.userDeatils.email,
    };
    this.io._JSupdateProfile(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        if (localStorage.getItem('workNigeriaUser') === null) {
          this.auth.setCurrentUserDetailsInSessoin(res['result'].user);
        } else {
          this.auth.setCurrentUserDetails(res['result'].user);
        }
        this.loaderService.hide();
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }
}
