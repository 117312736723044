<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<div class="dell-area">
  <div>
    <h4 style="text-align: center">
      Are you sure you want to make this change?
    </h4>
    <!-- <button mat-flat-button style="color: #3eab70; border: 1px solid #3eab70;"
(click)="close('yes')">YES</button>&nbsp;
<button  mat-raised-button color="primary" style=" min-width: auto;"
(click)="close('no')">NO</button> -->
    <p style="text-align: center">
      <button class="btn-green" mat-flat-button (click)="close('yes')">
        YES</button
      >&nbsp;
      <button
        class="btn-green wh-bg"
        mat-raised-button
        color="primary"
        (click)="close('no')"
      >
        NO
      </button>
    </p>
  </div>
</div>
