<div class="overview-container">
    <div class="cv-upload-section">
        <h1 class="upload-cv-heading"><b>Upload CV to Worknigeria Bank</b></h1>
        <div class="upload-section">
            <label class="custom-file-upload">
                <input type="file" (change)="onFilesSelected($event)" accept=".pdf, .docx" multiple/>
                <img src="../../../../assets/images/browse-arrow.png" /> Browse computer
            </label>
            <p>Upload one or Multiple CV</p>
        </div>
        <div *ngFor="let file of files">
            <div class="d-flex align-items-center justify-content-center progress-bar-container">
                    <mat-progress-bar mode="determinate" [value]="file.progress"></mat-progress-bar>
                <p class="uploaded-file-name">{{ file.name }}</p>
                <button (click)="removeFile(file)" class="btn-remove">&#10006;</button>
            </div>
        </div>
        <div class="btn-div">
            <button class="btn-verify" [disabled]="!this.files.length" (click)="saveCVs()">Upload CV</button>
        </div>
    </div>
</div>