import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DynamicSnacbarErrorComponent } from '../container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from '../helper/defult';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private _authService: AuthService,
    private helper: Helper,
    private _router: Router,
    private snackBar: MatSnackBar
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let userLocal;
    let userSeeson;
    let seeson = sessionStorage.getItem('workNigeriaUser');
    let regxValue = new RegExp(
      '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$'
    );
    if (seeson) {
      if (seeson.match(regxValue) != null) {
        userSeeson = JSON.parse(
          this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
        );
      } else {
        this._router.navigate(['/home']);
        sessionStorage.clear();
        // this.snackBar.open("User Details Not Found,Please Login Again", "OK", {})
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: 'User Details Not Found,Please Login Again',

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);

        // window.location.reload();
        return false;
      }
    }

    let loocal = localStorage.getItem('workNigeriaUser');
    if (loocal) {
      if (loocal.match(regxValue) != null) {
        userLocal = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))));
      } else {
        this._router.navigate(['/home']);
        localStorage.clear();
        // this.snackBar.open("User Details Not Found,Please Login Again", "OK", {})
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: 'User Details Not Found,Please Login Again',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);

        // window.location.reload();
        return false;
      }
    }

    // if((localStorage.getItem('workNigeriaUser'))) {
    //   userLocal = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))))
    // }
    // if((sessionStorage.getItem('workNigeriaUser'))) {
    //   userSeeson = JSON.parse(this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser'))))
    // }

    if (userLocal || userSeeson) {
      return true;
    } else {
      this._router.navigate(['/home']);
      return false;
    }
  }
}
