<section class="common-banner new-screen">
  <div class="common-banner-info">
    <h5>OVERLINE</h5>
    <h1>Headline</h1>
    <h6>Description & Instructions</h6>
  </div>
</section>
<section class="bodyjobsearch-area bgcolor">
  <div class="max-width">
    <div class="bodyrow">
      <div class="bodyjobsearch-left">
        <ng-container
          *ngFor="let job of [0, 1, 2, 3, 4, 0, 1, 2, 3, 4]; let i = index"
        >
          <div class="bodyjobsearch-box bgcolor success">
            <div class="bodyjobsearch-img">
              <img src="../../../assets/images/sm-Logo.png" />
            </div>
            <div class="bodyjobsearch-desc">
              <h5>Sope Aluko</h5>
              <p>
                <b>Sope:</b> Can we schedule an interview some time next week?
                Your application is just the...
              </p>
            </div>
            <div class="bodyjobsearch-share">
              <ul>
                <li><a>07:10 AM</a></li>
                <li>
                  <a class="badge-count" href="javascript:void(0);"> 5</a>
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="bodyjobsearch-right bodyjobsearch-right-shadow">
        <ng-container>
          <div class="profilelogo">
            <img src="../../../assets/images/sm-Logo.png" />
            <h4>Adeola Ariyo <span class="location">Jobot</span></h4>
          </div>
          <hr />
          <div class="chat_u">
            <img src="../../../assets/images/sm-Logo.png" />
            <h4>Adeola Ariyo <span>Jun 25, 5:38 PM</span></h4>
          </div>
          <div class="msg-box">
            <p>
              Hi Dayo,<br /><br />
              Are you currently keeping your options open for new opportunities?
              I am working with a growing supply chain software startup that is
              looking for a talented Senior UI/UX Designer. <br /><br />
              This is a 100% remote, full time role that offers a base salary of
              $100k to $130k and a competitive benefits package. Let me know if
              you would be open to chatting! <br _ngcontent-woa-c114="" /><br
                _ngcontent-woa-c114=""
              />
              Best,<br _ngcontent-woa-c114="" />
              Adeola Ariyo<br />
              Recruiting Manager at Jobot<br />
              949-688-2006
            </p>
          </div>
          <hr />
          <div class="msg-box-new">
            <textarea></textarea>
            <div style="text-align: right">
              <a href="job-added" class="green-btn">Attach file</a>
              &nbsp;&nbsp;<a href="job-added" class="green-btn">
                Send Message</a
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
