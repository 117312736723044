import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Helper } from 'src/app/helper/defult'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-emp-dashboard-dash',
  templateUrl: './emp-dashboard-dash.component.html',
  styleUrls: ['./emp-dashboard-dash.component.scss']
})
export class EmpDashboardDashComponent implements OnInit  {
  selectedElement: string = 'job-overview';
  isCampaignBeingAdded: boolean = false;
  userDetails: any;
  showSavedCV = false;
  companyData: any;

  constructor(
    private route: ActivatedRoute,
    private helper: Helper,
    private auth: AuthService,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    if (this.userDetails.userType === 'super_admin') {
      this.selectedElement = 'employers';
    } else if (this.userDetails.isSuperAdmin){
      this.selectedElement = 'overview';
    }else {
      this.selectedElement = 'job-overview';
    }
    this.showSavedCV = this.route.snapshot.queryParams.data;
  }
  
}
