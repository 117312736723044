import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-succec-error-modal',
  templateUrl: './succec-error-modal.component.html',
  styleUrls: ['./succec-error-modal.component.scss'],
})
export class SuccecErrorModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SuccecErrorModalComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  // Closed Modal //
  closeModal() {
    this.dialogRef.close(true);
  }
}
