import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { CreateAlertComponent } from '../create-alert/create-alert.component';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-job-alerts',
  templateUrl: './job-alerts.component.html',
  styleUrls: ['./job-alerts.component.scss'],
})
export class JobAlertsComponent implements OnInit {
  anonymousForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  checked: boolean = false;
  disabled = false;
  declrationCheckBox: any = false;
  userDeatils: any;
  companyData: any;
  alertRecord: any;
  constructor(
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    // public dialogRef: MatDialogRef<JobAlertsComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.validationForm();
    this.getAllAlertData();
  }
  // Validation Controls //
  get f() {
    return this.anonymousForm.controls;
  }

  validationForm() {
    this.anonymousForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  createAlert() {
    const dialogRef = this.dialog.open(CreateAlertComponent, {
      //height: '600px',
      width: '600px',
      // data: {Perticularjob }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        this.getAllAlertData();
      }
    });
  }

  getAllAlertData() {
    let jsonData = {
      path: 'user-job-alerts',
      userId: this.userDeatils.id,
    };
    this.io._getUserSpecificAlert(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        this.alertRecord = res['result'].alertList;
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // });
      }
    });
  }

  edit(PerticularAlert) {
    // console.log(PerticularAlert)
    const dialogRef = this.dialog.open(CreateAlertComponent, {
      //height: '610px',
      width: '650px',
      data: { PerticularAlert },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        // console.log("++++++++++++")
        this.getAllAlertData();
      }
    });
  }

  removeAlert(PerticularAlert) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      //height: '400px',
      width: '400px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        let jsonData = {
          path: 'delete-alert',
          id: PerticularAlert.id,
          userId: this.userDeatils.id,
        };
        this.io._removeAlert(jsonData).subscribe((res: any) => {
          // console.log(res);
          if (res.serverResponse.code == 200) {
            this.getAllAlertData();
            this.snackBar
              .openFromComponent(DynamicSnacbarComponent, {
                data: res.serverResponse.message,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });
      }
    });
  }
}
