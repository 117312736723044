<section class="padd-top common-padd-lt-rt job-aleart-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!-- <div class="profile-big-ttl"
                    style="background-image: url(../assets/images/star-bg.png); background-repeat: no-repeat; background-position: right center;  background-size: cover;">
                    <h1>Saved Jobs</h1>
                </div> -->
        <div class="smallbannerAlerts white">
          <div class="img">
            <img src="../../../../assets/images/savejob-banner.png" />
          </div>
          <h3 style="color: #0d6134">Saved Jobs</h3>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="jobsaveform-area common-padd-lt-rt savejob">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="savedselect custom-saved-part">
          <mat-form-field appearance="fill">
            <mat-label>Collection Name</mat-label>
            <mat-select
              (selectionChange)="changeCollection($event)"
              [(ngModel)]="selectedCollectionID"
            >
              <mat-option
                *ngFor="let row of collectionRecords; let i = index"
                [value]="row?.id"
              >
                <!-- {{row?.name}} -->
                {{ row?.name[0].toUpperCase() + row?.name.slice(1) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <button class="btn bdr" (click)="renameCollectionDialog()"  >Rename list</button> -->
          <button
            mat-raised-button
            (click)="renameCollectionDialog()"
            [disabled]="buttonDisable"
          >
            Rename list
          </button>

          <!-- <button class="green-btn" (click)="shareCollectionAllJob()"><i class="fas fa-share-alt"></i> Share this
        list</button> -->
          &nbsp; &nbsp;
          <button
            mat-raised-button
            (click)="removeCollection()"
            [disabled]="buttonDisable"
          >
            Remove List
          </button>
          &nbsp; &nbsp;
          <button
            mat-raised-button
            color="accent"
            (click)="shareCollectionAllJob()"
          >
            <i class="fas fa-share-alt"></i> Share this list
          </button>
          <!-- <button class="green-btn" (click)="removeCollection()" >Remove List</button> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="bodyjobsearch-area common-padd common-padd-lt-rt savejob"
  *ngIf="!nojob"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="listwrap">
          <h3>Your Lists</h3>
          <a
            href="javascript:void(0);"
            class="btn-green"
            (click)="createCollection()"
            >+ Create Lists</a
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-5">
        <div class="bodyjobsearch-left">
          <div class="moreinfo-wrap">
            <h4>Saved Jobs</h4>
            <div _ngcontent-qwx-c136="" class="bodyjobsearch-share">
              <ul _ngcontent-qwx-c136="">
                <li _ngcontent-qwx-c136="">
                  <!-- <a _ngcontent-qwx-c136="" href="javascript:void(0);"><i
                                            _ngcontent-qwx-c136="" class="fas fa-share-alt"></i>SHARE</a></li> -->
                </li>
              </ul>
            </div>
          </div>

          <ng-container *ngFor="let job of jobLists; let i = index">
            <!-- [ngClass]="status ? 'bodyjobsearch-box bgcolor success' : 'bodyjobsearch-box bgcolor'" -->
            <div
              [ngClass]="
                status == job?.id
                  ? 'bodyjobsearch-box bgcolor success'
                  : 'bodyjobsearch-box bgcolor'
              "
            >
              <div class="bodyjobsearch-img">
                <img
                  [src]="
                    job?.logo ? job?.logo : '../../../assets/images/sm-Logo.png'
                  "
                  onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                />
                <div *ngIf="this.isExpriedButton == false" class="jobtag-area">
                  <div *ngIf="job?.job_type == 'hot'" class="jobtag hot">
                    <img src="../../../../assets/images/fire.png" /> Hot
                  </div>
                  <div *ngIf="job?.job_type == 'cold'" class="jobtag cool">
                    <img src="../../../../assets/images/cool.png" /> Cold
                  </div>
                  <div *ngIf="job?.job_type == 'new'" class="jobtag new">
                    <img src="../../../../assets/images/new.png" /> New
                  </div>
                </div>
              </div>
              <div class="bodyjobsearch-desc" (click)="jobDetails(i, job)">
                <h5>{{ job?.title | titlecase }}</h5>
                <h6>
                  {{
                    job?.company_behalf_name
                      ? job?.company_behalf_name
                      : job?.company_name
                  }}
                  <span
                    *ngIf="job.companyApprovedStatus == 'approved'"
                    class="verifi-icon"
                    ><img
                      src="../../../../assets/images/Verification-icon.png" /></span
                  ><br />
                  {{ job?.job_location
                  }}<span *ngIf="job?.city">/{{ job?.city }}</span>
                </h6>
                <p>
                  {{ job?.employment_type }} |
                  {{ eventExpireIn(job?.created_at) }} |
                  <span *ngIf="job?.minimum_salary != '0'"
                    >{{ job?.currency }} {{ job?.minimum_salary | number }}</span
                  >
                  <span *ngIf="job?.maximum_salary != '0'">
                    - {{ job?.currency }} {{ job?.maximum_salary | number }}</span
                  >
                  <span *ngIf="job?.minimum_salary == '0'">
                    Salary - Not specified or see the job description</span
                  >
                </p>
              </div>
              <div class="bodyjobsearch-share">
                <ul>
                  <li>
                    <a href="javascript:void(0);" (click)="shareJob(job)"
                      ><i class="fas fa-share-alt"></i>SHARE</a
                    >
                  </li>
                  <br />
                  <!-- <li *ngIf="!job?.isSaved"><a href="javascript:void(0);" *ngIf="userDeatils?.id" (click)="savedJob(job)"><i class="far fa-heart"></i>SAVE</a>
                               </li> -->
                  <li>
                    <a
                      class="active"
                      href="javascript:void(0);"
                      *ngIf="userDeatils?.id"
                      style="color: #3eab70"
                      (click)="savedJob(job)"
                      ><i class="far fa-heart"></i>SAVED</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-lg-7">
        <div
          class="bodyjobsearch-right bodyjobsearch-right-shadow"
          [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''"
          *ngIf="detailsOfJob"
        >
          <button (click)="mobileViewClassClose()" class="mobilejob-back">
            <i class="far fa-times-circle"></i>
          </button>
          <div [ngClass]="showRightSectionInMobile ? 'mobile-view-child' : ''">
            <ng-container *ngIf="detailsOfJob">
              <div class="title-wrap">
                <div
                  class="profilelogo"
                  *ngIf="!detailsOfJob?.company_behalf_logo"
                >
                  <img
                    [src]="
                      detailsOfJob.logo
                        ? detailsOfJob?.logo
                        : '../../../assets/images/sm-Logo.png'
                    "
                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                  />
                  <h4>{{ detailsOfJob?.title }}</h4>
                </div>
                <div
                  class="profilelogo"
                  *ngIf="detailsOfJob?.company_behalf_logo"
                >
                  <img
                    [src]="
                      detailsOfJob?.company_behalf_logo
                        ? detailsOfJob?.company_behalf_logo
                        : '../../../assets/images/sm-Logo.png'
                    "
                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                  />
                  <h4>{{ detailsOfJob?.title }}</h4>
                </div>
                <div class="jobcat-name" *ngIf="this.isExpriedButton == false">
                  <div
                    *ngIf="detailsOfJob?.job_type == 'hot'"
                    class="jobtag hot"
                  >
                    <img src="../../../../assets/images/fire.png" /> Hot
                  </div>
                  <div
                    *ngIf="detailsOfJob?.job_type == 'cold'"
                    class="jobtag cool"
                  >
                    <img src="../../../../assets/images/cool.png" /> Cold
                  </div>
                  <div
                    *ngIf="detailsOfJob?.job_type == 'new'"
                    class="jobtag new"
                  >
                    <img src="../../../../assets/images/new.png" /> New
                  </div>
                  <h6>
                    <span>{{ this.dateDiff }}</span> Days left
                  </h6>
                </div>
                <div class="bodyjobsearch-share">
                  <ul>
                    <!-- <li *ngIf="!detailsOfJob?.isSaved"><a href="javascript:void(0);"
                                            (click)="savedJob(detailsOfJob)"><i class="far fa-heart"></i>
                                            SAVE</a></li> -->
                    <li>
                      <a
                        class="active"
                        href="javascript:void(0);"
                        style="color: #3eab70"
                        (click)="savedJob(detailsOfJob)"
                        ><i class="far fa-heart"></i> SAVED</a
                      >
                    </li>
                    <!-- <li class="applied-btn"><a href="javascript:void(0);"
                                            (click)="applyJob(detailsOfJob)">Applied</a></li> -->

                    <li class="applied-btn">
                      <a href="javascript:void(0);" *ngIf="this.isExpriedButton"
                        >Expired
                      </a>
                    </li>

                    <li class="applied-btn">
                      <a
                        href="javascript:void(0);"
                        *ngIf="
                          detailsOfJob?.isApplied == true &&
                          this.isExpriedButton == false
                        "
                        (click)="alreadyApply()"
                        >Applied
                      </a>
                    </li>

                    <li class="applied-btn">
                      <a
                        href="javascript:void(0);"
                        *ngIf="
                          detailsOfJob?.isApplied == false &&
                          this.isExpriedButton == false
                        "
                        (click)="applyJob(detailsOfJob)"
                        >Apply Now</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              <div class="moreinfo-wrap">
                <h6>Job info</h6>
                <button
                  href="javascript:void(0);"
                  class="btn-bdr-green"
                  *ngIf="
                    detailsOfJob.applied_log &&
                    detailsOfJob.applied_log.length > 0
                  "
                >
                  {{ detailsOfJob.applied_log.at(-1).activity }}
                </button>
              </div>

              <div class="desc-half">
                <p>
                  <span class="plft">Job title</span>
                  <span class="prit">{{ detailsOfJob.title }}</span>
                </p>
                <p *ngIf="detailsOfJob.job_location">
                  <span class="plft">Job location</span>
                  <span class="prit"
                    >{{ detailsOfJob.job_location
                    }}<span *ngIf="detailsOfJob?.city"
                      >/{{ detailsOfJob?.city }}</span
                    ></span
                  >
                </p>
                <p>
                  <span class="plft">Job type</span>
                  <span class="prit">{{ detailsOfJob.employment_type }}</span>
                </p>
                <p>
                  <span class="plft">Minimum experience</span>
                  <span class="prit">{{
                    detailsOfJob.minimum_experience
                  }}</span>
                </p>
                <div class="moreinfo-wrap">
                  <p>
                    <span class="plft">Salary</span>
                    <span class="prit">
                      <span *ngIf="detailsOfJob?.minimum_salary != '0'"
                        >{{ detailsOfJob?.currency }} {{ detailsOfJob?.minimum_salary | number }}</span
                      >
                      <span *ngIf="detailsOfJob?.maximum_salary != '0'">
                        - {{ detailsOfJob?.currency }} {{ detailsOfJob?.maximum_salary | number }}</span
                      >
                      <span *ngIf="detailsOfJob?.minimum_salary == '0'">
                        Salary - Not specified or see the job description</span
                      >
                    </span>
                  </p>
                </div>
              </div>
              <hr />
              <h6>Job Description</h6>
              <p class="disc-list" [innerHTML]="detailsOfJob?.description"></p>

              <!-- <hr> 
                        <h6>Google</h6> -->
              <!-- <p>Google aims to build products that organize the world's information and make it
                            universally accessible to our users.</p> -->
              <hr />
              <div class="ddr-info">
                <h3>{{ detailsOfJob?.company_name }}</h3>
                <p class="disc-list" [innerHTML]="detailsOfJob?.about"></p>

                <!-- <button (click)="reportModel()"></button> -->
                <!-- <div class="moreinfo-wrap">
                                <div class="green-text">Google.com</div>
                                <div class="howmuchleft">
                                    <p>4 Days Left</p>
                                </div>
                            </div> -->
                <div class="moreinfo-wrap">
                  <div class="green-text">More jobs at Google</div>
                  <div
                    class="red"
                    style="cursor: pointer"
                    (click)="reportModel(detailsOfJob)"
                  >
                    <i class="fas fa-exclamation-triangle"></i> REPORT
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="nojob" class="min-height">
  <section class="nosavedjob-found new-screen">
    <div class="no-job-posted-yet">
      <h4>No jobs saved yet</h4>
      <!-- <button class="green-btn">Add your first job</button> -->
    </div>
  </section>
</div>
