import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Helper } from 'src/app/helper/defult'
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-emp-dashboard-sidenav',
  templateUrl: './emp-dashboard-sidenav.component.html',
  styleUrls: ['./emp-dashboard-sidenav.component.scss']
})
export class EmpDashboardSidenavComponent {

  cards = [
    {
      image: 'assets/images/help-hire.webp',
      title: 'Let’s help you hire',
      subtitle: 'Fast, Easy and Seamless',
      buttonText: 'Get Started',
      buttonAction: 'hiringTeam',
      headingClass: 'help-hire-h1',
      buttonClass: 'hire-btn',
    },
    {
      image: 'assets/images/higher-plan.webp',
      title: 'Move to higher plan to enjoy more amazing features',
      subtitle: '',
      buttonText: 'Upgrade Plan',
      buttonAction: 'upgradePlan',
      headingClass: 'higher-plan-h1',
      buttonClass: 'higher-btn',
    },
    {
      image: 'assets/images/training-staff.webp',
      title: 'Do you have a training need for your staffs?',
      subtitle: 'Let our professionals help you.',
      buttonText: 'Get Started',
      buttonAction: 'trainingStaff',
      headingClass: 'training-staff-h1',
      buttonClass: 'training-btn',
    },
    {
      image: 'assets/images/know-employee.webp',
      title: 'How well do you know your employee?',
      subtitle: 'Let’s help you verify their background',
      buttonText: 'Get Started',
      buttonAction: 'knowEmployee',
      headingClass: 'know-employee-h1',
      buttonClass: 'know-btn',
    }
  ]

  userDetails: any;
  companyData: any;

  constructor(
    private router: Router,
    private dataService: DataService,
    private route: ActivatedRoute,
    private helper: Helper,
    private auth: AuthService,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
   }

  currentElement: string = 'job-overview';
  currentCardIndex: number = 0;

  ngOnInit(): void {
    if (this.userDetails.isSuperAdmin) {
      this.currentElement = 'overview';
    }else if (this.userDetails.userType === 'super_admin'){
      this.currentElement = 'employers';
    } else {
      this.currentElement = 'job-overview';
    }
    this.route.queryParams.subscribe(params => {
      if (params && Object.keys(params).length > 0) {
        this.selectElement(params[0]);
      } else {
        console.log("params empty")
      }
    });

    const initialCard = document.querySelector('.dashboard-ad') as HTMLElement;
    if (initialCard) {
      setTimeout(() => {
        initialCard.style.opacity = '1';
        initialCard.style.transition = 'opacity 1s ease-in-out';
      }, 5000);
    }
    // Start the card switching cycle:
    this.cycleCards();
  }

  cycleCards() {
    setTimeout(() => {
      this.currentCardIndex = (this.currentCardIndex + 1) % this.cards.length;
      // current card fade-out:
      const previousCard = document.querySelector('.dashboard-ad:not([style*="opacity: 0"])') as HTMLElement;
      if (previousCard) {
        previousCard.style.opacity = '0';
      }
      // new card fade-in
      const newCard = document.querySelector(`.dashboard-ad[style*="opacity: 0"]`) as HTMLElement;
      if (newCard) {
        setTimeout(() => {
          newCard.style.opacity = '1';
        }, 1000);
      }
      // Continuing the cycle:
      this.cycleCards();
    }, 5000);
  }

  @Output() elementSelected = new EventEmitter<string>();

  selectElement(element: string): void {
    this.currentElement = element;
    this.elementSelected.emit(element);
  }

  ngAfterViewInit() {
    this.currentCardIndex = 0;
  }

  handleButtonClick(action: string) {
    if (action === 'hiringTeam') {
      this.dataService.setData({ value: 'Use our hiring team', label: 'Use our hiring team' });
      this.router.navigate(['/contactUs']);
    } else if (action === 'upgradePlan') {
      this.router.navigate(['/choose-employer-subscription']);
    }else if (action === 'trainingStaff') {
      this.dataService.setData({ value: 'Staff Trainings', label: 'Staff Trainings' });
      this.router.navigate(['/contactUs']);
    }else if (action === 'knowEmployee') {
      this.dataService.setData({ value: 'Know Your Employee', label: 'Know Your Employee' });
      this.router.navigate(['/contactUs']);
    }
  }

  trackByFn(index: number, item: any): number {
    return item.id || index;
  }
}
