<div class="notifications-bar testbar skip">
  <div class="tickctn">
    <div class="tickctn-icon"><i class="fas fa-exclamation-triangle"></i></div>
    <div class="tickctn-info">
      <p><strong>Reminder.</strong> {{ this.data }}</p>
    </div>
  </div>
  <div class="tickctn-right">
    <p>
      <strong>
        &nbsp;&nbsp;<a href="javascript:void(0);" (click)="close()"
          ><i class="fas fa-times"></i></a
      ></strong>
    </p>
  </div>
</div>
