<section class="job-manage-area job-manage-dash">
    <div class="overview-job-row">
        <form [formGroup]="dashBoardDetails">
            <div class="select-wrap">
                <div class="select-row">
                    <mat-form-field class="example-full-width sortSelect" appearance="fill">
                        <mat-select formControlName="SortOrder" placeholder="Sort">
                            <mat-option value="ascending" (click)="fetchJobList('ASC')">Ascending</mat-option>
                            <mat-option value="descending" (click)="fetchJobList('DESC')">Descending</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label class="search-jobs">Search Jobs <i class="fas fa-search mt-1"></i></mat-label>
                        <input type="text" matInput placeholder="" formControlName="title" (keyup)="onKey($event)" />
                    </mat-form-field>
                </div>
                <div class="btn-set">
                    <a href="javascript:void(0);" *ngIf="clearButton" class="gray-btn"
                        (click)="resetFrom()">Clear</a>&nbsp;
                </div>
                <!-- <div class="btn-set">
                    <a href="javascript:void(0);" *ngIf="clearButton" class="gray-btn" (click)="resetFrom()">Clear</a>&nbsp;
                    <a href="javascript:void(0);" class="btn border" (click)="exportAllJobs()"><img
                            src="../assets/images/el.png" alt="" /></a>
                </div> -->
            </div>
        </form>

        <button class="btn-post-job" [ngClass]="userDeatils.isSuperAdmin ? 'btn-post-job-not': 'btn-post-job'" (click)="routeToAddJob()"> <img
                src="../../../../../../../assets/images/plus-icon.png" class="plus-icon" /> Post a Job</button>
    </div>
    <div class="row">
        <div class="col-lg-5">
            <span class="d-flex align-items-center justify-content-between mb-3">
                <span class="job-list-heading">Job List</span>
                <span class="job-count">{{jobsPagination.total}} jobs</span>
            </span>
            <div class="job-manage-left jobsearch-height">
                <ng-container *ngFor="let job of jobLists; let i = index">
                    <div [ngClass]="
                                        status == job?.id
                                        ? 'job-manage-box bgcolor success'
                                        : 'job-manage-box bgcolor'
                                    ">
                        <ng-container>
                            <div class="job-manage-img" *ngIf="job.is_3mtt">
                                <img [src]="'../../../assets/images/3mtt.svg'
                                            " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                            </div>
                            <div class="job-manage-img" *ngIf="!job.is_3mtt">
                                <img [src]="job.company_behalf_logo
                                                ? job.company_behalf_logo
                                                : this.companyData.companyLogo
                                                ? this.companyData.companyLogo
                                                : '../../../assets/images/sm-Logo.png'
                                            " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                            </div>
                            <div class="job-manage-desc" (click)="jobDetails(i, job)">
                                <h5>{{ job?.title }}</h5>
                                <h6>
                                    {{ job?.viewed || 0 }} viewed &nbsp;
                                    {{ job?.applied || 0 }} applied
                                </h6>
                                <p>{{ eventExpireIn(job.created_at) }}</p>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- <p class="job-manage-load-more" *ngIf="loadMoreButtonVisible" (click)="onScrollDown()">
                    load more ...
                </p> -->

                <div class="no-post-yet" *ngIf="detailsOfJob == ''">
                    <h4>No jobs posted yet</h4>
                </div>
            </div>
            <mat-paginator *ngIf="jobLists?.length > 0" [length]="this.jobsPagination?.total"
                (page)="onJobsPageChanged($event)" [pageIndex]="this.currentJobsPage" [pageSize]="10"
                showFirstLastButtons>
            </mat-paginator>
            <!-- <div class="pagination">
          <span class="pagination-text">
            Showing {{ jobsPagination.start + 1 }} to
            {{ jobsPagination.start + jobLists.length }}
            of {{ jobsPagination.total }} results
          </span>
          <ngb-pagination
            [page]="currentJobsPage"
            (pageChange)="onJobsPageChanged($event)"
            [pageSize]="jobsPagination.size"
            [ellipses]="true"
            [maxSize]="5"
            [rotate]="true"
            [collectionSize]="jobsPagination.total"
          ></ngb-pagination>
        </div> -->
        </div>
        <div class="col-lg-7 job-manage-preview">
            <div class="mangage-job-manage-right job-manage-right-shadow p-0"
                [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''" *ngIf="detailsOfJob; let i = index">
                <button (click)="mobileViewClassClose()" class="mobilejob-back">
                    <i class="far fa-times-circle"></i>
                </button>
                <div [ngClass]="showRightSectionInMobile ? 'mobile-view-child' : ''">
                    <div class="preview-div row">
                        <div class="col-lg-3">
                            <h4 class="preview-text">Preview</h4>
                        </div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-7 btn-wrap edit-deact"
                            style="display: flex; justify-content: flex-end; gap: 5px; align-items: center;">
                            <button href="javascript:void(0);" class="btn btn-actions share" (click)="shareJob(detailsOfJob)">
                                <img class="preview-icons" src="../../../../../../assets/images/management-share.png" />
                            </button>
                            <button href="javascript:void(0);" class="btn btn-actions promote" (click)="promoteJob()">
                                <img class="preview-icons"
                                    src="../../../../../../assets/images/management-promote.png" />
                            </button>
                            <button href="javascript:void(0);" class="btn btn-actions edit"
                                (click)="routeToEditJob(detailsOfJob.id)">
                                <img class="preview-icons" src="../../../../../../assets/images/management-edit.png" />
                            </button>
                            <label class="toggle">
                                <input type="checkbox" [checked]="detailsOfJob.job_status == 'active'"
                                    (change)="activeInactive(detailsOfJob, currentIndex)" />
                                <span class="slider round" [ngClass]="detailsOfJob.job_status == 'active' ? 'slider-green' : 'slider-red'"></span>
                                <span class="toggle-text"></span>
                            </label>
                        </div>
                    </div>
                    <div class="job-manage-desc-2">
                        <div class="job-manage-wrap mt-2">
                            <div class="profilelogo" *ngIf="detailsOfJob.is_3mtt">
                                <img [src]="'../../../assets/images/3mtt.svg'
                                            " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                                <h4 class="job-manage-title">{{ detailsOfJob?.title }}</h4>
                            </div>
                            <div class="profilelogo" *ngIf="!detailsOfJob.is_3mtt">
                                <img [src]="detailsOfJob.company_behalf_logo
                                                ? detailsOfJob.company_behalf_logo
                                                : this.companyData.companyLogo
                                                ? this.companyData.companyLogo
                                                : '../../../assets/images/sm-Logo.png'
                                            " onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                                <h4 class="job-manage-title">{{ detailsOfJob?.title }}</h4>
                            </div>
                        </div>
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap pt-4">
                            <h6>Job info</h6>
                        </div>
                        <div class="job-manage-desc-half">
                            <p>
                                <span class="plft">Job title</span>
                                <span class="prit">{{ detailsOfJob.title }}</span>
                            </p>
                            <p *ngIf="detailsOfJob.job_location">
                                <span class="plft">Job location</span>
                                <span class="prit">{{ detailsOfJob.job_location
                                    }}<span *ngIf="detailsOfJob?.city">/{{ detailsOfJob?.city }}</span></span>
                            </p>
                            <p>
                                <span class="plft">Job type</span>
                                <span class="prit">{{ detailsOfJob.employment_type }}</span>
                            </p>
                            <p>
                                <span class="plft">Minimum experience</span>
                                <span class="prit">{{ detailsOfJob.minimum_experience }}</span>
                            </p>
                            <div class="">
                                <p>
                                    <span class="plft">Salary</span>
                                    <span class="prit">{{ detailsOfJob.currency }} {{ detailsOfJob.minimum_salary |
                                        number
                                        }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>Job Description</h6>
                        </div>
                        <p class="job-manage-disc-list" [innerHTML]="detailsOfJob?.description"></p>

                        <hr class="job-manage-hr" />
                        <div class="job-manage-moreinfo-wrap">
                            <h6>Job About</h6>
                        </div>
                        <p class="job-manage-disc-list" [innerHTML]="detailsOfJob?.about"></p>
                    </div>
                </div>
            </div>
            <div class="no-post-yet" *ngIf="detailsOfJob == ''">
                <h4>No preview</h4>
                <button class="btn btn-green" (click)="routeToAddJob()">
                    + Add your first job
                </button>
            </div>
        </div>
    </div>
</section>