<div class="contact-us-page common-padd">
  <div class="container">
    <div class="contact-us-page-wraper">
      <div class="row align-items-center">
        <div class="col-lg-12 text-center">
          <h1>Contact Us</h1>
        </div>
        <div class="col-lg-4">
          <div class="contact-right-box">
            <h3>Contact Info</h3>
            <p><a href="mailto:helpdesk@worknigeria.com">Email: helpdesk@worknigeria.com</a></p>
            <p><a href="tel:09137000206">Phone: 09137000206</a></p>
            <p>
              Got Questions? Wondering how our services can help you thrive ?
            </p>
            <p>
              We're available 24/7 and 365 days to assist you with your unique
              requirements.
            </p>
            <p>
              Simply fill out the form below and someone from our team will
              reach out to you in no time
            </p>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="contact-frm-section">
            <div class="cntct-frm">
              <form [formGroup]="contactUsFrom">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Name"
                    required="required"
                    formControlName="Name"
                  />
                  <div *ngIf="f.Name.touched && f.Name.invalid">
                    <span
                      class="text-left text-danger pb-1"
                      *ngIf="f.Name.errors.required"
                    >
                      Name is required
                    </span>
                    <span
                      class="text-left text-danger pb-1"
                      *ngIf="f.Name.errors?.pattern"
                    >
                      Name is not valid
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Email Address"
                    required="required"
                    formControlName="email"
                    [pattern]="emailPattern"
                  />
                  <div *ngIf="f.email.touched && f.email.invalid">
                    <span
                      class="text-left text-danger pb-1"
                      *ngIf="f.email.errors.required"
                      >Email is required
                    </span>
                    <span
                      class="text-left text-danger pb-1"
                      *ngIf="f.email.errors?.pattern"
                    >
                      Email is not valid.
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    id=""
                    aria-describedby=""
                    placeholder="Phone Number"
                    required="required"
                    formControlName="number"
                    [pattern]="numberPattern"
                  />
                  <div *ngIf="f.number.touched && f.number.invalid">
                    <span
                      class="text-left text-danger pb-1"
                      *ngIf="f.number.errors.required"
                      >Number is required
                    </span>
                    <span
                      class="text-left text-danger pb-1"
                      *ngIf="f.number.errors?.pattern"
                    >
                      Number is not valid
                    </span>
                  </div>
                </div>
                <div class="form-group">
                  <!-- <select class="form-control" placeholder="Email Address" id="exampleFormControlSelect1" formControlName="selectedOption">
                                        <option>Please Select</option>
                                        <option value="General Inquiry">General Inquiry</option>
                                        <option value="Feedback">Feedback</option>
                                        <option value="Other">Other</option>(selectionChange)="selectedOption($event)"
                                        
                                    </select> -->
                  <mat-form-field style="padding: 12px 0 0 10px;">
                    <mat-label>Please Select</mat-label>
                    <mat-select formControlName="selectedOption">
                      <mat-option
                        *ngFor="let data of dropDownData"
                        value="{{ data.value }}"
                        >{{ data.label }}</mat-option
                      >
                      <!-- <mat-option value="Feedback">Feedback</mat-option>
                                            <mat-option value="Other">Other</mat-option> -->
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="form-group">    
                  <textarea
                    class="form-control"
                    id=""
                    rows="3"
                    placeholder="Write Message"
                    formControlName="Message"
                  ></textarea>
                </div>
                <!-- <input
                  type="submit"
                  value="Send message"
                  class="btn btn-green"
                  (click)="submitContactFrom()"
                /> -->
                <button class="btn btn-contact" (click)="submitContactFrom()">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
