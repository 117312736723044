<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<h4
  *ngIf="!show"
  style="display: flex; align-items: center; justify-content: space-between"
>
  Add Education
</h4>
<h4
  *ngIf="show"
  style="display: flex; align-items: center; justify-content: space-between"
>
  Edit Education
</h4>
<form class="example-form modal-dropdown" [formGroup]="educationForm">
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Degree/Course Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Degree/Course Name"
            required="required"
            formControlName="DegreeCourseName"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.DegreeCourseName.touched && f.DegreeCourseName.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.DegreeCourseName.errors.required"
          >Degree/Course Name is required
        </span>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>University/institute</mat-label>
          <input
            type="text"
            matInput
            placeholder="University/institute"
            required="required"
            formControlName="Universityinstitute"
          />
        </mat-form-field>
      </div>
      <div
        *ngIf="f.Universityinstitute.touched && f.Universityinstitute.invalid"
      >
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.Universityinstitute.errors.required"
          >University/institute is required
        </span>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <!-- <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>End year (or expected)</mat-label>
                        <input type="number" matInput placeholder="End year (or expected)" required="required"
                            formControlName="Endyear" />
                    </mat-form-field> -->
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>End year (or expected)</mat-label>
          <mat-select formControlName="Endyear">
            <mat-option
              value="{{ row }}"
              *ngFor="let row of Year; let i = index"
            >
              {{ row }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="f.Endyear.touched && f.Endyear.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.Endyear.errors.required"
          >End year is required
        </span>
      </div>
    </div>
  </div>
</form>
<p>
  <button
    *ngIf="show"
    mat-raised-button
    color="primary"
    (click)="removeEducation()"
  >
    Delete Education
  </button>
  <button
    *ngIf="!show"
    style="float: right"
    mat-raised-button
    color="primary"
    (click)="saveEducation()"
  >
    SAVE
  </button>
  <button
    *ngIf="show"
    style="float: right"
    mat-raised-button
    color="primary"
    (click)="updateEducation()"
  >
    UPDATE
  </button>
</p>
