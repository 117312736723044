<div class="main">
  <header>Elevate Your Hiring Game</header>
  <p class="main-subheading">Match your needs with the ideal Hiring Gear by subscribing to a plan</p>
  <div class="card-row">
    <div class="card" [ngClass]="{'current-plan': this.userPlan?.subscriptionPlan === 'Bronze'}">
      <div class="card-shape bg-brown"></div>

      <div class="top-items">
        <h6>BRONZE PLAN</h6>
        <p class="subheading">
          An affordable plan for our new user to test out our amazing features
        </p>
        <h2>
          FREE
        </h2>
        <p class="title brown">With BRONZE PLAN you can:</p>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">Post Jobs</div>
        <div class="info">Free</div>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">Manage Jobs</div>
        <div class="info">Free</div>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">Customize Company Profile</div>
        <div class="info">Free</div>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">Message Jobseekers</div>
        <div class="info">Free</div>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">Add Team Members</div>
        <div class="info">Free</div>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">
          Promote Job <span>- N5,000 Per Campaign</span>
        </div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Bronze'}">
        <div class="detail">
          Unlock CVs from our CV Bank <span>- N1,000 Per Profile</span>
        </div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="action-container">
        <div *ngIf="this.userPlan?.subscriptionPlan === 'Bronze'" class="current-plan-div">
          <img src="../../../../assets/images/check-mark.webp" class="bronze-check"/>
          <p class="current-plan-text bronze">Current Plan</p>
        </div>
        <div class="action-button bg-brown" *ngIf="this.userPlan?.subscriptionPlan !== 'Bronze'">
          <button
            *ngIf="this.userPlan?.subscriptionPlan !== 'Bronze'"
            class="action-button bg-brown action-text"
            (click)="cancelSubscription()"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>

    <div class="card" [ngClass]="{'current-plan': this.userPlan?.subscriptionPlan === 'Silver'}">
      <div class="recomm">
        <div class="recomm-text">Recommended</div>
      </div>
      <div class="card-shape bg-silver"></div>
      <div class="top-items">
        <h6 class="silver">SILVER PLAN</h6>
        <p class="subheading">
          A comprehensive plan for companies looking to hire at scale.
        </p>
        <h2 class="silver-dark">N50,000<span>/month</span></h2>
        <p class="title green">With SILVER PLAN you can:</p>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Post Jobs</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Manage Jobs</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Customize Company Profile</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Message Jobseekers</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Add Team Members</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Promote Jobs (5 Jobs)</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Silver'}">
        <div class="detail">Access (25) CVs from our CV Bank</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="action-container">
        <div *ngIf="this.userPlan?.subscriptionPlan === 'Silver'" class="current-plan-div">
          <img src="../../../../assets/images/check-mark.webp" class="silver-check"/>
          <p class="current-plan-text silver">Current Plan</p>
        </div>
        <div class="action-button bg-green" *ngIf="this.userPlan?.subscriptionPlan !== 'Silver'">
          <button
            *ngIf="this.userPlan?.subscriptionPlan !== 'Silver'"
            angular4-paystack
            class="action-button bg-green action-text"
            [key]="this.paystackKey"
            [email]="userDetails?.email"
            [amount]="silverPlan?.paymentAmount"
            [ref]="transactionRef"
            (onClose)="paymentCancel()"
            (callback)="paymentDone($event)"
            [plan]="silverPlan?.paystackID"
            (paymentInit)="updateTransactionId($event)"
            (click)="setPurchasingPlan('Silver')"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>

    <div class="card" [ngClass]="{'current-plan': this.userPlan?.subscriptionPlan === 'Gold'}">
      <div class="card-shape bg-yellow">
      </div>
      <div class="top-items">
        <h6 class="yellow-light">GOLD PLAN</h6>
        <p class="subheading">
          Unleash the power of premium features designed for companies with a vision for excellence.
        </p>
        <h2 class="yellow-dark">N100,000<span>/month</span></h2>
        <p class="title green">With GOLD PLAN you can:</p>
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Post Jobs</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Manage Jobs</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Customize Company Profile</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Message Applicants</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Add Team Members</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Promote Jobs (10 Jobs)</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Unlock (25) CVs from our CV Bank</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">(3) shortlisted Candidate for one Role</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="feature" [ngClass]="{'no-bg': this.userPlan?.subscriptionPlan === 'Gold'}">
        <div class="detail">Background Check for 2 Candidate</div>
        <img
          src="../../../../assets/images/info-icon.svg"
          alt="Icon"
          width="11"
          height="11"
        />
      </div>
      <div class="action-container">
        <div *ngIf="this.userPlan?.subscriptionPlan === 'Gold'" class="current-plan-div">
          <img src="../../../../assets/images/check-mark.webp" class="gold-check"/>
          <p class="current-plan-text gold">Current Plan</p>
        </div>
        <div class="action-button bg-yellow" *ngIf="this.userPlan?.subscriptionPlan !== 'Gold'">
          <button
            *ngIf="this.userPlan?.subscriptionPlan !== 'Gold'"
            angular4-paystack
            class="action-button bg-yellow action-text"
            [key]="this.paystackKey"
            [email]="userDetails?.email"
            [amount]="goldPlan?.paymentAmount"
            [ref]="transactionRef"
            (onClose)="paymentCancel()"
            (callback)="paymentDone($event)"
            [plan]="goldPlan?.paystackID"
            (paymentInit)="updateTransactionId($event)"
            (click)="setPurchasingPlan('Gold')"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="packs-main">
    <p class="packs-heading"><strong>Packs:</strong> Uncover the advantages and Delve into the flexibility of our 'pay-as-you-go' hiring packs</p>
    <div class="rectangle"></div>
  </div>
  <div class="packs-row">
    <div class="pack">
      <img class="pack-img" src="../../../../assets/images/fund-graphic.webp"/>
      <div class="pack-inner">
        <p class="pack-heading">Fund your wallet to Pay As You Use</p>
        <!-- <p class="pack-heading">As You Use</p> -->
        <p class="pack-desc">Campaign/Job - N5000</p>
        <p class="pack-desc">CV Search  - N1000/Profile</p>
      </div>
      <div class="button-container">
        <button class="learn-more blue" (click)="navigateToBundles('pay')">Learn More</button>
      </div>
    </div>
    <div class="pack white">
      <img class="pack-img" src="../../../../assets/images/pack-of-10.webp"/>
      <div class="pack-inner">
        <p class="pack-heading green">Promote A Pack of 5 Jobs</p>
        <!-- <p class="pack-heading green">Jobs</p> -->
        <p class="pack-desc-other">for one month at 50% discount</p>
        <p class="pack-price">N25,000</p>
      </div>
      <div class="button-container">
        <button class="learn-more green" (click)="navigateToBundles('campaign')">Learn More</button>
      </div>
    </div>
    <div class="pack orange">
      <img class="pack-img" src="../../../../assets/images/pack-of-25.webp"/>
      <div class="pack-inner">
        <p class="pack-heading orange">Unlock A Pack of 25 CV</p>
        <!-- <p class="pack-heading orange">Pack of 25 CV</p> -->
        <p class="pack-price gray">For N25,000</p>
      </div>
      <div class="button-container">
        <button class="learn-more orange" (click)="navigateToBundles('cv')">Learn More</button>
      </div>
    </div>
  </div>
</div>
