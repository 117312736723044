import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
@Component({
  selector: 'app-company-verification',
  templateUrl: './company-verification.component.html',
  styleUrls: ['./company-verification.component.scss'],
})
export class CompanyVerificationComponent implements OnInit {
  VerificationForm: UntypedFormGroup;
  userDeatils: any;
  companyData: any;
  fileToUpload: any;
  cacNumberCheck = /^[a-zA-Z]{2}\d+$/;

  constructor(
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialog,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private empService: EmployerService,
    private dialog: MatDialog,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.validationForm();
  }
  // Validation Controls //
  get f() {
    return this.VerificationForm.controls;
  }


  validationForm() {
    this.VerificationForm = this.fb.group({
      CompanyName: [this.companyData.companyName, Validators.required],
      CompanyAddress: ['', Validators.required],
      CompanyWebsite: [''],
      registrationNumber: ['', [Validators.required, Validators.pattern(this.cacNumberCheck)]],
      // NameContactPerson: [this.companyData.primaryContactPersonName,[Validators.required, this.nameContactPersonValidator]],
      // PhoneNumerContactPerson: [this.companyData.primaryContactPersonPhoneNo,[Validators.required, this.contactPersonPhoneNumberValidator]],
      // email: [this.companyData.email, [Validators.required, this.emailValidator]],

    });
  }

  selectedFile: File | null = null;
  fileName: string | null = null;
  uploadProgress = 0;

  // Takes the file added as input and uploads it
  onFileSelected(event: any): void {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      this.fileName = fileInput.files[0].name;
      this.uploadProgress = 0; 
      this.fileToUpload = fileInput.files[0]; // save the file here to upload 

      // Clear file input to allow re-uploading
      fileInput.value = '';

      this.uploadFile();
    } else {
      this.selectedFile = null;
      this.fileName = null;
      this.fileToUpload = null;
    }
  }

  // To trigger the progress bar on file upload
  uploadFile(): void {
    const interval = setInterval(() => {
      this.uploadProgress += 10;
      if (this.uploadProgress >= 100) {
        clearInterval(interval);
      }
    }, 1000);
  }

  
  // Custom Validators to add proper validations
  companyNameValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const companyName = control.value;
    const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(companyName);

    if (hasSpecialCharacters) {
      return { hasSpecialCharacters: true };
    }

    return null;
  }

  phoneNumberValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const phoneNumber = control.value;
    const isValid = /^[a-zA-Z]{2}\d+$/.test(phoneNumber);

    if (!isValid) {
      return { phoneNumberInvalid: true };
    }

    return null;
  }

  nameContactPersonValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const name = control.value;
    const isValid = /^[A-Za-z]*$/.test(name);

    if (!isValid) {
      return { nameContactPersonInvalid: true };
    }

    return null;
  }

  contactPersonPhoneNumberValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const contactPersonPhoneNumber = control.value;
    const isValid = /^\+?\d*$/.test(contactPersonPhoneNumber);

    if (!isValid) {
      return { contactPersonPhoneNumberInvalid: true };
    }

    return null;
  }

  emailValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const email = control.value;
    const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    if (!isValid) {
      return { emailInvalid: true };
    }

    return null;
  }

  submit() {
    if (!this.fileToUpload) {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please select a CAC ',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    if (this.VerificationForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.VerificationForm);
      return false;
    }
    // let jsonData = {
    //   path: 'verify-company',
    //   company_id: this.companyData.id,
    //   companyName: this.VerificationForm.value.CompanyName,
    //   email: this.VerificationForm.value.email,
    //   companyLocation: this.VerificationForm.value.CompanyAddress,
    //   cacCertificate: '',
    //   phone: this.VerificationForm.value.PhoneNumber,
    //   primaryContactPersonName: this.VerificationForm.value.NameContactPerson,
    //   primaryContactPersonPhoneNo:
    //     this.VerificationForm.value.PhoneNumerContactPerson,
    // };
    let jsonData = {
      path: 'verify-company-cac',
      company_id: this.companyData.id,
      cac_number: this.VerificationForm.value.registrationNumber,
      cac_certificate: '',
    }
    const formData = new FormData();
    formData.append('path', 'upload-cac-certificate');
    formData.append('file', this.fileToUpload);
    formData.append('purpose', 'cacCertificateUpload');
    this.empService._CAC_upload(formData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        jsonData.cac_certificate = res.result.cac_certificate;
        this.empService._CAC_verify(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: 'auto',
              autoFocus: false,
              data: {
                data: 'verify company',
              },
            });
            dialogRef.afterClosed().subscribe();
          } else {
            this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          }
        });
      }
    });
  }
}
