import {
  FormControl,
  UntypedFormGroup,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AbstractControl } from '@angular/forms';

export function PasswordValidation(
  controlName: string,
  matchingControlName: string
) {
  // static MatchPassword(AC: AbstractControl) {
  //    let password = AC.get('password').value; // to get value in input tag
  //    let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
  //     if(password != confirmPassword) {
  //         //console.log('false');
  //         AC.get('confirmPassword').setErrors( {MatchPassword: true} )
  //     } else {
  //         //console.log('true');
  //         return null
  //     }
  // }
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.notEqual) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ notEqual: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
