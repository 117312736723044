import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgTinyUrlService } from 'ng-tiny-url';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-jobseeker-share-profile',
  templateUrl: './jobseeker-share-profile.component.html',
  styleUrls: ['./jobseeker-share-profile.component.scss'],
})
export class JobseekerShareProfileComponent implements OnInit {
  url: any;
  record: any;
  title: any;
  showTittle: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tinyUrl: NgTinyUrlService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    // console.log(this.data['userData'])
    this.record = this.data['userData'];
    this.title = 'Share profile ..!  ';
    this.url = `https://worknigeria.com/profile?id=${this.record.publicId}`;
    // this.url = `http://localhost:4200/profile?id=${this.record.publicId}`;
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  share() {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${this.url}`
    );
  }
}
