import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EmployerComponent } from './employer.component';
import { EmployerAddjobComponent } from './employer-addjob/employer-addjob.component';
import { EmpDashboardComponent } from './emp-dashboard/emp-dashboard.component';
import { EmpManageJobsComponent } from './emp-manage-jobs/emp-manage-jobs.component';
import { EmpEditjobComponent } from './emp-editjob/emp-editjob.component';
import { AlertsComponent } from './alerts/alerts.component';
import { MessageComponent } from './../message/message.component';

import { AuthGuard } from 'src/app/gaurd/auth.guard';
import { JobProviderProfileComponent } from './job-provider-profile/job-provider-profile.component';
import { GenPasswordComponent } from './gen-password/gen-password.component';
import { AuthEmployerGuard } from 'src/app/gaurd/auth-employer.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { AppliedJobseekerComponent } from './emp-dashboard/emp-jobs/applied-jobseeker/applied-jobseeker.component';
import { EmpDashPromoteJobsComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-promote-jobs/emp-dash-promote-jobs.component';
import { EmpDashManagementComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-management/emp-dash-management.component';
import { EmpDashCvComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-cv/emp-dash-cv.component';
import { EmpDashPaymentComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-payment/emp-dash-payment.component';
import { EmpDashHelpComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-help/emp-dash-help.component';
import { EmpDashSavedCvComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-saved-cv/emp-dash-saved-cv.component';
import { EmpFundWalletComponent } from './emp-dashboard/emp-fund-wallet/emp-fund-wallet.component';
import { CompanyVerificationComponent } from './company-verification/company-verification.component';
import { EmpDashAdminComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-admin/emp-dash-admin.component';

const routes: Routes = [
  { path: '', component: EmployerComponent },
  {
    path: 'invitationLink',
    component: GenPasswordComponent,
    data: { title: 'Gen Password' },
  },
  {
    path: 'post-job',
    component: EmployerAddjobComponent,
    data: { title: 'Add Job' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'Dashboard',
    component: EmpDashboardComponent,
    data: { title: 'Dashboard' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'manage-job',
    component: EmpManageJobsComponent,
    data: { title: 'Manage Jobs' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'edit-job',
    component: EmpEditjobComponent,
    data: { title: 'Edit Jobs' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'email-alerts',
    component: AlertsComponent,
    data: { title: 'Alerts' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'jobProvider-profile',
    component: JobProviderProfileComponent,
    data: { title: 'Job Provider Profile' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'applied-jobs',
    component: AppliedJobseekerComponent,
    data: { title: 'Applied Jobs' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'promote-job',
    component: EmpDashPromoteJobsComponent,
    data: { title: 'Promote Jobs' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'search-cv',
    component: EmpDashCvComponent,
    data: { title: 'Search CV' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'saved-cv',
    component: EmpDashSavedCvComponent,
    data: { title: 'Saved CV' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'payment',
    component: EmpDashPaymentComponent,
    data: { title: 'Payment' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'fund-wallet',
    component: EmpFundWalletComponent,
    data: { title: 'Fund Wallet' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'help',
    component: EmpDashHelpComponent,
    data: { title: 'Help' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'job-management',
    component: EmpDashManagementComponent,
    data: { title: 'Job Management' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'overview',
    component: EmpDashAdminComponent,
    data: { title: 'Overview Super Admin' },
    canActivate: [AuthEmployerGuard],
  },
  {
    path: 'verification',
    component: CompanyVerificationComponent,
    data: { title: 'verification' },
    canActivate: [AuthEmployerGuard],
  },
  // {
  //   path: 'message',
  //   component: MessageComponent,
  //   data: {
  //     title: 'message',
  //   },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes),  MatDialogModule,],
  exports: [RouterModule],
})
export class EmployerRoutingModule {}
