<div class="jobseeker-ui">
    <h2 class="jobseeker-heading">Jobseekers</h2>
    <div class="search-section">
        <div class="search-container">
            <div class="search-bar">
                <img src="../../../../../../assets/images/search-icon.svg" alt="Search Icon" class="search-icon">
                <input type="text" placeholder="Search Jobseeker" [(ngModel)]="searchQuery" (ngModelChange)="checkSearchQuery()">
                <button (click)="onSearch()" class="search-button">Search</button>
            </div>
        </div>
        <div class="date-container">
            <div class="date-input-wrapper">
                <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" class="date-input" placeholder="Start from" [(ngModel)]="startDate" #dateInput>
                <img *ngIf="!startDate" 
                    src="../../../../../../assets/images/calendar-icon.svg" 
                    alt="Calendar Icon" 
                    class="calendar-icon" 
                    (click)="dateInput.click()">

                <img *ngIf="startDate" 
                    src="../../../../../../assets/images/cal-cancel.svg" 
                    alt="Clear Icon" 
                    class="calendar-icon" 
                    (click)="clearStartDate()">
            </div>
            <div class="date-input-wrapper">
                <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" class="date-input" placeholder="To" [(ngModel)]="endDate" #dateInput2>
                <img *ngIf="!endDate" 
                    src="../../../../../../assets/images/calendar-icon.svg" 
                    alt="Calendar Icon" 
                    class="calendar-icon" 
                    (click)="dateInput2.click()">

                <img *ngIf="endDate" 
                    src="../../../../../../assets/images/cal-cancel.svg" 
                    alt="Clear Icon" 
                    class="calendar-icon" 
                    (click)="clearEndDate()">
            </div>
        </div>
        <div class="btn-set">
            <a href="javascript:void(0);" class="btn border" (click)="exportJobseekers()"><img
                    src="../assets/images/el.png" alt="" /></a>
        </div>
    </div>
        <div class="emp-table-section">
            <table class="table table-for-employer">
                <thead>
                    <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>EMAIL</th>
                        <th>STATUS</th>
                        <!-- <th>LAST SEEN</th> -->
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let jobseeker of paginatedRoles">
                        <td>{{ jobseeker.firstName }}</td>
                        <td>{{ jobseeker.lastName }}</td>
                        <td>{{ jobseeker.email }}</td>
                        <td>
                            <div class="dropdown-container">
                                <select [disabled]="true" [(ngModel)]="jobseeker.isEmailVerified" (ngModelChange)="onVerificationChange(jobseeker)"
                                    class="custom-dropdown" [ngClass]="jobseeker.isEmailVerified ? 'green-back' : 'red-back'">
                                    <option [ngStyle]="{ 'background-color': 'white', 'border': 'none', 'border-radius' : '0' }" value="false">Need Verification
                                    </option>
                                    <option [ngStyle]="{ 'background-color': 'white', 'border': 'none', 'border-radius' : '0' }" value="true">Verified</option>
                                </select>
                            </div>
                        </td>
                        <!-- <td>to be added</td> -->
                        <td class="d-flex align-items-center gap-2">
                            <img src="../../../../../../assets/images/green-msg-icon.png" class="msg-icon" (click)="messageOpen(jobseeker)"/>
                            <label class="toggle">
                                <input type="checkbox" (change)="activeInactive(jobseeker.id)" [checked]="jobseeker.isUserActive" />
                                <span class="slider round"></span>
                                <span class="toggle-text"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation" class="mb-1">
                <ngb-pagination [collectionSize]="this.searchInitiated ? filteredJobseekersList.length : jobseekersList?.jobSeekerData.length" [(page)]="currentPage"
                    [pageSize]="pageSize" (pageChange)="onPageChanged($event)" [maxSize]="10" [ellipses]="false">
                </ngb-pagination>
            </nav>
        </div>
    </div>