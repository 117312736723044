import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';

@Injectable()
export class EmailService {
  constructor(private http: HttpClient) {}
  async commonService(rawData: any, token: string, path: string) {
    try {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });
      let options = { headers: headers };
      const body = rawData;
      const data = await this.http
        .post(`${environment.baseUrl}${path}`, body, options)
        .toPromise();
      return data;
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
    return 'Somthing wrong ';
  }
}

@Component({
  selector: 'new_user_dialog',
  providers: [EmailService],
  templateUrl: 'new-user-dialog.html',
})
export class NewUserDialog {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
  companyDetails: any;
  isView: boolean = false;
  userDetails: any;
  isValid: boolean;
  emailExist: boolean;
  path: any = {
    emailAvailUrl: 'auth/is-email-available',
  };
  isAval: boolean;

  constructor(
    public dialogRef: MatDialogRef<NewUserDialog>,
    private emailService: EmailService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.companyDetails = JSON.parse(
      atob(sessionStorage.getItem('workNigeriacompanyData'))
    );

    console.log(this.data);
    this.userDetails = data['users'];
    Object.assign(this.userDetails, { type: data['type'] });

    if (this.userDetails.type == 'view' || this.userDetails.type == 'edit') {
      if (this.userDetails.type == 'edit') {
        this.isView = false;
      } else {
        this.isView = true;
      }
      this.firstName = this.userDetails.firstName;
      this.lastName = this.userDetails.lastName;
      this.email = this.userDetails.email;
      this.role = this.userDetails.userRole;
    }
  }

  ngOnInit() {}

  async emailIsAvailable(email) {
    let body = { email: email, path: 'is-email-available' };
    await this.emailService
      .commonService(body, '', this.path.emailAvailUrl)
      .then((res) => {
        if (res['serverResponse']['code'] == 200) {
          this.emailExist = true;
        } else if (res['serverResponse']['code'] == 400) {
          this.emailExist = false;
        }
      })
      .catch((e) => {
        this.emailExist = false;
        console.log(e);
      });
    return this.emailExist;
  }

  async onFormSubmit() {
    this.isValid = false;
    if (
      this.firstName != undefined &&
      this.firstName != '' &&
      this.lastName != undefined &&
      this.lastName != '' &&
      this.email != undefined &&
      this.email != '' &&
      this.role != undefined &&
      this.role != ''
    ) {
      if (this.userDetails.type != 'edit') {
        this.isAval = await this.emailIsAvailable(this.email);
      }

      this.isValid = true;
      if (this.isAval == true || this.userDetails.type == 'edit') {
        if (this.data.superAdminSelectedCompany) {
          var body = {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            companyId: this.data.superAdminSelectedCompany.id,
            userRole: this.role,
          };
        } else {
          var body = {
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            companyId: this.companyDetails.id,
            userRole: this.role,
          };
        }
        console.log(body);
        if (this.userDetails.type == 'edit') {
          body['path'] = 'update-user';
          body['id'] = this.userDetails.id;
          this.dialogRef.close({ event: 'edit', body: body });
        } else {
          body['path'] = 'add-user';
          this.dialogRef.close({ event: 'create', body: body });
        }
      }
    }
  }
}
