import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';
import { EmployerService } from 'src/app/services/employer.service';
import { ExportEmployerAppliedJobsService } from 'src/app/services/export-employer-applied-jobs.service';
import { DatePipe } from '@angular/common';
import { ChangeStatusComponent } from '../../models/change-status/change-status.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-applied-jobseeker',
  templateUrl: './applied-jobseeker.component.html',
  styleUrls: ['./applied-jobseeker.component.scss'],
})
export class AppliedJobseekerComponent implements OnInit {
  @Input() appliedJobseeker: any;
  @Input() selectedJobId: any;
  @Input() selectedJob: any;
  statusList: any = [
    'in-progress',
    'applied',
    'interviewing',
    'withdrawn',
    'rejected',
    'hired',
    'hold for future openings',
  ];
  appliedDashBoardDetails: UntypedFormGroup;
  filtered: any = {};
  clearButton: boolean = false;
  copyAppliedJobseeker: any;
  noJobFound: boolean;
  sortfuction: boolean = true;
  dataSource: any;
  displayedColumns: any;
  userDetails: any;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private employerService: EmployerService,
    private router: Router,
    private helper: Helper,
    private ExportAppliedJobsList: ExportEmployerAppliedJobsService,
    private datepipe: DatePipe,
    private _liveAnnouncer: LiveAnnouncer
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');

    if (localUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
    }
    if (sessionUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
    }
   }

  ngOnInit(): void {
    this.displayedColumns = this.userDetails.is_3mtt ? ['createdAt', 'firstName', 'lastName', 'is_3mtt', 'email', 'appliedData.appliedStatus'] : ['createdAt', 'firstName', 'lastName', 'email', 'appliedData.appliedStatus'];
    this.dataSource = new MatTableDataSource(this.appliedJobseeker);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.copyAppliedJobseeker = this.appliedJobseeker;
    this.validationForm();
  }

  // Insilize Form //
  validationForm() {
    this.appliedDashBoardDetails = this.fb.group({
      firstName: [''],
      appliedStatus: [''],
    });
  }

  onKey(event) {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      this.searchByDependency();
    }
  }

  changeStatus(event) {
    console.log(event.value);
    this.searchByDependency();
  }

  searchByDependency() {
    this.filtered = {
      path: 'get-applied-job-seeker',
      jobId: this.selectedJobId,
    };
    if (this.appliedDashBoardDetails) {
      for (let key in this.appliedDashBoardDetails.value) {
        if (this.appliedDashBoardDetails.value[key]) {
          this.filtered[key] = this.appliedDashBoardDetails.value[key];
        }
      }
    }
    console.log(this.filtered);
    this.employerService
      ._getAppliedJobseeker(this.filtered)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
          this.appliedJobseeker = res['result'].userData;
          this.clearButton = true;
          this.noJobFound = false;
        }
        if (res.serverResponse.code == 400) {
          this.clearButton = true;
          this.noJobFound = true;
          this.appliedJobseeker = [];
        }
      });
  }

  resetFrom() {
    this.appliedDashBoardDetails.reset();
    this.clearButton = false;
    this.noJobFound = false;
    this.appliedJobseeker = this.copyAppliedJobseeker;
  }

  cvOpen(data: any) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].purpose == 'resume') {
          window.open(data[i].url);
        }
      }
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: 'No CV found',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  coverOpen(data: any) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].purpose == 'coverLetter') {
          window.open(data[i].url);
        }
      }
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: 'No Cover-letter found',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  hasCoverLetter(data: any): boolean {
    return data.some(doc => doc.purpose === 'coverLetter');
  }

  messageOpen(row) {
    this.router.navigate(['/message']);
    this.employerService.setforSeletedIn_cvSearch(row);
  }

  wnProfileOpen(row) {
    window.open(`${window.location.origin}/profile?id=${row.publicId}`);
  }

  getJobTittle(row) {
    // console.log(row)
    if (row.length > 0) {
      for (let i = 0; i < row.length; i++) {
        // console.log(this.appliedJobseeker[0].experience[i])
        if (row[i].stillWorking == true) {
          return row[i].title;
        }
      }
    } else {
      return '-';
    }
  }

  getJobCompany(row) {
    // console.log(row)
    if (row.length > 0) {
      for (let i = 0; i < row.length; i++) {
        // console.log(this.appliedJobseeker[0].experience[i])
        if (row[i].stillWorking == true) {
          return row[i].company;
        }
      }
    } else {
      return '-';
    }
  }


  // Format the date to month. date
  formatDateString(dateString: string): string {
    const date = new Date(dateString);
    const format = 'MMM. dd';
    return this.datepipe.transform(date, format);
  }

  changeStatusModel(status, wholeData) {
    const dialogRef = this.dialog.open(ChangeStatusComponent, {
      height: '450px',
      width: '650px',
      data: {
        status: status,
        wholeData: wholeData,
        selectedJob: this.selectedJob,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        this.filtered = {
          path: 'get-applied-job-seeker',
          jobId: this.selectedJobId,
        };
        this.employerService
          ._getAppliedJobseeker(this.filtered)
          .subscribe((res: any) => {
            if (res.serverResponse.code == 200) {
              this.appliedJobseeker = res['result'].userData;
              this.noJobFound = false;
            }
            if (res.serverResponse.code == 400) {
              this.noJobFound = true;
              this.appliedJobseeker = [];
            }
          });
      }
    });
  }

  exportexcel() {
    this.ExportAppliedJobsList.genExcel(this.appliedJobseeker);
  }

  sortName() {
    if (this.sortfuction == true) {
      console.log('true');
      this.appliedJobseeker = this.appliedJobseeker.sort((a, b) =>
        a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
      );
    } else {
      console.log('false');
      this.appliedJobseeker = this.appliedJobseeker.sort((a, b) =>
        b.firstName > a.firstName ? 1 : a.firstName > b.firstName ? -1 : 0
      );
    }
    this.sortfuction = !this.sortfuction;
    // console.log( this.sortfuction);
  }

  openJobOverview() {
    this.router.navigate(['Dashboard'], { queryParams: ['job-overview'] });
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
