import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { CompleteRegistrationComponent } from '../../complete-registration/complete-registration.component';
import { LoginComponent } from '../../login/login.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { environment } from 'src/environments/environment';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { EmailNotificationComponent } from '../../email-notification/email-notification.component';
@Component({
  selector: 'app-sign-up-employer',
  templateUrl: './sign-up-employer.component.html',
  styleUrls: ['./sign-up-employer.component.scss'],
})
export class SignUPEmployerComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  employerSignup: UntypedFormGroup;
  email: any;
  role: any;
  googleValue: any;
  fbValue: any;
  GuestUserjobId: any;
  userSocialLogin: any;
  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private socialauthService: SocialAuthService,
  ) {
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.GuestUserjobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.role = params['_role'];
    });
  }

  ngOnInit(): void {
    this.formInsli();
  }

  // Create Modal Driven Form //
  formInsli() {
    this.employerSignup = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });

    if (this.email && this.role == 'employer') {
      this.employerSignup.patchValue({
        email: this.email,
      });
    }
  }

  // Validation Controls //
  get f() {
    return this.employerSignup.controls;
  }

  // Next Step of Employer SignUP //
  async completeRegistration() {
    if (this.employerSignup.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.employerSignup);
      return false;
    }
    var emailVeryfy: any = await this.helper.emailVerify(
      this.employerSignup.value.email
    );
    if (
      emailVeryfy.serverResponse.code == 200 &&
      emailVeryfy.serverResponse.message == 'Email available to use.'
    ) {
      this.router.navigate(['/complete-registration'], {
        queryParams: {
          role: 'employer',
          email: this.employerSignup.value.email,
        },
      });
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: emailVeryfy.serverResponse.message,
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  login() {
    this.router.navigate(['/login']);
  }

    // SIGNIN WITH FACEBOOK //
    signInWithFB(): void {
      this.socialauthService
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((fbResponse: any) => {
          console.log(fbResponse);
          if (!fbResponse.email) {
            const dialogRef = this.dialog.open(EmailNotificationComponent, {
              height: 'auto',
              width: '450px',
              hasBackdrop: false,
              data: {},
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res) {
                // console.log(res);
                this.fbValue = res;
                // this.loginWithSocialTwitter();
              }
            });
            return false;
          }
          this.userSocialLogin = {
            socialId: fbResponse.id,
            email: fbResponse.email ? fbResponse.email : this.fbValue,
            socialType: 'facebook',
            userType: 'jobseeker',
            path: 'social-signup',
            firstName: fbResponse.firstName,
            lastName: fbResponse.lastName,
            profileImage: fbResponse.response.picture.data.url
              ? fbResponse.response.picture.data.url
              : '',
          };
          console.log(this.userSocialLogin);
          this.io
            .apicall(this.userSocialLogin, 'auth/social-signup')
            .subscribe((res: any) => {
              console.log(res);
              if (res.serverResponse.code == 200) {
                this.snackBar
                  .openFromComponent(DynamicSnacbarComponent, {
                    data: 'Your account has been successfully created. Please check your email to verify your account',
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
                this.router.navigate(['/login']);
              } else {
                this.snackBar
                  .openFromComponent(DynamicSnacbarErrorComponent, {
                    data: res.serverResponse.message,
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
              }
            });
        });
    }
  
    // SIGNIN WITH GOOGLE //
    signInWithgoogle(): void {
      this.socialauthService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((googleResponse: any) => {
          if (!googleResponse.email) {
            const dialogRef = this.dialog.open(EmailNotificationComponent, {
              height: 'auto',
              width: '450px',
              hasBackdrop: false,
              data: {},
            });
            dialogRef.afterClosed().subscribe((res) => {
              if (res) {
                // console.log(res);
                this.googleValue = res;
                // this.loginWithSocialTwitter();
              }
            });
            return false;
          }
          console.log(googleResponse);
          this.userSocialLogin = {
            socialId: googleResponse.id,
            email: googleResponse.email ? googleResponse.email : this.googleValue,
            socialType: 'google',
            userType: 'jobseeker',
            path: 'social-signup',
            firstName: googleResponse.firstName,
            lastName: googleResponse.lastName,
          };
  
          this.io
            .apicall(this.userSocialLogin, 'auth/social-signup')
            .subscribe((res: any) => {
              console.log(res);
              if (res.serverResponse.code == 200) {
                this.snackBar
                  .openFromComponent(DynamicSnacbarComponent, {
                    data: 'Your account has been successfully created. Please check your email to verify your account',
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
                this.router.navigate(['/login']);
              } else {
                this.snackBar
                  .openFromComponent(DynamicSnacbarErrorComponent, {
                    data: res.serverResponse.message,
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
              }
            });
        });
    }
  
    // Login With Linkedin //
    loginWithLinkedin() {
      window.location.href = `https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=${environment.linkedInCredentials.clientId}&redirect_uri=${environment.linkedInCredentials.redirectUrl}&state=signup&scope=${environment.linkedInCredentials.scope}`;
    }
}
