import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EmployerService } from 'src/app/services/employer.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { Router } from '@angular/router';
import { ExportAdminJobseekerService } from 'src/app/services/export-admin-jobseeker.service';
import moment from 'moment';
@Component({
  selector: 'app-jobseeker-super-admin',
  templateUrl: './jobseeker-super-admin.component.html',
  styleUrls: ['./jobseeker-super-admin.component.scss']
})
export class JobseekerSuperAdminComponent {
  startDate: string = '';
  endDate: string = '';
  jobseekersList: any;
  filteredJobseekersList: any = [];
  pagination = {
    size: 8,
    start: 0,
    total: 0,
  };
  currentPage: number = 1;
  pageSize: number = 7;
  selectedOption = 'Need Verification';
  searchQuery: string = '';
  sortDate: string = '';
  searchInitiated: boolean = false;

  constructor(
    private empService: EmployerService,
    private snackBar: MatSnackBar,
    private helper: Helper,
    private io: HttpIOService,
    private exportJobseekerService: ExportAdminJobseekerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    // this.startDate = '2022-12-27'
    this.getJobseekersAnalytics();
  }

  onSearch(): void {
    const strtDt = this.startDate ? moment.utc(this.startDate) : null;
    const endDt = this.endDate ? moment.utc(this.endDate) : null;
    this.searchInitiated = true
    this.filteredJobseekersList = this.jobseekersList?.jobSeekerData?.filter((item) => {
      const itemDate = moment(item.createdAt);
      const isWithinDateRange = 
        (!strtDt || itemDate.isSameOrAfter(strtDt)) && 
        (!endDt || itemDate.isSameOrBefore(endDt));
      const matchesQuery = 
        item.firstName?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
        item.email?.toLowerCase().includes(this.searchQuery.toLowerCase())
      
      return isWithinDateRange && matchesQuery;
    });
    console.log(this.filteredJobseekersList)
    this.currentPage = 1
  }

  checkSearchQuery(): void {
    if(this.searchInitiated && this.searchQuery === '') {
      this.searchInitiated = false
      this.filteredJobseekersList = []
    }
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
  }

  clearEndDate(): void {
    this.endDate = null
  }

  clearStartDate(): void {
    this.startDate = null
  }

  get paginatedRoles() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.searchInitiated ? this.filteredJobseekersList?.slice(startIndex, endIndex) : this.jobseekersList?.jobSeekerData.slice(startIndex, endIndex);
  }

  // Gets the jobseeker list from backend
  async getJobseekersAnalytics() {
    let params = new HttpParams();
    params = this.startDate && params.append('startDate', this.startDate);
    await this.empService._getJobSeekers(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.jobseekersList = res['result'];
      }
    });
  }

  // Saves the Jobseekers list as an Excel Sheet
  async exportJobseekers() {
    this.exportJobseekerService.genExcel(this.searchInitiated ? this.filteredJobseekersList : this.jobseekersList.jobSeekerData);
  }

  // ACTIVE OR INACTIVE SLIDE
  async activeInactive(userId: String) {
    await this.empService._toggleUserStatus({id: userId}).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        console.log(res);
      }
    });
  }

  onVerificationChange(company: any): void {
    console.log('Verification status changed for:', company.companyName, 'New status:', company.verified);
  }

/**
 * Navigates the user to the chat screen.
 * @param {Object} row - employer data for messaging.
 */
  messageOpen(row) {
    this.router.navigate(['/message']);
    this.empService.setforSeletedIn_cvSearch(row);
  }

}
