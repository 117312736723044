<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<h4>Log in to save jobs</h4>
<br />
<div>
  Already a member of WorkNigeria?
  <a
    (click)="gotoLogin()"
    style="color: #3eab70; text-decoration: none; font-weight: 700"
    >Log in</a
  >
  Log in to save this job.
</div>
<br />
<br />
<p>
  If you haven’t joined yet,
  <a
    (click)="gotoSingUp()"
    style="color: #3eab70; text-decoration: none; font-weight: 700"
    >sign up</a
  >
  now for a free jobseeker account. Registration allows you to save and share
  jobs, create a CV, and set up personalized job alerts.
</p>
<!-- <button class="btn bdr" (click)="gotoSingUp()">sign up</button> -->
<a href="javascript:void(0);" (click)="gotoSingUp()" class="btn btn-green">
  sign up
</a>
&nbsp; &nbsp;

<!-- <button class="btn green-btn" (click)="gotoLogin()">login</button> -->
<a href="javascript:void(0);" (click)="gotoLogin()" class="btn btn-green">
  login
</a>
