<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row">
            <div class="col-lg-6">
              <div class="log-lt">
                <h3>Sign Up</h3>

                <mat-tab-group
                  #tabs
                  [(selectedIndex)]="selectedTabIndex"
                  mat-align-tabs="center"
                >
                  <mat-tab label="JOBSEEKER" [disabled]="jobseeker">
                    <app-complete-registration-jobseeker></app-complete-registration-jobseeker>
                  </mat-tab>

                  <mat-tab label="EMPLOYER" [disabled]="employer">
                    <app-complete-registration-employer></app-complete-registration-employer>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img
                    src="../../../../assets/images/Forgot-password.png"
                    alt=""
                  />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
