import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'restricted-job-view-modal',
  templateUrl: './restricted-job-view-modal.component.html',
  styleUrls: ['./restricted-job-view-modal.component.scss'],
})
export class RestrictedJobViewModal implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RestrictedJobViewModal>,
    public dialog: MatDialog,
    private router: Router,

  ) {}

  ngOnInit(): void {}

  // Closed Modal //
  closeModal() {
    this.dialogRef.close(true);
  }
  redirectUser(){
    this.router.navigate(['/employer-home']);
  }
}
