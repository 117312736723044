import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { HomeComponent } from './home/home.component';
import { MaterialModule } from '../../modules/material/material.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUPJonSeekerComponent } from '../auth/sign-up/sign-up-jon-seeker/sign-up-jon-seeker.component';
import { SignUPEmployerComponent } from './sign-up/sign-up-employer/sign-up-employer.component';
import { CompleteRegistrationComponent } from './complete-registration/complete-registration.component';
import { CompleteRegistrationJobseekerComponent } from './complete-registration/complete-registration-jobseeker/complete-registration-jobseeker.component';
import { CompleteRegistrationEmployerComponent } from './complete-registration/complete-registration-employer/complete-registration-employer.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailedCodeComponent } from './emailed-code/emailed-code.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AuthProcessComponent } from './auth-process/auth-process.component';
import { EmailNotificationComponent } from './email-notification/email-notification.component';
import { SubscribtionComponent } from './subscribtion/subscribtion.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CompanyApprovalActionComponent } from './company-approval-action/company-approval-action.component';
import { HomeNewComponent } from './home-new/home-new.component';
import { EmployerHomeComponent } from './employer-home/employer-home.component';
import { ServiceComponent } from './service/service.component';
import { EmployerSignUpComponent } from './employer-sign-up/employer-sign-up.component';
import { EmployerSubscriptionsComponent } from './employer-subscriptions/employer-subscriptions.component';
import { Angular4PaystackModule } from 'angular4-paystack';
import { EmployerBundlesComponent } from './employer-bundles/employer-bundles.component';
import { GoogleSigninComponent } from './google-signin/google-signin.component';
import { TrainingDevelopmentComponent } from './training-development/training-development.component';

@NgModule({
  declarations: [
    GoogleSigninComponent,
    AuthComponent,
    HomeComponent,
    LoginComponent,
    SignUpComponent,
    SignUPJonSeekerComponent,
    SignUPEmployerComponent,
    CompleteRegistrationComponent,
    CompleteRegistrationJobseekerComponent,
    CompleteRegistrationEmployerComponent,
    ForgotPasswordComponent,
    EmailedCodeComponent,
    ChangePasswordComponent,
    NewPasswordComponent,
    VerifyEmailComponent,
    AuthProcessComponent,
    EmailNotificationComponent,
    SubscribtionComponent,
    AboutUsComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    AppSettingsComponent,
    CompanyApprovalActionComponent,
    HomeNewComponent,
    EmployerHomeComponent,
    ServiceComponent,
    EmployerSignUpComponent,
    EmployerSubscriptionsComponent,
    EmployerBundlesComponent,
    TrainingDevelopmentComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    MaterialModule,
    SharedModule,
    CarouselModule,
    Angular4PaystackModule,
  ]
})
export class AuthModule {}
