<div
  property="schema:text"
  class="trmsncnd clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item common-padd"
>
  <div class="container">
    <div class="row">
      <!-- <button (click)="scrollToId('elId')">Scroll to Element by Id</button>
            <button (click)="scrollToElement(target)">Scroll to Element</button> -->

      <div class="col-lg-12">
        <h2><span>Terms &amp; Conditions</span></h2>

        <h4><span>1.</span> Introduction</h4>
        <p>
          Please read this page carefully. It contains the terms and conditions
          (the “Terms and Conditions”) governing your access to and use of the
          WorkNigeria Web Sites and the Services (as each are defined below)
          provided by WorkNigeria, LTD or one of its divisions (collectively,
          “WorkNigeria”). If you do not accept these Terms and Conditions or you
          do not meet or comply with their provisions, you may not use the
          WorkNigeria Web Sites or Services. These Terms and Conditions are
          effective as of January 15, 2021.
        </p>

        <p>
          These Terms and Conditions (as they may be amended from time to time
          by WorkNigeria), together with your Advertising and Candidate Search
          Service Agreement (if applicable), form a binding agreement (the
          "Agreement") between you and WorkNigeria LTD. &nbsp;Your access to or
          use of the WorkNigeria Web Sites or Services indicates your acceptance
          of these Terms and Conditions. &nbsp;You are agreeing to use the Sites
          at your own risk.
        </p>

        <h4><span>2.</span> Certain Definitions</h4>
        <p>The following definitions apply to this Agreement:</p>

        <h5><span>2.1.</span> “WorkNigeria Materials”</h5>
        <p>
          includes any materials, methodologies, implementation plans or other
          intellectual property used during the provision of Services.
        </p>

        <h5>
          <span>2.2.</span> “WorkNigeria Web Site” or the “Site” (collectively,
          the “WorkNigeria Websites” or the “Sites”)
        </h5>
        <p>
          means any web site under WorkNigeria’s control, whether partial or
          otherwise and includes but is not limited to such Site’s&nbsp; like
          WorkNigeria.com, Worknigeria.org, WorkNaija.com, TaskNaija.com as well
          as WorkNigeria Materials and Services (as applicable in each context).
        </p>

        <h5><span>2.3.</span> “Content”</h5>
        <p id="elId" #target>
          means WorkNigeria’s web pages, web forms, programming (including
          software code used on the Sites and in the Services, including (i)
          tools, kits, and object libraries, (ii) all third-party or open source
          code embedded therein, and (iii) any upgrades, updates, releases,
          fixes, enhancements or modifications to the foregoing), graphics,
          images, design (color combinations and page layout), text,
          information, data, resumes stored in various commercial databases
          operated and licensed by WorkNigeria (including, among others, its
          “WorkNigeria” domains), data submitted via the Sites by Users and
          other content made available through the Sites by WorkNigeria.
        </p>

        <h5><span>2.4.</span> “Document”</h5>
        <p>refers to any posting to a Site, whether job or resume.</p>

        <h5><span>2.5.</span> “Employer”</h5>
        <p>
          means a person or entity that is accessing a Site to post a job or
          utilizing the Services for any reason related to the purpose of
          seeking candidates for employment.
        </p>

        <h5><span>2.6.</span> “Employer Materials”</h5>
        <p>
          includes any brochures, emails, sample job postings, web site content,
          career fair material, audio, videos, photographs, logos, trademarks,
          service marks, domain names, documents or other materials provided by
          Employer, if any, for use in connection with the Services.
        </p>

        <h5><span>2.7.</span> “Job Seeker”</h5>
        <p>
          means a User who is accessing a Site to search for a job or in any
          other capacity except as an Employer.
        </p>

        <h5><span>2.8.</span> “Services”</h5>
        <p>
          means any services provided by WorkNigeria or its agents described
          herein and specified more fully in the Service Activation Agreement.
        </p>

        <h5><span>2.9.</span> “User”</h5>
        <p>
          refers to any individual or entity that uses any aspect of the Sites.
        </p>

        <h5><span>2.10.</span> “You” or “you”</h5>
        <p>
          means the person who (or the entity on behalf of whom you are acting)
          that is agreeing to these Terms and Conditions.
        </p>

        <h4>
          <span>3.</span> Intellectual Property Rights and Acceptable Use of the
          Sites and Services
        </h4>

        <h5><span>3.1.</span> General Use Rules</h5>
        <p>
          The Sites are intended for individuals seeking employment and for
          employers or recruiters seeking candidates for employment. You may use
          the Sites only for lawful purposes within the stated context of
          WorkNigeria's intended and acceptable use of the Sites. WorkNigeria is
          the sole interpreter of the Sites’ intended and acceptable use.
        </p>

        <h5><span>3.2.</span> WorkNigeria Intellectual Property Rights</h5>
        <p>
          The Sites, the WorkNigeria Materials and all right, title and interest
          in and to the Sites and WorkNigeria Materials are the sole property of
          WorkNigeria or its licensors, and are protected by United States and
          foreign copyright, trademark and other laws. Except for the limited
          licenses expressly granted to you in these Terms and Conditions,
          WorkNigeria reserves for itself and its licensors all other rights,
          title and interest. Without limitation on the foregoing, you may not
          reproduce, modify, display, sell, or distribute the Content or
          WorkNigeria Materials, or use them in any other way for public or
          commercial purpose. &nbsp;Notwithstanding anything to the contrary
          contained herein, this prohibition includes: (a) copying or adapting
          the HTML code used to generate web pages on the Sites; (b) using or
          attempting to use engines, manual or automated software, tools,
          devices, agents, scripts, robots or other means, devices, mechanisms
          or processes (including, but not limited to, browsers, spiders,
          robots, avatars or intelligent agents) to navigate, search, access,
          “scrape,” “crawl,” or “spider” any web pages or any Services provided
          on the Sites other than the search engine and search agents available
          from WorkNigeria on such WorkNigeria Sites and other than generally
          available third party web browsers (e.g., Internet Explorer, Firefox,
          Safari); and (c) aggregating, copying or duplicating in any manner any
          of the Content or information available from any of the WorkNigeria
          Sites, without the express written consent of WorkNigeria.&nbsp; The
          use of the Content on any other web site or in a networked computer
          environment for any purpose is strictly prohibited.&nbsp; The
          WorkNigeria Materials are not considered to be works for hire and you
          may duplicate such WorkNigeria Materials only for the purposes
          outlined in the Service Activation Agreement.&nbsp; "WorkNigeria,"
          “WorkNigeria”, the WorkNigeria design logo and certain other names or
          logos are service marks or trademarks of WorkNigeria, and all related
          product and service names, design marks and slogans are the service
          marks or trademarks of WorkNigeria. In addition, the "look" and "feel"
          of the Sites (including color combinations, button shapes, layout,
          design and all other graphical elements) are also protected by
          WorkNigeria's trademarks, service marks and copyrights. Any code that
          WorkNigeria creates to generate or display the Content or the pages
          making up the Sites is also protected by WorkNigeria’s
          copyright.&nbsp; You must retain all copyright, trademark, service
          mark and other proprietary notices contained on the Content or
          WorkNigeria Materials on any authorized copy you make of the Content
          or WorkNigeria Materials.&nbsp; All other product and service marks
          contained on the Sites are the trademarks of their respective
        </p>

        <h5><span>3.3.</span> License to Use by Users who are Job Seekers</h5>
        <p>
          WorkNigeria hereby grants you a limited, terminable, non-exclusive
          right to access and use the Sites only for your personal use seeking
          employment opportunities for yourself. This authorizes you to view and
          download a single copy of the material on the Sites solely for your
          personal, noncommercial use. You agree that you are solely responsible
          for the content of any Document you post to a Site and any
          consequences arising from such posting. Your use of the Sites is a
          privilege. WorkNigeria reserves the right to suspend or terminate that
          privilege for any reason at any time, in its sole discretion.
        </p>

        <h5><span>3.4.</span> License to Use by Users who are Employers</h5>
        <p>
          WorkNigeria hereby grants you a limited, terminable, non-exclusive
          right to access and use the Sites only for your internal business use
          seeking candidates for employment. This authorizes you to view and
          download a single copy of the material on the Sites solely for your
          personal use directly related to searching for and recruiting job
          prospects. WorkNigeria also grants you a limited, terminable,
          non-exclusive license to use the WorkNigeria Materials and Services
          for your internal use only.&nbsp; You may not sell, transfer or assign
          any of the Services or your rights to any of the Services provided by
          WorkNigeria to any third party without the express written
          authorization of WorkNigeria. You agree that you are solely
          responsible for the content of any Document you post to a Site and any
          consequences arising from such posting. WorkNigeria reserves the right
          to suspend or terminate your access and use at any time if WorkNigeria
          determines that you are in breach of these Terms and Conditions.
        </p>

        <h5><span>3.5.</span> Employer Materials</h5>
        <p>
          Employer represents, warrants and covenants that any Employer
          Materials provided by Employer for use in connection with the Services
          will not violate any laws or regulations or third-party proprietary
          rights, including, without limitation, copyright, trademark,
          obscenity, rights of publicity or privacy, and defamation laws.&nbsp;
          Employer hereby grants WorkNigeria a non-exclusive, worldwide,
          royalty-free license to use the Employer Materials and to hyperlink to
          Employer’s website in connection with the Services.
        </p>

        <h5><span>3.6.</span> Use of Aggregate Data</h5>
        <p>
          You understand and agree that WorkNigeria owns and has the right to
          collect, extract, compile, synthesize, and analyze Aggregate Data.
          WorkNigeria may use such Aggregate Data for any lawful business
          purpose without a duty of accounting to you, provided that the data
          and information is used only in an aggregated and anonymized form so
          that it cannot be identifiable as relating to you, your Company or to
          any other Job Seekers.&nbsp; “Aggregate Data” means de-identified
          aggregated data or information regarding Job Seekers’ educational or
          career history (including, by way of example and not limitation,
          aggregate data relating to Job Seekers’ occupation, location, salary,
          education and experience).
        </p>

        <h5><span>3.7.</span> Other Specific Rules Regarding Site Usage</h5>
        <p>
          You represent, warrant and agree that you (a) are at least 13 years of
          age or older, and if under the age of 18 or the age of majority as
          that is defined in your jurisdiction, you will only use the Sites and
          Services under the supervision of a parent, legal guardian, or other
          responsible adult; and (b) will not use (or plan, encourage or help
          others to use) the Sites for any purpose or in any manner that is
          prohibited by these Terms and Conditions or by applicable law. It is
          your responsibility to ensure that your use of the Sites complies with
          these Terms and Conditions and all applicable laws.
        </p>

        <h5><span>3.8.</span> User Submissions</h5>
        <p>
          WorkNigeria welcomes your comments regarding the Services and the
          Sites and appreciates hearing from you. Please note, however, that
          WorkNigeria does not accept or consider creative ideas, suggestions,
          inventions or materials other than those which it has specifically
          requested. If you submit feedback on the Services, please be specific
          in your comments and do not submit creative ideas, inventions,
          suggestions, or materials. If, despite this notice, you send
          WorkNigeria creative suggestions, ideas, drawings, concepts,
          inventions, or other information (a "User Submission"), you understand
          and agree that the User Submission shall become the property of
          WorkNigeria. User Submission and any elements contained in User
          Submissions, shall not be subject to any obligation of confidentiality
          on WorkNigeria’s part, and WorkNigeria will not be liable for any use
          or disclosure of any User Submission. WorkNigeria shall exclusively
          own all now known or later discovered rights to the User Submission
          and shall be entitled to unrestricted use of the User Submission for
          any purpose whatsoever, commercial or otherwise, without compensation
          to you or any other person.
        </p>

        <h5><span>3.9.</span> Third Party Providers</h5>

        <p>
          You acknowledge and agree that WorkNigeria may provide the Services
          using third party providers, including subcontractors and consultants
          (the “Third Party Providers”).&nbsp; You agree that, as between
          WorkNigeria and its Third Party Providers, WorkNigeria will have sole
          responsibility for handling all billing and contract negotiations.
        </p>

        <h4>
          <span>4.</span> Disclaimers and Limitations on WorkNigeria's
          Liability
        </h4>
        <h5><span>4.1.</span> Allocation of Responsibility</h5>

        <p>
          WorkNigeria assumes no responsibility for Documents posted by Users
          and no responsibility for the activities, omissions or other conduct
          of Users. WorkNigeria acts as a portal for the online distribution and
          publication of User submitted information and has no obligation to
          screen communications or information in advance and is not responsible
          for screening or monitoring Documents posted by Users. If notified by
          a User of a Document which allegedly does not conform to these Terms
          and Conditions, WorkNigeria may investigate the allegation and
          determine in good faith and in its sole discretion whether to remove
          or request the removal of such Document. WorkNigeria has no liability
          or responsibility to Users for performance or nonperformance of such
          activities. WorkNigeria may take any action with respect to User
          submitted information that it deems necessary or appropriate, in its
          sole discretion.
        </p>

        <h5><span>4.2.</span> No endorsements by WorkNigeria</h5>

        <p>
          Nothing on the Sites shall be considered an endorsement,
          representation or warranty with respect to any User or third party,
          whether in regard to its web site, products, services, hiring,
          experience, employment or recruiting practices, or otherwise.
        </p>

        <h5><span>4.3.</span> Warranty Disclaimers</h5>

        <p>
          <span>4.3.1.</span> The sites are provided on an 'as is' basis without any warranties of any kind, 
          express or implied. Worknigeria, to the fullest extent permitted by law, disclaims all warranties, 
          including, but not limited to, the warranties of merchantability, title, non-infringement of third 
          parties’ rights, and fitness for a particular purpose. worknigeria makes no warranties about the 
          accuracy, reliability, completeness, or timeliness of the sites.
        </p>

        <p>
          <span>4.3.2.</span> Without limitation on the foregoing:worknigeria
          does not warrant that the sites will operate error-free or that the
          sites and their servers are free of computer viruses or other harmful
          mechanisms. If your use of the sites results directly or indirectly in
          the need for servicing or replacing equipment or data, worknigeria is
          not responsible for those costs.
        </p>

        <p>
          <span>4.3.3.</span> WorkNigeria makes no representations or guarantees
          regarding the truthfulness, accuracy, LEGALITY, completeness,
          timeliness or reliability of any Documents posted by Users, or of any
          other form of communication engaged in by Users. Documents may contain
          inaccuracies or typographical errors. You agree that any reliance on
          Documents posted by Users, or on any other form of communication with
          Users, will be at your own risk.
        </p>

        <p>
          <span>4.3.4.</span> WorkNigeria makes no representations or guarantees
          regarding the Content of the Sites, including, but not limited to,
          broken links, inaccuracies or typographical errors.
        </p>

        <p>
          <span>4.3.5.</span> Worknigeria makes no representations or guarantees
          regarding the effectiveness of the services or timeliness of the
          services in meeting your employment objectives. Worknigeria does not
          guarantee that the services will result in candidates being hired,
          positions being filled or employees being retained, and is not
          responsible or liable for any business, employment, hiring and/or
          salary decisions, for whatever reason made, made by you.
        </p>

        <h5>
          <span>4.4.</span> Damage limitations, allocations of liability and
          equitable relief
        </h5>

        <p>
          <span>4.4.1.</span> You assume all responsibility and risk for your
          use of the sites, the internet generally, and the documents or
          employer materials that you post, provide or access and for your
          conduct on and off the sites.
        </p>

        <p>
          <span>4.4.2.</span> In no event shall WorkNigeria (or any of its
          officers, directors, shareholders, employees, subsidiaries,
          affiliates, agents or advertisers), be liable for any non-direct
          damages whatsoever (including, without limitation, incidental and
          consequential damages, lost profits, or damages resulting from lost
          data, lost employment opportunity, or business interruption) resulting
          from or arising under or in connection with services or the use or
          access to, or the inability to use or access, the sites and/or any
          document, whether based on warranty, contract, tort, or any other
          legal theory, and whether or not WorkNigeria is advised of the
          possibility of such damages.
        </p>

        <p>
          <span>4.4.3.</span> Because some states or jurisdictions do not allow
          the exclusion or limitation of liability for consequential or
          incidental damages, the limitations set forth in the preceding
          paragraph may not apply to you. If any are held inapplicable or
          unenforceable for any reason, then WorkNigeria's maximum liability to
          you for any non-direct type of damages shall be limited to U.S.
          $200.00 in the aggregate.
        </p>

        <p>
          <span>4.4.4.</span> In no event shall WorkNigeria (or any of its
          officers, directors, shareholders, employees, subsidiaries,
          affiliates, agents or advertisers), be liable for any direct damages
          in excess in the aggregate of 200.00 naira (or, if you are an employer
          with a services agreement with WorkNigeria, the amounts actually paid
          by you to WorkNigeria under this agreement if greater than 200.00
          naira).
        </p>

        <p>
          <span>4.4.5.</span> Due to the nature of this Agreement, in addition
          to money damages, you agree that WorkNigeria will be entitled to
          equitable relief upon a breach of this agreement by you.
        </p>

        <h5><span>4.5.</span> User Authentication</h5>
        <p>
          Because User authentication on the Internet is difficult, WorkNigeria
          cannot and does not confirm that each User is who they claim to be.
          Because WorkNigeria does not and cannot be involved in User-to-User
          dealings or control the behavior of participants on the Sites, in the
          event that you have a dispute with one or more Users, you release
          WorkNigeria from claims, demands and damages (actual and consequential
          and direct and indirect) of every kind and nature, known and unknown,
          suspected and unsuspected, disclosed and undisclosed, arising out of
          or in any way connected with such disputes.
        </p>

        <h4><span>5.</span> Links to Other Sites</h4>
        <p>
          WorkNigeria&nbsp; and “WorkNigeria” contains links to third party web
          sites. These links are provided solely as a convenience to you and not
          as an endorsement by WorkNigeria of the contents on such third-party
          web sites. WorkNigeria is not responsible for the content of linked
          third-party sites and does not make any representations regarding the
          content or accuracy of materials on such third-party web sites. If you
          decide to access linked third-party web sites, you do so at your own
          risk.
        </p>

        <h4>
          <span>6.</span> Amendments to this Agreement and Changes to Sites
        </h4>

        <p>
          WorkNigeria may revise these Terms and Conditions at any time by
          updating this page. Changes will be binding on you on the date they
          are posted on the Sites (or as otherwise stated in the any notice of
          such changes). Any use of a Site will be considered acceptance by you
          of the then-current Terms and Conditions (including any exhibits
          thereto) contained on such Site. If at any time you find the Terms and
          Conditions unacceptable, you may not use the applicable Site any
          longer. Any new or different terms supplied by you are specifically
          rejected by WorkNigeria unless WorkNigeria agrees to them in a signed
          writing specifically including those new or different terms.
          WorkNigeria may change the Sites at any time.
        </p>

        <h4><span>7.</span> Indemnity</h4>
        <p>
          You agree to defend, indemnify, and hold harmless WorkNigeria (and its
          subsidiaries, affiliates, officers, directors, employees and agents)
          from and against any third party claims, actions or demands
          (including, without limitation, costs, damages and reasonable legal
          and accounting fees) alleging or resulting from or in connection with
          your use of the Sites, any Document posted by you, your use of the
          Services or your breach of this Agreement. WorkNigeria shall use
          reasonable efforts to provide you prompt notice of any such claim,
          suit, or proceeding and may assist you, at your expense, in defending
          any such claim, suit or proceeding.
        </p>

        <h4><span>8.</span> User Information and Privacy Policy</h4>

        <h5>
          <span>8.1.</span> When you register on any of the WorkNigeria Sites or
          in connection with the Services, you will be asked to create an
          account and provide WorkNigeria with certain information, including
          but not limited to, a valid email address (“User Information”). You
          acknowledge and agree that you have no ownership rights in your
          account
        </h5>

        <h5>
          <span>8.2.</span> All User Information will be used in accordance with
          the terms of WorkNigeria’s Privacy Policy
        </h5>
        <p>
          Please note, as set forth in the&nbsp;<strong style="cursor: pointer"
          routerLink="/privacy-policy">Privacy Policy</strong>,
          that WorkNigeria may collect certain User Information and may contact
          Users periodically in accordance with the terms of the Privacy Policy.
          In addition, WorkNigeria reserves the right to comply, in its sole
          discretion, with legal requirements, requests from law enforcement
          agencies or requests from government entities, even to the extent that
          such compliance may require disclosure of certain User
          Information.&nbsp; In addition, third parties may retain cached copies
          of User Information.
        </p>

        <h4><span>9.</span> General</h4>
        <p>
          WorkNigeria contact information is listed on the Sites. WorkNigeria
          makes no claims that the Content is appropriate or may be downloaded
          outside of the United States. Access to the Content may not be legal
          by certain persons or in certain countries, and such persons have no
          right to access or use the Sites. If you access WorkNigeria from
          outside of Nigeria, you do so at your own risk and are responsible for
          compliance with the laws of your jurisdiction. This Agreement and your
          Service Activation Agreement, if you have one, are governed by the
          internal substantive laws of the government of Nigeria, without
          respect to its conflict of laws principles. The sole relationship
          between you and WorkNigeria is that of independent contractors. If any
          provision of this Agreement is found to be invalid by any court having
          competent jurisdiction, the invalidity of all or part of a provision
          shall not affect the validity of the remaining parts and provisions of
          this Agreement, which shall remain in full force and effect. All
          provisions of this Agreement shall survive termination except those
          granting access or use to the Sites, and you shall cease all your use
          and access thereof immediately. You may not assign or transfer your
          obligations under this Agreement. No waiver of any term of this
          Agreement shall be deemed a further or continuing waiver of such term
          or any other term. Except as expressly provided by WorkNigeria in a
          particular "Legal Notice," or material on particular web pages of the
          Sites, this Agreement and your Service Activation Agreement where
          applicable, constitute the entire agreement between you and
          WorkNigeria.
        </p>

        <h4><span>10.</span> Rules regarding Posting, Conduct and Security</h4>
        <p>
          The following rules apply to your use of the Sites and/or the
          Services.&nbsp; The list of rules is for illustration only and is not
          a complete list of all posting, conduct and security rules.
        </p>

        <p>
          You may not post any Document to a Site that contains: (i) URLs or
          links to web sites other than to recruitment related pages on your
          company web site (to advertise your company or web site; (ii)
          copyrighted material (unless you own the copyright or have the owner's
          permission to post the copyrighted material); (iii) trade secrets
          (unless you own them or have the owner's permission to post them);
          (iv) material that infringes on or misappropriates any other
          intellectual property rights, or violates the privacy or publicity
          rights of others; (v) irrelevant keywords or white text keywords
          (including any words embedded in the Document and hidden from the
          User); (vi) anything that is discriminatory, sexually explicit,
          obscene, libelous, defamatory, threatening, harassing, abusive, or
          hateful; or (vii) anything that is embarrassing or offensive to
          another person or entity.
        </p>

        <h5><span>10.1.</span> Posting Rules</h5>
        <p>
          <span>10.1.1.</span> You may not use a Document(s) to: (i) impersonate
          another person, living or dead; (ii) post false, inaccurate or
          misleading information, opinions or notices (commercial or otherwise)
          or chain letters; (iii) post advertisements, or solicitations of
          business (including, but not limited to, email processors, any pyramid
          scheme or “club membership”); provided, however that WorkNigeria may
          allow posting of certain training and business opportunities (see
          14.1(e) below).
        </p>

        <p>
          <span>10.1.2.</span> Your Document(s) must contain sufficient detail
          to convey clearly to the User the nature and requirements of the job
          opportunity, or, in the case of Users seeking employment, your
          qualifications as a candidate for employment. Documents that encourage
          the User to "email for more details" are not permitted.
        </p>

        <p>
          <span>10.1.3.</span> Job postings must describe individual openings
          for traditional employee jobs or contract. &nbsp;You may not advertise
          multiple job openings in a single posting.
        </p>

        <p>
          <span>10.1.4.</span> In limited circumstances, at WorkNigeria’s sole
          discretion, WorkNigeria allows non-traditional job postings such as
          education/training, business opportunities, franchise, and multi-level
          marketing opportunities to be posted on the Sites.&nbsp; These
          non-traditional job postings may require an investment of time and/or
          money by the User seeking the position.&nbsp; WorkNigeria reserves the
          right, in its sole discretion, to move, modify, or remove entirely a
          non-traditional job posting if WorkNigeria deems such job posting to
          conflict with the best interests of its Users or detract from the User
          experience.
        </p>

        <p>
          <span>10.1.5.</span> Resume postings must contain the accurate resume
          of a living individual seeking employment on a full-time, part-time,
          or contractual basis on his or her own behalf.
        </p>

        <p>
          <span>10.1.6.</span> WorkNigeria is under no obligation to monitor the
          Documents posted on the Sites, but it may monitor Documents at random.
          Documents found to violate the above Posting Rules may be removed at
          WorkNigeria's sole discretion.
        </p>

        <h5><span>10.2.</span> Conduct Rules</h5>

        <p>
          <span>10.2.1.</span> You may not respond to postings by other Users in
          any manner or for any purpose other than that which is expected (i.e.,
          to apply for the job or to initiate further discussion with the
          candidate). Communications soliciting the Employer's business are
          prohibited.
        </p>

        <p>
          <span>10.2.2.</span> You may not send unsolicited commercial email to
          Users.
        </p>

        <p>
          <span>10.2.3.</span> Protect your password. Your WorkNigeria account
          and any related Services accessed through such account are to be
          accessed and used solely by you. Upon your successful registration for
          a WorkNigeria account, you will be provided with a unique password
          that will permit you to access the account. You may not provide your
          password or otherwise permit access to your WorkNigeria account to any
          third party. You are responsible for maintaining the confidentiality
          of your information and password. You agree to protect and keep your
          password confidential, to change your password on a regular basis, and
          to maintain appropriate and regularly updated malware scanning and
          cleaning tools on your network and individual computer(s).You are
          responsible for all uses of your account, whether or not authorized by
          you. If others use your password to post inappropriate material on the
          Sites, you risk losing your access to the Sites. You agree to notify
          WorkNigeria immediately of any unauthorized use of your account or
          password.
        </p>

        <p>
          <span>10.2.4.</span> Report inappropriate postings or conduct to
          Feedback
        </p>

        <p>
          <span>10.2.5.</span> You may not delete or revise any material posted
          by any other person or entity.
        </p>

        <p>
          <span>10.2.6.</span> If at any time WorkNigeria comes to the
          understanding that you: (i) misled WorkNigeria regarding your business
          practices and/or services, or (ii) purchased services that do not
          represent your precise business, WorkNigeria reserves the right to
          terminate your Agreement.
        </p>

        <h5><span>10.3.</span> Security Rules</h5>

        <p>
          <span>10.3.1.</span> Users are prohibited from violating or attempting
          to violate the security of the Sites, including, without limitation:
          (i) accessing data not intended for such User or logging into a server
          or account which the User is not authorized to access; (ii) attempting
          to probe, scan or test the vulnerability of a system or network or to
          breach security or authentication measures without proper
          authorization; (iii) attempting to interfere with service to any User,
          host or network, including, without limitation, via means of
          submitting a virus to the Sites, overloading, "flooding", "mail
          bombing" or "crashing"; (iv) sending unsolicited e-mail, including
          promotions and/or advertising of products or services; (v) forging any
          TCP/IP packet header or any part of the header information in any
          e-mail or newsgroup posting.
        </p>

        <p>
          <span>10.3.2.</span> Violation of these Security Rules may result in
          civil or criminal liability. WorkNigeria will investigate occurrences
          which may involve such violations and may involve, and cooperate with,
          law enforcement authorities in prosecuting Users who are involved in
          such violations.
        </p>

        <h4><span>11.</span> Copyright Complaints</h4>

        <h5>
          <span>11.1.</span> WorkNigeria respects the intellectual property of
          others
        </h5>

        <p>
          It is WorkNigeria's policy to respond to claims of copyright and other
          intellectual property infringement. WorkNigeria will promptly process
          and investigate notices of alleged infringement and will take
          appropriate actions under the Digital Millennium Copyright Act
          ("DMCA") and other applicable intellectual property laws. Upon receipt
          of notices complying with the DMCA, WorkNigeria may act expeditiously
          to remove or disable access to any material claimed to be infringing
          or claimed to be the subject of infringing activity and may act
          expeditiously to remove or disable access to any reference or link to
          material or activity that is claimed to be infringing. WorkNigeria
          will terminate access for Users who are repeat infringers.
        </p>

        <h5>
          <span>11.2.</span> Providing WorkNigeria with Counter-Notification: If
          we remove or disable access to content in response to an infringement
          notice, we will make reasonable attempts to contact the owner or
          administrator of the affected site or content
        </h5>
      </div>
    </div>
  </div>
</div>
