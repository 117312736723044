// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://ne8dhnimwd.execute-api.us-east-1.amazonaws.com/prod/',
  baseUrl: 'https://pgqtzavoce.execute-api.us-east-1.amazonaws.com/dev/',
  paystack_key: 'pk_test_5c635797c32aee412ccf63d990fd9983b4324b34',

  // baseUrl: 'http://127.0.0.1:3000/',
  Fb_ID: '299212462404883',
  // Fb_ID: '313811057401201',
  // Gmail_Client_ID: '880169148514-mr3nor9dm05911ruck7kvh4lvg0dav61.apps.googleusercontent.com',
  // Gmail_Secret_key: 'HNNNn6DO4x4aUhQXFvpBlVF2',
  Gmail_Client_ID:
    '141668435408-mo726duu3idqq5mpa3eflq5aurniamgf.apps.googleusercontent.com',
    // Gmail_Client_ID:
    // '382383300199-hrm7nru6il89d50hc8ghgfrqpmh0p3du.apps.googleusercontent.com',
  Gmail_Secret_key: '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',
  CVpurse_version: '8.0.0',
  CVpurse_subuserid: 'Sandeep',
  CVpurse_userkey: 'MHET4YX4',
  CVpurse_Link:
    'https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary',
  // linkedin //
  // link: 'https://www.linkedin.com/developers/apps/verification/08dc5cae-fab1-46f5-9939-a830e7d9c446',
  // linkedinClientId: '78htywvw3zhmjd',
  // linkedinSecretKey: 'QddcOk02FqDtPzSW',

  linkedInCredentials: {
    clientId: '77esmvqwhq700t',
    redirectUrl: "https://development.worknigeria.com/auth-process",
    //  redirectUrl: "https://www.google.com",
    // redirectUrl: 'https://worknigeria.com/auth-process',
    // redirectUrl:"https://www.urlencoder.org",

    scope: 'r_liteprofile%20r_emailaddress', // To read basic user profile data and email
    linkedSecretKey: 'l1UnV12RdLBFgIGL',
  },

  // Angular Google Map //
  map: {
    apiKey: 'AIzaSyCYwD6oifDBn9dm7_RAdrnUTT-NLWJdALU',
    libraries: ['places'],
  },

  // FireBase //
  firebaseConfig: {
    apiKey: 'AIzaSyDsioeH8nKIRpyn_ZDxLYFNHgSnnZzKOaU',
    authDomain: 'realjobs-9b797.firebaseapp.com',
    databaseURL: 'https://realjobs-9b797-default-rtdb.firebaseio.com',
    projectId: 'realjobs-9b797',
    storageBucket: 'realjobs-9b797.appspot.com',
    messagingSenderId: '329693088301',
    appId: '1:329693088301:web:90f284a12309b371a986a4',
    measurementId: 'G-3NC7JH0XQT',
  },

  twitterDetails: {
    // callBackUrlForLogin:"http://localhost:4200/login",
    // callBackUrlForSignUp:"http://localhost:4200/signup",
    callBackUrlForLogin: 'https://djfggwe6zy5jp.cloudfront.net/login',
    callBackUrlForSignUp: 'https://djfggwe6zy5jp.cloudfront.net/signup',
  },

  activityType: {
    via_workNigeria: 'apply_via_worknigeria',
    via_url: 'apply_job_on_external_site',
    via_email: 'apply_job_via_email',
  },

  WNSquareCompanyLogo:
    'https://worknigeria-frontend-stag-storage.s3.amazonaws.com/assets/images/sm-Logo.png',

  bundles : {
    cv: "4202bb56-6975-465d-95bb-062afb21fe7e",
    campaign: "62e0d3f3-0d00-4c78-8dca-57024ccb1f10",
  },

  messagingWS: "wss://48db2ia0re.execute-api.us-east-1.amazonaws.com/dev",

  // Month:[January – 31 days
  //   February – 28 days in a common year and 29 days in leap years
  //   March – 31 days
  //   April – 30 days
  //   May – 31 days
  //   June – 30 days
  //   July – 31 days
  //   August – 31 days
  //   September – 30 days
  //   October – 31 days
  //   November – 30 days
  //   December – 31 days]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
