<div class="tab-content py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active">
    <div style="text-align: right; border: 1px">
      <a href="javascript:void(0);" (click)="sendReport()"
        ><img src="../assets/images/el.png" alt=""
      /></a>
    </div>

    <div></div>
    <div></div>

    <div class="overview-job-row">
      <h3>Jobseeker List</h3>
    </div>

    <div class="well-views table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>SL</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Verified Email</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of allJobSeekerList; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ job.firstName }}</td>
            <td>{{ job.lastName }}</td>
            <td>{{ job.email }}</td>
            <td>{{ job.isEmailVerified }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
