<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="line-scale-party"
  [fullScreen]="true"
>
  <p style="font-size: 20px; color: white"></p>
</ngx-spinner>

<router-outlet></router-outlet>
