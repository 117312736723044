<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="log-lt">
                <h3>Forgot Password?</h3>
                <p class="sub-heading">
                  Reset your password in two quick steps
                </p>
                <div class="form-wrap forgotPassword">
                  <form [formGroup]="forgotPassword">
                    <div class="form-group">
                      <mat-form-field
                        class="example-full-width"
                        appearance="fill"
                      >
                        <mat-label>Email</mat-label>
                        <input
                          type="email"
                          matInput
                          placeholder="Email"
                          required="required"
                          formControlName="email"
                          [pattern]="emailPattern"
                        />
                      </mat-form-field>
                      <div *ngIf="f.email.touched && f.email.invalid">
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.email.errors.required"
                          >Email is required
                        </span>
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.email.errors?.pattern"
                        >
                          Email is not valid.
                        </span>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        type="submit"
                        value="Reset Password"
                        class="btn btn-green w-100"
                        (click)="emailed()"
                      />
                    </div>

                    <div class="forgot-section">
                      <p>
                        <a href="javascript:void(0);" [routerLink]="['/login']"
                          >Back</a
                        >
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img
                    src="../../../../assets/images/Forgot-password.png"
                    alt=""
                  />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
