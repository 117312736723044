import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ViewChild, Injectable, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ExportEmployerJobsService } from 'src/app/services/export-employer-jobs.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult'
import { SharejobComponent } from 'src/app/views/job-search/sharejob/sharejob.component';
import { IntroJSService } from 'src/app/services/introjs.service';

@Component({
  selector: 'app-emp-dash-job-overview',
  templateUrl: './emp-dash-job-overview.component.html',
  styleUrls: ['./emp-dash-job-overview.component.scss']
})
export class EmpDashJobOverviewComponent implements AfterViewInit {

  paths: any = {
    list: '/job/get-job-by-provider-or-company',
    appliedJobs: '/job/applied-jobs',
  };
  dashBoardDetails: UntypedFormGroup;
  jobsList: any = null;
  userToken: string;
  userDetails: any;
  finalJobList: any = [];
  statusList: any = ['Active', 'Inactive'];
  userDeatils: any;
  companyData: any;
  show: boolean = false;
  selectedJob: any;
  appliedJobseekerDetails: any;
  postedDate: Date = new Date();
  searchText: any = '';
  postedByList: any;

  jobTypeList: any = ['active', 'inactive'];
  filtered: any = {};
  clearButton: boolean = false;
  selectedJobByEmployer: any;
  filteredOptions: Observable<string[]>;
  CompaniesResult: any = [];

  selectCompany: any;

  startJobIndex: any = 0;
  nextButtonflag: boolean = true;

  displayedColumns: string[];
  dataSource: any = [];
  sortOrder: string = 'DESC';
  sortParam: string = 'created_at'
  pagination = {
    size: 10,
    start: 0,
    total: 0,
  };
  currentPage: number = 0


  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private router: Router,
    private helper: Helper,
    private auth: AuthService,
    private io: HttpIOService,
    private EmployerService: EmployerService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private employerService: EmployerService,
    private snackBar: MatSnackBar,
    private exportEmpJobsService: ExportEmployerJobsService,
    private introService: IntroJSService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    this.getEmployerByCompany();
  }

  ngOnInit(): void {
    this.validationForm();
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
    } else {
      this.getAllCompaniesList();
      this.searchByDependency();
    }

    this.filteredOptions =
      this.dashBoardDetails.controls.companies.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    if((localStorage.getItem('jobOverviewTour') ?? '0') === '0' && (localStorage.getItem('companyVerificationTour') ?? '0') === '1'){
      setTimeout(() => {
        this.introService.jobOverview()
      }, 2000)
    }
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.pagination.start = 0;
    this.currentPage = 0
    this.sortOrder = sortState.direction.toUpperCase()
    this.sortParam = sortState.active
    console.log(sortState)
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
    } else {
      this.getAllCompaniesList();
      this.searchByDependency();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CompaniesResult.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  // Insilize Form //
  validationForm() {
    this.dashBoardDetails = this.fb.group({
      created_at: [''],
      title: [''],
      job_status: [''],
      provider_id: [''],
      companies: [''],
    });
  }

  async getAllCompaniesList() {
    let jsonData = {
      path: 'get-company-list',
    };
    await this.employerService
      ._getAllCompaniesList(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res['result']);
          this.CompaniesResult = res['result'].companyList;
        }
      });
  }

  async getEmployerJobs() {
    if (this.userDeatils.userRole == 'company_admin') {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        //  "provider_id": this.userDeatils.id,
        company_id: this.userDeatils.companyId,
        size: this.pagination.size,
        start: this.pagination.start,
        sortParam: this.sortParam,
        sortOrder: this.sortOrder,
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobsList = res['result'].job;
            this.displayedColumns = ['viewed', 'applied', 'title', 'location_type', 'apply_via', 'created_at', 'providerName', 'city'];
            this.dataSource = new MatTableDataSource(this.jobsList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.pagination = res['pagination'];
          }
        });
    }
    else if (this.userDeatils.userRole == 'company_basic') {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        provider_id: this.userDeatils.id,
        company_id: this.userDeatils.companyId,
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobsList = res['result'].job;
            this.displayedColumns = ['viewed', 'applied', 'title', 'location_type', 'apply_via', 'created_at', 'providerName', 'city'];
            this.dataSource = new MatTableDataSource(this.jobsList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.pagination = res['pagination'];
          }
        });
    }
  }

  getEmployerByCompany() {
    let jsonData = {
      path: 'get-employer-by-company',
      companyId: this.userDeatils.companyId,
    };
    this.employerService
      ._getEmployerByCompany(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.postedByList = res['result'].userData;
        }
      });
  }

  // View the applications submitted by jobseekers
  async getAppliedJobseekerDetails(job) {
    // console.log(job)
    this.selectedJob = '';
    this.selectedJob = job;
    let jsonData = {
      jobId: job.id,
      path: 'get-applied-job-seeker',
    };
    await this.employerService
      ._getAppliedJobseeker(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.appliedJobseekerDetails = res['result'].userData;
          this.selectedJobByEmployer = job.id;
          this.show = true;
          this.clearButton = false;
        }

        if (res.serverResponse.code == 400) {
          this.snackBar
            .openFromComponent(DynamicSnacbarSkipComponent, {
              data: 'Notice: no one applied yet',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  async routeToAddJob() {
    let checkEmailValid = await this.io._checkEmailValid();
    // console.log(checkEmailValid)
    if (checkEmailValid == true) {
      const dataToSend = {
        data: 'from dashboard add job'
      }
      this.router.navigate(['post-job'], {
        queryParams: dataToSend
      });
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to add jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  routeToEditJob(jobId) {
    let checkEmailValid = this.io._checkEmailValid();
    if (checkEmailValid == true) {
      this.router.navigate(['/edit-job'], { queryParams: { id: jobId, popup: 'from dashboard edit job' } });
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  onclick() {
    this.show = false;
    if (this.userDeatils.userType == 'super_admin') {
      this.clearButton = true;
    }
  }

  onKey(event) {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      this.searchByDependency();
    }
    this.clearButton = true;
  }

  filterFrom(e: MatDatepickerInputEvent<Date>) {
    this.searchByDependency();
  }

  changeJobType(event) {
    // console.log(event.value);
    this.searchByDependency();
    this.clearButton = true;
  }

  changePostedBy(event) {
    // console.log(event);
    if (this.selectCompany) {
      this.searchByDependency();
    }
    if (this.userDeatils.userType != 'super_admin') {
      this.searchByDependency();
    }
    this.clearButton = true;
    this.startJobIndex = 0;
    // this.nextButtonflag = false
  }

  changeCompany(event) {
    // console.log(event);
    this.selectCompany = '';
    this.selectCompany = this.CompaniesResult.find(
      (x) => x.companyName == event
    );
    // console.log(this.selectCompany);
    this.searchByDependency();
    let jsonData = {
      path: 'get-employer-by-company',
      companyId: this.selectCompany.id,
    };
    this.employerService
      ._getEmployerByCompany(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res);
          this.postedByList = res['result'].userData;
          this.clearButton = true;
        }
      });
  }

  transformTreeDateTime(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  // Format the date to month. date
  formatDateString(dateString: string): string {
    const date = new Date(dateString);
    const format = 'MMM. dd';
    return this.datepipe.transform(date, format);
  }

  searchByDependency() {
    if (this.userDeatils.userType != 'super_admin') {
      this.filtered = {
        path: 'get-job-by-provider-or-company',
        company_id: this.userDeatils.companyId,
        size: this.pagination.size,
        start: this.pagination.start,
        sortParam: this.sortParam,
        sortOrder: this.sortOrder
      };
    } else {
      this.filtered = {
        path: 'get-job-by-provider-or-company',
        company_id: this.selectCompany ? this.selectCompany.id : 'all',
        size: this.pagination.size,
        start: this.pagination.start,
        sortParam: this.sortParam,
        sortOrder: this.sortOrder
      };
    }
    if (this.dashBoardDetails) {
      for (let key in this.dashBoardDetails.value) {
        if (this.dashBoardDetails.value[key]) {
          if (key == 'created_at' && this.dashBoardDetails.value[key] != '') {
            this.filtered[key] = this.transformTreeDateTime(
              this.dashBoardDetails.value[key]
            );
          } else {
            this.filtered[key] = this.dashBoardDetails.value[key];
          }
        }
      }
    }
    this.employerService
      ._getJobByproviderAndCompany(this.filtered)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          if (res['result']) {
            this.jobsList = res['result'].job;
            this.displayedColumns = ['viewed', 'applied', 'title', 'location_type', 'apply_via', 'created_at', 'providerName', 'city'];
            this.dataSource = new MatTableDataSource(this.jobsList);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
          if (this.jobsList.length > 0) {
            if (this.userDeatils.userType != 'super_admin') {
              this.clearButton = true;
            }
            if (
              this.userDeatils.userType == 'super_admin' &&
              this.selectCompany
            ) {
              this.clearButton = true;
            }
          }
        }
      });
  }

  loadData(data) {
    if (data == 'previous' && this.startJobIndex != 0) {
      this.startJobIndex = this.startJobIndex - 10;
      this.nextButtonflag = true;
      this.filtered['start'] = this.startJobIndex;
      if (this.userDeatils.userType != 'super_admin') {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.userDeatils.companyId;
      } else {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.selectCompany
          ? this.selectCompany.id
          : 'all';
      }
      this.employerService
        ._getJobByproviderAndCompany(this.filtered)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res['result'].job);
            if (res['result']) {
              this.jobsList = res['result'].job;
            }
            if (this.jobsList.length > 0) {
              if (this.userDeatils.userType != 'super_admin') {
                this.clearButton = true;
              }
              if (
                this.userDeatils.userType == 'super_admin' &&
                this.selectCompany
              ) {
                this.clearButton = true;
              }
            }
            if (this.selectCompany == undefined) {
              // console.log(this.selectCompany)
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          }
        });
    }
    if (data == 'next') {
      this.startJobIndex = this.startJobIndex + 10;
      this.filtered['start'] = this.startJobIndex;
      if (this.userDeatils.userType != 'super_admin') {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.userDeatils.companyId;
      } else {
        this.filtered['path'] = 'get-job-by-provider-or-company';
        this.filtered['company_id'] = this.selectCompany
          ? this.selectCompany.id
          : 'all';
      }
      this.employerService
        ._getJobByproviderAndCompany(this.filtered)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res['result'].job);
            if (res['result']) {
              this.jobsList = res['result'].job;
            }

            if (this.selectCompany == undefined) {
              this.snackBar
                .openFromComponent(DynamicSnacbarComponent, {
                  data: res.serverResponse.message,
                  verticalPosition: 'top', // 'top' | 'bottom'
                  horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                })
                .afterOpened()
                .subscribe(this.helper.setOnTop);
            }
          }
          if (res.serverResponse.code == 404) {
            if (typeof res['result'] === 'object' && res['result'] !== null) {
              // console.log(res['result']);
              this.nextButtonflag = false;
            }
          }
        });
    }
  }

  //ACTIVE OR INACTIVE SLIDE //
  activeInactive(job, index) {
    let jobStatus = job.job_status;
    if (jobStatus === 'active') {
      let jsonData = {
        path: 'inactive-job',
        id: job.id,
      };
      this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => {
        this.jobsList[index].job_status = res['result'].job.job_status;
      });
    } else {
      let jsonData = {
        path: 'active-job',
        id: job.id,
      };
      this.io._activeForEmpManage(jsonData).subscribe((res: any) => {
        // console.log(res);
        this.jobsList[index].job_status = res['result'].job.job_status;
      });
    }
  }

  resetFrom() {
    this.dashBoardDetails.reset({ companies: '' });
    this.clearButton = false;
    if (this.userDeatils.userType == 'super_admin') {
      this.selectCompany = '';
      this.jobsList = [];
      this.startJobIndex = 0;
      this.searchByDependency();
    }
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
    }
  }

  exportAllJobs() {
    if (this.userDeatils.userRole == 'company_admin') {
      this.exportEmpJobsService.genExcel(this.jobsList);
    } else {
      this.exportEmpJobsService.genExcelCompanyBasic(this.jobsList);
    }
  }

  feature(job) {
    if (job.is_featured == true) {
      let jsonData = {
        path: 'update-featured-status',
        is_featured: false,
        id: job.id,
      };
      this.EmployerService._FeatureJob(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.searchByDependency();
        }
      });
    } else {
      let jsonData = {
        path: 'update-featured-status',
        is_featured: true,
        id: job.id,
      };
      this.EmployerService._FeatureJob(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.searchByDependency();
        }
      });
    }
  }

  // To open the share job component 
  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: 'auto',
      width: '450px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  promoteJob() {
    this.router.navigate(['Dashboard'], {
      queryParams: ['promote-job']
    });
  }

  // Convert to lowercase and replace underscores with spaces for "JOB TYPE" column
  transformData(inputString: string): string {
    return inputString.toLowerCase().replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase());
  }

  applyViaText(apply) {
    if (apply === 1) {
      return 'WorkNigeria'
    } else {
      return 'External'
    }
  }

  onPageChanged(event: any): void {
    this.pagination.start = event.pageIndex * this.pagination.size;
    this.currentPage = event.pageIndex;
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
    } else {
      this.getAllCompaniesList();
      this.searchByDependency();
    }
  }
}