import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { EmployerService } from 'src/app/services/employer.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { EmpDashSavedCvComponent } from '../emp-dash-saved-cv/emp-dash-saved-cv.component';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DataService } from 'src/app/services/data.service';
import { IntroJSService } from 'src/app/services/introjs.service';

@Component({
  selector: 'app-emp-dash-cv',
  templateUrl: './emp-dash-cv.component.html',
  styleUrls: ['./emp-dash-cv.component.scss']
})
export class EmpDashCvComponent implements OnInit {
  userAssets: any;
  payFromWallet: boolean = false;
  constructor (
    private datePipe: DatePipe,
    private router: Router,
    private employerService: EmployerService,
    public dialog: MatDialog,
    private auth: AuthService,
    private io: HttpIOService,
    private dataService: DataService,
    private introService: IntroJSService

  ) {}
  searchCorner: UntypedFormGroup;
  clearButton = false;
  mode = 'all';
  skill = ''
  location = ''

  statusOfEmployer = 'bronze';

  showRightSectionInMobile: boolean = false;
  currentIndex: number;
  status: boolean = false;
  detailsOfJob: any = '';

  loadMoreButtonVisible: boolean = true;

  candidatesList: any;

  ngOnInit(): void {
    this.dataService.getUserData().subscribe((data: any) => {
      if(data){
        this.userAssets = data;
      }else{
        this.loadUpdatedAssets()
      }
    });
    this.getCVs()
  }

  // GET JOB DETAILS //
  jobDetails(i, list) {
    // console.log(i);
    this.showRightSectionInMobile = true;
    this.status = list.id;
    this.currentIndex = i;
    this.detailsOfJob = this.candidatesList[i];
  }

  onScrollDown() { }

  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }

  formatDate(date: string) {
    let formattedDate = this.datePipe.transform(date, 'dd MMM yyyy');
    return formattedDate;
  }

  saveInFolder(job) {
    const dialogRef = this.dialog.open(PopupConfirmationComponent, {
      height: 'auto',
      width: 'auto',
      autoFocus: false,
      data: {
        data: 'save CV',
        jobDetails: job,
      },
    });
    dialogRef.afterClosed().subscribe();
  }

  convertMonthsToYearsAndMonths(months: number) {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    let result = `${years} ${years === 1 ? 'Year' : 'Years'}`;
    if (remainingMonths > 0) {
        result += ` ${remainingMonths} ${remainingMonths === 1 ? 'Month' : 'Months'}`;
    }
    return result;
}

  messageOpen(row) {
    this.router.navigate(['/message']);
    this.employerService.setforSeletedIn_cvSearch(row);
  }

  unlockCV(candidateId) {
    this.io._unlockCV({candidateId, payFromWallet: this.payFromWallet}).subscribe((res: any) => {
      if (res['serverResponse']['code'] == 200) {
        this.loadUpdatedAssets()
        this.detailsOfJob = res.result
        this.replaceUnlockedCandidate(candidateId, res.result)
      }else{
        console.log(res['serverResponse'])
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Insufficient Credits CV',
            subscription: this.userAssets?.subscription?.subscriptionPlan || "Bronze",
            walletBalance: res['serverResponse'].details?.balance || 0,
            details: { path: 'unlock-cv', candidateId, extraAmountRequired: parseFloat(res['serverResponse'].details?.requiredAmount) || 1000}
          },
        })
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'payFromWallet') {
            this.payFromWallet = true
            this.io._unlockCV({candidateId, payFromWallet: this.payFromWallet}).subscribe((res: any) => {
              if (res['serverResponse']['code'] == 200) {
                this.loadUpdatedAssets()
                this.detailsOfJob = res.result
                this.replaceUnlockedCandidate(candidateId, res.result)
              }
            })
          }
        });
      }
    })
  }

  // unlockCV(): void {
  //   this.io._unlockCV({candidateId: this.campaignDetails.candidateId, payFromWallet: true}).subscribe((res) => {
  //     console.log(res['serverResponse'])
  //     if (res['serverResponse']['code'] == 200) {
  //       const dialogRef = this.dialog.open(PopupConfirmationComponent, {
  //         height: 'auto',
  //         width: 'auto',
  //         autoFocus: false,
  //         data: {
  //           data: 'CV Unlocked Successful',
  //           campaignDetails: this.campaignDetails
  //         },
  //       })
  //     }else{
  //       console.log('failed')
  //     }
  //   });
  // }

  openSavedCV() {
    this.router.navigate(['Dashboard'], {queryParams: {data: true}});
  }

  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets()
    .subscribe(() => {
      this.dataService.getUserData().subscribe((data: any) => {
        this.userAssets = data;
      });
    });
  }

  getCVs(){
    this.mode = 'all'
    let params = {
      skill: this.skill,
      location: this.location,
    };

    this.io._getCandidates(params).subscribe((res: any) => {
      this.candidatesList = res.result
      this.status = this.candidatesList[0].id || '';
      this.detailsOfJob = this.candidatesList[0] || '';
      if((localStorage.getItem('cvSearchTour') ?? '0') === '0'){
        setTimeout(() => {
          this.introService.cvSearch()
        }, 1000)
      }
    })
  }

  viewUnlockedCVs(){
    this.mode = 'unlocked'
    let params = {
      skill: this.skill,
      location: this.location,
    };
    this.io._getUnlockedCVs(params).subscribe((res: any) => {
      this.candidatesList = res.result
      this.status = this.candidatesList[0].id || '';
      this.detailsOfJob = this.candidatesList[0] || '';
    })
  }

  replaceUnlockedCandidate(candidateId, unlockedCV){
    this.candidatesList = this.candidatesList.map(candidate => {
          if(candidate.id === candidateId){
            return unlockedCV
          }else{
            return candidate
          }
        })
  }

  dateFormatter(date: string){
    const inputDate = moment.utc(date);
    return inputDate.format('MMMM YYYY').toUpperCase();
  }
  dateFormatterYear(date: string){
    const inputDate = moment.utc(date);
    return inputDate.format('YYYY');
  }
}
