import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { EmailedCodeComponent } from '../emailed-code/emailed-code.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  forgotPassword: UntypedFormGroup;
  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.formInsli();
  }

  // Create Modal Driven Form //
  formInsli() {
    this.forgotPassword = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      path: ['forgot-password', Validators.required],
    });
  }

  // Validation Controls //
  get f() {
    return this.forgotPassword.controls;
  }

  // Forget Password //
  emailed() {
    if (this.forgotPassword.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.forgotPassword);
      return false;
    }
    this.io
      .apicall(this.forgotPassword.value, 'auth/forgot-password')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // this.dialogRef.close(true);
          // const dialogRef = this.dialog.open(EmailedCodeComponent, {
          //   height: 'auto',
          //   width: '450px',
          //   data: { id: res.result.id }
          // });
          // dialogRef.afterClosed().subscribe(res => {
          //   if (res === true) {
          //   }
          // });
          this.router.navigate(['/code-verify'], {
            queryParams: {
              _id: res.result.id,
              eId: this.forgotPassword.value.email,
            },
          });
        } else {
          // this.snackBar.open(res.serverResponse.message, 'OK', {})
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     this.snackBar.open('Please check connectivity', 'OK', {})
    //   }
    //   else {
    //     this.snackBar.open(err.error.serverResponse.message, 'OK', {})
    //   }
    // })
  }
}
