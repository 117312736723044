import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SaveWithoutLoginComponent } from '../../job-search/save-without-login/save-without-login.component';
import { SavedjobComponent } from '../../job-search/savedjob/savedjob.component';
import { SharejobComponent } from '../../job-search/sharejob/sharejob.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { CompleteRegistrationComponent } from '../complete-registration/complete-registration.component';
import { EmailedCodeComponent } from '../emailed-code/emailed-code.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LoginComponent } from '../login/login.component';
import { NewPasswordComponent } from '../new-password/new-password.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { SubscribtionComponent } from '../subscribtion/subscribtion.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  key: any;
  location: any;
  homeLogOutSearch: UntypedFormGroup;
  userDtls: any = null;
  userDtlssessionStorage: any;
  jobCatagoryTypeList: any;
  subscribtionForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  hotJobsList: any;
  allSubscriptionsData: any;
  postButtonShow: boolean = true;
  buildSectionShow: boolean = true;
  featuredJobsList: any;
  result: any = [];
  count: number = 1;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private io: HttpIOService,
    private fb: UntypedFormBuilder,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private auth: AuthService
  ) {
    if (localStorage.getItem('workNigeriaUser')) {
      this.userDtls = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))));
      if (this.userDtls.userType == 'jobseeker') {
        this.postButtonShow = false;
      }
    } else {
      this.userDtls = '';
    }
    // this.tokensessionStorage = JSON.parse(sessionStorage.getItem('workNigeriaToken'));routerLink="/login"
    if (sessionStorage.getItem('workNigeriaUser')) {
      this.userDtlssessionStorage = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      if (this.userDtlssessionStorage.userType == 'jobseeker') {
        this.postButtonShow = false;
      }
    } else {
      this.userDtlssessionStorage = '';
    }
    if (this.userDtls || this.userDtlssessionStorage) {
      if (
        this.userDtls.isResumeParsed ||
        this.userDtlssessionStorage.isResumeParsed
      ) {
        this.buildSectionShow = false;
      } else if (
        this.userDtls.userType == 'employer' ||
        this.userDtlssessionStorage.userType == 'employer'
      ) {
        this.buildSectionShow = false;
      }
    }
  }

  ngOnInit(): void {
    this.subscribtionForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
    this.homeLogOutSearch = this.fb.group({
      searchText: [''],
      job_location: [''],
    });
    this.getAllDetails();
    this.jobCatagory();
    this.hotJobs();
    this.allSubscriptions();
    console.log("all SUBS: ", this.allSubscriptions);
    this.featuredJobs();

    // let arr = [1, 2, 3, 2, 1, 3, 2, 3, 3, 2, 2, 4];
    // // output = {
    // //   1: { count: 2 },
    // //   2: { count: 5 },
    // //   3: { count: 3 },
    // //   4: { count: 1 },
    // // };

    var Arr = ['ac', 'gugjgjj', 'hfyfufyrjjgj', 'g', 'iiy'];
    // var Arr = [1, 7, 2, 8, 3, 4, 5, 0, 9];

    // for (var i = 1; i < Arr.length; i++)
    //     for (var j = 0; j < i; j++)
    //         if (Arr[i].length > Arr[j].length) {
    //           let x = Arr[i];
    //           console.log(x)
    //           Arr[i] = Arr[j];
    //           console.log(Arr[i])

    //           Arr[j] = x;
    //         }

    // console.log(Arr);

    let a = {
      f: 'jhj',
      h: 'khk',
    };
    let v = a;
    console.log(v);
    v.f = 'hhiih';
  }

  hf(arr: any) {
    // console.log(arr)
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[i] == arr[j]) {
          this.count = this.count + 1;
        }
      }

      // arr = arr.filter((item)=>item != arr[0])
      // // console.log(arr)
    }
    this.result.push(`${arr[0]}:{count:${this.count}}`);
    console.log(this.result);
  }

  get f() {
    return this.subscribtionForm.controls;
  }
  get g() {
    return this.homeLogOutSearch.controls;
  }

  goToJobSearch(type: any) {
    if (type == 'jobcategory') {
      this.router.navigate(['/job-search']);
    }
    if (type == 'hot') {
      this.router.navigate(['/job-search'], {
        queryParams: { job_type_Home: type },
      });
    }
  }

  // perticular Catogery wise job search //
  categotyWiseJobSearch(perticularCategoty) {
    // console.log(perticularCategoty)
    this.router.navigate(['/job-search'], {
      queryParams: { job_type_Category: perticularCategoty.id },
    });
  }

  // Job Catogery //
  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
        }
      });
  }
  // const arr = ['fjhfh','j','hg','fyfhfhfiuriuf']
  // console.log(arr.join('6'))
  // const arr2 = [1,2,3,4,9,5]
  // console.log(arr2.find(item=>item>1))
  // let ansARR = arr2.sort((a,c)=>a>c?1:-1)
  // console.log(ansARR)
  // const arr2 = ["1","2","3","4","9","5"]
  //   const arr2 = ["av","rftrtr","trf","uoegaeg","ljwrwrwr","tyrhrhwr"]
  //   var longest = arr2.reduce(
  //      (a, b) =>{
  //         return a.length < b.length ? a : b;

  //     }
  // );
  // console.log(longest)
  postOpportunities() {
    // console.log(this.userDtls,this.userDtlssessionStorage)
    if (this.userDtls == '' && this.userDtlssessionStorage == '') {
      this.router.navigate(['/login']);
      // console.log("+++++++")
    } else {
      this.router.navigate(['/post-job']);
    }
  }

  subscribtion() {
    if (this.subscribtionForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.subscribtionForm);
      return false;
    }
    // console.log(this.subscribtionForm.value)
    const dialogRef = this.dialog.open(SubscribtionComponent, {
      height: '450px',
      width: '550px',
      data: {
        data: this.allSubscriptionsData,
        email: this.subscribtionForm.value,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        this.subscribtionForm.reset();
      }
    });
  }

  hotJobs() {
    let jsonData = {
      path: 'search-job',
      job_type: 'hot',
      limit: 4,
      userId: '',
    };
    if (this.userDtls || this.userDtlssessionStorage) {
      if (this.userDtls) {
        jsonData.userId = this.userDtls.id;
      } else {
        jsonData.userId = this.userDtlssessionStorage.id;
      }
    }
    this.io._fiveHotJob(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.hotJobsList = res['result'].jobList;
        // console.log(this.hotJobsList );
      }
    });
  }

  featuredJobs() {
    let jsonData = {
      path: 'featured-jobs',
    };

    this.io._featureJob(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.featuredJobsList = res['result'].data;
        // console.log(this.hotJobsList );
      }
    });
  }

  afterLoginAlertPage() {
    if (this.userDtls == '' && this.userDtlssessionStorage == '') {
      const dialogRef = this.dialog.open(SaveWithoutLoginComponent, {
        height: '450px',
        width: '450px',
        data: 'alertPage',
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else {
      this.router.navigate(['/job-alert']);
    }
  }
  // Saved job //
  savedJob(job) {
    if (this.userDtls == '' && this.userDtlssessionStorage == '') {
      const dialogRef = this.dialog.open(SaveWithoutLoginComponent, {
        height: '450px',
        width: '450px',
        data: { Perticularjob: job, hint: 'logout-save-job' },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else {
      if (job.isSaved == true) {
        let jsonData = {
          path: 'unsaved-job',
          jobId: job.id,
          id: this.userDtls ? this.userDtls.id : this.userDtlssessionStorage.id,
        };
        //  console.log(jsonData);
        this.io._unSaveJob(jsonData).subscribe((res: any) => {
          //  console.log(res);
          if (res.serverResponse.code == 200) {
            // this.snackBar.openFromComponent(
            //   DynamicSnacbarComponent, {
            //   data: res.serverResponse.message
            // });
            this.hotJobs();
          }
        });
      } else {
        const dialogRef = this.dialog.open(SavedjobComponent, {
          height: '450px',
          width: '450px',
          data: { data: job },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
            this.hotJobs();
          }
        });
      }
    }
  }
  // without login if any one click save button

  // Share job //
  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: '480px',
      width: '450px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  allSubscriptions() {
    let jsonData = {
      path: 'all-subscriptions',
    };
    this.io._getAllSubscription(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.allSubscriptionsData = res['result'].subscriptions;
      }
    });
  }

  search() {
    if (
      this.homeLogOutSearch.get('job_location').value == '' &&
      this.homeLogOutSearch.get('searchText').value == ''
    ) {
      this.router.navigate(['/job-search']);
    } else {
      let jsonData = {
        job_location: this.homeLogOutSearch.get('job_location').value,
        path: 'search-job',
        searchText: this.homeLogOutSearch.get('searchText').value,
      };
      this.io.setforjobCategory(jsonData);
      // console.log(this.location,"++++++++++")/isResumeParsed
      this.router.navigate(['/job-search']);
    }
  }

  ccjcujcj() {
    this.snackBar
      .openFromComponent(DynamicSnacbarComponent, {
        data: 'res.serverResponse.message',

        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);
  }

  buildCVsection() {
    if (this.userDtls || this.userDtlssessionStorage) {
      if (
        this.userDtls.isResumeParsed ||
        this.userDtlssessionStorage.isResumeParsed
      ) {
        this.buildSectionShow = false;
      } else {
        this.router.navigate(['/jobseeker-profile']);
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { role: 'cv-build' } });
    }
  }

  getAllDetails() {
    if (this.userDtls) {
      let jsonData = {
        id: this.userDtls.id,
        path: 'get-user',
      };
      this.io._JSallDetails(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res)
          if (localStorage.getItem('workNigeriaUser') === null) {
            this.auth.setCurrentUserDetailsInSessoin(res['result'].userData);
          } else {
            this.auth.setCurrentUserDetails(res['result'].userData);
          }
        }
      });
    }

    if (this.userDtlssessionStorage) {
      let jsonData = {
        id: this.userDtlssessionStorage.id,
        path: 'get-user',
      };
      this.io._JSallDetails(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
          if (localStorage.getItem('workNigeriaUser') === null) {
            this.auth.setCurrentUserDetailsInSessoin(res['result'].userData);
          } else {
            this.auth.setCurrentUserDetails(res['result'].userData);
          }
        }
      });
    }
  }

  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    if (dateDiff === 0) {
      return 'today';
    } else if (dateDiff === 1) {
      return 'yesterday';
    } else {
      return `${dateDiff} days ago`;
    }
  }

  jobDetails(selectedJob: any) {
    if (this.userDtls || this.userDtlssessionStorage) {
      this.router.navigate(['/job-search'], {
        queryParams: { id: selectedJob.id, title: selectedJob.title },
      });
    }
  }
}
