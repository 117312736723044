import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { EmployerService } from 'src/app/services/employer.service';
import { ExportAdminEmployerService } from 'src/app/services/export-admin-employer.service';
import { Router } from '@angular/router';
import { ExportAdminJobsService } from 'src/app/services/export-admin-jobs.service';
import moment from 'moment';

@Component({
  selector: 'app-employer-super-admin',
  templateUrl: './employer-super-admin.component.html',
  styleUrls: ['./employer-super-admin.component.scss']
})

export class EmployerSuperAdminComponent implements OnInit {
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('job-description') jobDescription: ElementRef;
  startDate: string = '';
  endDate: string = '';
  employersList: any;
  filteredEmployeeList: any = [];
  pagination = {
    size: 8,
    start: 0,
    total: 0,
  };
  currentPage: number = 1;
  pageSize: number = 8;
  selectedOption = 'Need Verification';
  searchQuery: string = '';
  jobSearch: string = '';
  jobSearchQuery: any = {}
  selectedEmployer: any = null;
  detailsOfJob: any = null;
  clickSection: any = '';
  jobDesc: any = '';
  qualifications: any = '';
  jobLists: any;
  searchInitiated: boolean = false;
  orderSelected: any = '';

  constructor(
    private empService: EmployerService,
    private exportEmployerService: ExportAdminEmployerService,
    private router: Router,
    private exportJobsService: ExportAdminJobsService,
  ) { }

  ngOnInit(): void {
    // this.startDate = '2022-12-27'
    this.getEmployersAnalytics();
  }

  // Cuts the string short to display 
  // @param {string} str: takes the whole string
  // @return {string}: returns the string cut to only 10 letters including spaces
  truncateString(str: string): string {
    if (str.length <= 10) {
      return str;
    } else {
      return str.slice(0, 10) + "...";
    }
  }

  onSearch(): void {
    const strtDt = this.startDate ? moment.utc(this.startDate) : null;
    const endDt = this.endDate ? moment.utc(this.endDate) : null;
    this.searchInitiated = true
    this.filteredEmployeeList = this.employersList.employerData.filter((item) => {
      const itemDate = moment(item.createdAt);
      const isWithinDateRange = 
        (!strtDt || itemDate.isSameOrAfter(strtDt)) && 
        (!endDt || itemDate.isSameOrBefore(endDt));
      const matchesQuery = 
        item.companyName?.toLowerCase().includes(this.searchQuery.toLowerCase()) || 
        item.email?.toLowerCase().includes(this.searchQuery.toLowerCase());
      
      return isWithinDateRange && matchesQuery;
    });
    this.currentPage = 1
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
  }

  clearEndDate(): void {
    this.endDate = null
  }

  clearStartDate(): void {
    this.startDate = null
  }

  searchEmployerJob(): void {
    this.jobSearchQuery = {
      searchText: this.jobSearch,
      sortOrder: this.orderSelected ? this.orderSelected : undefined,
      start: 0
    }
  }

  checkSearchQuery(): void {
    if(this.searchInitiated && this.searchQuery === '' && !this.startDate && !this.endDate) {
      this.searchInitiated = false
      this.filteredEmployeeList = []
    }
  }

  get paginatedRoles() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.searchInitiated ? this.filteredEmployeeList?.slice(startIndex, endIndex) : this.employersList?.employerData.slice(startIndex, endIndex);
  }

  async getEmployersAnalytics() {
    let params = new HttpParams();
    params = this.startDate && params.append('startDate', this.startDate);
    await this.empService._getEmployers(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.employersList = res['result'];
      }
    });
  }

  async exportEmployers() {
    this.exportEmployerService.genExcel(this.searchInitiated ? this.filteredEmployeeList : this.employersList.employerData);
  }

  //----------- ACTIVE OR INACTIVE SLIDE ------------//
  async activeInactive(userId: String) {
    await this.empService._toggleUserStatus({id: userId}).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        console.log(res);
      }
    });
  }

  onVerificationChange(company: any): void {
    console.log('Verification status changed for:', company.companyName, 'New status:', company.verified);
  }

/**
 * Navigates the user to the chat screen.
 * @param {Object} row - employer data for messaging.
 */
  messageOpen(row) {
    this.router.navigate(['/message']);
    this.empService.setforSeletedIn_cvSearch(row);
  }

/**
 * Navigates the user to the employer jobs.
 * @param {Object} emp - selected employer object.
 */
  navigateToEmployer(emp) {
    this.selectedEmployer = emp;
  }

  async exportJobs(start: number, id: any) {
    let payLoad = {
      path: 'get-job-by-provider-or-company',
      provider_id: id,
      size: 100,
      start: start,
      sortParam: 'created_at',
      sortOrder : this.orderSelected ? this.orderSelected : 'desc',
      ...this.jobSearchQuery
    };
    this.empService
      ._getJobByproviderAndCompany(payLoad)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobLists = res['result'].job;
        }
        this.exportJobsService.genExcel(this.jobLists);
      });
  }

  handlejobSelected(job: any) {
    this.detailsOfJob = job;
  }

  backToJobs() {
    this.detailsOfJob = null
  }

  onOrderChange() {
    this.searchEmployerJob()
  }

}
