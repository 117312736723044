import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Helper } from '../helper/defult';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  companyData: any;
  userDeatils: any;
  private WSconnect = new BehaviorSubject<any>(null);
  private seletedIn_cvSearch = new BehaviorSubject<any>(null);
  private websocket = new BehaviorSubject<any>(null);
  private dynamicMessageCountInOnSeenApi = new BehaviorSubject<any>(null);
  private loadMoreInSearchJob = new BehaviorSubject<any>(null);
  constructor(
    private auth: AuthService,
    public http: HttpClient,
    private helper: Helper
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  setforWSconnect(data) {
    this.WSconnect.next(data);
  }
  getforWSconnect() {
    return this.WSconnect.asObservable();
  }

  setforMessageWSconnect(data) {
    this.websocket.next(data);
  }
  getforMessageWSconnect() {
    return this.websocket.asObservable();
  }

  setforSeletedIn_cvSearch(data) {
    this.seletedIn_cvSearch.next(data);
  }
  getforSeletedIn_cvSearch() {
    return this.seletedIn_cvSearch.asObservable();
  }

  setMessageCountForOnSeenApi(data) {
    this.dynamicMessageCountInOnSeenApi.next(data);
  }
  getMessageCountForOnSeenApi() {
    return this.dynamicMessageCountInOnSeenApi.asObservable();
  }

  setloadMoreInSearchJob(data) {
    this.loadMoreInSearchJob.next(data);
  }
  getloadMoreInSearchJob() {
    return this.loadMoreInSearchJob.asObservable();
  }
  // ------------------------  EMP DASHBOARD JOB ----------------------//
  _getJobByproviderAndCompany(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/get-job-by-provider-or-company`,
      data
    );
  }

  _getAppliedJobseeker(data: any) {
    return this.http.post(
      `${environment.baseUrl}user/get-applied-job-seeker`,
      data
    );
  }

  _getEmployerByCompany(data: any) {
    return this.http.post(
      `${environment.baseUrl}user/get-employer-by-company`,
      data
    );
  }

  // ------------------------  EMP DASHBOARD JOB DETAILS  ----------------------//

  _changeStatus(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/change-application-status`,
      data
    );
  }
  _emailSendForStatusChange(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/status-change-notification-mail`,
      data
    );
  }

  // ------------------------  EMP DASHBOARD USER MANAGEMENT ----------------------//
  _activeAndDeactiveUsers(data: any) {
    return this.http.post(
      `${environment.baseUrl}user/user-active-deactive`,
      data
    );
  }

  _genInvitationUserDetails(data: any) {
    return this.http.post(
      `${environment.baseUrl}user/get-invitation-user`,
      data
    );
  }

  _setPasswordForInvitationUser(data: any) {
    return this.http.post(
      `${environment.baseUrl}user/invitation-authorization-user`,
      data
    );
  }

  // ------------------------  EMP DASHBOARD COMPANY PIC UPLOAD ----------------------//
  _companyProfilePicUpload(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/update-company-logo`,
      data
    );
  }
  _getCompanyAdminList(data: any) {
    return this.http.post(`${environment.baseUrl}user/manage-users`, data);
  }
  // ------------------------  EMP DASHBOARD USER MANAGEMENT FOR SUPER ADMIN ----------------------//

  _getAllCompaniesList(data: any) {
    return this.http.post(`${environment.baseUrl}job/get-company-list`, data);
  }

  // ------------------------  EMP MANAGE USER AND ADD USER ----------------------//
  _getAllActivity(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/count-total-active-applied-views-job`,
      data
    );
  }

  // ------------------------  COMPANIES TAB IN SUPER ADMIN ----------------------//
  _changeCompanyStatus(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/change-company-status`,
      data
    );
  }

  // ------------------------  SUPER ADMIN TAB IN SUPER ADMIN ----------------------//


  _fetchUsersList(params: HttpParams) {
    return this.http.get(`${environment.baseUrl}admin/users?`, { params });
  }

  _downloadJobSeekersCsv() {
    return this.http.get(`${environment.baseUrl}admin/download-jobseekers`);
  }

  _downloadEmployersCsv() {
    return this.http.get(`${environment.baseUrl}admin/download-employers`);
  }

  // --------------------- 3MTT SUPER ADMIN -----------------------//
  _getAnalytics(params: HttpParams) {
    return this.http.get(`${environment.baseUrl}admin/fellow-analytics`, {params} );
  }

  // -------------------- SUPER ADMIN ----------------------------//
  _getEmployers(params: HttpParams) {
    return this.http.get(`${environment.baseUrl}admin/employer-data`, {params});
  }

  _getJobSeekers(params: HttpParams) {
    return this.http.get(`${environment.baseUrl}admin/jobseeker-data`, {params});
  }

  _toggleUserStatus(data: any) {
    return this.http.post(`${environment.baseUrl}admin/toggle-user-status`, data);
  }

  _getJobsList(data: any) {
    return this.http.post(`${environment.baseUrl}admin/all-jobs-data`, data);
  }

  _parseResumes(data: any) {
    return this.http.post(`${environment.baseUrl}admin/resume-parser`, data);
  }

  _parseResumeSandbox(data: any) {
    return this.http.post(`${environment.baseUrl}public/resume-parser`, data);
  }

  // ------------------------  CV SEARCH ----------------------//
  _cvSearch(data: any) {
    return this.http.post(
      `${environment.baseUrl}cv-search/search-document`,
      data
    );
  }

  // ------------------------  MESSAGE  ----------------------//
  _getChatRooms(data: any) {
    return this.http.post(
      `${environment.baseUrl}messaging/get-chat-rooms`,
      data
    );
  }
  _createChatRoom(data: any) {
    return this.http.post(
      `${environment.baseUrl}messaging/create-chat-room`,
      data
    );
  }
  _getMessage(data: any) {
    return this.http.post(`${environment.baseUrl}messaging/get-messages`, data);
  }

  _onSeenMessage(data: any) {
    return this.http.post(`${environment.baseUrl}messaging/on-seen`, data);
  }
  _getMessageCount(data: any) {
    return this.http.post(
      `${environment.baseUrl}messaging/get-message-count`,
      data
    );
  }
  _leaveMessage(data: any) {
    return this.http.post(
      `${environment.baseUrl}messaging/leave-message`,
      data
    );
  }
  _getSuggestedMessages(data: any) {
    return this.http.post(
      `${environment.baseUrl}messaging/suggested-messages`,
      data
    );
  }
  // -------------------------    verification form   ----------------
  _CAC_verify(data: any) {
    return this.http.post(
      `${environment.baseUrl}company/verify-company-cac`,
      data
    );
  }

  _CAC_upload(data: any) {
    return this.http.post(
      `${environment.baseUrl}job/upload-cac-certificate`,
      data
    );
  }
  _companyVerification(data: any) {
    return this.http.post(`${environment.baseUrl}job/verify-company`, data);
  }
  _AllEmployeeDetails(data: any) {
    return this.http.post(`${environment.baseUrl}user/export-data`, data);
  }
  _AllJobSeekerList(data: any) {
    return this.http.post(`${environment.baseUrl}user/manage-users`, data);
  }

  _AllHotNewFeatureJob(data: any) {
    return this.http.post(
      `${environment.baseUrl}jobsearch/hot-new-featured-jobs`,
      data
    );
  }

  _FeatureJob(data: any) {
    return this.http.post(`${environment.baseUrl}job/update-job`, data);
  }

  // -----------------    Campaign Management   -------------------
  _getCampaigns(params: any) {
    return this.http.get(`${environment.baseUrl}job/campaigns`, { params });
  }

  _postCampaign(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/campaigns`, JsonBody);
  }

  _getCreditUtilization(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/credit-utilization`, JsonBody);
  }

  //---------------------------------------- Payment History ---------------------------------------
  _paymentHistory(params: any) {
    return this.http.get(`${environment.baseUrl}user/payment-history`, { params });
  }

  //---------------------------------------- Campaign Test -----------------------------------------
  _campaignSchedulerTest(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}campaign-test`, JsonBody);
  }

  //---------------------------------------- Create Campaigns ---------------------------------------
  _createCampaign(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/campaigns`, JsonBody);
  }

  //---------------------------------------- Update Analytics ---------------------------------------
  _updateAnalytics(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}job/analytics`, JsonBody);
  }

  _updateCampaign(JsonBody: any, id: string) {
    return this.http.patch(
      `${environment.baseUrl}job/campaigns/${id}`,
      JsonBody
    );
  }

  _fundWallet(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/fund-wallet`, JsonBody);
  }

  _getCurrentUser(JsonBody: any) {
    return this.http.post(`${environment.baseUrl}user/get-job-seeker`, JsonBody);
  }

  // ----------------- SEARCH CV -----------------//
  _getFolderforCVs() {
    return this.http.get(`${environment.baseUrl}cv-search/folders`);
  }

  _saveCV(params) {
    return this.http.post(`${environment.baseUrl}cv-search/save-cv`, params)
  }
  _createEmptyFolder(params) {
    return this.http.post(`${environment.baseUrl}cv-search/create-empty-folder`, params)
  }

  _inviteToApply(params) {
    return this.http.post(`${environment.baseUrl}cv-search/invite-to-apply`, params)
  }

  _getFolderCandidates(body) {
    return this.http.post(`${environment.baseUrl}/user/candidates`, body)
  }
}
