import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ExportEmployerJobsService } from 'src/app/services/export-employer-jobs.service';
import { Observable, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SaveWithoutLoginComponent } from '../../job-search/save-without-login/save-without-login.component';
import { SavedjobComponent } from '../../job-search/savedjob/savedjob.component';
import { SharejobComponent } from '../../job-search/sharejob/sharejob.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult';
import { JobSeekerWantToRecruiterSideComponent } from '../../general/job-seeker-want-to-recruiter-side/job-seeker-want-to-recruiter-side.component';
import { CompanyVerificationComponent } from '../../employer/company-verification/company-verification.component';
import { CreateAlertComponent } from '../../jobseeker/create-alert/create-alert.component';
import { DataService } from 'src/app/services/data.service';
import { SubscribtionComponent } from '../subscribtion/subscribtion.component';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss'],
})
export class HomeNewComponent implements OnInit {
  userDeatils: any;
  companyData: any;
  cityList: any;
  filteredOptions: Observable<any[]>;
  homeNew: UntypedFormGroup;
  recentJob: any;
  hotJob: any;
  featureJob: any;
  jobCatagoryTypeList: any;
  jobAlertForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  allSubscriptionsData: any = [];
  errorCheckVisible = false;
  searchTextPattern = /^[A-Za-z0-9][A-Za-z0-9\s]*$/;

  constructor(
    public dialog: MatDialog,
    private helper: Helper,
    private router: Router,
    private route: ActivatedRoute,
    private route1: ActivatedRoute,
    private auth: AuthService,
    private io: HttpIOService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private employerService: EmployerService,
    private snackBar: MatSnackBar,
    private exportEmpJobsService: EmployerService,
    private dialogRef: MatDialog,
    private dataService: DataService,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  isMobileScreen = false;

  ngOnInit(): void {
    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
    });

    this.validationForm();
    this.getLoacationList();
    this.hotNewFeatureJob();
    this.jobCatagory();

    this.jobAlertForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
    this.allSubscriptions();

    // Only triggers when we unsubcribe
    if (this.route.snapshot.queryParams['code']) {
      this.unsubscribeJobAlert(this.route.snapshot.queryParams['code']);
    }

    this.route1.queryParams.subscribe(params => {
      const jobAlertSet = params['data'];
      if (jobAlertSet === 'job alert set') {
        setTimeout(() => {
          this.scrollToJobAlertCard();
        }, 0);
      }
    });
  }

  // Validation Controls //
  get f() {
    return this.homeNew.controls;
  }

  unsubscribeJobAlert(code: any) {
    let payload = {
      path: 'unsubscribe-job-alert',
      code: code,
    };

    this.io._unsubscribeJobAlert(payload).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        let successData = "unsubscribed daily alerts"
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: successData,
          },
        });
        dialogRef.afterClosed().subscribe();
        this.router.navigate(['/home']);
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.router.navigate(['/home']);
      }
    });
  }

  allSubscriptions() {
    let jsonData = {
      path: 'all-subscriptions',
    };
    this.io._getAllSubscription(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.allSubscriptionsData = res['result'].subscriptions;
      }
    });
  }

  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  navigateTo3MTT() {
    if (environment.production) {
      window.location.href = 'http://3mtt.worknigeria.com/';
    } else {
      window.location.href = 'http://3mtt-development.worknigeria.com/';
    }
  }

  scrollToJobAlertCard() {
    const element = <HTMLElement>document.querySelector(`#jobAlertCard`);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  navigateToCvReview() {
    this.router.navigate(['/cv-review']);
  }

  checkScreenSize() {
    this.isMobileScreen = window.innerWidth <= 426;
  }

  get errorCheck() {
    return this.jobAlertForm.controls;
  }

  // navigateToContactUs() {
  //   this.router.navigate(['/contactUs']);
  // }

  getLoacationList() {
    this.io._cityList({ path: 'city-list' }).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.cityList = res['result'].cityList.map((item) => item.state);;
        this.filteredOptions = this.homeNew.controls.cityName.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      }
    });
  }

  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.cityList.filter((option) =>
      option.state.toLowerCase().includes(filterValue)
    );
  }

  // Insilize Form //
  validationForm() {
    this.homeNew = this.fb.group({
      cityName: [''],
      searchText: ['', Validators.compose([
          Validators.required,
          Validators.pattern(this.searchTextPattern),
        ]),
      ],
    });
  }

  changeLocation(event) {
    console.log(event);
  }

  search() {
    if (
      this.homeNew.get('cityName').value == '' &&
      this.homeNew.get('searchText').value == ''
    ) {
      this.router.navigate(['/job-search']);
    } else {
      let jsonData = {
        job_location: this.homeNew.get('cityName').value,
        path: 'search-job',
        searchText: this.homeNew.get('searchText').value,
      };
      console.log(jsonData);
      this.io.setforjobCategory(jsonData);
      // console.log(this.location,"++++++++++")/isResumeParsed
      this.router.navigate(['/job-search']);
    }
  }

  ccjcujcj() {
    this.snackBar
      .openFromComponent(DynamicSnacbarComponent, {
        data: 'res.serverResponse.message',

        verticalPosition: 'top', // 'top' | 'bottom'
        horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);

    // let elements = document.getElementsByClassName('cdk-global-overlay-wrapper');
    // let length = elements.length;
    // for (let i = 0; i < length; i++) {
    //   elements[i].setAttribute('style', 'justify-content: right;align-items: flex-start;margin:30px ;z-index:1000');
    // }
    // this.snackBar.openFromComponent(DynamicSnacbarComponent, {
    //   data: "res.serverResponse.message",

    // }).onAction().subscribe(this.helper.setOnTop);
  }
  // this.router.navigate(['/job-search'], { queryParams: { role: 'Guest-User' } });
  // ${window.location.origin}/profile?id=${row.publicId}
  setOnTop = () => {
    let elements = document.getElementsByClassName(
      'cdk-global-overlay-wrapper'
    );
    let length = elements.length;
    for (let i = 0; i < length; i++) {
      elements[i].setAttribute(
        'style',
        'justify-content: right;align-items: flex-start;margin:30px ;z-index:1000'
      );
    }
  };

  setSearchText(text) {
    console.log(text);
    let jsonData = {
      path: 'search-job',
      searchText: text,
    };
    console.log(jsonData);
    this.io.setforjobCategory(jsonData);
    this.router.navigate(['/job-search']);
  }

  hotNewFeatureJob() {
    if (environment.production){
    let jsonData = {
      path: 'hot-new-featured-jobs',
      userId: '',
      limit: 5,
    };
    if (this.userDeatils) {
      jsonData.userId = this.userDeatils.id;
    }
    this.employerService
      ._AllHotNewFeatureJob(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.featureJob = res['result'].featuredJobs;
          this.hotJob = res['result'].hotJobs;
          this.recentJob = res['result'].newJobs;
        }
      });
    } else {
      return true;
    }
  }

  jobDetails(selectedJob: any) {
    // if (this.userDeatils) {
    this.router.navigate(['/job-search'], {
      queryParams: { id: selectedJob.id, title: selectedJob.title },
    });
    // }
  }

  // Saved job //
  savedJob(job) {
    if (this.userDeatils == undefined) {
      const dialogRef = this.dialog.open(SaveWithoutLoginComponent, {
        height: '450px',
        width: '450px',
        data: { Perticularjob: job, hint: 'logout-save-job' },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
        }
      });
    } else {
      if (job.isSaved == true) {
        let jsonData = {
          path: 'unsaved-job',
          jobId: job.id,
          id: this.userDeatils.id,
        };
        this.io._unSaveJob(jsonData).subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.hotNewFeatureJob();
          }
        });
      } else {
        const dialogRef = this.dialog.open(SavedjobComponent, {
          height: '450px',
          width: '450px',
          data: { data: job },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
            this.hotNewFeatureJob();
          }
        });
      }
    }
  }

  // Share job //
  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: '480px',
      width: '450px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  BuildCV() {
    if (this.userDeatils) {
      this.router.navigate(['/jobseeker-profile']);
    }
  }

  buildCVsection() {
    if (this.userDeatils) {
      if (this.userDeatils.isResumeParsed) {
        this.router.navigate(['/jobseeker-profile']);
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'jobseeker-profile');
    }
  }

  // Job Catogery //
  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
        }
      });
  }

  // perticular Catogery wise job search //DynamicSnacbarComponent
  categotyWiseJobSearch(perticularCategoty) {
    // console.log(perticularCategoty)
    this.router.navigate(['/job-search'], {
      queryParams: { job_type_Category: perticularCategoty.id },
    });
  }
  // succes() {
  //   this.snackBar.openFromComponent(DynamicSnacbarComponent, {
  //     data: "success"
  //   });
  // }

  // error() {
  //   this.snackBar.openFromComponent(DynamicSnacbarErrorComponent, {
  //     data: "error"
  //   });
  // }

  // skip() {
  //   this.snackBar.openFromComponent(DynamicSnacbarSkipComponent, {
  //     data: "skip"
  //   });
  // }

  job_alert() {
    if (this.userDeatils) {
      this.router.navigate(['/job-alert']);
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'job-alert');
    }
  }

  createAlert() {
    if (this.jobAlertForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.jobAlertForm);
      return false;
    }
    if (this.allSubscriptionsData.length == 0) {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: 'Something went wrong ',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    let jsonData = {
      path: 'add-user-subscription',
      email: this.jobAlertForm.value['email'],
      subscriptions: [this.allSubscriptionsData[3].id],
    };
    let successData = 'daily job alert';
    this.io._updateUserSubscription(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: successData,
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
            this.jobAlertForm.value.reset();
          }
        });
      } else if (res.serverResponse.code == 400) {
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  postAjob() {
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'jobseeker') {
        const dialogRef = this.dialog.open(
          JobSeekerWantToRecruiterSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'employer') {
        this.router.navigate(['/post-job']);
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'post-job');
    }
  }

  Dashboard() {
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'jobseeker') {
        const dialogRef = this.dialog.open(
          JobSeekerWantToRecruiterSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'employer') {
        if (this.companyData.approvedStatus == 'approved') {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your company aleady varified',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        } else if (this.companyData.approvedStatus == 'pending') {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your application has already submitted',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        } else {
          this.verification();
        }
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'Dashboard');
    }
  }

  verification() {
    const dialogRef = this.dialog.open(CompanyVerificationComponent, {
      height: 'auto',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        //  console.log(res)
        // if (localStorage.getItem("workNigeriacompanyData") === null) {
        //   this.auth.setCurrentCompanyDataInSessoin(res);
        // } else {
        //   this.auth.setCurrentCompanyData(res);
        // }
      }
    });
  }
  getColumns(list: any[]): any[][] {
    // Sort the list alphabetically
    const sortedList =
      list !== undefined
        ? list.sort((a, b) => a.categoryName.localeCompare(b.categoryName))
        : [];
    const columns: any[][] = [];
    const itemsPerColumn = Math.ceil(sortedList.length / 3);
    // Split the sorted list into three equal columns
    for (let i = 0; i < sortedList.length; i += itemsPerColumn) {
      const column = sortedList.slice(i, i + itemsPerColumn);
      columns.push(column);
    }
    return columns;
  }
}