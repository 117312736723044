import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SubscribtionComponent } from 'src/app/views/auth/subscribtion/subscribtion.component';
import { CompanyVerificationComponent } from 'src/app/views/employer/company-verification/company-verification.component';
import { JobSeekerWantToRecruiterSideComponent } from 'src/app/views/general/job-seeker-want-to-recruiter-side/job-seeker-want-to-recruiter-side.component';
import { RecruiterWantToJobSeekerSideComponent } from 'src/app/views/general/recruiter-want-to-job-seeker-side/recruiter-want-to-job-seeker-side.component';
import { SaveWithoutLoginComponent } from 'src/app/views/job-search/save-without-login/save-without-login.component';
import { DynamicSnacbarErrorComponent } from '../dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from '../dynamic-snacbar/dynamic-snacbar.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  subscribtionForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  allSubscriptionsData: any = [];
  year: number;
  userDeatils: any = '';
  companyData: any = '';

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private io: HttpIOService,
    private fb: UntypedFormBuilder,
    private helper: Helper,
    private empService: EmployerService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private dialogRef: MatDialog,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    } else {
      this.userDeatils = '';
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    } else {
      this.userDeatils = '';
    }

    // this.empService.getforWSconnect().subscribe(res => {
    //   console.log(res)
    //   if (res) {
    //     this.userDeatils = res['result'].userData;
    //     this.companyData = res['result'].companyData;
    //   } else {
    //     this.userDeatils = '';
    //   }

    // })
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.subscribtionForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });

    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.allSubscriptions();
  }

  get f() {
    return this.subscribtionForm.controls;
  }

  subscribtion() {
    if (this.subscribtionForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.subscribtionForm);
      return false;
    }
    if (this.allSubscriptionsData.length == 0) {
      this.snackBar
        .openFromComponent(DynamicSnacbarErrorComponent, {
          data: 'Something went wrong ',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    const dialogRef = this.dialog.open(SubscribtionComponent, {
      height: 'auto',
      width: 'anto',
      autoFocus: false,
      data: {
        data: this.allSubscriptionsData,
        email: this.subscribtionForm.value,
        subscriptionFrom: 'footer-alert',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
        this.subscribtionForm.reset();
      }
    });
  }


  allSubscriptions() {
    let jsonData = {
      path: 'all-subscriptions',
    };
    this.io._getAllSubscription(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.allSubscriptionsData = res['result'].subscriptions;
        // console.log("all subscriptions: ",this.allSubscriptions)
      }
    });
  }

  buildCVsection() {
    console.log(this.userDeatils);
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'employer') {
        const dialogRef = this.dialog.open(
          RecruiterWantToJobSeekerSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'jobseeker') {
        this.router.navigate(['/jobseeker-profile']);
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'jobseeker-profile');
    }
  }

  goToPlans() {
    // choose-employer-subscription
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'employer') {
        this.router.navigate(['/choose-employer-subscription']);
      } else if (this.userDeatils.userType == 'jobseeker') {
        const dialogRef = this.dialog.open(
          JobSeekerWantToRecruiterSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {}
        });
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { userType: 'employer' } });
      sessionStorage.setItem('setUrlRedirection', '/choose-employer-subscription');
    }
  }

  job_alert() {
    console.log(this.userDeatils);
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'employer') {
        const dialogRef = this.dialog.open(
          RecruiterWantToJobSeekerSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'jobseeker') {
        this.router.navigate(['/job-alert']);
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'job-alert');
    }
  }

  setSearchText(text) {
    console.log(text);
    let jsonData = {
      path: 'search-job',
      job_type: text,
    };
    console.log(jsonData);
    this.io.setforjobCategory(jsonData);
    this.router.navigate(['/job-search']);
  }

  // if (this.userDeatils == undefined) {
  //   const dialogRef = this.dialog.open(SaveWithoutLoginComponent, {
  //     height: '450px',
  //     width: '450px',
  //     data: { Perticularjob: job, hint: "logout-save-job" },
  //     autoFocus: false,
  //   });
  //   dialogRef.afterClosed().subscribe(res => {
  //     if (res == "save") {
  //     }
  //   });
  // }

  postAjob() {
    console.log(this.userDeatils);

    if (this.userDeatils) {
      if (this.userDeatils.userType == 'jobseeker') {
        const dialogRef = this.dialog.open(
          JobSeekerWantToRecruiterSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'employer') {
        this.router.navigate(['/post-job']);
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { userType: 'employer' } });
      sessionStorage.setItem('setUrlRedirection', 'post-job');
    }
  }

  Dashboard() {
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'jobseeker') {
        const dialogRef = this.dialog.open(
          JobSeekerWantToRecruiterSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'employer') {
        if (this.companyData.approvedStatus == 'approved') {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your company aleady varified',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        } else if (this.companyData.approvedStatus == 'pending') {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your application has already submitted',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        } else {
          this.router.navigate(['/verification']);
        }
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { userType: 'employer' } });
      sessionStorage.setItem('setUrlRedirection', 'verification');
    }
  }

  cvSearch() {
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'jobseeker') {
        const dialogRef = this.dialog.open(
          JobSeekerWantToRecruiterSideComponent,
          {
            height: 'auto',
            width: '450px',
            data: {},
            autoFocus: false,
          }
        );
        dialogRef.afterClosed().subscribe((res) => {
          if (res == 'save') {
          }
        });
      }
      if (this.userDeatils.userType == 'employer') {
        this.router.navigate(['Dashboard'], {
          queryParams: ['search-cv']
        });
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { userType: 'employer' } });
      sessionStorage.setItem('setUrlRedirection', 'cv-search');
    }
  }

}
