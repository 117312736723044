import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private activeRequests: number = 0;
  private ignoredUrls: string[] = 
  [
    `${environment.baseUrl}messaging/get-chat-rooms`, 
    `${environment.baseUrl}messaging/get-messages`, 
    `${environment.baseUrl}messaging/create-chat-room`,
    `${environment.baseUrl}messaging/on-seen`,
    `${environment.baseUrl}messaging/leave-message`,
    `${environment.baseUrl}job/employment-type-list`,
    `${environment.baseUrl}subscription/all-subscriptions`,
    `${environment.baseUrl}job/category-list`,
    `${environment.baseUrl}job/company-list`,
    `${environment.baseUrl}job/view-job`,
    `${environment.baseUrl}job/get-company`,
    `${environment.baseUrl}job/city-list`,
    `${environment.baseUrl}messaging/leave-message`
  ]

  constructor(private loaderService: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.shouldShowLoader(req)) {
      return next.handle(req); // Skip showing loader and proceed with the request
    }
    if (this.activeRequests === 0) {
      this.loaderService.show();
    }
    this.activeRequests++;

    return next.handle(req).pipe(
      tap(
        () => {},
        () => {
          this.decreaseRequests();
        }
      ),
      finalize(() => {
        this.decreaseRequests();
      })
    );
  }

  private decreaseRequests() {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loaderService.hide();
    }
  }
  private shouldShowLoader(req: HttpRequest<any>): boolean {
    // Check if the request URL is in the ignoredUrls array
    return !this.ignoredUrls.some(url => req.url.includes(url));
  }
}
