<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>

<h4
  *ngIf="!show"
  style="display: flex; align-items: center; justify-content: space-between"
>
  Add experience
</h4>
<h4
  *ngIf="show"
  style="display: flex; align-items: center; justify-content: space-between"
>
  Edit experience
</h4>
<form class="example-form modal-dropdown" [formGroup]="experienceForm">
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Title</mat-label>
          <input
            type="text"
            matInput
            placeholder="Title"
            required="required"
            formControlName="title"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.title.touched && f.title.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.title.errors.required"
          >Title is required
        </span>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Company</mat-label>
          <input
            type="text"
            matInput
            placeholder="Company"
            required="required"
            formControlName="company"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.company.touched && f.company.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.company.errors.required"
          >Company is required
        </span>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Industry</mat-label>
          <input
            type="text"
            matInput
            placeholder="Industry"
            required="required"
            formControlName="industry"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.industry.touched && f.industry.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.industry.errors.required"
          >Industry is required
        </span>
      </div>
    </div>

    <div class="col-lg-12 mb-2">
      <div class="form-group">
        <mat-checkbox
          class="example-margin"
          [checked]="checked"
          [disabled]="disabled"
          (change)="checkDeclaration($event.checked)"
        >
          I currently work in this role
        </mat-checkbox>
      </div>
    </div>

    <div class="col-lg-5">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="startDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="col-lg-3"></div>
    <div class="col-lg-5">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker11"
            formControlName="endDate"
            [min]="experienceForm.controls.startDate.value"
          />
          <mat-datepicker-toggle matSuffix [for]="picker11">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker11></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="col-lg-3"></div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Description</mat-label>
          <!-- <input type="text" matInput placeholder="Description" required="required"
                            formControlName="description" /> -->
          <textarea
            type="text"
            matInput
            placeholder="Description"
            required="required"
            style="min-height: 50px"
            formControlName="description"
          >
          </textarea>
        </mat-form-field>
      </div>
      <div *ngIf="f.description.touched && f.description.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.description.errors.required"
          >Description is required
        </span>
      </div>
    </div>
  </div>
</form>
<button
  *ngIf="show"
  mat-raised-button
  color="primary"
  (click)="removeExperiance()"
>
  Delete Experience
</button>
<button
  *ngIf="!show"
  style="float: right"
  mat-raised-button
  color="primary"
  (click)="saveExperiance()"
>
  SAVE
</button>
<button
  *ngIf="show"
  style="float: right"
  mat-raised-button
  color="primary"
  (click)="updateExperiance()"
>
  UPDATE
</button>
