import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';

@Component({
  selector: 'app-subscribtion',
  templateUrl: './subscribtion.component.html',
  styleUrls: ['./subscribtion.component.scss'],
})
export class SubscribtionComponent implements OnInit {
  userDeatils: any;
  companyData: any;
  allSubscriptionData: any;
  selectedItems: any = null;
  emailFormArray: any = [];

  title = 'angular-checkboxes-example';
  form: UntypedFormGroup;
  CountryData: Array<any> = [
    { name: 'IND', value: 'India' },
    { name: 'AUS', value: 'Australia' },
    { name: 'USA', value: 'America' },
    { name: 'RUS', value: 'Rusia' },
    { name: 'Eng', value: 'England' },
  ];

  jsonData = {};
  successData = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    public dialogRef: MatDialogRef<SubscribtionComponent>,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private fb: UntypedFormBuilder
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');

    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void { }

  onCheckboxChange(e) {
    const checkArray: UntypedFormArray = this.form.get('checkArray') as UntypedFormArray;

    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: UntypedFormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    if (this.data['subscriptionFrom'] === 'home-screen-alert') {
      this.jsonData = {
        path: 'add-user-subscription',
        email: this.data['email'].email,
        subscriptions: [this.data['data'][3].id],
      };
      this.successData = 'daily job alert';
    } else {
      this.jsonData = {
        path: 'add-user-subscription',
        email: this.data['email'].email,
        subscriptions: [this.data['data'][0].id, this.data['data'][1].id],
      };
      this.successData = 'news blogs'
    }
    this.io._updateUserSubscription(this.jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.dialogRef.close('save');
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: this.successData,
          },
        });
        dialogRef.afterClosed().subscribe();
      } else if (res.serverResponse.code == 400) {
        this.dialogRef.close('save');
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }
  onNoClick() {
    this.dialogRef.close();
  }
}