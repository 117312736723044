import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/gaurd/auth.guard';
import { AuthEmployerGuard } from 'src/app/gaurd/auth-employer.guard';
import { CvReviewLandingComponent } from './cv-review-landing/cv-review-landing.component';

const routes: Routes = [
  {
    path: '',
    component: CvReviewLandingComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CvReviewRoutingModule {}
