import { Inject, Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DynamicSnacbarErrorComponent } from '../container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { Helper } from '../helper/defult';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthJobseekerGuard  {
  userDeatils: any;
  companyData: any;
  constructor(
    private _authService: AuthService,
    private helper: Helper,
    @Inject(Router) private _router: Router,
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this._authService.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this._authService.getCurrentCompanyDataInSessoin();
    }
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // let userLocal;
    // let userSeeson;
    // let seeson = sessionStorage.getItem('workNigeriaUser');
    // let regxValue = new RegExp("^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$")
    // if (seeson) {
    //   if (seeson.match(regxValue) != null  ) {
    //     userSeeson = JSON.parse(this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser'))))
    //     if(userSeeson.userType == 'employer'){
    //       return true;
    //     }
    //   }
    //   else {
    //     this._router.navigate(['/home']);
    //     sessionStorage.clear();
    //     // this.snackBar.open("User Details Not Found,Please Login Again", "OK", {})
    //     this.snackBar.openFromComponent(DynamicSnacbarErrorComponent, {
    //       data: "User Details Not Found,Please Login Again",

    //       verticalPosition: 'top', // 'top' | 'bottom'
    //       horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'

    //     }).afterOpened().subscribe(this.helper.setOnTop);

    //     // window.location.reload();
    //     return false;
    //   }
    // }

    // let loocal = localStorage.getItem('workNigeriaUser');
    // if (loocal) {
    //   if (loocal.match(regxValue) != null) {
    //     userLocal = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))))
    //     if(userLocal.userType == 'employer'){
    //       return true;
    //     }
    //   }
    //   else {
    //     this._router.navigate(['/home']);
    //     localStorage.clear();
    //     // this.snackBar.open("User Details Not Found,Please Login Again", "OK", {})
    //     this.snackBar.openFromComponent(DynamicSnacbarErrorComponent, {
    //       data: "User Details Not Found,Please Login Again",
    //       verticalPosition: 'top', // 'top' | 'bottom'
    //       horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
    //     }).afterOpened().subscribe(this.helper.setOnTop)

    //     // window.location.reload();
    //     return false;
    //   }
    // }

    // if((localStorage.getItem('workNigeriaUser'))) {
    //   userLocal = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))))
    // }
    // if((sessionStorage.getItem('workNigeriaUser'))) {
    //   userSeeson = JSON.parse(this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser'))))
    // }

    // if (userLocal || userSeeson) {
    //   return true;
    // }
    // else {
    //   this._router.navigate(['/home']);
    //   return false;
    // }

    // if (!this.userDeatils) {
    //   return true;
    // }
    if (this.userDeatils) {
      if (this.userDeatils.userType == 'jobseeker') {
        return true;
      } else {
        this._router.navigate(['/home']);
      }
    }
    this._router.navigate(['/home']);
    return false;
  }
}
