import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-without-login',
  templateUrl: './save-without-login.component.html',
  styleUrls: ['./save-without-login.component.scss'],
})
export class SaveWithoutLoginComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<SaveWithoutLoginComponent>
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  gotoLogin(): void {
    this.dialogRef.close();
    this.router.navigate(['/login']);
    if (this.data == 'alertPage') {
      sessionStorage.setItem(
        'guestUserSelectedJob',
        btoa(JSON.stringify('job-alert'))
      );
    } else {
      sessionStorage.setItem(
        'guestUserSelectedJob',
        btoa(JSON.stringify(this.data['Perticularjob'].id))
      );
    }
  }
  gotoSingUp(): void {
    this.dialogRef.close();
    this.router.navigate(['/signup']);
    if (this.data == 'alertPage') {
      sessionStorage.setItem(
        'guestUserSelectedJob',
        btoa(JSON.stringify('job-alert'))
      );
    } else {
      sessionStorage.setItem(
        'guestUserSelectedJob',
        btoa(JSON.stringify(this.data['Perticularjob'].id))
      );
    }
  }
}
