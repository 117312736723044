import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { JobSearchRoutingModule } from './job-search-routing.module';
import { JobSearchComponent } from './job-search.component';
import { MaterialModule } from '../../modules/material/material.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { JobFilterComponent } from './job-filter/job-filter.component';
import { JobFilterListComponent } from './job-filter-list/job-filter-list.component';
import { JobFilterDescriptionComponent } from './job-filter-description/job-filter-description.component';
import { SavedjobComponent } from './savedjob/savedjob.component';
import { SharejobComponent } from './sharejob/sharejob.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgTinyUrlModule } from 'ng-tiny-url';
import { SaveWithoutLoginComponent } from './save-without-login/save-without-login.component';
import { ViaExternalUrlComponent } from './via-external-url/via-external-url.component';
import { ViaEmailComponent } from './via-email/via-email.component';
import { ViaWorkNigeriaComponent } from './via-work-nigeria/via-work-nigeria.component';
import { AnonymousUserComponent } from './anonymous-user/anonymous-user.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { ReportJobComponent } from './report-job/report-job.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollspyDirective } from './scrollspy.directive';
import { NgxSpinnerModule } from 'ngx-spinner';
// import {NgxPaginationModule} from 'ngx-pagination';
export const options: Partial<IConfig> = {
  thousandSeparator: "'",
};
@NgModule({
  declarations: [
    JobSearchComponent,
    JobFilterComponent,
    JobFilterListComponent,
    JobFilterDescriptionComponent,
    SavedjobComponent,
    SharejobComponent,
    SaveWithoutLoginComponent,
    ViaExternalUrlComponent,
    ViaEmailComponent,
    ViaWorkNigeriaComponent,
    AnonymousUserComponent,
    ReportJobComponent,
    ScrollspyDirective,
  ],
  imports: [
    InfiniteScrollModule,
    CommonModule,
    NgbTooltipModule,
    NgxMaskModule.forRoot(options),
    JobSearchRoutingModule,
    MaterialModule,
    SharedModule,
    MatButtonModule,
    MatCheckboxModule,
    NgxSpinnerModule,
    // ShareButtonsModule
    NgbPaginationModule,
    ShareButtonsModule,
  ],
  providers: [DatePipe],
})
export class JobSearchModule {}
