<section class="common-padd-lt-rt profile-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <!--

                <div class="profile-big-ttl"
                    style="background-image: url(../assets/images/star-bg.png); background-repeat: no-repeat; background-position: right center;  background-size: cover;">
                    <h1>User Profile</h1>
                </div>-->
        <div class="small-banner">
          <div class="img">
            <img src="../../../assets/images/User-profile.jpg" />
          </div>
        </div>

        <div class="import-cv-top" *ngIf="!this.profileRecords.isResumeParsed">
          <div class="row align-items-center">
            <div class="col-lg-7">
              <div class="import-cv-top-desc">
                <h2>Import your CV</h2>
                <p>
                  Already have a CV? Upload the file (doc, docx, or pdf) and we
                  will automatically convert it to an online profile that you
                  can edit, share as a link, and use to apply for positions.
                  Recruiters can also search for you based on your profile to
                  see if you are a match for open jobs.
                </p>
                <div class="upload-cv-btn-wrap">
                  <a
                    _ngcontent-cwt-c376=""
                    href="javascript:void(0);"
                    class="btn-green"
                  >
                    Upload CV</a
                  >
                  <input
                    _ngcontent-cwt-c376=""
                    type="file"
                    (change)="onSelectFile($event)"
                    accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <figure>
                <div class="img">
                  <img src="../assets/images/image75.png" />
                </div>
              </figure>
            </div>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Profile</h4>
            <a href="javascript:void(0);" (click)="profile()" class="edit-link"
              ><i class="fas fa-pen"></i> EDIT PROFILE</a
            >
          </div>
          <div class="profile-user-topinfo">
            <div class="user-img">
              <img
                src="{{
                  profileRecords?.profileImage
                    ? profileRecords?.profileImage
                    : '../assets/images/defaultuser.png'
                }}"
                alt=""
              />
            </div>
            <div class="pro-top-hd sm-padd-btm">
              <a
                href="javascript:void(0);"
                class="btn-green"
                (click)="exportAsPDF()"
                ><i class="fas fa-download"></i> Download as CV</a
              >
              &nbsp;
              <a
                href="javascript:void(0);"
                class="btn-green wh-bg"
                (click)="shareJob()"
                ><i class="fas fa-share-alt"></i> Share profile link</a
              >
            </div>
            <h5>
              {{ profileRecords?.firstName }} {{ profileRecords?.middleName }}  {{ profileRecords?.lastName }}
            </h5>
            <h5>{{ profileRecords?.contactNumber }}</h5>
            <p>About</p>
            <div class="info-list">
              <ul>
                <li>
                  <div [innerHTML]="profileRecords.about"></div>
                  <!-- <p [innerHTML]="profileRecords.about"></p> -->
                </li>
                <li *ngIf="profileRecords?.title != ''">
                  Title - {{ profileRecords?.title }}
                </li>
                <li *ngIf="profileRecords?.website != ''">
                  Website - {{ profileRecords?.website }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Education</h4>
            <a
              href="javascript:void(0);"
              (click)="education('NEW')"
              class="btn btn-green"
              >Add Education</a
            >
          </div>
          <div class="profile-info-list">
            <ul *ngFor="let row of educationAllRecords; let i = index">
              <li>
                <div class="lt-info">
                  <p>{{ row.universityName }}</p>
                  <p>
                    <small>{{ row.courseName }}</small>
                  </p>
                </div>
                <a
                  href="javascript:void(0);"
                  (click)="education(row)"
                  class="edit-link"
                  ><i class="fas fa-pen"></i> EDIT</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Experience</h4>
            <a
              href="javascript:void(0);"
              (click)="experiance('NEW')"
              class="btn btn-green"
              >Add Experience</a
            >
          </div>
          <div class="profile-info-list">
            <ul *ngFor="let row of experianceAllRecords; let i = index">
              <li>
                <div class="lt-info">
                  <p>{{ row.company }}</p>
                  <p>
                    <small
                      >{{ row.title }}<br />{{ row.startDate | date }} –
                      {{
                        row.endDate == null ? "Present " : (row.endDate | date)
                      }}
                      • {{calculateDuration(row.startDate, row.endDate)}}
                    </small>
                  </p>
                </div>
                <a
                  href="javascript:void(0);"
                  (click)="experiance(row)"
                  class="edit-link"
                  ><i class="fas fa-pen"></i> EDIT</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Licenses & certifications</h4>
            <a
              href="javascript:void(0);"
              (click)="certifiate('NEW')"
              class="btn btn-green"
              >Add Licenses & Certifications</a
            >
          </div>
          <div class="profile-info-list">
            <ul *ngFor="let row of licenseAllRecords; let i = index">
              <li>
                <div class="lt-info">
                  <p>{{ row.name }}</p>
                  <p>
                    <small
                      >{{ row.issuingOrganization }}<br />Issued -
                      {{ row.issueDate | date }} •
                      {{
                        row.endDate == null ? "Present" : (row.endDate | date)
                      }}
                      <br />Credential ID {{ row.credentialId }}</small
                    >
                  </p>
                </div>
                <a
                  href="javascript:void(0);"
                  (click)="certifiate(row)"
                  class="edit-link"
                  ><i class="fas fa-pen"></i> EDIT</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="prfl-userbox">
          <div class="edt-hdng">
            <h4>Skills</h4>
            <a
              href="javascript:void(0);"
              (click)="skill()"
              class="btn btn-green"
              >Add Skills</a
            >
          </div>
          <div class="profile-skill-list">
            <figcaption>
              <mat-form-field
                class="example-chip-list custom-chip-set"
                [ngClass]="
                  this.skillAllRecords.length > 5
                    ? 'mobile-view-skill-section'
                    : ''
                "
                style="border: none"
              >
                <mat-chip-list
                  #chipList
                  aria-label="Fruit selection"
                  style="border: none"
                >
                  <mat-chip
                    style="border: none"
                    *ngFor="let fruit of this.skillAllRecords"
                  >
                    {{ fruit }}
                  </mat-chip>
                </mat-chip-list>
              </mat-form-field>
            </figcaption>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <div class="custom-page-height">



                <div class="card">
                    <h2>
                        Skills <span><a href="javascript:void(0);" class="btn-green editicon" (click)="skill()"><i
                            class="fas fa-pen"></i> EDIT
                                SKILLS</a></span>
                    </h2>
                    <div class="card-mid-description">
                        <div class="row">
                            <div class="col-lg-12">

                                <figcaption>
                                    <mat-form-field class="example-chip-list custom-chip-set" [ngClass]="this.skillAllRecords.length > 5 ? 'mobile-view-skill-section' : ''"style="border: none;">

                                        <mat-chip-list #chipList aria-label="Fruit selection" style="border: none;">
                                            <mat-chip style="border: none;" *ngFor="let fruit of this.skillAllRecords">
                                                {{fruit}}

                                            </mat-chip>

                                        </mat-chip-list>

                                    </mat-form-field>
                                </figcaption>
                            </div>
                        </div>
                    </div>

                   
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- [ngClass]="{'subhojitDisplay' : isDisplay == true }" -->
<!-- <a [href]="profileRecords?.profileImage" class="btn cancel-button" target="_self" download>Download</a> -->

<div *ngIf="isDisplay" id="htmlData">
  <body
    marginwidth="0"
    marginheight="0"
    style="
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    "
    offset="0"
    topmargin="0"
    leftmargin="0"
  >
    <table
      mc:hideable=""
      class="bodyBgColor"
      width="100%"
      border="0"
      align="center"
      cellpadding="0"
      cellspacing="0"
      bgcolor="#ffffff"
      style="width: 100%; max-width: 100%"
    >
      <tbody>
        <tr>
          <td align="center" valign="top">
            <table
              class="row"
              width="600"
              border="0"
              align="center"
              cellpadding="0"
              cellspacing="0"
              bgcolor="#f5f5f5"
              style="width: 600px; max-width: 600px"
            >
              <tbody>
                <tr>
                  <td class="whiteBgcolor" valign="top" bgcolor="#fff">
                    <table
                      class="row"
                      width="600"
                      border="0"
                      align="center"
                      cellpadding="0"
                      cellspacing="0"
                      style="width: 600px; max-width: 600px"
                    >
                      <tbody style="padding: 0">
                        <tr>
                          <td
                            align="center"
                            valign="top"
                            bgcolor="#3B423E"
                            style="width: 30%; padding: 5px"
                          >
                            <table>
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style="padding-bottom: 16px"
                                  >
                                    <!-- <img  width="132" height="132" alt=""  style="display:block;border:0;width:132px; height: 132px;"
                                                                            src={{imageForPdf}}/> -->
                                    <!-- <img
                                      alt=""
                                      style="
                                        display: block;
                                        border: 0;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                      "
                                      src="{{ imageForPdf }}"
                                    /> -->
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="center"
                                    style="
                                      color: #fcfcfc;
                                      font-weight: 700;
                                      font-size: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    {{ profileRecords?.firstName }}
                                    {{ profileRecords?.lastName }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>

                          <td valign="top" bgcolor="#E8FFF3">
                            <table style="width: 100%">
                              <tbody>
                                <tr bgcolor="#ffffff">
                                  <td align="right" style="padding: 10px">
                                    <img
                                      src="../assets/images/logo-work-nigeria.png"
                                      width="112"
                                      height="20"
                                      alt=""
                                      style="
                                        display: block;
                                        border: 0;
                                        width: 112px;
                                        height: 20px;
                                      "
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td style="padding: 20px">
                                    <table>
                                      <tbody>
                                        <tr style="display: flex">
                                          <td
                                            style="
                                              font-size: 20px;
                                              line-height: 20px;
                                              color: #444242;
                                              font-weight: 700;
                                              margin-right: 8px;
                                            "
                                          >
                                            About
                                          </td>
                                          <td
                                            width="4"
                                            height="4"
                                            bgcolor="#3EAB70"
                                            style="margin-top: 12px"
                                          ></td>
                                        </tr>
                                        <tr
                                          style="
                                            margin-bottom: 13px;
                                            display: block;
                                            margin-top: 10px;
                                          "
                                        >
                                          <td
                                            style="
                                              color: #44424299;
                                              font-size: 12;
                                              font-weight: 400;
                                              margin-top: 10px;
                                            "
                                          >
                                            {{ profileRecords?.about }}
                                          </td>
                                        </tr>
                                        <tr
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          <td style="margin-right: 6px">
                                            <img
                                              src="../assets/images/location.png"
                                              width="19"
                                              height="20"
                                              alt=""
                                              style="
                                                display: block;
                                                border: 0;
                                                width: 19px;
                                                height: 19px;
                                              "
                                            />
                                          </td>
                                          <td
                                            style="
                                              font-size: 9px;
                                              font-weight: 400;
                                              color: #444444;
                                            "
                                          >
                                            {{ profileRecords?.userLocation }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td align="center" valign="top">
            <table
              class="row"
              width="600"
              border="0"
              align="center"
              cellpadding="0"
              cellspacing="0"
              bgcolor="#ffffff"
              style="
                width: 600px;
                max-width: 600px;
                padding-top: 27px;
                padding-bottom: 72px;
                padding-right: 24px;
                padding-left: 24px;
              "
            >
              <tbody>
                <tr
                  style="display: block; margin-bottom: 22px"
                  *ngIf="educationAllRecords?.length > '0'"
                >
                  <td>
                    <table width="100%">
                      <p class="tittle1">Education</p>
                      <tbody *ngFor="let row of educationAllRecords">
                        <tr>
                          <td class="tittle2">{{ row.universityName }}</td>
                        </tr>
                        <tr>
                          <td class="info2">{{ row.courseName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr
                  style="display: block; margin-bottom: 29px"
                  *ngIf="experianceAllRecords?.length > '0'"
                >
                  <td>
                    <table width="100%">
                      <p class="tittle1">Experience</p>
                      <tbody *ngFor="let row of experianceAllRecords">
                        <tr>
                          <td class="tittle2">{{ row.company }}</td>
                        </tr>
                        <tr>
                          <td class="info1">{{ row.title }}</td>
                        </tr>
                        <tr>
                          <td class="info2">
                            {{ row.startDate | date }} –
                            {{
                              row.endDate == null
                                ? "Present "
                                : (row.endDate | date)
                            }}
                            • years
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr
                  style="display: block; margin-bottom: 35px"
                  *ngIf="licenseAllRecords?.length > '0'"
                >
                  <td>
                    <table width="100%">
                      <p class="tittle1">Licenses & certifications</p>
                      <tbody
                        *ngFor="let row of licenseAllRecords; let i = index"
                      >
                        <tr>
                          <td class="tittle2">{{ row.name }}</td>
                        </tr>
                        <tr>
                          <td class="info1">{{ row.issuingOrganization }}</td>
                        </tr>
                        <tr>
                          <td class="info1">
                            Issued - {{ row.issueDate | date }} •
                            {{
                              row.endDate == null
                                ? "Present"
                                : (row.endDate | date)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="info2">
                            Credential ID : {{ row.credentialId }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr
                  style="display: block; margin-bottom: 22px"
                  *ngIf="skillAllRecords?.length > '0'"
                >
                  <td>
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td class="tittle1">Skills</td>
                        </tr>
                        <tr style="display: flex; flex-wrap: wrap">
                          <td
                            class="skill"
                            *ngFor="let fruit of this.skillAllRecords"
                          >
                            {{ fruit }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</div>
