import { Component, OnInit } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AllRecruitersService } from 'src/app/services/all-recruiters.service';
import { Helper } from 'src/app/helper/defult';
@Component({
  selector: 'app-companies-for-super-admin',
  templateUrl: './companies-for-super-admin.component.html',
  styleUrls: ['./companies-for-super-admin.component.scss'],
})
export class CompaniesForSuperAdminComponent implements OnInit {
  CompaniesResult: any = [];
  filteredOptions: Observable<any[]>;
  companiesTab: UntypedFormGroup;
  companies: any = '';
  CompaniesResultFroDropDown: any = [];
  searchCompany = '';
  constructor(
    private employerService: EmployerService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private helper: Helper,
    private AllRecruiters: AllRecruitersService
  ) { }

  ngOnInit(): void {
    this.validationForm();
    this.getAllCompaniesList();
    this.filteredOptions =
      this.companiesTab.controls.companies.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.CompaniesResultFroDropDown.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }
  // Insilize Form //
  validationForm() {
    this.companiesTab = this.fb.group({
      companies: [''],
    });
  }

  async getAllCompaniesList() {
    let jsonData = {
      path: 'get-company-list',
    };
    await this.employerService
      ._getAllCompaniesList(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res['result']);
          this.CompaniesResult = res['result'].companyList;
          this.CompaniesResultFroDropDown = res['result'].companyList;
        }
      });
  }

  getSearchedCompany(searchVal) {
    let jsonData = {
      path: 'get-company-list',
      search: searchVal,
    }
    this.employerService._getAllCompaniesList(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code === 200) {
        this.CompaniesResult = res['result'].companyList;
        this.CompaniesResultFroDropDown = res['result'].companyList;
      }
    })
  }

  async changeCompany(event) {
    console.log(event);
    this.CompaniesResult = [];
    for (let i = 0; i < this.CompaniesResultFroDropDown.length; i++) {
      if (event == this.CompaniesResultFroDropDown[i].companyName) {
        this.CompaniesResult.push(this.CompaniesResultFroDropDown[i]);
      }
    }
    console.log(this.CompaniesResult);
  }

  companyVarify(data, statusCode) {
    let jsonData = {
      path: 'change-company-status',
      company_id: data.id,
      status: statusCode,
    };
    this.employerService
      ._changeCompanyStatus(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.getAllCompaniesList();
        }
      });
  }

  resetFrom() {
    this.companiesTab.reset({ companies: '' });
    this.getAllCompaniesList();
  }

  exportexcel() {
    this.AllRecruiters.genExcel(this.CompaniesResult);
  }
}
