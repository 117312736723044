<section class="common-padd common-padd-lt-rt job-aleart-wrap">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="smallbannerAlerts">
          <div class="img">
            <img src="../../../../assets/images/Set-Job-Alerts.png" />
          </div>
          <h3>Set Job Alerts</h3>
        </div>
        <div class="alrt-ttl">
          <h3>Alerts</h3>
          <button mat-raised-button color="primary" (click)="createAlert()">
            Add New Alert
          </button>
        </div>

        <form class="example-form" [formGroup]="anonymousForm">
          <div class="table-responsive">
            <table class="table job-aleart-table">
              <thead>
                <tr>
                  <th>Email Frequency</th>
                  <th>Alert Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngFor="let row of alertRecord; let i = index">
                <tr>
                  <td>
                    <div class="d-flex" style="align-items: center">
                      <div class="custom-form-wrap">
                        <div class="form-group">
                          <span>{{ row.emailFrequency }}</span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    <span>{{ row.alertName }}</span>
                  </td>

                  <td class="dell-edit" style="text-align: right">
                    <!-- <button mat-flat-button style="color: #000000; min-width: auto; " 
                                        (click)="edit(row)"><i class="fas fa-edit"></i></button>&nbsp;
                                        <button mat-flat-button style="color: #000000; min-width: auto;" 
                                        (click)="removeAlert(row)"><i class="far fa-trash-alt"></i></button> -->
                    <button
                      mat-flat-button
                      style="color: #3eab70; border: 1px solid #3eab70"
                      (click)="removeAlert(row)"
                    >
                      <i class="far fa-trash-alt"></i> Delete</button
                    >&nbsp;
                    <button
                      mat-raised-button
                      color="primary"
                      style="min-width: auto"
                      (click)="edit(row)"
                    >
                      <i class="fas fa-edit"></i> Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
        <div *ngIf="alertRecord?.length == '0'" class="min-height">
          <section style="flex: center" class="nosavedjob-found new-screen">
            <div style="text-align: center" class="no-job-posted-yet">
              <h4>No Alert found</h4>
              <!-- <button class="green-btn" >Add your first job</button> -->
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
