<section class="message-page-area new-screen">
  <div class="container-fluid">
    <div class="inbox_msg row">
      <div class="inbox_people" [ngClass]="chatOnMobile ? 'd-none' : '' ">
        <div class="return-text" (click)="goToCV()">
          <img src="../../../assets/images/left-arrow.png"/>
          <p>Return To CV</p>
        </div>
        <div class="search-container">
          <button type="button" class="search-icon">
            <img src="../../../../assets/images/search-black2.png" alt="Search Icon" />
          </button>
          <input type="text" class="search-bar" placeholder="Search" />
        </div>
        <div class="inbox_chat">
          <div [ngClass]="
                status == row.user_id_belongs
                  ? 'chat_list active_chat'
                  : 'chat_list'
              " *ngFor="let row of chatRoomsList; let i = index" (click)="selectChatRoom(row, i)">
            <div class="chat_people">
              <div class="chat_img">
                <p class="chat_img" [style.background]="randomColor(row)">
                  {{ row?.room_name?.[0].toUpperCase()}}{{getFirstLetterAfterSpace(row?.room_name).toUpperCase()}}
                </p>
              </div>
              <div class="chat_ib">
                <h5>
                  {{ row.room_name }}
                  <span class="chat_online_markAsImportant">
                    <span style="display: none" *ngIf="row.is_online" class="online"></span>
                    <span style="display: none" class="markAsImportant"><i class="fas fa-star"></i></span>
                  </span>
                  <span class="chat-date">{{
                    transformTreeTime(row.updated_at)
                    }}</span>
                </h5>
                <p>
                  {{ row?.message?.attachments?.length ? row?.message?.attachments[0]?.name : row?.message?.message }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="no-deta-here" style="display: none">
          <div class="no-deta-here-info">
            <p>no chat list here</p>
          </div>
        </div>
      </div>
      <div class="mesgs" [ngClass] = "chatOnMobile ? suggestedMessagesOpened ? 'd-none' : 'mobile-view-chat' : suggestedMessagesOpened ? 'mesgs-sm' : 'mesgs'">
        <div class="message-hdr-top" *ngIf="rightSideView === true">
          <div class="return-to-chat" (click)="goToChat()" *ngIf="chatOnMobile === true">
            <img src="../../../assets/images/left-arrow.png"/>
            <p>Chat List</p>
          </div>
          <div class="nameofhead" style="cursor: pointer" (click)="wnProfileOpen()">
            <span>
              <p class="chat_img" [style.background]="randomColor(selectedRoom)">
                {{
                selectedRoom?.room_name?.[0].toUpperCase()}}{{getFirstLetterAfterSpace(selectedRoom?.room_name).toUpperCase()}}
              </p>
            </span>
            <h6 style="margin: 0;">
              {{ selectedRoom.room_name }}
              <span *ngIf="this.userDeatils.userType == 'jobseeker'">{{
                this.companyName
                }}</span>
            </h6>
          </div>
          <div class="nameofheadright">
          </div>
        </div>
        <div *ngIf="rightSideView" class="mesgs-wrap">
          <div class="msg_history" #scrollMe>
            <span *ngFor="let row of chatHistory">
              <div class="incoming_msg" *ngIf="this.userDeatils.id != row.sender_id && row.message">
                <div class="received_msg">
                  <div class="received_withd_msg">
                    <p>{{ row.message }}<br>
                      <span class="time_date">{{
                        transformTreeDateTime(row.create_at)
                        }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="incoming_msg_attachment"
                *ngIf="this.userDeatils.id != row.sender_id && row.attachments?.length">
                <div class="received_msg_attachment" *ngFor="let attachment of row.attachments; let i = index">
                  <div class="received_withd_msg_attachment">
                    <div>
                      <img [src]="getIconSrc(attachment.name)" />
                    </div>
                    <div style="width: 100%;">
                      <p class="attachment-name">{{ truncateLongFileName(attachment.name) }}<br></p>
                      <p class="doc-size">{{ convertBytes(attachment.size) }}</p>
                      <span class="time_date">{{
                        transformTreeDateTime(row.create_at)
                        }}</span>
                    </div>
                  </div>
                  <p class="download-btn" (click)="downloadFile(attachment.url)">Download</p>
                </div>
              </div>
              <div class="outgoing_msg" *ngIf="this.userDeatils.id == row.sender_id && row.message">
                <div class="sent_msg">
                  <p>{{ row.message }}<br>
                    <span class="time_date">{{
                      transformTreeDateTime(row.create_at)
                      }}</span>
                  </p>
                </div>
              </div>
              <div class="outgoing_msg_attachment"
                *ngIf="this.userDeatils.id == row.sender_id && row.attachments?.length">
                <div class="sent_msg_attachment" *ngFor="let attachment of row.attachments; let i = index">
                  <div class="sent_attachment">
                    <div>
                      <img [src]="getIconSrc(attachment.name)" />
                    </div>
                    <div style="width: 100%;">
                      <p class="attachment-name">{{ truncateLongFileName(attachment.name) }}<br></p>
                      <p class="doc-size">{{ convertBytes(attachment.size) }}</p>
                      <span class="time_date">{{
                        transformTreeDateTime(row.create_at)
                        }}</span>
                    </div>
                  </div>
                  <p class="download-btn-receiver" (click)="downloadFile(attachment.url)">Download</p>
                </div>
              </div>
            </span>
            <div class="incoming_msg_attachment" *ngIf="this.attachments.length"
              style="width: 100%; gap: 10px; margin-top: auto;">
              <div class="received_msg_attachment" *ngFor="let attachment of attachments; let i = index"
                style="margin-left: 0; width: 35%;">
                <div class="received_withd_msg_attachment">
                  <div>
                    <img [src]="getIconSrc(attachment.name)" />
                  </div>
                  <div style="width: 100%;">
                    <p class="attachment-name">{{ truncateLongFileName(attachment.name) }}<br></p>
                    <p class="doc-size">{{ convertBytes(attachment.size) }}</p>
                  </div>
                </div>
                <p class="download-btn" (click)="cancelAttachment(i)">Cancel</p>
              </div>
            </div>
          </div>

          <div class="type_msg">
            <div class="over-fild">
              <div class="emoji">
                <a href="javascript:void(0);"><img src="../../../../assets/images/emoji.png" /></a>
              </div>
            </div>
            <div class="input_msg_write">
              <div class="input-container">
                <input type="text" [disabled]="this.attachments?.length" class="write_msg" placeholder="Message"
                  [(ngModel)]="writeText" (keyup.enter)="sendMessage()" />
                <button class="send-btns">
                  <img src="../../../assets/images/suggested-msg-icon.png" class="suggested-msg-icon" (click)="toggleSuggestedMsgWindow()" />
                </button>
                <button class="send-btns">
                  <img src="../../../assets/images/attachment-icon.png" class="attachment-icon"
                    (click)="fileInput.click()" />
                  <input #fileInput type="file" (change)="onSelectFile($event)"
                    accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    style="display: none" multiple="false" />
                </button>
                <button class="send-btns" (click)="sendMessage()">
                  <img src="../../../assets/images/send-msg-icon.png" class="send-msg-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="no-deta-here" *ngIf="!this.rightSideView">
          <div class="no-deta-here-info">
            <img src="../../../../assets/images/emty-page.png" />
            <p>Select A Chat To View Conversation</p>
          </div>
        </div>
      </div>
      <div [ngClass] = "chatOnMobile ? 'suggest-msg-fs' : 'suggest-msg' " *ngIf="suggestedMessagesOpened">
        <div class="suggest-msg-text">
          <p>Suggest Message</p>
        </div>
        <div class="suggested-msg-box">
          <div *ngFor="let row of suggestedMessagesEmployer;" (click)="setMessage(row)">
            <p class="suggested-msg-row">
              {{ row }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>