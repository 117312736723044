<div class="sign-lt">
  <p class="sub-heading">
    Tell us about yourself so we can connect you with recruiters and hiring
    managers.
  </p>
  <div class="form-wrap">
    <form [formGroup]="completeRegiSeeker" autocomplete="off">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>First Name</mat-label>
          <input type="text" matInput placeholder="First Name" required="required" formControlName="firstName" />
        </mat-form-field>
        <div class="error-msg" *ngIf="f.firstName.touched && f.firstName.invalid">
          <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors.required">First Name is required
          </span>
          <span class="text-left text-danger pb-1" *ngIf="f.firstName.errors?.pattern">
            Please enter a valid name.
          </span>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Last Name</mat-label>
          <input type="text" matInput placeholder="Last Name" required="required" formControlName="lastName" />
        </mat-form-field>
        <div class="error-msg" *ngIf="f.lastName.touched && f.lastName.invalid">
          <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors.required">Last Name is required
          </span>
          <span class="text-left text-danger pb-1" *ngIf="f.lastName.errors?.pattern">
            Please enter a valid name.
          </span>
        </div>
      </div>

      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Password</mat-label>
          <input type="password" matInput placeholder="Password" required="required" formControlName="password"
            [pattern]="passwordPattern" [type]="showPassword ? 'text' : 'password'" />
          <mat-icon matSuffix (click)="togglePasswordVisibility()" style="cursor: pointer">{{ showPassword ?
            "visibility_off" : "visibility" }}</mat-icon>
        </mat-form-field>
        <div class="error-msg" *ngIf="f.password.touched && f.password.invalid">
          <span class="text-left text-danger pb-1" *ngIf="f.password.errors.required">Password is required
          </span>
          <span class="text-left text-danger pb-1" *ngIf="f.password.errors?.pattern">You must use at least one upper
            case letter, one lower case letter, one number, and one special character. The password should be at least 8
            characters long
          </span>
        </div>
      </div>

      <div class="form-group">
        <input type="submit" value="Sign up" class="btn btn-green w-100" (click)="completeRegistrasion()" />
      </div>
      <div class="form-group text-center">
        <button class="btn-back" (click)="signUP()">Back</button>
      </div>
    </form>
  </div>
</div>