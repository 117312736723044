<section class="job-provider-profile-area common-padd-lt-rt common-padd">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="img smbanner">
          <img src="../../../../assets/images/company's-profile.jpg" />
        </div>
      </div>
      <div class="col-lg-12">
        <h4>job-provider-profile</h4>
        <div>
          <div>
            <div>
              <div class="card-mid pro-img nxmarge">
                <div class="profile-withPic">
                  <figure>
                    <img src="{{ imgURL == '' ? profileImage : imgURL }}" />
                  </figure>
                  <div class="upload-cv-btn-wrap">
                    <a href="javascript:void(0);" class="btn-green">
                      Upload Picture</a
                    >
                    <input
                      type="file"
                      accept="image/*"
                      (change)="onSelectFile($event)"
                    />
                    <!-- <button style="float: right;" mat-raised-button color="primary" (click)="updateProfile()">UPDATE</button> -->
                  </div>
                </div>
                <!-- <figcaption class="mb-3">
                                    <strong class="d-block">{{Name}}</strong>
                                </figcaption> -->
              </div>
            </div>
          </div>
        </div>

        <form class="example-form modal-dropdown" [formGroup]="profileForm">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>First Name</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="First Name"
                    required="required"
                    formControlName="firstName"
                  />
                </mat-form-field>
              </div>
              <div *ngIf="f.firstName.touched && f.firstName.invalid">
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="f.firstName.errors.required"
                  >First Name is required
                </span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Last Name</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="Last Name"
                    required="required"
                    formControlName="lastName"
                  />
                </mat-form-field>
              </div>
              <div *ngIf="f.lastName.touched && f.lastName.invalid">
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="f.lastName.errors.required"
                  >Last Name is required
                </span>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Email</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
        <p>
          <button
            style="float: right"
            mat-raised-button
            color="primary"
            (click)="updateProfile()"
          >
            UPDATE
          </button>
        </p>
      </div>
    </div>
  </div>
</section>
