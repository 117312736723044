import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { CompanyVerificationComponent } from '../company-verification/company-verification.component';
import { DataService } from 'src/app/services/data.service';
import { IntroJSService } from 'src/app/services/introjs.service';

@Component({
  selector: 'app-emp-dashboard',
  templateUrl: './emp-dashboard.component.html',
  styleUrls: ['./emp-dashboard.component.scss'],
})
export class EmpDashboardComponent implements OnInit, AfterViewInit {
  selectedTabIndex: number = 0;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  userDetails: any;
  companyData: any;
  currentBalance: any;
  cvBalance: number = 0;
  campaignBalance: number = 0;
  userAssets: any;
  currentPath: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public auth: AuthService,
    private io: HttpIOService,
    private helper: Helper,
    private dataService: DataService,
    private introService: IntroJSService
  ) {
  }
  
  ngOnInit(): void {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDetails = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    this.loadUpdatedAssets()
    this.dataService.userAssets.subscribe(assets => {
      this.userAssets = assets;
      this.cvBalance = assets?.totalCvCredits - assets?.usedCvCredits || 0
      this.campaignBalance = assets?.totalCampaignCredits - assets?.usedCampaignCredits || 0
      this.currentBalance = assets?.balance || 0
    });
    this.currentPath = window.location.pathname;
    if(this.companyData.cacVerified) localStorage.setItem('companyVerificationTour', '1')
  }
  ngAfterViewInit(): void {

    if(!this.companyData.cacVerified && (localStorage.getItem('companyVerificationTour') ?? '0') === '0'){
      setTimeout(() => {
        this.introService.companyVerification()
      }, 1000)
    }
  }
  verification() {
    this.router.navigate(['verification']);
  }

  getAllDetails() {
    if (this.userDetails) {
      let jsonData = {
        id: this.userDetails.id,
        path: 'get-user',
      };
      this.io._JSallDetails(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {

          if (localStorage.getItem('workNigeriaUser') === null) {
            this.auth.setCurrentUserDetailsInSessoin(res['result'].userData);
          } else {
            this.auth.setCurrentUserDetails(res['result'].userData);
          }
        }
      });
    }
  }
  navigateToFundWallet(){
    this.router.navigate(['fund-wallet'])
  }
  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets()
    .subscribe(() => {
      this.dataService.getUserData().subscribe((data: any) => {});
    });
  }
}