<div class="tab-content py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active" id="nav-profile">
    <form class="fillupjob">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div class="img-and-profile">
              <div class="row">
                <div class="col-8">
                  <div class="form-group form-floating">
                    <input
                      type="text"
                      class="form-control"
                      name="companyName"
                      [(ngModel)]="companyDetails.companyName"
                      value="{{ companyDetails.companyName }}"
                      placeholder="Company name (required)"
                      [disabled]="viewOnly"
                    />
                    <label for="Company">Company name (required)</label>
                  </div>
                </div>
                <div class="col-4">
                  <div class="profilelogo">
                    <label for="image_uploads" class="btn btn-green"
                      >Upload logo</label
                    >
                    <input
                      type="file"
                      (change)="selectFile($event)"
                      value="Upload logo"
                      id="image_uploads"
                      [disabled]="viewOnly"
                    />

                    <div
                      [innerHtml]="msg"
                      *ngIf="msg"
                      style="color: red"
                      class="warning"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="company-info-fields">
              <div class="form-group form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="Location"
                  name="companyLocation"
                  placeholder="Location"
                  [(ngModel)]="companyDetails.companyLocation"
                  value="{{ companyDetails.companyLocation }}"
                  [disabled]="viewOnly"
                />
                <label for="Location">Location</label>
              </div>
              <div class="form-group form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="industry"
                  name="industry"
                  placeholder="industry"
                  [(ngModel)]="companyDetails.industry"
                  value="{{ companyDetails.industry }}"
                  [disabled]="viewOnly"
                />
                <label for="industry">Industry</label>
              </div>
            </div>

            <div class="about-comp">
              <h4>About the company</h4>
              <p>
                You can copy and paste into this space. Please use 'Ctrl C' to
                copy and use 'Ctrl V' to paste.
              </p>
              <div class="editor-text">
                <angular-editor
                  [placeholder]="'Enter text here...'"
                  [(ngModel)]="htmlContent"
                  name="desc"
                  [config]="editorConfig"
                >
                </angular-editor>
                <!-- <img src="../../../assets/images/Richtextedit.png" /> -->
              </div>
            </div>

            <div class="company-info-fields">
              <div class="form-group form-floating nobdr">
                <input
                  type="text"
                  class="form-control"
                  id="description"
                  name="shortDescription"
                  placeholder="Short description (140 characters or less)"
                  [(ngModel)]="companyDetails.shortDescription"
                  value="{{ companyDetails.shortDescription }}"
                  [disabled]="viewOnly"
                />
                <label for="description"
                  >Short description (140 characters or less)</label
                >
              </div>
              <div class="form-group form-floating bdrbtm">
                <!-- <input type="text" class="form-control" id="person" name="primaryContactPersonName"
                  [(ngModel)]="companyDetails.primaryContactPersonName" value="{{ companyDetails.primaryContactPersonName }}"
                  placeholder="Primary contact person name" [disabled]="viewOnly" />
                <label for="person">Primary contact person name</label> -->

                <mat-form-field
                  class="example-full-width selectbox"
                  appearance="fill"
                >
                  <mat-label>Primary contact person name</mat-label>
                  <mat-select
                    [(ngModel)]="selectedPrimaryContactperson"
                    [ngModelOptions]="{ standalone: true }"
                    (selectionChange)="changePrimaryContactPerson($event)"
                  >
                    <mat-option
                      [value]="row"
                      *ngFor="let row of this.companyAdminList"
                      >{{ row.firstName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group form-floating">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  [(ngModel)]="companyDetails.email"
                  value="{{ companyDetails.email }}"
                  placeholder="Email"
                  [disabled]="viewOnly"
                />
                <label for="Email">Email</label>
              </div>
              <div class="form-group form-floating">
                <input
                  type="number"
                  class="form-control"
                  id="Phone"
                  name="phone"
                  maxlength="10"
                  minlength="10"
                  [(ngModel)]="companyDetails.phone"
                  value="{{ companyDetails.phone }}"
                  placeholder="Phone number"
                  [disabled]="viewOnly"
                />
                <label for="Phone">Phone number</label>
              </div>

              <input
                type="button"
                (click)="onFormSubmit()"
                value="Update"
                class="btn btn-green mt-2"
                *ngIf="viewOnly !== true"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="img-for-upload">
              <img [src]="url ? url : '../../../assets/images/sm-Logo.png'" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
