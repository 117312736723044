<div class="sidebar">
  <div
    class="frame-container"
    [ngClass]="currentElement === 'campaigns' ? 'active' : ''"
    (click)="selectElement('campaigns')"
  >
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/campaigns-icon.svg" />

        <div class="wallet-label">Campaigns</div>
      </div>
    </div>
  </div>
  <div
    class="frame-container"
    [ngClass]="currentElement === 'analytics' ? 'active' : ''"
    (click)="selectElement('analytics')"
  >
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/analytics-icon.svg" />

        <div class="wallet-label">Analytics</div>
      </div>
    </div>
  </div>

  <div
    class="frame-container"
    [ngClass]="currentElement === 'wallet' ? 'active' : ''"
    (click)="selectElement('wallet')"
  >
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/wallet-icon.svg" />

        <div class="wallet-label">Wallet</div>
      </div>
    </div>
  </div>

  <div
    class="frame-container"
    [ngClass]="currentElement === 'help' ? 'active' : ''"
    (click)="selectElement('help')"
  >
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/help-icon.svg" />

        <div class="wallet-label">Help</div>
      </div>
    </div>
  </div>
</div>
