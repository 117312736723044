<div class="jobs-container">
    <div *ngIf="!detailsOfJob">
        <h2 class="jobs-heading">Jobs</h2>
        <div class="search-section">
            <div class="search-container">
                <div class="search-bar">
                    <img src="../../../../../../assets/images/search-icon.svg" alt="Search Icon" class="search-icon">
                    <input type="text" placeholder="Search Jobs by Title" [(ngModel)]="searchQuery">
                    <button (click)="onSearch()" class="search-button">Search</button>
                </div>
            </div>
            <div class="date-container">
                <div class="date-input-wrapper">
                    <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" class="date-input"
                        placeholder="Start from" [(ngModel)]="startDate" #dateInput>
                    <img *ngIf="!startDate" 
                        src="../../../../../../assets/images/calendar-icon.svg" 
                        alt="Calendar Icon" 
                        class="calendar-icon" 
                        (click)="dateInput.click()">

                    <img *ngIf="startDate" 
                        src="../../../../../../assets/images/cal-cancel.svg" 
                        alt="Clear Icon" 
                        class="calendar-icon" 
                        (click)="clearStartDate()">
                </div>
                <div class="date-input-wrapper">
                    <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" class="date-input"
                        placeholder="To" [(ngModel)]="endDate" #dateInput2>
                    <img *ngIf="!endDate" 
                        src="../../../../../../assets/images/calendar-icon.svg" 
                        alt="Calendar Icon"
                        class="calendar-icon" 
                        (click)="dateInput2.click()">

                    <img *ngIf="endDate" 
                        src="../../../../../../assets/images/cal-cancel.svg" 
                        alt="Clear Icon" 
                        class="calendar-icon" 
                        (click)="clearEndDate()">
                </div>
            </div>
            <div class="btn-set">
                <a href="javascript:void(0);" class="btn border" (click)="exportJobs(0)"><img
                        src="../assets/images/el.png" alt="" /></a>
            </div>
        </div>
        <app-jobs-table (selectedJob)="handlejobSelected($event)" [jobSearch]="jobSearchQuery"></app-jobs-table>
    </div>
    <div *ngIf="detailsOfJob">
        <div class="back-div">
            <button class="back-btn" (click)="backToJobs()">
                <img src="../../../../../../assets/images/back-arrow-btn.svg" class="back-btn-img">
                Return to Jobs
            </button>
        </div>
        <app-job-view [detailsOfJob]="detailsOfJob"></app-job-view>
    </div>
</div>