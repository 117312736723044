import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
@Component({
  selector: 'app-dynamic-snacbar',
  templateUrl: './dynamic-snacbar.component.html',
  styleUrls: ['./dynamic-snacbar.component.scss'],
})
export class DynamicSnacbarComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
  }
  close() {
    this.snackBar.dismiss();
  }
}
