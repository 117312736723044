<section class="bodyjobsearch-area" *ngIf="!nojob">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 p-0">
        <div class="bodyjoblist-left">
          <div class="jobsearch-height" appScrollspy #jobsList>
            <div class="alert-toggle" [style.margin-bottom.px]="totalNoJobs <= 1 || totalNoJobs === undefined ? 0 : -20">
              <p *ngIf="totalNoJobs > 1" class="jobs-found-txt">
                {{ totalNoJobs }} jobs found
              </p>
              <p *ngIf="totalNoJobs === 0" class="jobs-found-txt">
                Loading Jobs...
              </p>
              <div class="alert-gtn-section" *ngIf="searchPhrase">
                <p class="alert-txt" *ngIf="alert_off">
                  create alert for this search
                </p>
                <p class="alert-txt" *ngIf="alert_on">
                  alert on for {{ searchPhrase }}
                </p>
                <div class="btn-toggle d-flex align-items-center">
                  <div
                    class="alert-off"
                    (click)="alertOff()"
                    [ngClass]="{ active: alert_off }"
                  >
                    Off
                  </div>
                  <div
                    class="alert-on"
                    (click)="alertOn(); createAlert()"
                    [ngClass]="{ active: alert_on }"
                  >
                    On
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let job of jobLists; let i = index">
              <span>
                <div
                  [ngClass]="
                    status == job?.id && (job.isPromoted)
                      ? 'bodyjobsearch-box bgcolor success promoted'
                      : job.isPromoted
                      ? 'bodyjobsearch-box bgcolor promoted'
                      : status == job?.id
                      ? 'bodyjobsearch-box bgcolor success'
                      : 'bodyjobsearch-box bgcolor'
                  "
                  (click)="onScrollJobChange('topOfDesc'); jobDetails(i, job)"
                >
                <div class="bodyjobsearch-img" *ngIf="job.is_3mtt">
                  <img [src]="'../../../assets/images/3mtt.svg'" onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                </div>
                <div class="bodyjobsearch-img" *ngIf="!job.is_3mtt">
                  <img [src]="job.company_behalf_logo
                                  ? job.company_behalf_logo
                                  : job.logo
                                  ? job.logo
                                  : '../../../assets/images/sm-Logo.png'" onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                </div>

                  <div class="bodyjobsearch-desc">
                    <h5>{{ job?.title }}</h5>
                    <p class="job-desc-city-salary">
                      <span class="job-desc-company-name">
                        {{
                          detailsOfJob?.company_behalf_name
                            ? job?.company_behalf_name
                            : job?.company_name
                        }}
                      </span>
                      <span class="job-desc-location">
                        -
                        {{ job?.city }}, {{ job?.job_location }}
                      </span>
                    </p>
                    <h6 class="left-list-salary">
                      <span
                        *ngIf="
                          job?.minimum_salary != '0' &&
                          job?.minimum_salary != ''
                        "
                        >{{ job?.currency }}
                        {{ job?.minimum_salary | number }}</span
                      >
                      <span
                        *ngIf="
                          job?.maximum_salary != '0' &&
                          job?.maximum_salary != ''
                        "
                      >
                        - {{ job?.currency }}
                        {{ job?.maximum_salary | number }}</span
                      >
                      <span
                        *ngIf="
                          job?.minimum_salary == '0' ||
                          job?.minimum_salary == ''
                        "
                      >
                        Salary - Not specified or see the job description</span
                      >
                    </h6>
                    <!-- <h6>
                      {{
                        job?.company_behalf_name
                          ? job?.company_behalf_name
                          : job?.company_name
                      }}
                      <span
                        *ngIf="job.companyApprovedStatus == 'approved'"
                        class="verifi-icon"
                        ><img
                          src="../../../../assets/images/Verification-icon.png" /></span
                      ><br />
                      {{ job?.job_location
                      }}<span *ngIf="job?.city">/{{ job?.city }}</span>
                    </h6> -->
                    <!-- <h6> ₦ {{job?.minimum_salary| number }}
                                        - ₦ {{job?.maximum_salary | number}} </h6> -->
                    <div class="tag-hot-list">
                      <ul>
                        <li>
                          <a href="javascript:void(0);"
                            ><img
                              class="query-builder"
                              src="../../../../assets/images/query_builder-new.png"
                            />
                            {{ eventExpireIn(job?.created_at) }}</a
                          >
                        </li>
                        <!-- <li>
                          <a href="javascript:void(0);">
                            <div class="jobtag-area">
                              <div
                                *ngIf="job?.job_type == 'hot'"
                                class="jobtag hot"
                              >
                                <img
                                  src="../../../../assets/images/fire-new.png"
                                />
                                Hot
                              </div>
                              <div
                                *ngIf="job?.job_type == 'cold'"
                                class="jobtag cool"
                              >
                                <img src="../../../../assets/images/cool.png" />
                                Cold
                              </div>
                              <div
                                *ngIf="job?.job_type == 'new'"
                                class="jobtag new"
                              >
                                <img src="../../../../assets/images/new.png" />
                                New
                              </div>
                            </div>
                          </a>
                        </li> -->
                        <li [style.display]="(job?.employment_type === 'None' || job?.employment_type === '') ? 'none' : 'relative'">
                          <a
                            href="javascript:void(0);"
                            class="employment-type"
                            >{{ job?.employment_type }}</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void(0);" class="employment-years"
                            >{{ job?.minimum_experience }}+ years</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div *ngIf="job?.isPromoted" class="sponsored">
                    <!-- <div [style.visibility]="job?.isPromoted && job.isPromoted !== '' ? 'visible' : 'hidden'" class="sponsored"> -->
                      <a class="sponsored-img"><img src="../../../../assets/images/promoted.svg"/></a>
                      <h6 class="sponsored-tag">Sponsored</h6>
                    </div>
                  </div>
                  <!-- <div class="bodyjobsearch-share">
                    <ul class="d-flex flex-column">
                      <li *ngIf="!job?.isSaved" class="mb-3">
                        <a href="javascript:void(0);" (click)="savedJob(job)"
                          ><img
                            src="../../../../assets/images/favorite-new.png"
                        /></a>
                      </li>
                      <li *ngIf="job?.isSaved" class="mb-3">
                        <a
                          class="active"
                          href="javascript:void(0);"
                          *ngIf="userDeatils?.id"
                          (click)="savedJob(job)"
                          ><img
                            src="../../../../assets/images/favorite-new-fill.png"
                        /></a>
                      </li>
                      <li class="mb-3">
                        <a href="javascript:void(0);" (click)="shareJob(job)"
                          ><img src="../../../../assets/images/share-new.png"
                        /></a>
                      </li>
                    </ul>
                  </div> -->
                </div>
                <div
                  *ngIf="detailsOfJob && job?.id === detailsOfJob.id"
                  class="job-details-small"
                >
                  <div class="cross-icon" (click)="clearJobDetails()">
                    <i class="fa fa-times"></i>
                  </div>
                  <div class="hsc-top-hd-small">
                    <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                        <div class="tag-applied-days-list">
                          <ul>
                            <li>
                              <p
                                href="javascript:void(0);"
                                class="job-desc-days-left"
                              >
                                <img
                                  src="../../../../assets/images/people-new.png"
                                />
                                {{ this.countOfApplied }}
                                applied
                              </p>
                            </li>
                            <li *ngIf="!this.isExpriedButton">
                              <p
                                href="javascript:void(0);"
                                class="job-desc-days-left"
                              >
                                <img
                                  src="../../../../assets/images/hourglass-new.png"
                                />
                                {{ this.dateDiff }}d left
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-sm-2"></div>
                    </div>
                    <div class="apply-btn-div">
                      <a
                        class="apply-btn"
                        href="javascript:void(0);"
                        *ngIf="
                          detailsOfJob?.isApplied == false &&
                          this.isExpriedButton == false
                        "
                        (click)="applyJob(detailsOfJob)"
                        ><img src="../../../../assets/images/send_24px.png" class="apply-icon" />
                        Apply for this job</a
                      >
                    </div>
                  </div>
                  <div class="job-desc-row-container">
                    <div class="job-desc-row-2">
                      <div class="test">
                        <ul>
                          <li
                            [ngClass]="
                              clickSection == 'jobDescription' ? 'active' : ''
                            "
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="scrollToElement('jobDescription')"
                              >Job Description</a
                            >
                          </li>
                          <li
                            [ngClass]="
                              clickSection == 'qualifications' ? 'active' : ''
                            "
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="scrollToElement('qualifications')"
                              >Qualification</a
                            >
                          </li>
                          <li
                            [ngClass]="
                              clickSection == 'company' ? 'active' : ''
                            "
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="scrollToElement('company')"
                              >Company Info</a
                            >
                          </li>
                          <li
                            [ngClass]="
                              clickSection == 'related' ? 'active' : ''
                            "
                          >
                            <a
                              href="javascript:void(0);"
                              (click)="scrollToElement('related')"
                              >Related Jobs</a
                            >
                          </li>
                          <li class="job-desc-row-2-report">
                            <a
                              class="report"
                              href="javascript:void(0);"
                              *ngIf="showReportSection"
                              (click)="reportModel(detailsOfJob)"
                              ><img
                                src="../../../../assets/images/flag-report.png"
                              />Report</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="detailsOfJob" class="search-filter-right">
                    <div class="test-info">
                      <div
                        class="step-1 jobDescription disc-list"
                        id="jobDescription"
                        [ngClass]="
                          clickSection == 'jobDescription'
                            ? 'padding-jobDescription'
                            : ''
                        "
                      >
                        <h4>Job Description</h4>
                        <div [innerHTML]="jobDesc"></div>
                      </div>
                      <div
                        class="step-1 qualifications disc-list"
                        id="qualifications"
                        [ngClass]="
                          clickSection == 'qualifications'
                            ? 'padding-qualifications'
                            : ''
                        "
                      >
                        <h4>Qualifications</h4>

                        <div [innerHTML]="qualifications"></div>
                      </div>
                      <div
                        class="step-1 company disc-list"
                        id="company"
                        [ngClass]="
                          clickSection == 'company' ? 'padding-company' : ''
                        "
                      >
                        <h4>Company Info</h4>
                        <h4 class="company-name">
                          {{
                            detailsOfJob?.company_behalf_name
                              ? detailsOfJob?.company_behalf_name
                              : detailsOfJob?.company_name
                          }}
                        </h4>
                        <p [innerHTML]="eachJobCompanyDetails?.about"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </ng-container>
            <ngb-pagination
              *ngIf="jobLists?.length > 0"
              [page]="currentPage"
              [pageSize]="pageSize"
              [maxSize]="7"
              (pageChange)="onPageChanged($event)"
              [ellipses]="false"
              [rotate]="true"
              [collectionSize]="collectionSize"
            ></ngb-pagination>
          </div>
        </div>
      </div>
      <div
        class="col-lg-8 p-0 job-desc"
        [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''"
        *ngIf="detailsOfJob"
      >
        <button (click)="mobileViewClassClose()" class="mobilejob-back">
          <i class="far fa-times-circle"></i>
        </button>
        <span [ngClass]="showRightSectionInMobile ? 'mobile-view-child' : ''">
          <div class="hsc-top-hd">
            <div class="row p-4 job-desc-row-1">
              <div class="col-lg-1 p-0 d-flex">
                <div class="company-img" *ngIf="detailsOfJob.is_3mtt">
                  <img [src]="'../../../assets/images/3mtt.svg'" onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                </div>
                <div class="company" *ngIf="!detailsOfJob.is_3mtt">
                  <img [src]="detailsOfJob.company_behalf_logo
                                  ? detailsOfJob.company_behalf_logo
                                  : detailsOfJob.logo
                                  ? detailsOfJob.logo
                                  : '../../../assets/images/sm-Logo.png'" onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
                </div>
              </div>
              <div class="col-lg-5">
                <h3 class="job-desc-title">
                  {{ detailsOfJob.title }}
                </h3>
                <p class="job-desc-city-salary">
                  <span class="job-desc-company-name">
                    {{
                      detailsOfJob?.company_behalf_name
                        ? detailsOfJob?.company_behalf_name
                        : detailsOfJob?.company_name
                    }}
                  </span>
                  <span class="job-desc-location">
                    -
                    {{ detailsOfJob?.city }}, {{ detailsOfJob?.job_location }}
                  </span>
                  <span> | </span>
                  <span
                    class="job-desc-currency"
                    *ngIf="
                      detailsOfJob?.minimum_salary != '0' &&
                      detailsOfJob?.minimum_salary != ''
                    "
                    >{{ detailsOfJob?.currency }}
                    {{ detailsOfJob?.minimum_salary | number }}
                  </span>
                  <span
                    class="job-desc-currency"
                    *ngIf="
                      detailsOfJob?.maximum_salary != '0' &&
                      detailsOfJob?.maximum_salary != ''
                    "
                  >
                    - {{ detailsOfJob?.currency }}
                    {{ detailsOfJob?.maximum_salary | number }}
                  </span>
                  <span
                    class="job-desc-currency"
                    *ngIf="
                      detailsOfJob?.minimum_salary == '0' ||
                      detailsOfJob?.minimum_salary == ''
                    "
                  >
                    Salary - Not specified or see the job description
                  </span>
                </p>
                <div class="tag-hot-list">
                  <ul>
                    <li>
                      <a href="javascript:void(0);"
                        ><img
                          class="query-builder"
                          src="../../../../assets/images/query_builder-new.png"
                        />
                        {{ eventExpireIn(detailsOfJob?.created_at) }}
                      </a>
                    </li>
                    <!-- <li>
                      <a href="javascript:void(0);">
                        <div class="jobtag-area">
                          <div
                            *ngIf="detailsOfJob?.job_type == 'hot'"
                            class="jobtag hot"
                          >
                            <img src="../../../../assets/images/fire-new.png" />
                            Hot
                          </div>
                          <div
                            *ngIf="detailsOfJob?.job_type == 'cold'"
                            class="jobtag cool"
                          >
                            <img src="../../../../assets/images/cool.png" />
                            Cold
                          </div>
                          <div
                            *ngIf="detailsOfJob?.job_type == 'new'"
                            class="jobtag new"
                          >
                            <img src="../../../../assets/images/new.png" /> New
                          </div>
                        </div>
                      </a>
                    </li> -->
                    <li [style.display]="(detailsOfJob?.employment_type === 'None' || detailsOfJob?.employment_type === '') ? 'none' : 'relative'">
                      <a href="javascript:void(0);" class="employment-type">{{
                        detailsOfJob.employment_type
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="employment-years"
                        >{{ detailsOfJob.minimum_experience }}+ years</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-1"></div>
              <div class="col-lg-3 p-0">
                <span *ngIf="jobAppliedButtonDisable">
                  <div class="active">
                    <a
                      class="apply-btn"
                      href="javascript:void(0);"
                      *ngIf="
                        detailsOfJob?.isApplied == false 
                      "
                      (click)="applyJob(detailsOfJob)"
                      ><img src="../../../../assets/images/send_24px.png" />
                      Apply for this job</a
                    >
                  </div>
                  <!-- <div class="active">
                    <a
                      class="linebdr"
                      href="javascript:void(0);"
                      *ngIf="this.isExpriedButton"
                      >Expired</a
                    >
                  </div> -->
                  <div class="active" style="text-align: center;">
                    <!-- &&this.isExpriedButton == false -->
                    <a
                      class="linebdr"
                      href="javascript:void(0);"
                      *ngIf="
                        detailsOfJob?.isApplied == true 
                      "
                      (click)="alreadyApply()"
                      >Applied</a
                    >
                  </div>
                </span>
                <div class="tag-applied-days-list">
                  <ul class="pt-3">
                    <li>
                      <p href="javascript:void(0);" class="job-desc-days-left">
                        <img src="../../../../assets/images/people-new.png" />
                        {{ this.countOfApplied }}
                        applied
                      </p>
                    </li>
                    <li *ngIf="!this.isExpriedButton">
                      <p href="javascript:void(0);" class="job-desc-days-left">
                        <img
                          src="../../../../assets/images/hourglass-new.png"
                        />
                        {{ this.dateDiff }}d left
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-2">
                <ul class="d-flex flex-column">
                  <li *ngIf="!detailsOfJob?.isSaved" class="mb-2">
                    <a
                      class="job-desc-save"
                      href="javascript:void(0);"
                      (click)="savedJob(detailsOfJob)"
                    >
                      <img src="../../../../assets/images/favorite-new.png" />
                      Save</a
                    >
                  </li>
                  <li *ngIf="detailsOfJob?.isSaved" class="mb-2">
                    <a
                      class="job-desc-save"
                      href="javascript:void(0);"
                      *ngIf="userDeatils?.id"
                      (click)="savedJob(detailsOfJob)"
                    >
                      <img
                        src="../../../../assets/images/favorite-new-fill.png"
                      />
                      Saved</a
                    >
                  </li>

                  <li class="mb-3">
                    <a
                      class="job-desc-share"
                      href="javascript:void(0);"
                      (click)="shareJob(detailsOfJob)"
                    >
                      <img src="../../../../assets/images/share-new.png" />
                      Share</a
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="row job-desc-row-2">
              <div class="test">
                <ul>
                  <li
                    [ngClass]="clickSection == 'jobDescription' ? 'active' : ''"
                  >
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('jobDescription-large')"
                      >Job Description</a
                    >
                  </li>
                  <li
                    [ngClass]="clickSection == 'qualifications' ? 'active' : ''"
                  >
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('qualifications-large')"
                      >Qualification</a
                    >
                  </li>
                  <li [ngClass]="clickSection == 'company' ? 'active' : ''">
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('company-large')"
                      >Company Info</a
                    >
                  </li>
                  <li [ngClass]="clickSection == 'related' ? 'active' : ''">
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('related')"
                      >Related Jobs</a
                    >
                  </li>
                  <li class="job-desc-row-2-report">
                    <a
                      class="report"
                      href="javascript:void(0);"
                      *ngIf="showReportSection"
                      (click)="reportModel(detailsOfJob)"
                      ><img
                        src="../../../../assets/images/flag-report.png"
                      />Report</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="detailsOfJob" class="search-filter-right">
            <div class="test-info" id="topOfDesc">
              <div
                class="step-1 jobDescription disc-list"
                id="jobDescription-large"
                [ngClass]="
                  clickSection == 'jobDescription'
                    ? 'padding-jobDescription'
                    : ''
                "
              >
                <h4>Job Description</h4>
                <div [innerHTML]="jobDesc"></div>
              </div>
              <div
                class="step-1 qualifications disc-list"
                id="qualifications-large"
                [ngClass]="
                  clickSection == 'qualifications'
                    ? 'padding-qualifications'
                    : ''
                "
              >
                <h4>Qualifications</h4>

                <p [innerHTML]="qualifications"></p>
              </div>
              <div
                class="step-1 company disc-list"
                id="company-large"
                [ngClass]="clickSection == 'company' ? 'padding-company' : ''"
              >
                <h4>Company Info</h4>
                <h4 class="company-name">
                  {{
                    detailsOfJob?.company_behalf_name
                      ? detailsOfJob?.company_behalf_name
                      : detailsOfJob?.company_name
                  }}
                </h4>
                <p [innerHTML]="eachJobCompanyDetails?.about"></p>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="row">
        <!-- Ads Section -->
        <div class="col-lg-4">
          <div class="ads-container" id="ads-container-timer">
            <!-- <div class="card ads-card">
              <img src="../../../../assets/images/Ad-text.png" class="ad-text" />
              <div class="row ad-card-desc-training">
                <div class="col-lg-5">
                  <p class="ad-text-highlighted-training">
                    Want A Training for Your Team?
                  </p>
                  <p class="ad-text-normal-training">
                    Our Team of Trainers can Help you
                  </p>
                </div>
                <div class="col-lg-6">
                  <img class="ad-training-img" src="../../../../assets/images/Ad-training-img.png" />
                </div>
              </div>
            </div> -->
            <div class="card ads-card">
              <img src="../../../../assets/images/Ad-1.png" alt="Card image" />
              <button
                class="btn btn-training"
                (click)="navigateToContactUs('Training')"
              ></button>
            </div>
            <div class="card ads-card">
              <img src="../../../../assets/images/Ad-2.png" alt="Card image" />
              <button
                class="btn btn-request"
                (click)="navigateToCvReview()"
              ></button>
            </div>
            <div class="card ads-card">
              <img src="../../../../assets/images/Ad-3.png" alt="Card image" />
              <button
                class="btn btn-recruiter"
                (click)="navigateToContactUs('Recruitment')"
              ></button>
            </div>
          </div>
        </div>
        <!-- Related Jobs Section -->
        <div class="col-lg-8">
          <div
            class="related-jobs"
            #relatedSection
            id="related"
            *ngIf="allRelatedJob"
            [ngClass]="clickSection == 'related' ? 'padding-related' : ''"
          >
            <h4>Related Jobs</h4>
            <div class="row related-job-row">
              <div
                class="col-lg-4"
                *ngFor="let job of allRelatedJob | slice : 0 : 3; let i = index"
              >
                <div #firstCard class="card-grid">
                  <div
                    class="related-job-card"
                    (click)="onScrollJobChange('topOfDesc')"
                    [ngClass]="
                      status == job?.id
                        ? 'related-job-card success'
                        : 'related-job-card'
                    "
                  >
                    <div
                      class="bodyjobsearch-img"
                      *ngIf="!job.company_behalf_logo"
                    >
                      <img
                        [src]="
                          job?.logo
                            ? job?.logo
                            : '../../../assets/images/sm-Logo.png'
                        "
                        onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                      />
                    </div>

                    <div
                      class="bodyjobsearch-img"
                      *ngIf="job.company_behalf_logo"
                    >
                      <img
                        [src]="
                          job?.company_behalf_logo
                            ? job?.company_behalf_logo
                            : '../../../assets/images/sm-Logo.png'
                        "
                        onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                      />
                    </div>

                    <div
                      class="bodyjobsearch-desc"
                      (click)="jobDetailsRelated(i, job)"
                    >
                      <h4 class="related-jobs-title">{{ job?.title }}</h4>
                      <p class="job-desc-city-salary">
                        <span class="job-desc-company-name">
                          {{
                            detailsOfJob?.company_behalf_name
                              ? job?.company_behalf_name
                              : job?.company_name
                          }}
                        </span>
                        <span class="job-desc-location">
                          -
                          {{ job?.city }}, {{ job?.job_location }}
                        </span>
                      </p>
                      <h6 class="left-list-salary">
                        <span
                          *ngIf="
                            job?.minimum_salary != '0' &&
                            job?.minimum_salary != ''
                          "
                          >{{ job?.currency }}
                          {{ job?.minimum_salary | number }}</span
                        >
                        <span
                          *ngIf="
                            job?.maximum_salary != '0' &&
                            job?.maximum_salary != ''
                          "
                        >
                          - {{ job?.currency }}
                          {{ job?.maximum_salary | number }}</span
                        >
                        <span
                          *ngIf="
                            job?.minimum_salary == '0' ||
                            job?.minimum_salary == ''
                          "
                        >
                          Salary - Not specified or see the job
                          description</span
                        >
                      </h6>
                      <div class="tag-hot-list">
                        <ul>
                          <li>
                            <a href="javascript:void(0);"
                              ><img
                                class="query-builder"
                                src="../../../../assets/images/query_builder-new.png"
                              />
                              {{ eventExpireIn(job?.created_at) }}</a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="jobtag-area">
                                <div
                                  *ngIf="job?.job_type == 'hot'"
                                  class="jobtag hot"
                                >
                                  <img
                                    src="../../../../assets/images/fire-new.png"
                                  />
                                  Hot
                                </div>
                                <div
                                  *ngIf="job?.job_type == 'cold'"
                                  class="jobtag cool"
                                >
                                  <img
                                    src="../../../../assets/images/cool.png"
                                  />
                                  Cold
                                </div>
                                <div
                                  *ngIf="job?.job_type == 'new'"
                                  class="jobtag new"
                                >
                                  <img
                                    src="../../../../assets/images/new.png"
                                  />
                                  New
                                </div>
                              </div>
                            </a>
                          </li>
                          <li [style.display]="job?.employment_type === 'None' ? 'none' : 'relative'">
                            <a
                              href="javascript:void(0);"
                              class="employment-type"
                              >{{ job?.employment_type }}</a
                            >
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              class="employment-years"
                              >{{ job?.minimum_experience }}+ years</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="relatedjobsearch-share">
                      <ul class="d-flex flex-column">
                        <li *ngIf="!job?.isSaved" class="mb-3">
                          <a href="javascript:void(0);" (click)="savedJob(job)"
                            ><img
                              src="../../../../assets/images/favorite-new.png"
                          /></a>
                        </li>
                        <li *ngIf="job?.isSaved" class="mb-3">
                          <a
                            class="active"
                            href="javascript:void(0);"
                            *ngIf="userDeatils?.id"
                            (click)="savedJob(job)"
                            ><img
                              src="../../../../assets/images/favorite-new-fill.png"
                          /></a>
                        </li>
                        <li class="mb-3">
                          <a href="javascript:void(0);" (click)="shareJob(job)"
                            ><img src="../../../../assets/images/share-new.png"
                          /></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div *ngIf="nojob" class="min-height">
  <section class="nosavedjob-found new-screen">
    <div class="no-job-posted-yet">
      <h4>No jobs found!</h4>
      <!-- <button class="green-btn" >Add your first job</button> -->
    </div>
  </section>
</div>