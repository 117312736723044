import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-emp-campaigns-help',
  templateUrl: './emp-campaigns-help.component.html',
  styleUrls: ['./emp-campaigns-help.component.scss']
})
export class EmpCampaignsHelpComponent implements OnInit {
  contactUsFrom: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  numberPattern = /^[0-9]*$/;
  isChecked: boolean = false;
  allSubscriptionsData: any;

  constructor(
    private fb: UntypedFormBuilder,
    private helper: Helper,
    private io: HttpIOService,
    @Inject(MatDialog) public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.validationForm();
  }

  validationForm() {
    this.contactUsFrom = this.fb.group({
      Name: ['', Validators.required],
      Message: [''],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      number: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      selectedOption: [''],
      isChecked: [false]
    });
  }

  submitContactFrom() {
    if (this.contactUsFrom.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.contactUsFrom);
      return false;
    }
    let jsonData = {
      path: 'send-contact-form',
      name: this.contactUsFrom.get('Name').value,
      email: this.contactUsFrom.get('email').value,
      subject: this.contactUsFrom.get('selectedOption').value,
      message: this.contactUsFrom.get('Message').value,
    };
    this.io._contactUs(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        if(this.contactUsFrom.get('isChecked').value){
            this.newsletterSignup(jsonData.email)
          }
        this.dialog.open(PopupConfirmationComponent, {
          height: '366px',
          width: '390px',
          autoFocus: false,
          data: {
            data: 'help-successful',
          },
        })
        this.contactUsFrom.reset();
      }
    });
  }

  newsletterSignup(email: string) {
    let jsonData = {
      path: 'all-subscriptions',
    };
    this.io._getAllSubscription(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.allSubscriptionsData = res['result'].subscriptions;
        let jsonData = {
          path: 'add-user-subscription',
          email: email,
          subscriptions: [this.allSubscriptionsData.find(item => item.name === 'NEWS')?.id],
        }
        this.io._updateUserSubscription(jsonData).subscribe((res: any) => {})
      }
    });
  }

  get f() {
    return this.contactUsFrom.controls;
  }

}
