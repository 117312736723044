import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { EmpExtendPopupComponent } from '../emp-extend-popup/emp-extend-popup.component';
import { EmpJobPreviewComponent } from '../emp-job-preview/emp-job-preview.component';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-emp-campaigns',
  templateUrl: './emp-campaigns.component.html',
  styleUrls: ['./emp-campaigns.component.scss'],
})
export class EmpCampaignsComponent implements OnInit {
  campaignList = [];
  jobsList = [];
  selectedJobsList = [];

  isCampaignBeingAdded: boolean = false;
  userDetails: any;
  companyData: any;
  today = this.formatDateToDDMMYYYY(Date.now());

  currentPage = 1;
  currentJobsPage = 1;
  currentCampaignsPage = 1;
  currentBalance = 0;
  filteredJobs: any[] = [];

  campaignsPagination = {
    size: 4,
    start: 0,
    total: 0,
  };

  jobsPagination = {
    size: 5,
    start: 0,
    total: 0,
  };

  newCampaignForm: UntypedFormGroup;

  title = '';
  transactionRef = uuidv4();

  @Output() dataEvent = new EventEmitter<boolean>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private employerService: EmployerService,
    private helper: Helper,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyData();
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
        this.companyData = this.auth.getCurrentCompanyDataInSessoin();
      }

      this.currentBalance = this.userDetails.balance ?? 0;
    });

    this.newCampaignForm = this._formBuilder.group({
      title: ['', Validators.required],
      budget: [10000.0, Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  updateLocalUserDetails() {
    let jsonData = {
      path: 'get-job-seeker',
      id: this.userDetails.id,
    };
    this.employerService._getCurrentUser(jsonData).subscribe((res) => {
      if (res['serverResponse'].code === 200) {
        this.auth.setCurrentUserDetails(res['result']['userData']);
        this.auth.setCurrentUserDetailsInSessoin(res['result']['userData']);
      }
    });
  }

  ngOnInit(): void {
    this.updateLocalUserDetails();
    this.getAllCampaigns();
    this.getEmployerJobs();
  }

  async getEmployerJobs(): Promise<void> {
    let jsonData = {
      path: 'get-job-by-provider-or-company',
      company_id: this.userDetails.companyId,
      start: this.jobsPagination.start,
      size: this.jobsPagination.size,
    };
    await this.employerService
      ._getJobByproviderAndCompany(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobsList = res['result'].job;
          this.jobsPagination.total = res['pagination']['total'];
          this.jobsPagination.start += this.jobsPagination.size;
        }
      });
  }

  createdAtDate(datetime: string) {
    const dateObj = new Date(datetime);

    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const year = dateObj.getFullYear();

    const formattedDate = `${dateObj.getDate()} ${month} ${year}`;

    return formattedDate;
  }

  daysLeft(endDate: string) {
    const today = new Date();
    const end = new Date(endDate);

    // Calculate the difference in milliseconds between the two dates
    const timeDiff = end.getTime() - today.getTime();

    // Calculate the number of days left
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    return daysLeft;
  }

  getAllCampaigns(): void {
    let params = {
      size: this.campaignsPagination.size,
      start: this.campaignsPagination.start,
      sortParam: 'createdAt',
      sortOrder: 'DESC',
    };

    this.employerService._getCampaigns(params).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {
        this.campaignList = res['result'];
        this.campaignsPagination.total = res['pagination']['total'];
        this.campaignsPagination.start = res['pagination']['start'];
      }
    });
  }

  startNewCampaign(): void {
    this.isCampaignBeingAdded = true;
    console.log('startNewCampaign()');
    this.dataEvent.emit(this.isCampaignBeingAdded);
  }

  postCampaign(): void {
    let jsonData = {
      path: 'create-campaign',
      type: this.newCampaignForm.value.title,
      budget: this.newCampaignForm.value.budget,
      budgetCurrency: 'NGN',
      startDate: this.newCampaignForm.value.startDate+'T00:00:00Z',
      endDate: this.newCampaignForm.value.endDate,
      jobIds: this.selectedJobsList,
    };

    this.employerService._postCampaign(jsonData).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {
        this.isCampaignBeingAdded = false;
        this.dataEvent.emit(this.isCampaignBeingAdded);
        this.currentPage = 1;
        this.updateLocalUserDetails();
        this.getAllCampaigns();
      }
    });
  }

  goToNextPage(): void {
    if (this.currentPage === 1 && this.selectedJobsList.length === 0) {
      return;
    }
    if (this.currentPage === 2 && !this.newCampaignForm.valid) {
      return;
    }
    this.currentPage++;
  }

  goBack(): void {
    if (this.currentPage === 1) {
      this.isCampaignBeingAdded = false;
      this.dataEvent.emit(this.isCampaignBeingAdded);
      return;
    }

    this.currentPage--;
  }

  onJobsPageChanged(page: number): void {
    this.jobsPagination.start = (page - 1) * this.jobsPagination.size;
    this.currentJobsPage = page;
    this.getEmployerJobs();
  }

  onCampaignsPageChanged(page: number): void {
    this.campaignsPagination.start = (page - 1) * this.campaignsPagination.size;
    this.currentCampaignsPage = page;
    this.getAllCampaigns();
  }

  updateSelectedJobsList(isChecked: any, jobId: String): void {
    if (isChecked.checked) {
      this.selectedJobsList.push(jobId);
    } else {
      this.selectedJobsList.splice(this.selectedJobsList.indexOf(jobId), 1);
    }
  }

  formatDateToDDMMYYYY(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  }

  getCampaignStatus(status: string): string {
    switch (status) {
      case 'Start':
        return 'ACTIVE';
      case 'Stop':
        return 'INACTIVE';
      case 'Expired':
        return 'EXPIRED';
      default:
        return 'ACTIVE';
    }
  }

  getCampaignStatusClass(status: string): string {
    switch (status) {
      case 'Start':
        return 'curved-pill-active';
      case 'Stop':
        return 'curved-pill-inactive';
      case 'Expired':
        return 'curved-pill-expired';
      default:
        return 'curved-pill-active';
    }
  }

  toggleCampaignStatus(event: any, campaign: any): void {
    let jsonData = {
      path: 'update-campaign',
      status: event.target.checked ? 'Start' : 'Stop',
    };
    this.employerService
      ._updateCampaign(jsonData, campaign.id)
      .subscribe((res) => {
        if (res['serverResponse']['code'] == 200) {
          this.getAllCampaigns();
        } else {
          console.log('res 2: ', res);
        }
      });
  }

  openExtendPopUp() {
    const dialogRef = this.dialog.open(EmpExtendPopupComponent, {
      width: '876px',
      height: '461px',
      panelClass: 'no-border-radius-dialog',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'save') {
      }
    });
  }

  fundWallet() {
    let jsonData = {
      path: 'fund-wallet',
      cardName: 'Neil abc',
      cardType: 'Visa',
      transactionId: '1234',
      paymentAmount: 1000000,
      currency: 'NGN',
      status: 'Paid',
    };

    this.employerService._fundWallet(jsonData).subscribe((res) => {
      if (res['serverResponse']['code'] == 200) {
        console.log(res);
        this.currentBalance += 1000000;
        this.userDetails.balance = this.currentBalance;
        this.auth.setCurrentUserDetails(this.userDetails);
        this.auth.setCurrentUserDetailsInSessoin(this.userDetails);
        // this.postCampaign();
      } else {
        console.log(res);
      }
    });
  }

  previewJobList() {
    this.filteredJobs = this.jobsList.filter((job) =>
      this.selectedJobsList.includes(job.id)
    );

    const dialogRef = this.dialog.open(EmpJobPreviewComponent, {
      width: '881px',
      height: '675px',
      autoFocus: false,
      data: { filteredJobs: [...this.filteredJobs] }, // Pass a copy of the filtered job list
    });

    dialogRef.afterClosed().subscribe((updatedJobsList: any[]) => {
      if (updatedJobsList) {
        this.selectedJobsList = updatedJobsList;
      }
    });
  }

  locationType(loc: any) {
    switch (loc) {
      case 'REMOTE':
        return 'Remote';
      case 'OUT_OF_COUNTRY':
        return 'Out of Country';
      case 'NIGERA':
        return 'Onsite';
    }
  }

  formatTitle(jobTitle: string) {
    return jobTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  paymentCancel() {
    // this.snackBar
    //   .openFromComponent(DynamicSnacbarComponent, {
    //     data: { message: 'Payment Failed', type: 'error' },
    //     verticalPosition: 'top',
    //     horizontalPosition: 'right',
    //     duration: 3000,
    //   })
    //   .afterOpened();
    console.log('Payment Cancelled');
  }

  paymentDone(event: any) {
    console.log(event);
    let jsonData: any = {
      path: 'fund-wallet',
      cardName: 'N/A',
      cardType: 'N/A',
      transactionId: event.reference,
      paymentAmount: this.newCampaignForm.value.budget - this.currentBalance,
      currency: 'NGN',
      status: 'Paid',
    };
    this.employerService._fundWallet(jsonData).subscribe((res: any) => {
      if (res['serverResponse'].code === 200) {
        console.log('Payment Successful');
        this.userDetails.balance = 0;
        // this.updateLocalUserDetails();
        this.postCampaign();
      } else {
        console.log('Payment Failed');
      }
    });
  }

  updateTransactionId(event: any) {
    this.transactionRef = uuidv4();
  }
}
