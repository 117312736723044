<div class="profile-middle-sec appsettings-custom">
  <div class="container">
    <div class="card">
      <h3
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        Set Password To Complete Your Account
      </h3>
      <div class="card-mid-description">
        <form [formGroup]="appSettingsPasswordFrom">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Email</mat-label>
                  <input
                    type="text"
                    matInput
                    placeholder="Email"
                    readonly
                    formControlName="email"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>New Password</mat-label>
                  <input
                    type="password"
                    matInput
                    placeholder="New Password"
                    required="required"
                    formControlName="NewPassword"
                    [pattern]="passwordPattern"
                  />
                </mat-form-field>
              </div>

              <div *ngIf="g.NewPassword.touched && g.NewPassword.invalid">
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="g.NewPassword.errors.required"
                  >New Password is required
                </span>
                <span
                  class="text-left text-danger pb-1"
                  *ngIf="g.NewPassword.errors?.pattern"
                  >You must use at least one upper case letter, one lower case letter, one number, and one special character. The password should be at least 8 characters long
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Confirm Password</mat-label>
                  <input
                    type="password"
                    matInput
                    placeholder="Confirm Password"
                    required="required"
                    formControlName="ConfirmPassword"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="g.ConfirmPassword.touched && g.ConfirmPassword.invalid"
                class="text-left text-danger pb-1"
              >
                <div *ngIf="g.ConfirmPassword.errors.required">
                  Confirm Password is required.
                </div>
                <div *ngIf="g.ConfirmPassword.errors.confirmedValidator">
                  Password and Confirm Password must be match.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <p style="text-align: right">
        <button mat-raised-button color="primary" (click)="setPassWord()">
          UPDATE
        </button>
      </p>
    </div>
  </div>
</div>
