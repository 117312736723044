<div class="tab-content select-wrap py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active" id="jobs">
    <!-- <button *ngIf="show" (click)="onclick()">back</button> -->
    <div class="overview">
      <h5>Companies List</h5>
    </div>
    <div class="row">
      <div class="col-lg-12 companies-wp">
        <form [formGroup]="companiesTab">
          <div class="select-row">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label style="color: black"
                ><i class="fas fa-search"></i> Companies</mat-label
              >
              <input
                type="text"
                matInput
                formControlName="companies"
                [(ngModel)]="searchCompany"
                (keyup)="getSearchedCompany(searchCompany)"
              />
              <!-- <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option.companyName"
                >
                  {{ option.companyName }}
                </mat-option>
              </mat-autocomplete> -->
            </mat-form-field>
          </div>

          <div class="btnrightadd">
            <a href="javascript:void(0);" class="gray-btn" (click)="resetFrom()"
              >Clear</a
            >
          </div>
        </form>
        <div class="btn-set">
          <a
            href="javascript:void(0);"
            class="btn border position-button-export"
            (click)="exportexcel()"
            ><img src="../../../../../../assets/images/el.png" alt="" /></a
          >&nbsp;
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content py-3 px-3 px-sm-0">
    <div class="tab-pane fade show active" id="jobs">
      <div class="well-views">
        <table class="table">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Primary Admin Name</th>
              <th>Primary Admin Email</th>
              <th>Phone Number</th>
              <th>register at</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr *ngFor="let job of jobsList" (click)="openDialog(job)"> -->
            <tr *ngFor="let job of CompaniesResult" style="cursor: pointer">
              <td>{{ job.companyName }}</td>
              <td>{{ job.firstName }}</td>
              <td>{{ job.email }}</td>
              <td>{{ job.primaryContactPersonPhoneNo }}</td>
              <td>{{ job.createdAt | date : "short" }}</td>
              <!-- <td><button [ngClass]="job.isActive == true ? 'gray-btn' : 'green-btn'"
                                    [disabled]="job.isActive ? true: null" (click)="companyVarify(job)">{{ job.isActive
                                    ==
                                    true ? 'verified' : 'verify' }}</button>row?.name[0].toUpperCase()+ row?.name.slice(1)
                            </td> -->
              <td *ngIf="job.approvedStatus == 'notApply'">
                <button class="btn btn-gray" [disabled]="true">
                  {{
                    job.approvedStatus[0].toUpperCase() +
                      job.approvedStatus.slice(1)
                  }}
                </button>
              </td>
              <td *ngIf="job.approvedStatus == 'pending'">
                <button
                  class="btn btn-green"
                  (click)="companyVarify(job, 'approved')"
                >
                  Approve</button
                >&nbsp;
                <button
                  class="btn red rejected"
                  (click)="companyVarify(job, 'rejected')"
                >
                  Reject
                </button>
              </td>
              <!-- <td>
                                <button class="btn btn-blue">Not Applied</button>
                                <button class="btn btn-gray">Not Applied</button>
                            </td> -->
              <td *ngIf="job.approvedStatus == 'approved'">
                <button class="btn btn-green" [disabled]="true">
                  {{
                    job.approvedStatus[0].toUpperCase() +
                      job.approvedStatus.slice(1)
                  }}
                </button>
              </td>
              <td *ngIf="job.approvedStatus == 'rejected'">
                <button class="btn red rejected" [disabled]="true">
                  {{
                    job.approvedStatus[0].toUpperCase() +
                      job.approvedStatus.slice(1)
                  }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="no-job-found"
        *ngIf="CompaniesResult.length == '0'"
        style="display: hidden"
      >
        <h4>No job found</h4>
      </div>
    </div>
  </div>
</div>
