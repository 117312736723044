<div class="parent">
  <header>
    <img
      class="banner-img"
      src="../../../../assets/images/cv-review-banner.png"
      alt="Banner Image"
    />
  </header>

  <div class="card-row">
    <div class="card" (click)="cardClick('nysc_special_offer')">
      <div class="card-shape bg-green"></div>
      <h6>NYSC Special Offer</h6>
      <p>
        This package caters for Serving/Just passing out National Youth Copper
        Member and entry level candidate with 1-2 years experience
      </p>
      <h2>
        <img
          src="../../../../assets/images/money-icon-green.svg"
          alt="Icon"
          width="24"
          height="24"
        />
        15,000
      </h2>
      <p class="title green">Turnaround Time</p>
      <p class="text-large">
        You will get the first draft of your Reviewed CV after
        <span class="bold"> 3 days</span> of request submission
      </p>
      <p class="title green">Revision and Feedback</p>
      <p class="text-large">
        You can give us feedback on your first draft, and we`ll make the needed
        adjustments. Up to 3 revisions.
      </p>
    </div>

    <div class="card" (click)="cardClick('mid_senior_level_review')">
      <div class="card-shape bg-yellow"></div>
      <h6 class="dark-yellow">Mid/Senior Level CV Review</h6>
      <p>
        This package we will
        <span class="bold">Review and Rewrite CV only </span> for Mid/Senior
        Level i.e Candidate with over 3 years of experience
      </p>
      <h2 class="dark-yellow">
        <img
          src="../../../../assets/images/money-icon-yellow.svg"
          alt="Icon"
          width="24"
          height="24"
        />
        25,000
      </h2>
      <p class="title dark-yellow">Turnaround Time</p>
      <p class="text-large">
        You will get the first draft of your Reviewed CV after
        <span class="bold"> 3 days </span> of request submission
      </p>
      <p class="title dark-yellow">Revision and Feedback</p>
      <p class="text-large">
        You can give us feedback on your first draft, and we`ll make the needed
        adjustments. Up to 3 revisions.
      </p>
    </div>

    <div class="card" (click)="cardClick('cv_cover_letter_review')">
      <div class="card-shape bg-orange"></div>
      <h6 class="orange">CV + Cover Letter Review</h6>
      <p>
        With this package we will review and re-write you
        <span class="bold">CV and cover letter </span> to meet the global
        standard
      </p>
      <h2 class="orange">
        <img
          src="../../../../assets/images/money-icon-orange.svg"
          alt="Icon"
          width="24"
          height="24"
        />
        40,000
      </h2>
      <p class="title orange">Turnaround Time</p>
      <p class="text-large">
        You will get the first draft of your Reviewed CV after
        <span class="bold"> 3 days </span> of request submission
      </p>
      <p class="title orange">Revision and Feedback</p>
      <p class="text-large">
        You can give us feedback on your first draft, and we`ll make the needed
        adjustments. Up to 3 revisions.
      </p>
    </div>
  </div>
</div>
