import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { MapsAPILoader } from '@agm/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpIOService } from 'src/app/services/http-io.service';
import { Helper } from 'src/app/helper/defult';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { EmployerService } from 'src/app/services/employer.service';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as currency from '../../../../assets/jsons/currency.json';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

declare var google: any;

@Component({
  selector: 'app-emp-editjob',
  templateUrl: './emp-editjob.component.html',
  styleUrls: ['./emp-editjob.component.scss'],
})
export class EmpEditjobComponent implements OnInit {
  nullJob: boolean = true;
  name = 'shaw';
  cityRecords: any;
  currencies: any = (currency as any).default;
  jobCatagoryName: any;
  // @ViewChild('search1') search1: ElementRef;
  // @ViewChild('search2') search2: ElementRef;
  locationName: any;
  lat: any;
  lng: any;
  zoom: number;
  userData: any;
  reqMsg = '';
  htmlContent = '';
  htmlContent2 = '';
  editJob: UntypedFormGroup;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        // 'link',
        // 'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        // 'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  employemntTypeList: any;
  jobCatagoryTypeList: any;
  applyValue: any;
  public minExp: any[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  public maxExp: any = [];
  isDescription: boolean = false;
  isChoseApply: boolean = false;
  isAbout: boolean = false;
  userDeatils: any;
  jobLists: any;
  detailsOfJob: any;
  jobId: any;
  minmum: any;
  companyData: any;
  jobLocationNames: any;
  jobCityId: any;
  akhariLocation: string;
  employementTypeID: any;
  LocationDropShowValue: any;
  minExperiancevalue: any;
  countData: any = '';
  externalUrl: any;
  viaEmail: any;
  filteredLocation: Observable<any[]>;
  filteredCategory: Observable<any[]>;
  logoUploadBehalfOfCompanyError: Boolean = false;
  company_behalf_logo: String;
  dataReceived: any;
  // @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = '';
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private employerService: EmployerService
  ) {
    this.activeRoute.queryParams.subscribe((event) => {
      this.jobId = event['id'];
      this.dataReceived = event['popup'];
    });

    let localUser = localStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    } else {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    if (this.jobId) {
      // this.getJobDetailsById();
      this.getJobDetailsById().then((res: any) => {
        this.patchData();
      });
    }
    this.cityLIst();
    this.jobCatagory();
    this.employementType();
    this.activityLog();
    this.validationForm();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        console.log(file);
        this.myfilename += file.name;
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // Return Base64 Data URL
          const imgBase64Path = e.target.result;
        };
      };
      this.uploadLogoBehalfOfCompany(fileInput.target.files[0]);
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      // this.uploadFileInput.nativeElement.value = '';
    } else {
      this.myfilename = 'No file selected';
    }
  }

  async uploadLogoBehalfOfCompany(file) {
    const formData = new FormData();

    formData.append('path', 'upload-file');
    formData.append('file', file);
    formData.append('purpose', 'companyBehalfJobLogoUpload');
    await this.io._uploadFile(formData).subscribe(
      (res: any) => {
        if (res.serverResponse.code == 200) {
          this.company_behalf_logo = res.result.documentUrl;
          this.logoUploadBehalfOfCompanyError = false;
        }
      },
      (err) => console.log(err)
    );
  }
  // GET JOB DETAILS BY JOBID //

  getJobDetailsById() {
    return new Promise((resolve, reject) => {
      let payLoad = {
        path: 'get-job',
        id: this.jobId,
      };

      this.io.apicall(payLoad, 'job/get-job').subscribe((res: any) => {
        console.log(res);
        if (res.serverResponse.code == 200) {
          this.detailsOfJob = res['result'].job;
          console.log("details of job: ", this.detailsOfJob);
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
        resolve(this.detailsOfJob);
      });
    });
  }

  patchData() {
    this.minExperiancevalue = this.detailsOfJob.minimum_experience;
    this.rangeValidation();
    if (this.detailsOfJob.location_type == 'NIGERIA') {
      this.editJob.controls['location'].enable();
    } else {
      this.editJob.controls['location'].disable();
    }
    this.LocationDropShowValue = this.detailsOfJob.location_type;
    this.company_behalf_logo = this.detailsOfJob.company_behalf_logo;
    this.editJob.patchValue({
      jobTitle: this.detailsOfJob.title,
      location: this.detailsOfJob.job_location,
      location_type: this.detailsOfJob.location_type,
      employementTypes: this.detailsOfJob.employment_type,
      jobCategoryTypes: this.detailsOfJob.job_category,
      currency: this.detailsOfJob.currency,
      minimumSalary: this.detailsOfJob.minimum_salary.toString(),
      maximumSalary: this.detailsOfJob.maximum_salary.toString(),
      maxexprience: this.detailsOfJob.maximum_experience,
      minexprience: this.detailsOfJob.minimum_experience,
      campanyName: this.detailsOfJob.company_name,
      company_behalf_name: this.detailsOfJob.company_behalf_name,
      company_behalf_logo: this.detailsOfJob.company_behalf_logo,
      // featureJob: this.detailsOfJob.is_featured,
      city: this.detailsOfJob.city,
    });
    this.htmlContent = this.detailsOfJob.description;
    this.htmlContent2 = this.detailsOfJob.about;
    this.applyValue = this.detailsOfJob.apply_via;
    this.jobCityId = [{ id: this.detailsOfJob.city_id }];
    this.jobCatagoryName = [{ id: this.detailsOfJob.category_id }];
    this.employementTypeID = [{ id: this.detailsOfJob.employment_type_id }];
    this.akhariLocation = this.detailsOfJob.job_location;
  }

  rangeValidation() {
    this.maxExp = [];
    let minexprience = this.minExperiancevalue;
    for (let i = parseInt(minexprience); i <= 15; i++) {
      this.maxExp.push(i + 1);
    }
  }

  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    if (dateDiff === 0) {
      return 'today';
    } else if (dateDiff === 1) {
      return 'yesterday';
    } else {
      return `${dateDiff} days ago`;
    }
  }

  // Insilize Form //
  validationForm() {
    this.editJob = this.fb.group({
      jobTitle: ['', Validators.required],
      location: ['', Validators.required],
      location_type: ['', Validators.required],
      employementTypes: ['', Validators.required],
      jobCategoryTypes: ['', Validators.required],
      currency: ['', Validators.required],
      minimumSalary: ['', Validators.required],
      maximumSalary: [''],
      minexprience: ['', Validators.required],
      maxexprience: [''],
      campanyName: [this.detailsOfJob?.companyName, Validators.required],
      company_behalf_name: [''],
      company_behalf_logo: [''],
      description: ['', Validators.required],
      about: ['', Validators.required],
      via_email: [''],
      external_url: [''],
      featureJob: [''],
      city: [''],
    });
  }

  // Validation Controls //
  get f() {
    return this.editJob.controls;
  }

  // Employement Type //
  employementType() {
    this.io
      .apicall({ path: 'employment-type-list' }, 'job/employment-type-list')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.employemntTypeList = res['result'].employmentList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  // Change DropDown Og EmploymentType //
  employmentTypeChange(e) {
    this.employementTypeID = this.employemntTypeList.filter(
      (name) => name.employmentType == e
    );
  }

  // Job Catogery //
  jobCatagory() {
    this.io
      .apicall({ path: 'category-list' }, 'job/category-list')
      .subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.jobCatagoryTypeList = res['result'].categoryList;
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    setTimeout(() => {
      this.filteredCategory =
        this.editJob.controls.jobCategoryTypes.valueChanges.pipe(
          map((row) => this._filterCategory(row))
        );
    }, 3000);
  }

  private _filterCategory(value: any) {
    // console.log(value)
    const filterValue = value.toLowerCase();
    // console.log(this.jobCatagoryTypeList.filter((state: any) => state.categoryName.toLowerCase().includes(filterValue)))
    return this.jobCatagoryTypeList.filter((state: any) =>
      state.categoryName.toLowerCase().includes(filterValue)
    );
  }

  // Change Drop Down Of Job category //
  jobCategoryChange(e) {
    // console.log(e);
    this.jobCatagoryName = this.jobCatagoryTypeList.filter(
      (jobname) => jobname.categoryName == e
    );
    console.log(this.jobCatagoryName);
  }

  // CIty List //
  cityLIst() {
    let data = { path: 'city-list' };
    this.io._cityList(data).subscribe((res: any) => {
      // console.log(res)
      if (res.serverResponse.code == 200) {
        this.cityRecords = res['result'].cityList;
        // console.log(this.cityRecords)
      }
    });
    setTimeout(() => {
      this.filteredLocation = this.editJob.controls.location.valueChanges.pipe(
        startWith(''),
        map((row) => this._filterLocation(row))
      );
    }, 3000);
  }
  private _filterLocation(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.cityRecords.filter((row: any) =>
      row.state.toLowerCase().includes(filterValue)
    );
  }

  joblocationName(e) {
    this.jobLocationNames = this.cityRecords.filter(
      (jobname) => jobname.state == e
    );
    // console.log(this.jobLocationNames);
    this.jobCityId = this.jobLocationNames;
    this.akhariLocation = `${this.jobLocationNames[0].state}`;
    // console.log(this.akhariLocation);
  }

  apply_at_external_site: boolean = false;
  apply_via_email: boolean = false;
  // Chosse Apply method //
  applyJobs(event: MatRadioChange, flag) {
    console.log("event job selected: ", event)
    this.applyValue = flag;

    this.apply_at_external_site = this.applyValue == 2;
    this.apply_via_email = this.applyValue == 3;

    this.editJob.controls['external_url'].setValidators(
      this.apply_at_external_site ? [Validators.required] : null
    );
    this.editJob.controls['via_email'].setValidators(
      this.apply_via_email ? [Validators.required] : null
    );

    this.editJob.controls['external_url'].updateValueAndValidity();
    this.editJob.controls['via_email'].updateValueAndValidity();
    this.editJob.patchValue({
      external_url: this.apply_at_external_site
        ? ''
        : this.editJob.value.external_url,
      via_email: this.apply_via_email ? '' : this.editJob.value.via_email,
    });
  }

  //location Dropdown show //
  locationDropEvent(event: MatRadioChange) {
    this.LocationDropShowValue = event.value;
    if (event.value == 'NIGERIA') {
      // console.log(event.value)
      this.editJob.controls['location'].enable();
    } else {
      this.editJob.controls['location'].disable();
      this.editJob.value.location = event.value;
    }
  }

  addJob() {
    if (this.editJob.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.editJob);
      return false;
    }
    if (this.editJob.get('maximumSalary').value != '') {
      // if(this.editJob.get('minimumSalary').value > this.editJob.get('maximumSalary').value){
      if (
        Number(this.editJob.get('minimumSalary').value) >
        Number(this.editJob.get('maximumSalary').value)
      ) {
        // this.snackBar.open("' Salary To' needs to be greater ", "OK");
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: "' Maximum Salary' should be greater than minimum salary",
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        return false;
      }
    }
    if (!this.htmlContent) {
      this.isDescription = true;
      return false;
    }
    if (!this.htmlContent2) {
      this.isAbout = true;
      return false;
    }
    if (!this.applyValue) {
      this.isChoseApply = true;
      return false;
    }

    if (this.editJob.value.location_type == 'NIGERIA') {
      let locationValue = this.cityRecords.filter(
        (jobname) => jobname.state == this.editJob.value.location
      );
      if (locationValue.length == 0) {
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: 'Please select a state from list',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        return false;
      }
    }
    let CategoryValue = this.jobCatagoryTypeList.filter(
      (jobname) => jobname.categoryName == this.editJob.value.jobCategoryTypes
    );
    if (CategoryValue.length == 0) {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please select a category from list',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      return false;
    }
    let payload = {
      path: 'update-job',
      title: this.editJob.value.jobTitle,
      description: this.htmlContent,
      company_name: this.editJob.value.campanyName,
      company_behalf_name: this.editJob.value.company_behalf_name,
      company_behalf_logo: this.company_behalf_logo,
      logo: this.companyData?.companyLogo
        ? this.companyData?.companyLogo
        : environment.WNSquareCompanyLogo,
      city_id:
        this.editJob.value.location == undefined || ''
          ? this.LocationDropShowValue
          : this.jobCityId[0].id,
      employment_type: this.editJob.value.employementTypes,
      employment_type_id: this.employementTypeID[0].id,
      location_type: this.editJob.value.location_type,
      job_category: this.editJob.value.jobCategoryTypes,
      category_id: this.jobCatagoryName[0].id,
      about: this.htmlContent2,
      currency: this.editJob.value.currency,
      minimum_salary: this.editJob.value.minimumSalary.toString(),
      maximum_salary: this.editJob.value.maximumSalary.toString(),
      minimum_experience: this.editJob.value.minexprience.toString(),
      maximum_experience: this.editJob.value.maxexprience.toString(),
      company_id: this.detailsOfJob.company_id,
      job_provider_id: this.detailsOfJob.job_provider_id,
      id: this.detailsOfJob.id,
      job_location:
        this.editJob.value.location != undefined || ''
          ? this.akhariLocation
          : '',
      job_type: this.detailsOfJob.job_type,
      apply_via: this.applyValue,
      created_at: this.detailsOfJob.created_at,
      // "is_featured": this.editJob.value.featureJob,
      external_url: this.editJob.value.external_url,
      via_email: this.editJob.value.via_email,
      city: this.editJob.value.city,
    };
    this.io.apicall(payload, 'job/update-job').subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'from dashboard add job',
          },
        });
        dialogRef.afterClosed().subscribe();
      } else {
        // this.dialogRef.close();
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  cancelJob() {
    this.router.navigate(['Dashboard'])
  }
  //ACTIVE OR INACTIVE SLIDE //
  activeInactive(job, index) {
    // console.log(job);
    let jobStatus = job.job_status;
    if (jobStatus === 'active') {
      let jsonData = {
        path: 'inactive-job',
        id: job.id,
      };
      this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => {
        // console.log(res);
        this.jobLists[index].job_status = res['result'].job.job_status;
      });
    } else {
      let jsonData = {
        path: 'active-job',
        id: job.id,
      };
      this.io._activeForEmpManage(jsonData).subscribe((res: any) => {
        // console.log(res);
        this.jobLists[index].job_status = res['result'].job.job_status;
      });
    }
  }

  async activityLog() {
    // userRole: "company_admin"
    console.log(this.userDeatils.userRole);
    if (
      this.userDeatils.userRole == 'company_admin' ||
      this.userDeatils.userType == 'super_admin'
    ) {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        company_id: this.companyData.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.countData = res.countData;
          }
        });
    } else {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        provider_id: this.userDeatils.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.countData = res.countData;
          }
        });
    }
  }

  async routeToAddJob() {
    let checkEmailValid = await this.io._checkEmailValid();
    // console.log(checkEmailValid)
    if (checkEmailValid == true) {
      this.router.navigate(['post-job']);
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }
}
