import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-emp-dash-preview',
  templateUrl: './emp-dash-preview.component.html',
  styleUrls: ['./emp-dash-preview.component.scss']
})
export class EmpDashPreviewComponent {

  selectedJobsList: any;
  selectedJobsListIds: any;
  creditBreakup: any;
  jobIds: any;
  jobOverview: boolean = false;
  subscriptionPlan: string;
  userAssets: any;
  constructor(
    public dialogRef: MatDialogRef<EmpDashPreviewComponent>,
    private employerService: EmployerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.selectedJobsList = this.data['filteredJobs'];
    this.creditBreakup = this.data['creditBreakup'] || {};
    this.subscriptionPlan = this.data['subscriptionPlan'] || 'Silver'
    this.jobOverview = this.data['jobOverview'] || false;
    this.jobIds = this.data['jobIds'] || [];
  }

  onNoClickClose() {
    this.dialogRef.close(this.selectedJobsList.map(job => job.id) );
  }

  truncateTitle(campaign: string, wordLimit: number): string {
    const words = campaign.split(' ');
    if (words.length > wordLimit) {
      const truncatedWords = words.slice(0, wordLimit);
      return truncatedWords.join(' ') + '...';
    }
  }

  locationType(loc: any) {
    switch (loc) {
      case 'REMOTE':
        return 'Remote';
      case 'OUT_OF_COUNTRY':
        return 'Out of Country';
      case 'NIGERA':
        return 'Onsite';
    }
  }

  formatTitle(jobTitle: string) {
    return jobTitle
      .toLowerCase()
      .split(' ') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '); 
  }

  deleteAJob(jobId: any) {
    this.selectedJobsList = this.selectedJobsList.filter(job => job.id !== jobId);
  }

  postCampaign(): void {
    this.employerService._postCampaign({...this.data, deductFromWallet: true}).subscribe((res) => {
      console.log(res['serverResponse'])
      if (res['serverResponse']['code'] == 200) {
        this.dialogRef.close('success')
      }else{
        console.log('failed')
      }
    });
  }
}
