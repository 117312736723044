import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emp-billing',
  templateUrl: './emp-billing.component.html',
  styleUrls: ['./emp-billing.component.scss'],
})
export class EmpBillingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
