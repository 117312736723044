import {
  Directive,
  Injectable,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  HostListener,
} from '@angular/core';
import { EmployerService } from '../../services/employer.service';
@Directive({
  selector: '[appScrollspy]',
})
export class ScrollspyDirective {
  lastOffsetvalue: any = 1000;
  @Input() public spiedTags = [];
  @Output() public sectionChange = new EventEmitter<string>();
  private currentSection: string;

  constructor(
    private _el: ElementRef,
    private EmployerService: EmployerService
  ) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const scrollTop = event.target.scrollTop;
    if (scrollTop > this.lastOffsetvalue) {
      this.lastOffsetvalue = this.lastOffsetvalue + 1500;
      this.EmployerService.setloadMoreInSearchJob('loadJobInSearchPage');
    }
  }
}
