import { Injectable } from '@angular/core';
import * as introJs from 'intro.js';

@Injectable({ providedIn: 'root' })
export class IntroJSService {
  introJS = null;

  companyVerification() {
    localStorage.setItem('companyVerificationTour', '1')
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        showBullets: false,
        steps: [
          {
            element: document.querySelector('.verified'),
            intro: 'Verify your company here to establish trust and credibility with potential hires.',
            position: 'bottom'
          },
        ]
      })
      .start();
  }

  jobPost() {
    localStorage.setItem('jobPostTour', '1')
    let obj = {}
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        hidePrev: true,
        doneLabel: 'Start',
        showBullets: false,
        steps: [
          {
            element: document.querySelector('.btn-post-job'),
            intro: `Click here to create a job listing and attract top talent. We'll guide you through the process step-by-step.`,
            position: 'left'
          }
        ]
      })
      .onafterchange(function(targetElement) {
        obj[this._currentStep] = targetElement
        if(this._currentStep === 1){
          obj[0].click()
        }
      })
      .oncomplete(function(x) {
        obj[x].click()
      })
      .start();
  }

  jobPostDetailed() {
    localStorage.setItem('jobPostDetailedTour', '1')
    let obj = {}
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        hidePrev: true,
        showProgress: true,
        disableInteraction: true,
        steps: [
          {
            element: document.querySelector('.jt'),
            intro: `Enter job title`,
            position: 'right'
          },
          {
            element: document.querySelector('.jd'),
            intro: `Enter job description and every other details about the job that the job seeker needs to be aware of`,
            position: 'right'
          },
          {
            element: document.querySelector('.jq'),
            intro: `Enter a detailed job qualification requirement`,
            position: 'right'
          },
          {
            element: document.querySelector('.ja'),
            intro: `Select how you want job seekers to apply for this job`,
            position: 'right'
          },
          {
            element: document.querySelector('.js'),
            intro: `Click on the save button to publish your job!`,
            position: 'right'
          },
        ]
      })
      .onbeforechange(function(targetElement) {
        obj[this._currentStep] = targetElement
        if(this._currentStep === 1){
          obj[0].click()
        }
      })
      .start();
  }

  jobManagement() {
    localStorage.setItem('jobManagement', '1')
    let obj = {}
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        hidePrev: true,
        showProgress: true,
        disableInteraction: true,
        steps: [
          {
            intro: `Easily manage your job postings. Edit job, review applications, share jobs, promote jobs and communicate with candidates all in one place`,
            nextLabel: 'Start tour'
          },
          {
            element: document.querySelector('.share'),
            intro: `Share jobs on social media`,
            position: 'right'
          },
          {
            element: document.querySelector('.promote'),
            intro: `Promote jobs`,
            position: 'right'
          },
          {
            element: document.querySelector('.edit'),
            intro: `Edit jobs`,
            position: 'right'
          },
          {
            element: document.querySelector('.toggle'),
            intro: `Click on the Active button toggle to active or deactivate a job. When you toggle to the right, Job is active and when you toggle to the left, Job is inactive!`,
            position: 'right'
          },
        ]
      })
      .onbeforechange(function(targetElement) {
        obj[this._currentStep] = targetElement
        if(this._currentStep === 1){
          obj[0].click()
        }
      })
      .start();
  }

  jobOverview() {
    localStorage.setItem('jobOverviewTour', '1')
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        hidePrev: true,
        disableInteraction: true,
        steps: [
          {
            intro: `From here you can get a comprehensive view of your active job postings, applications, and candidate statuses.`,
          }
        ]
      })
      .start();
  }

  promoteJob() {
    localStorage.setItem('promoteJobTour', '1')
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        showBullets: false,
        steps: [
          {
            intro: 'Boost the visibility of your job posting and attract more qualified candidates with our promotion options.',
          },
        ]
      })
      .start();
  }

  cvSearch() {
    localStorage.setItem('cvSearchTour', '1')
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        showBullets: false,
        steps: [
          {
            intro: 'Explore our database of qualified candidates. Use filters to narrow your search and find the perfect fit for your open positions.',
          },
        ]
      })
      .start();
  }

  subscriptionAndBundles() {
    localStorage.setItem('subscriptionsTour', '1')
    this.introJS = introJs.default();
    this.introJS
      .setOptions({
        tooltipClass: 'customTooltip',
        showBullets: false,
        steps: [
          {
            intro: 'Choose a subscription plan that suits your hiring needs. Upgrade for more features and reach a wider pool of talent.',
          },
        ]
      })
      .start();
  }

}
