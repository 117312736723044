import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobseekerRoutingModule } from './jobseeker-routing.module';
import { JobseekerAppliedComponent } from './jobseeker-applied/jobseeker-applied.component';
import { JobseekerSaveJobComponent } from './jobseeker-save-job/jobseeker-save-job.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RenameCollectionComponent } from './rename-collection/rename-collection.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { JobAlertsComponent } from './job-alerts/job-alerts.component';
import { MaterialModule } from '../../modules/material/material.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { CreateAlertComponent } from './create-alert/create-alert.component';
import { JobSeekerProfileComponent } from './job-seeker-profile/job-seeker-profile.component';
import { JsExperianceComponent } from './js-experiance/js-experiance.component';
import { JsEditProfileComponent } from './js-edit-profile/js-edit-profile.component';
import { JsEducationComponent } from './js-education/js-education.component';
import { JsLicenseOrCertificateComponent } from './js-license-or-certificate/js-license-or-certificate.component';
import { JsSkillComponent } from './js-skill/js-skill.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FirstProfileOptionComponent } from './first-profile-option/first-profile-option.component';
import { JobseekerShareProfileComponent } from './jobseeker-share-profile/jobseeker-share-profile.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { NgTinyUrlModule } from 'ng-tiny-url';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxPrintModule } from 'ngx-print';
import { CvPurserViewProfileComponent } from './cv-purser-view-profile/cv-purser-view-profile.component';
import { CollectionCreateComponent } from './collection-create/collection-create.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

export const options: Partial<IConfig> = {
  thousandSeparator: "'",
};
@NgModule({
  declarations: [
    JobseekerAppliedComponent,
    JobseekerSaveJobComponent,
    RenameCollectionComponent,
    JobAlertsComponent,
    CreateAlertComponent,
    JobSeekerProfileComponent,
    JsExperianceComponent,
    JsEditProfileComponent,
    JsEducationComponent,
    JsLicenseOrCertificateComponent,
    JsSkillComponent,
    FirstProfileOptionComponent,
    JobseekerShareProfileComponent,
    CvPurserViewProfileComponent,
    CollectionCreateComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(options),
    NgxPrintModule,
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    SharedModule,
    JobseekerRoutingModule,
    ShareIconsModule,
    ShareButtonsModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatChipsModule,
    AngularEditorModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class JobseekerModule {}
