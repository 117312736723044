import { DatePipe } from '@angular/common';
import { IfStmt } from '@angular/compiler';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Inject,
  LOCALE_ID,
  HostListener
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
// import { SNSClient, ListSubscriptionsByTopicCommand, SubscribeCommand } from "@aws-sdk/client-sns";
import {
  KinesisClient,
  GetShardIteratorCommand,
  GetRecordsCommand,
} from '@aws-sdk/client-kinesis';
import { SNSClient, PublishCommand } from '@aws-sdk/client-sns';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @ViewChild('scrollMe', { static: false }) myScrollContainer!: ElementRef;

  dynamicClass: boolean = false;
  creds = {
    accessKeyId: 'AKIAYWWKI6TGOE7DIR7M',
    secretAccessKey: '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',
  };
  config = { region: 'us-east-1', credentials: this.creds };
  client = new SNSClient(this.config);
  userDeatils: any;
  companyData: any;
  Sujected_id: any;
  Sujected_firstName: any;
  Sujected_lastName: any;
  profileRecords: any;
  chatRoomsList: any;
  rightSideView: boolean = false;
  status: any;
  writeText: any = '';
  getRoomId: any;
  chatHistory: any = [];
  responseMessage: any;
  selectedPeopleInCVSearch: any;
  companyName: any;
  roomIDForLeaveApi: any;
  count: number = 1;
  selectedRoom: any;
  rowColorsMap = new Map<string, string>();
  public_ID: any;
  date: any = new Date();
  searchForm: UntypedFormGroup;
  attachments: any = [];
  websocket: any;
  lastSentMessage: any = {};
  selectedChatIndex: number = 0;
  chatOnMobile = false;
  suggestedMessagesOpened = false;
  suggestedMessagesEmployer = [];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper,
    private dialog: MatDialog,
    private empService: EmployerService,
    public datepipe: DatePipe,
    @Inject(LOCALE_ID) public locale: string
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    this.empService.getforSeletedIn_cvSearch().subscribe((res) => {
      this.selectedPeopleInCVSearch = res;
    });
    this.empService.getforMessageWSconnect().subscribe((res) => {
      if (res) {
        this.onWNMessageReceived(res);
      }
    });
  }

  ngOnInit(): void {
    this.getChatRooms();
    this.getSuggestedMessages();
    this.WSconnect();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth > 532) {
      this.chatOnMobile = false;
    }
  }

  goToCV() {
    this.router.navigate(['/Dashboard']);
  }

  goToChat() {
    this.chatOnMobile = false;
    this.writeText = ''
  }

  selectChatRoom(data, index = 0) {
    this.selectedChatIndex = index
    this.writeText = ''
    if (this.selectedRoom) {
      let jsonData = {
        room_id: this.selectedRoom.room_id,
        user_id: this.userDeatils.id,
        path: 'leave-message',
      };
      this.empService._leaveMessage(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
        }
      });
    }
    this.selectedRoom = data;
    this.status = data.user_id_belongs;
    this.dynamicClass = true;
    this.rightSideView = true;
    if ( window.innerWidth < 531 ) {
      this.chatOnMobile = true;
    }
    // ------------   on seen api  ---------
    this.getMessage(data).then((res: any) => {
      if (res.length !== 0) {
        let allMessage = res.at(-1).message_id;
        this.onSeen(data.room_id, allMessage);
      }
    });
    if (this.userDeatils.userType == 'jobseeker') {
      let jsonData2 = {
        id: data.user_id_belongs,
        path: 'get-user',
      };
      this.io._JSallDetails(jsonData2).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.companyName = res.result.companyData.companyName;
        }
      });
    } else {
      let jsonData2 = {
        id: data.user_id_belongs,
        path: 'get-user',
      };
      this.io._JSallDetails(jsonData2).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.public_ID = res.result.userData.publicId;
          // this.companyName = res.result.companyData.companyName;
          // this.url = `https://d2c7gcaexouhqs.cloudfront.net/profile?id=${this.record.publicId}`;
        }
      });
    }
    // this.ngOnDestroy();
  }

  wnProfileOpen() {
    if (this.userDeatils?.userType == 'employer') {
      window.open(`${window.location.origin}/profile?id=${this.public_ID}`);
    }
  }

  dynamicClassRemove() {
    this.dynamicClass = false;
    this.rightSideView = false;
    this.status = '';
    this.ngOnDestroy();
  }

  async onWNMessageReceived(data) {
    this.snackBar
      .openFromComponent(DynamicSnacbarComponent, {
        data: 'You have a new  message',
        verticalPosition: 'top',
        horizontalPosition: 'right',
      })
      .afterOpened()
      .subscribe(this.helper.setOnTop);

    this.responseMessage = JSON.parse(data.data);
    if (data) {
      // this.getChatRooms();
      if (this.selectedRoom) {
        if (this.selectedRoom.room_id == this.responseMessage.room_id) {
          //this.getMessage(this.selectedRoom);
          this.chatHistory.push(JSON.parse(data.data))
        }
      }
    }
    this.empService.setforMessageWSconnect(null);
  }

  async getChatRooms() {
    let jsonData = {
      path: 'get-chat-rooms',
      user_id: this.userDeatils.id,
    };
    await this.empService._getChatRooms(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        if (res['result']) {
          this.chatRoomsList = res['result'];
        }
        if (this.selectedPeopleInCVSearch) {
          this.searchChatRoom();
        } else if (this.chatRoomsList?.length > 0) {
          // this.selectChatRoom(this.chatRoomsList[0]);
        }
      }
    });
  }
/**
 * Retrieves suggested messages for employers from the service and updates the component state.
 */
  async getSuggestedMessages() {
    let jsonData = {
      path: 'suggested-messages'
    };
    await this.empService._getSuggestedMessages(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.suggestedMessagesEmployer = res.serverResponse.messages
      }
    });
  }

  async searchChatRoom() {
    let index = this.chatRoomsList.findIndex(
      (element) => element.user_id_belongs == this.selectedPeopleInCVSearch.id
    );
    if (index < 0) {
      this.createChatRoom();
    } else {
      this.selectChatRoom(this.chatRoomsList[index], index);
    }
  }

  async getMessage(data) {
    // console.log(data)
    return new Promise((resolve, reject) => {
      let jsonData = {
        path: 'get-messages',
        room_id: data.room_id,
      };
      this.empService._getMessage(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // this.onSeen(data.room_id, res['result'])
          this.chatHistory = res['result'].reverse();
        }
        resolve(this.chatHistory);
      });
    });
  }

  convertBytes(sizeInBytes) {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (sizeInBytes < kilobyte) {
      return sizeInBytes + ' B';
    } else if (sizeInBytes < megabyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + ' KB';
    } else {
      return (sizeInBytes / megabyte).toFixed(2) + ' MB';
    }
  }

  truncateLongFileName(fileName, maxLength = 20) {
    if (fileName.length <= maxLength) {
      return fileName;
    }

    const extension = fileName.split('.').pop();
    const middleLength = maxLength - 8 - extension.length;

    const startIndex = 0;
    const endIndex = fileName.lastIndexOf('.') - 1;
    const start = fileName.substring(startIndex, Math.min(startIndex + 4, endIndex));
    const end = fileName.substring(Math.max(endIndex - 3, 0), endIndex + 1);

    const middle = fileName.substring(startIndex + start.length, endIndex).slice(0, middleLength) + '...';
    return `${start}${middle}${end}.${extension}`;
  }

  downloadFile(link: string) {
    window.open(link, '_blank');
  }

  randomColor(row: any): string {
    if (!this.rowColorsMap.has(row.room_name)) {
      const colors = ['#DEDD82', '#3EAB70', '#FC6748', '#F7C838', '#78E378'];
      const randomIndex = Math.floor(Math.random() * colors.length);
      const color = colors[randomIndex];
      this.rowColorsMap.set(row.room_name, color);
    }

    return this.rowColorsMap.get(row.room_name);
  }

  onSelectFile(event) {
    this.writeText = ''
    let selectedDocs = [];
    let fileName = event.target.files[0].name;
    let fileToUpload = null
    let uploadFileResult = null
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      const formData = new FormData();
      for (let i = 0; i < filesAmount; i++) {
        fileToUpload = event.target.files[i];
        formData.append('file', fileToUpload);
      }
      formData.append('documentType', 'chat-attachment');
      formData.append(
        'userId',
        this.userDeatils.id
      );
      formData.append('path', 'upload-file');
      formData.append('documentName', fileName);
      formData.append('purpose', 'chat');
      formData.append('room', this.selectedRoom?.room_id)
      this.io._uploadFile(formData).subscribe(
        (res: any) => {
          if (res.serverResponse.code == 200) {
            uploadFileResult = res['result'];
            this.attachments.push({
              'name': res['result'].fileName,
              'url': res['result'].documentUrl,
              'size': res['result'].originalSize
            })
          }
        },
        (err) => console.log(err)
      );
    }
  }

  cancelAttachment(index) {
    this.attachments.splice(index, 1);
  }

  async createChatRoom() {
    if (this.selectedPeopleInCVSearch.id) {
      let jsonData = {
        path: 'create-chat-room',
        user_id: this.userDeatils.id,
        user_id_belongs: [this.selectedPeopleInCVSearch.id],
        room_name: `${this.selectedPeopleInCVSearch.firstName} ${this.selectedPeopleInCVSearch.lastName}`,
        room_type: 'single',
        room_image: this.selectedPeopleInCVSearch.profileImage,
        message: {},
      };
      await this.empService._createChatRoom(jsonData).subscribe((res: any) => {
        // console.log(res)
        if (res.serverResponse.code == 200) {
          this.selectedChatIndex = res.result.findIndex(item => item.user_id_belongs === this.selectedPeopleInCVSearch.id)
          this.chatRoomsList = res.result
          this.selectChatRoom(res.result[this.selectedChatIndex], this.selectedChatIndex)
          //this.getChatRooms();
          this.empService.setforSeletedIn_cvSearch(null)
        }
      });
    }
  }

  onSeen(room_id, lastMessageId: any) {
    let jsonData = {
      path: 'on-seen',
      room_id: room_id,
      user_id: this.userDeatils.id,
      message_id: lastMessageId, //error
    };
    this.empService._onSeenMessage(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res)
        let result = res.messageCount.userRespectiveCount;
        // console.log(result)
        this.empService.setMessageCountForOnSeenApi(result);
      }
    });
  }

  getShortName(fullName) {
    return fullName.charAt(0);
  }

  getIconSrc(fileName) {
  const fileExtension = fileName.split('.').pop().toLowerCase();
  switch (fileExtension) {
    case 'pdf':
      return '../../../assets/images/pdf-doc.svg';
    case 'doc':
    case 'docx':
      return '../../../assets/images/word-doc.svg';
    default:
      return '../../../assets/images/word-doc.svg';
  }
}

  async sendMessage() {
    let jsonData = {
      topic: 'send_messege',
      userId: this.userDeatils.id,
      room_id: this.selectedRoom.room_id,
      subject: 'CHAT',
      message: this.writeText ? this.writeText : '',
      message_type: 'text',
      sender_id: this.userDeatils.id,
      file_type: 'null',
      create_at: moment.utc().format(),
      attachments: this.attachments,
    };
    try {
      if (this.websocket.readyState === WebSocket.OPEN) {
        this.websocket.send(JSON.stringify(jsonData));
        this.writeText = '';
        this.attachments = []
        this.chatHistory.push(jsonData)
        this.chatRoomsList[this.selectedChatIndex].message.message = jsonData.message
      } else if ([WebSocket.CONNECTING, WebSocket.CLOSING].includes(this.websocket.readyState)) {
        setTimeout(() => {
          this.sendMessage()
        }, 500)
      } else {
        this.WSconnect();
      }
      // this.getMessage(this.selectedRoom);
      // this.getChatRooms();
    } catch (error) {
      console.log('err', error);
    }
  }

  private getNowUTC() {
    const now = new Date();
    return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  }

  transformTreeTime(value = '', format = 'h:mm a') {
    var datePipe = new DatePipe(this.locale);
    value = datePipe.transform(value, format);
    return value;
  }

  transformTreeDateTime(value = '', format = 'MMM d, h:mm a') {
    var datePipe = new DatePipe(this.locale);
    value = datePipe.transform(value, format);
    return value;
  }

  getFirstLetterAfterSpace(name: string): string {
    if (name && typeof name === 'string') {
      const spaceIndex = name.indexOf(' ');
      if (spaceIndex !== -1 && spaceIndex < name.length - 1) {
        return name[spaceIndex + 1];
      }
    }
    return '';
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

/**
 * Sets the message and closes the suggested messages panel.
 * @param {string} message - The message to be set.
 */
  setMessage(message: string): void {
    this.writeText = message
    this.suggestedMessagesOpened = false
  }
/**
 * Toggles the state of the suggested messages panel.
 * @returns {void}
 */
  toggleSuggestedMsgWindow(): void {
    this.suggestedMessagesOpened = !this.suggestedMessagesOpened
  }

  ngOnDestroy(): void {
    // console.log(this.selectedRoom)
    if (this.selectedRoom) {
      let jsonData = {
        room_id: this.selectedRoom.room_id,
        user_id: this.userDeatils.id,
        path: 'leave-message',
      };
      this.empService._leaveMessage(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          console.log(res);
        }
      });
    }
  }

  async WSconnect() {
    this.websocket = new WebSocket(environment.messagingWS);
    this.websocket.onopen = (data) =>
      this.onWSOpen(data).then((res: any) => {
        if (res) {
          this.WSsubscribe('chat_room');
          this.websocket.onmessage = (data) => this.onWNMessageReceived2(data);
          this.websocket.onclose = (data) => this.onWSClose(data);
          this.websocket.onerror = (data) => this.onWSError(data);
          this.empService.setforWSconnect(null);
          if (this.writeText || this.attachments.length) {
            this.sendMessage()
          }
        }
      });
  }

  async WSdiconnect() {
    this.websocket.close();
  }

  async onWSOpen(event) {
    console.log('Connection open', event);
    return new Promise((resolve, reject) => {
      console.log('Connection open', event);
      resolve(event);
    });
  }

  async onWSClose(event) {
    this.WSconnect()
    console.log('Connection close', event);
  }

  async onWSError(event) {
    this.WSconnect()
    console.log('Connection close', event);
  }

  async onWNMessageReceived2(data) {
    // console.log('here2');
    console.log(data);
    this.empService.setforMessageWSconnect(data);
  }

  async WSsubscribe(topic) {
    this.websocket &&
      this.websocket.send(
        JSON.stringify({
          message: 'subscribe',
          topic: topic,
          userId: this.userDeatils.id,
        })
      );
  }
}
