import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupConfirmationComponent } from '../popup-confirmation/popup-confirmation.component';
import { Helper } from 'src/app/helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DynamicSnacbarErrorComponent } from '../dynamic-snacbar-error/dynamic-snacbar-error.component';
import { MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-download-brochure-popup',
  templateUrl: './download-brochure-popup.component.html',
  styleUrls: ['./download-brochure-popup.component.scss']
})
export class DownloadBrochurePopupComponent implements OnInit {
  userDetailsForm: FormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  namePattern = /^[a-zA-Z]+$/;
  numberPattern = /^\d+$/;

  constructor(
    public dialogRef: MatDialogRef<DownloadBrochurePopupComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private helper: Helper,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.userDetailsForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.pattern(this.namePattern)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      path: ['/add-training-user-info', Validators.required],
    });
  }

  get f() {
    return this.userDetailsForm.controls;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  toContinue() {
    if (this.userDetailsForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.userDetailsForm);
      return false;
    }
    this.dialogRef.close();
    this.io._saveUserData(this.userDetailsForm.value).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        let successData = 'brochure success';
        const dialogOpen = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: '450px',
          data: {
            data: successData,
          },
        });
        dialogOpen.afterClosed().subscribe(() => {
        });
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    })
  }
}
