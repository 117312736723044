import { Component, OnInit, NgZone } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { EmployerService } from 'src/app/services/employer.service';
import { MatDialog } from '@angular/material/dialog';
import { HttpIOService } from 'src/app/services/http-io.service';
import { Helper } from 'src/app/helper/defult';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnInit {
  sandBoxForm: UntypedFormGroup;
  file: { name: string, progress: number, file: File, size: number } | null = null;
  fileToUpload = [];
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  namePattern = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
  jobRolePattern = /^(?! )[A-Za-z0-9\s]*$|^.*[^ ]$/;
  uploadText = "Uploading...";

  constructor(
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private empService: EmployerService,
    private snackBar: MatSnackBar,
    private helper: Helper,
    public dialog: MatDialog,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    this.formInit();
  }

  formInit() {
    this.sandBoxForm = this.fb.group({
      firstName: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      lastName: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      jobRole: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.jobRolePattern),
        ]),
      ],
    });

  }

  // Validation Controls
  get f() {
    return this.sandBoxForm.controls;
  }

  async saveCVs() {
    if (this.sandBoxForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.sandBoxForm);
      return false;
    }

    const formData = new FormData();
    formData.append('path', 'upload-file');
    formData.append('purpose', 'parseResume');
    formData.append('file', this.file.file);

    await this.io._uploadFile(formData).subscribe(
      (res: any) => {
        this.fileToUpload.push(res['result'].id + '_' + res['result'].fileName);
        // Takes the CV and parse it
        let payLoad = {
          path: "/public/resume-parser",
          firstName: this.sandBoxForm.value.firstName,
          lastName: this.sandBoxForm.value.lastName,
          email: this.sandBoxForm.value.email,
          jobRoles: this.sandBoxForm.value.jobRole,
          files: this.fileToUpload.map(file => ({
            Key: file,
          })),
        }
        this.empService._parseResumeSandbox(payLoad).subscribe((res: any) => {
          if (res.statusCode == 200) {
            
            let successData = "sandbox CV";
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
              height: 'auto',
              width: 'auto',
              autoFocus: false,
              data: {
                data: successData,
                firstName: this.sandBoxForm.value.firstName,
              },
            });
            this.file = null;
            this.fileToUpload = [];
            this.sandBoxForm.reset();
            dialogRef.afterClosed().subscribe();
          } else {
            this.snackBar
              .openFromComponent(DynamicSnacbarErrorComponent, {
                data: res.body,
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        });

      },
      (err) => console.log(err)
    );

  }


  onFilesSelected(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.file = { name: selectedFile.name, progress: 0, file: selectedFile, size: selectedFile.size };
      this.uploadFile();
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Add some visual feedback for drag over
    (event.target as HTMLElement).classList.add('drag-over');
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    // Remove visual feedback when drag leaves
    (event.target as HTMLElement).classList.remove('drag-over');
  }

  onFileDropped(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer?.files[0];
    if (droppedFile) {
      this.file = { name: droppedFile.name, progress: 0, file: droppedFile, size: droppedFile.size };
      this.uploadFile();
    }
    (event.target as HTMLElement).classList.remove('drag-over');
  }

  uploadFile(): void {
    this.uploadText = "Uploading...";
    const interval = setInterval(() => {
      if (this.file) {
        this.file.progress += 10;
        if (this.file.progress >= 100) {
          this.uploadText = "Upload Complete";
          clearInterval(interval);
        } 
      }
    }, 1000);
  }


  removeFile(): void {
    this.file = null;
  }

}
