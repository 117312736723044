import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewProfileDataComponent } from './view-profile-data/view-profile-data.component';
import { AuthGuard } from 'src/app/gaurd/auth.guard';
import { MessagingComponent } from './messaging/messaging.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ViewProfileDataComponent,
    data: {
      title: 'PROFILE ',
    },
  },
  // {
  //   path: 'messaging',
  //   component:MessagingComponent,
  //   data:{
  //     title:"MESSAGING "
  //   }
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GeneralRoutingModule {}
