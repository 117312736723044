import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-cv-search',
  templateUrl: './cv-search.component.html',
  styleUrls: ['./cv-search.component.scss'],
})
export class CvSearchComponent implements OnInit {
  cvSearchList: any = [];
  profileRecords: any;
  educationAllRecords: any;
  experianceAllRecords: any;
  licenseAllRecords: any;
  skillAllRecords: any;
  SearchText: any = '';
  lacation: any = '';
  cancelButton: boolean = false;
  showRightSectionInMobile: boolean = false;
  startJobIndex: number = 0;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private helper: Helper,
    private auth: AuthService,
    private io: HttpIOService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    private employerService: EmployerService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    let jsonData = {
      path: 'search-document',
      index_name: 'jobseekers',
      from: 0,
      size: 10,
    };
    this.employerService._cvSearch(jsonData).subscribe((res: any) => {
      console.log(res);
      if (res.serverResponse.code == 200) {
        this.cvSearchList = res.result;
        this.selectedPerson(this.cvSearchList[0]);
      }
    });
  }

  async selectedPerson(data) {
    this.showRightSectionInMobile = true;
    let jsonData = {
      id: data.id,
      path: 'get-user',
    };
    await this.io._JSallDetails(jsonData).subscribe((res: any) => {
      console.log(res);
      if (res.serverResponse.code == 200) {
        this.profileRecords = res['result'].userData;
        this.educationAllRecords =
          res['result'].userData.educations == undefined
            ? []
            : res['result'].userData.educations;
        this.experianceAllRecords =
          res['result'].userData.experience == undefined
            ? []
            : res['result'].userData.experience;
        this.licenseAllRecords =
          res['result'].userData.license == undefined
            ? []
            : res['result'].userData.license;
        this.skillAllRecords =
          res['result'].userData.skills == undefined
            ? []
            : res['result'].userData.skills;
        this.workNigeriaProfileCompleteORnot();
      }
      if (res.serverResponse.code == 400) {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  workNigeriaProfileCompleteORnot() {
    if (
      this.educationAllRecords.length == 0 &&
      this.experianceAllRecords.length == 0 &&
      this.licenseAllRecords.length == 0 &&
      this.skillAllRecords.length == 0
    ) {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'User is yet to create profile.',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  yearClculation(data) {
    // console.log(data)
    if (data.startDate) {
      if (data.endDate === '' || data.endDate === null) {
        let thisYear = new Date().getFullYear();
        let dtArr = data.startDate.split('-');
        let final = thisYear - Number(dtArr[0]);
        // console.log(thisYear,final)
        return final;
      }
    }
    // console.log(data)
    if (data.startDate && data.endDate) {
      let dtstart = data.startDate.split('-');
      let dtend = data.endDate.split('-');
      let final = dtend[0] - dtstart[0];
      return final;
    }
    return '';
  }

  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }

  findCv() {
    let jsonData = {
      path: 'search-document',
      index_name: 'jobseekers',
      location: this.lacation != '' ? this.lacation : '',
      skills: this.SearchText != '' ? this.SearchText : '',
      from: 0,
      size: 10,
    };
    this.employerService._cvSearch(jsonData).subscribe((res: any) => {
      console.log(res);
      if (res.serverResponse.code == 200) {
        this.cancelButton = true;
        this.cvSearchList = res.result;
        if (this.cvSearchList.length != 0) {
          this.selectedPerson(this.cvSearchList[0]);
        }
      }
    });
  }

  reset() {
    this.cancelButton = false;
    this.SearchText = '';
    this.lacation = '';
    let jsonData = {
      path: 'search-document',
      index_name: 'jobseekers',
    };
    this.employerService._cvSearch(jsonData).subscribe((res: any) => {
      console.log(res);
      if (res.serverResponse.code == 200) {
        this.cvSearchList = res.result;
        this.selectedPerson(this.cvSearchList[0]);
      }
    });
  }

  goToMessage(data) {
    this.router.navigate(['/message']);
    this.employerService.setforSeletedIn_cvSearch(data);
  }

  onScrollDown() {
    this.startJobIndex = this.startJobIndex + 10;

    let jsonData = {
      path: 'search-document',
      index_name: 'jobseekers',
      location: this.lacation != '' ? this.lacation : '',
      skills: this.SearchText != '' ? this.SearchText : '',
      from: this.startJobIndex,
      size: 10,
    };
    this.employerService._cvSearch(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        let data = res.result;
        this.cvSearchList.push(...data);
      }
    });
  }
}
