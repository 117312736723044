import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Helper } from '../helper/defult';

@Injectable({
  providedIn: 'root',
})
export class EmpHomeGuard  {
  userDeatils: any;
  companyData: any;
  allow: boolean;

  constructor(private _authService: AuthService, private _router: Router, private helper: Helper) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this._authService.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this._authService.getCurrentCompanyDataInSessoin();
    }
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.userDeatils) {
      return true;
    }

    // if (this.userDeatils) {
    //   if (this.userDeatils.userType == 'jobseeker') {
    //     this.allow = false;
    //   }else{
    //     return true;
    //   }
    // }

    // if (this.allow  == false) {
    //   this._router.navigate(['/home']);
    //   return false;
    // }

    if (this.userDeatils) {
      if (this.userDeatils.userType != 'jobseeker') {
        return true;
      }
    }
    this._router.navigate(['/home']);
    return false;
  }
}
