import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmployerService } from 'src/app/services/employer.service';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { SharejobComponent } from 'src/app/views/job-search/sharejob/sharejob.component';
import { UntypedFormBuilder, UntypedFormGroup, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { IntroJSService } from 'src/app/services/introjs.service';
@Component({
  selector: 'app-emp-dash-management',
  templateUrl: './emp-dash-management.component.html',
  styleUrls: ['./emp-dash-management.component.scss']
})
export class EmpDashManagementComponent {
  valueNumber: number = 250;
  selectedTabIndex: number = 0;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  nullJob: boolean = true;
  name = 'shaw';
  userDeatils: any;
  jobLists: any;
  detailsOfJob: any = '';
  myFlagForSlideToggle: boolean;
  currentIndex: number;
  companyData: any;
  status: boolean = false;
  emailVaild: any;
  userToken: string;
  appliedUsers: any;
  countData: any = '';
  showRightSectionInMobile: boolean = false;
  startJobIndex: any = 0;
  loadMoreButtonVisible: boolean = true;
  dashBoardDetails: UntypedFormGroup;
  currentJobsPage = 0;
  jobsPagination = {
    size: 10,
    start: 0,
    total: 0,
  };
  show: boolean = false;
  filtered: any = {};
  clearButton: boolean = false;
  selectedJobByEmployer: any;
  filteredOptions: Observable<string[]>;
  CompaniesResult: any = [];
  selectCompany: any;

  constructor(
    // private jobService: JobServices,
    private router: Router,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private employerService: EmployerService,
    public datepipe: DatePipe,
    private introService: IntroJSService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    this.userToken = sessionStorage.getItem('workNigeriaToken');

    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }

    this.userDeatils.isEmail;
  }

  ngOnInit(): void {
    let arr = [10, 10, 30, 20, 40, 5, 20];
    arr.push(50);
    this.fetchJobList('DESC');
    this.activityLog();
    // this.getAllCompaniesList();

    this.validationForm();

    this.filteredOptions =
      this.dashBoardDetails.controls.companies.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  ngAfterViewInit(): void {
    if((localStorage.getItem('jobPostTour') ?? '0') === '0'){
      setTimeout(() => {
        this.introService.jobPost()
      }, 1000)
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.CompaniesResult.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  onKey(event) {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      this.searchByDependency();
    }
    this.clearButton = true;
  }

  validationForm() {
    this.dashBoardDetails = this.fb.group({
      created_at: [''],
      title: [''],
      job_status: [''],
      provider_id: [''],
      companies: [''],
      sortOrder: ['Sort'],
    });
  }

  async getAllCompaniesList() {
    let jsonData = {
      path: 'get-company-list',
    };
    await this.employerService
      ._getAllCompaniesList(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // console.log(res['result']);
          this.CompaniesResult = res['result'].companyList;
        }
      });
  }

  async getEmployerJobs() {
    if (this.userDeatils.userRole == 'company_admin') {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        //  "provider_id": this.userDeatils.id,
        company_id: this.userDeatils.companyId,
        size: this.jobsPagination.size,
        start: this.jobsPagination.start,
        sortParam: 'created_at',
        sortOrder: 'DESC',
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobLists = res['result'].job;
            this.jobsPagination = res['pagination'];
          }
        });
    }
    else if (this.userDeatils.userRole == 'company_basic') {
      let jsonData = {
        path: 'get-job-by-provider-or-company',
        provider_id: this.userDeatils.id,
        company_id: this.userDeatils.companyId,
        size: this.jobsPagination.size,
        start: this.jobsPagination.start,
        sortParam: 'created_at',
        sortOrder: 'DESC',
      };
      await this.employerService
        ._getJobByproviderAndCompany(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.jobLists = res['result'].job;
            this.jobsPagination = res['pagination'];
          }
        });
    }
  }

  searchByDependency() {
    if (this.userDeatils.userType != 'super_admin') {
      this.filtered = {
        path: 'get-job-by-provider-or-company',
        company_id: this.userDeatils.companyId,
      };
    } else {
      this.filtered = {
        path: 'get-job-by-provider-or-company',
        company_id: this.selectCompany ? this.selectCompany.id : 'all',
        start: 0,
        size: 10,
      };
    }
    if (this.dashBoardDetails) {
      for (let key in this.dashBoardDetails.value) {
        if (this.dashBoardDetails.value[key]) {
          if (key == 'created_at' && this.dashBoardDetails.value[key] != '') {
            this.filtered[key] = this.transformTreeDateTime(
              this.dashBoardDetails.value[key]
            );
          } else {
            this.filtered[key] = this.dashBoardDetails.value[key];
          }
        }
      }
    }
    this.employerService
      ._getJobByproviderAndCompany(this.filtered)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          if (res['result']) {
            this.jobLists = res['result'].job;
            this.jobsPagination = res['pagination'];
          }
          if (this.jobLists.length > 0) {
            if (this.userDeatils.userType != 'super_admin') {
              this.clearButton = true;
            }
            if (
              this.userDeatils.userType == 'super_admin' &&
              this.selectCompany
            ) {
              this.clearButton = true;
            }
          }
        }
      });
  }

  transformTreeDateTime(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  resetFrom() {
    this.dashBoardDetails.reset({ companies: '' });
    this.clearButton = false;
    if (this.userDeatils.userType == 'super_admin') {
      this.selectCompany = '';
      this.jobLists = [];
      this.startJobIndex = 0;
      this.searchByDependency();
    }
    if (this.userDeatils.userType != 'super_admin') {
      this.getEmployerJobs();
    }
  }

  onJobsPageChanged(event: any): void {
    this.jobsPagination.start = event.pageIndex * this.jobsPagination.size;
    this.currentJobsPage = event.pageIndex;
    this.fetchJobList('DESC');
  }


  // FETCH JOB LIST //
  async fetchJobList(sortOrder: string): Promise<void> {
    let payLoad = {
      path: 'get-job-by-provider-or-company',
      provider_id: this.userDeatils.id,
      size: this.jobsPagination.size,
      start: this.jobsPagination.start,
      sortParam: 'created_at',
      sortOrder,
    };

    this.employerService
      ._getJobByproviderAndCompany(payLoad)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.jobLists = res['result'].job;
          this.jobsPagination = res['pagination'];
          if (this.jobLists.length > 0) {
            this.detailsOfJob = this.jobLists[0];
            this.status = this.jobLists[0].id;
            this.currentIndex = 0;
            if((localStorage.getItem('jobManagement') ?? '0') === '0'){
              setTimeout(()=>{
                console.log(document.querySelector('.share'))
                this.introService.jobManagement()
              }, 1000)
            }
          }
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });

  }

  //infinite scroll
  onScrollDown() {
    this.startJobIndex = this.startJobIndex + 5;

    let payLoad = {
      path: 'get-job-by-provider-or-company',
      provider_id: this.userDeatils.id,
      start: this.startJobIndex,
    };
    this.io
      .apicall(payLoad, 'job/get-job-by-provider-or-company')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          if (res['result'].job.length > 0) {
            let data = res['result'].job;
            this.jobLists.push(...data);
          }
          if (res['result'].job.length == 0) {
            this.loadMoreButtonVisible = false;
            this.snackBar
              .openFromComponent(DynamicSnacbarSkipComponent, {
                data: 'No job found',
                verticalPosition: 'top', // 'top' | 'bottom'
                horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
              })
              .afterOpened()
              .subscribe(this.helper.setOnTop);
          }
        }
      });
  }

  // EVENT EXPAIRE TIME //
  eventExpireIn(eventDt: string | number | Date) {
    const dateDiff = this.helper.calculateDiff(new Date(), new Date(eventDt));
    if (dateDiff === 0) {
      return 'today';
    } else if (dateDiff === 1) {
      return 'yesterday';
    } else {
      return `${dateDiff} days ago`;
    }
  }

  // GET JOB DETAILS //
  jobDetails(i, job) {
    // console.log(i);
    this.showRightSectionInMobile = true;
    this.status = job.id;
    this.currentIndex = i;
    this.detailsOfJob = this.jobLists[i];
    // this.jobApplied(this.detailsOfJob);
  }
  mobileViewClassClose() {
    this.showRightSectionInMobile = false;
  }

  // REDIRECT TO EDIT JOB //
  routeToEditJob(jobId) {
    let checkEmailValid = this.io._checkEmailValid();
    if (checkEmailValid == true) {
      this.router.navigate(['/edit-job'], { queryParams: { id: jobId } });
    } else {
      // this.snackBar.open("Please verify your email to edit jobs", "OK");
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  async routeToAddJob() {
    let checkEmailValid = await this.io._checkEmailValid();
    // console.log(checkEmailValid)
    if (checkEmailValid == true) {
      const dataToSend = {
        data: 'from dashboard add job'
      }
      this.router.navigate(['post-job'], {
        queryParams: dataToSend
      });
    } else {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to edit jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  //ACTIVE OR INACTIVE SLIDE //
  activeInactive(job, index) {
    let checkEmailValid = this.io._checkEmailValid();
    let jobStatus = job.job_status;
    if (checkEmailValid == true) {
      if (jobStatus === 'active') {
        let jsonData = {
          path: 'inactive-job',
          id: job.id,
        };
        this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => {
          this.jobLists[index].job_status = res['result'].job.job_status;
        });
      } else {
        let jsonData = {
          path: 'active-job',
          id: job.id,
        };
        this.io._activeForEmpManage(jsonData).subscribe((res: any) => {
          console.log(res);
          this.jobLists[index].job_status = res['result'].job.job_status;
        });
      }
    } else {
      // this.snackBar.open("Please verify your email to active or inactive jobs", "OK");
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'Please verify your email to active or inactive jobs',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  async activityLog() {
    if (
      this.userDeatils.userRole == 'company_admin' ||
      this.userDeatils.userType == 'super_admin'
    ) {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        company_id: this.companyData.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            // console.log(res);
            this.countData = res.countData;
          }
        });
    } else {
      let jsonData = {
        path: 'count-total-active-applied-views-job',
        provider_id: this.userDeatils.id,
      };
      await this.employerService
        ._getAllActivity(jsonData)
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.countData = res.countData;
          }
        });
    }
  }

  shareJob(job) {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: '480px',
      width: '450px',
      data: { data: job },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  promoteJob() {
    this.router.navigate(['Dashboard'], {
      queryParams: ['promote-job']
    });
  }
}
