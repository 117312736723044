<div *ngIf="!this.jobOverview" class="container scrl">
  <div class="close-icon" (click)="onNoClickClose()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <p class="preview-heading">Preview Jobs <span class="no-of-jobs">{{selectedJobsList.length}}
      <span *ngIf="selectedJobsList.length < 2">Job</span>
      <span *ngIf="selectedJobsList.length >= 2">Jobs</span>
    </span></p>
  <div class="well-views table-responsive">
    <table class="table jobs">
      <tbody>
        <tr *ngFor="let job of selectedJobsList">
          <td class="first-col">{{ formatTitle(job.title) }}</td>
          <td class="first-col">{{ job.job_location }}</td>
          <td class="first-col">{{ locationType(job.location_type) }}</td>
          <td class="first-col">{{ job.minimum_salary }} - {{ job.maximum_salary }}</td>
          <td class="first-col">
            <button class="btn btn-delete" (click)="deleteAJob(job.id)">
              <img src="../../../../../../assets/images/delete-icon.png" class="delete-icon" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="this.jobOverview" class="container">
  <div class="close-icon" (click)="onNoClickClose()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <p class="job-overview-heading">You are about to promote
    <strong>{{selectedJobsList.length}}</strong>
    <span *ngIf="selectedJobsList.length < 2">{{' '}}Job</span>
    <span *ngIf="selectedJobsList.length >= 2">{{' '}}Jobs</span>
  </p>
  <div class="credit-breakup-main">
    <div class="custom-container">
      <div class="custom-column">
        <div class="custom-div cost">
          <div class="custom-text">Cost</div>
        </div>
        <div class="custom-div credit">
          <div class="custom-text">{{selectedJobsList.length}}.00 credit</div>
        </div>
      </div>

      <div class="custom-column payment">
        <div class="custom-div payment-breakdown">
          <div class="custom-text">Payment Breakdown</div>
        </div>

        <div *ngIf="this.creditBreakup.subscription" class="custom-div silver-plan">
          <div class="custom-div">
            <img *ngIf="this.subscriptionPlan === 'Silver'" class="custom-image"
              src="../../../../../../assets/images/silver_badge.png" />
            <img *ngIf="this.subscriptionPlan === 'Gold'" class="custom-image"
              src="../../../../../../assets/images/gold_badge.png" />
            <div class="custom-text">{{this.subscriptionPlan}} Plan</div>
          </div>

          <div
            style="width: 43px; height: 29px; padding: 10px; background: #E8F5EE; flex-direction: column; justify-content: center; align-items: center; gap: 10px; display: inline-flex">
            <div
              style="width: 12px; height: 28px; color: black; font-size: 20px; font-family: Hando Trial; font-weight: 400; line-height: 33.60px;">
              {{this.creditBreakup.subscription}}</div>
          </div>
        </div>

        <div *ngIf="this.creditBreakup.bundle" class="custom-div wallet" style="margin-top: 10px;">
          <div class="custom-div">
            <img class="custom-image" src="../../../../../../assets/images/pack-bundle.svg" />
            <div class="custom-text">Bundle</div>
          </div>

          <div
            style="width: 43px; height: 29px; padding: 10px; background: #E8F5EE; flex-direction: column; justify-content: center; align-items: center; gap: 10px; display: inline-flex">
            <div
              style="width: 12px; height: 28px; color: black; font-size: 20px; font-family: Hando Trial; font-weight: 400; line-height: 33.60px;">
              {{this.creditBreakup.bundle}}</div>
          </div>
        </div>

        <div *ngIf="this.creditBreakup.wallet" class="custom-div wallet" style="margin-top: 10px;">
          <div class="custom-div">
            <img class="custom-image" src="../../../../../../assets/images/wallet.webp" />
            <div class="custom-text">Wallet</div>
          </div>

          <div
            style="width: 43px; height: 29px; padding: 10px; background: #E8F5EE; flex-direction: column; justify-content: center; align-items: center; gap: 10px; display: inline-flex">
            <div
              style="width: 12px; height: 28px; color: black; font-size: 20px; font-family: Hando Trial; font-weight: 400; line-height: 33.60px;">
              {{this.creditBreakup.wallet}}</div>
          </div>
        </div>
      </div>
    </div>



  </div>
  <div class="well-views table-responsive scrl">
    <table class="table jobs">
      <tbody>
        <tr *ngFor="let job of selectedJobsList">
          <td class="first-col">{{ formatTitle(job.title) }}</td>
          <td class="first-col">{{ job.job_location }}</td>
          <td class="first-col">{{ locationType(job.location_type) }}</td>
          <td class="first-col">{{ job.minimum_salary }} - {{ job.maximum_salary }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="continue-container" (click)="postCampaign()">
    <div class="continue-content">
      <div class="continue-text">continue</div>
    </div>
  </div>


</div>