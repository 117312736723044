import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventManualInput]'
})
export class PreventManualInputDirective {

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
  }
}