import { Component, OnInit } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-emp-campaigns-wallet',
  templateUrl: './emp-campaigns-wallet.component.html',
  styleUrls: ['./emp-campaigns-wallet.component.scss']
})
export class EmpCampaignsWalletComponent implements OnInit {

  transactionHistory = [];
  toDay: any = new Date();
  currentWalletPage = 1;
  walletPagination = {
    size: 4,
    start: 0,
    total: 0,
  };
  userDetails: any;
  companyData: any;
  currentBalance = 0;

  constructor(
    private es: EmployerService,
    private helper: Helper,
    private auth: AuthService,
    ) { 
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyData();
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
        this.companyData = this.auth.getCurrentCompanyDataInSessoin();
      }
    });
    this.currentBalance = this.userDetails.balance ?? 0;
  }

  ngOnInit(): void {
    this.getPaymentHistory();
  }

  getPaymentHistory(){
    let params = {
      size: this.walletPagination.size,
      start: this.walletPagination.start,
      sort: 'ASC',
    };
    this.es._paymentHistory(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.transactionHistory = res['result'];
        this.walletPagination.total = res['pagination']['total'];
        this.walletPagination.start = res['pagination']['start'];
      }
    });
  }

  correctDayDate(datetime: string) {
    const dateObj = new Date(datetime);

    const day = dateObj.toLocaleString('en-US', { weekday: 'short' });
    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const year = dateObj.getFullYear();

    const formattedDate = `${day}, ${dateObj.getDate()} ${month} ${year}`;

    return formattedDate;
  }

  onWalletPageChanged(page: number): void {
    this.walletPagination.start = (page - 1) * this.walletPagination.size;
    this.currentWalletPage = page;
    this.getPaymentHistory();
  }
}
