<div class="close-icon">
  <span class="close"><a [mat-dialog-close]="false">&#10006;</a></span>
</div>
<div class="new-screen">
  <h5>{{ title }}</h5>
  <div class="googlelogo">
    <div class="img">
      <img
        src="{{
          record?.profileImage != ''
            ? record?.profileImage
            : '../assets/images/defaultuser.png'
        }}"
      />
    </div>
    <h4>
      {{ record?.firstName }} <span>{{ record?.lastName }}</span>
    </h4>
  </div>
  <hr />
  <div class="medialink">
    <h6>Social media</h6>
    <ul>
      <li>
        <div class="sameline">
          <share-buttons
            [include]="['facebook']"
            [showText]="true"
            [show]="5"
            [url]="url"
            [theme]="'modern-light'"
            [description]="'Take a look at my profile on WorkNigeria here!'"
          >
            <!-- <i class="fab fa-facebook"></i>            -->
          </share-buttons>
        </div>
      </li>
      <li>
        <div class="sameline">
          <share-buttons
            [theme]="'modern-light'"
            [include]="['whatsapp']"
            [showText]="true"
            [show]="5"
            [url]="url"
            [description]="'Take a look at my profile on WorkNigeria here!'"
          ></share-buttons>
        </div>
      </li>
    </ul>
  </div>
  <div class="medialink">
    <h6>Messaging & email</h6>
    <ul>
      <li>
        <div class="sameline">
          <!-- <share-buttons [theme]="'modern-light'" [include]="['linkedin']" [showText]="true" [show]="5"
                        [url]="url" [description]="'Take a look at my profile on WorkNigeria here!'"></share-buttons> -->
          <img
            src="../../../../assets/images/linkedin.png"
            alt=""
            width="20"
            height="20"
          />&nbsp;&nbsp;
          <a
            href="javascript:void(0);"
            (click)="share()"
            style="color: #3eab70"
          >
            linkedin
          </a>
        </div>
      </li>
      <!-- <li>
                <div class="sameline">
                    <share-buttons [theme]="'modern-light'" [include]="['whatsapp']" [showText]="true" [show]="5"
                        [url]="url"></share-buttons>
                </div>
            </li> -->
      <li *ngIf="detectMob()">
        <div class="sameline">
          <share-buttons
            [theme]="'modern-light'"
            [include]="['messenger']"
            [showText]="true"
            [show]="5"
            [url]="url"
            [description]="'Take a look at my profile on WorkNigeria here!'"
          ></share-buttons>
        </div>
      </li>
      <li>
        <div class="sameline">
          <share-buttons
            [theme]="'modern-light'"
            [include]="['email']"
            [showText]="true"
            [show]="5"
            [url]="url"
            [description]="'Take a look at my profile on WorkNigeria here!'"
          ></share-buttons>
        </div>
      </li>
    </ul>
  </div>

  <div class="copylink">
    <h6>Copy link</h6>
    <div class="copylinkbx">
      <share-buttons
        [theme]="'modern-light'"
        [include]="['copy']"
        [show]="5"
        [url]="url"
        [description]="'Take a look at my profile on WorkNigeria here!'"
      ></share-buttons>
      <p>{{ url }}</p>
    </div>
  </div>
</div>
