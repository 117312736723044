import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { AppMessage } from '../../../../../helper/messages';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Helper } from 'src/app/helper/defult';
@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss'],
})
export class ChangeStatusComponent implements OnInit {
  // show: boolean = true;
  // rejectedText: any ;
  selected: any = '';
  cause: any = '';
  statusList: any = [
    'applied',
    'interviewing',
    'withdrawn',
    'rejected',
    'hired',
    'hold for future openings',
    'consider for another role',
  ];
  userDeatils: any;
  companyData: any;
  mailContext: any = '';
  index: any;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        // 'justifyLeft',
        // 'justifyCenter',
        // 'justifyRight',
        // 'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  dropDownValue: any;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private employerService: EmployerService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private appMessage: AppMessage,
    private helper: Helper
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    console.log(this.data);
    this.selected = this.data['status'];
    this.index = this.statusList.findIndex((x) => x == this.selected);
    // console.log(index);
  }

  changeStatusInDropdown(e) {
    // console.log(e.value)
    this.dropDownValue = e.value;
    this.mailContext = e.value;
  }
  changeStatusInCause(e) {
    // console.log(e.value)
    // this.mailContext = e.value;
    this.mailContext = this.appMessage.getMessage(e.value, this.data);
  }

  changeStatus() {
    // this.mailSent();
    let jsonData = {
      applicationId: this.data['wholeData'].appliedData.id,
      employerId: this.data['wholeData'].appliedData.job_provider_id,
      status: this.selected,
      path: 'change-application-status',
    };
    this.employerService._changeStatus(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        //  console.log(res.serverResponse.message)
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        // this.dialogRef.close("save");

        this.mailSent();
      }
    });
  }

  mailSent() {
    let jsonData = {
      currentStatus: this.selected,
      userFirstName: this.data['wholeData'].firstName,
      jobId: this.data['selectedJob'].id,
      mailText: this.mailContext,
      cause: this.cause && this.selected == 'rejected' ? this.cause : '',
      email: this.data['wholeData'].email,
      jobTitle: this.data['selectedJob'].title,
      path: 'status-change-notification-mail',
    };
    console.log(jsonData);
    this.employerService
      ._emailSendForStatusChange(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          //  console.log(res)
          this.dialogRef.close('save');
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }

  onNoClickClose() {
    this.dialogRef.close();
  }
}
