import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Helper } from 'src/app/helper/defult';

@Component({
  selector: 'app-email-notification',
  templateUrl: './email-notification.component.html',
  styleUrls: ['./email-notification.component.scss'],
})
export class EmailNotificationComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  loginNotification: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmailNotificationComponent>,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private helper: Helper
  ) {
    this.loginNotification = this.fb.group({
      email_noti: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  ngOnInit(): void {}

  // Validation Controls //
  get f() {
    return this.loginNotification.controls;
  }

  closeDilog() {
    if (this.loginNotification.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.loginNotification);
      return false;
    }
    this.dialogRef.close(this.loginNotification.value.email_noti);
  }
}
