import { Component, OnInit, Renderer2, ViewChild, HostListener, ElementRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { LoginComponent } from 'src/app/views/auth/login/login.component';
import { CompanyVerificationComponent } from '../../employer/company-verification/company-verification.component';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-employer-home',
  templateUrl: './employer-home.component.html',
  styleUrls: ['./employer-home.component.scss'],
})
export class EmployerHomeComponent implements OnInit {
  userDeatils: any = '';
  companyData: any = '';

  logos = [
    '../../../../assets/images/logo_tbo.png',
    '../../../../assets/images/logo_zedcrest.png',
    '../../../../assets/images/logo_aim.png',
    '../../../../assets/images/logo_pecan.png',
    '../../../../assets/images/logo_route.png',
    '../../../../assets/images/logo_qlc.png',
    '../../../../assets/images/logo_magna.png',
    '../../../../assets/images/logo_manna.png',
    '../../../../assets/images/logo_fohat.png',
    '../../../../assets/images/logo_richway.png',
    '../../../../assets/images/logo_arise.png',
    '../../../../assets/images/logo_cochlear.png',
    '../../../../assets/images/logo_platview.png',
    '../../../../assets/images/logo_go2.png'
  ]

  // array for cards data on the recruitment section
  recruitment_cards = [
    {
      imgSrc: '../../../../assets/images/leadership-icon.webp',
      heading: 'Leadership',
      text: 'We are diligent in realising our vision to lead the industry and set standards in each line of our business. We are ready to take on challenges and develop our capabilities to create more value for our customers.'
    },
    {
      imgSrc: '../../../../assets/images/professionalism-icon.webp',
      heading: 'Professionalism',
      text: 'We view professionalism as our endurance factor in this dynamic business world. We believe that a professional attitude and behaviour creates superior value for all our stakeholders and society at large.'
    },
    {
      imgSrc: '../../../../assets/images/integrity-icon.webp',
      heading: 'Integrity',
      text: 'We endeavour to do the right thing in the right way and stand by it. Our business decisions and actions are in the frame of ethics, moral values as well as legal requirements.'
    },
    {
      imgSrc: '../../../../assets/images/sustain-icon.webp',
      heading: 'Business Sustainability',
      text: 'Ensuring business sustainability through excellent performance throughout the organisation, innovation, business and community and stakeholder engagement.'
    }
  ];

  // array for cards data on client sayings section
  client_sayings = [
    {
      imgSrc: '../../../../assets/images/innovate-africa.webp',
      heading: 'Innovate Africa Foundation',
      stars: 4,
      text: 'Using their hiring service was a game-changer for us! They quickly understood our needs and delivered top-notch candidates tailored to our requirements. Highly recommended for anyone looking to streamline their hiring process.'
    },
    {
      imgSrc: '../../../../assets/images/fohat-icon.webp',
      heading: 'Fohat Solutions',
      stars: 4,
      text: 'We employed our Procurement Specialist within 10 days of our recruitment request - <b>Kikelomo</b>'
    },
    {
      imgSrc: '../../../../assets/images/ctrl-icon.webp',
      heading: 'CTRL',
      stars: 3,
      text: 'The recruiter was prompt in responding to our requests. - HR Manager - CTRL'
    },
  ]
  constructor(
    public dialog: MatDialog,
    private helper: Helper,
    private router: Router,
    private route: ActivatedRoute,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private dialogRef: MatDialog,
    private empService: EmployerService,
    private renderer: Renderer2,
    private dataService: DataService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    } else if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    // console.log(this.companyData)
  }

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollBy({ left: -33, behavior: 'smooth' });
  }
  
  scrollRight() {
    this.widgetsContent.nativeElement.scrollBy({ left: 343, behavior: 'smooth' });
  }

  postAjob() {
    const dataToSend = {
      data: 'from homepage add job'
    }
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['post-job'], {
        queryParams: dataToSend
      });
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'post-job');
    }
  }

  searchCV() {
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['Dashboard'], {
          queryParams: ['search-cv']
        });
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'Dashboard');
    }
  }

  promoteJob() {
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['/promote-job']);
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'Dashboard');
    }
  }

  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  navigateToTraining() {
    this.router.navigate(['/training']);
  }

  Dashboard() {
    if (this.userDeatils.userType == 'employer') {
      if (this.companyData.approvedStatus == 'approved' || this.userDeatils.isEmailVerified) {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: 'Your company aleady varified',

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      } else if (this.companyData.approvedStatus == 'pending') {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: 'Your application has already submitted',

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      } else {
        this.verification();
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'Dashboard');
    }
  }

  verification() {
    const dialogRef = this.dialog.open(CompanyVerificationComponent, {
      height: 'auto',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        //  console.log(res)
        // if (localStorage.getItem("workNigeriacompanyData") === null) {
        //   this.auth.setCurrentCompanyDataInSessoin(res);
        // } else {
        //   this.auth.setCurrentCompanyData(res);
        // }
      }
    });
  }
  goToServices() {
    this.router.navigate(['services']);
  }
}
