<div class="super-admin-container">
    <div class="row search-div">
        <!-- Cards side -->
        <div class="col-lg-8">
            <!-- search row -->
            <div class="row d-flex align-items-baseline justify-content-center">
                <div class="col-10">
                    <div class="input-group">
                        <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
                            <mat-select [(value)]="selectedOption"
                                (selectionChange)="updateSelectedOption($event.value)">
                                <mat-option value="" disabled selected hidden>Start From</mat-option>
                                <mat-option *ngFor="let option of options" [value]="option">{{ option }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-2">
                    <div class="btn-set">
                        <a href="javascript:void(0);" class="btn border" (click)="exportAllJobs()"><img
                                src="../assets/images/el.png" alt="" /></a>
                    </div>
                </div>
            </div>
            <!-- card - total employers & active followers -->
            <div class="row emp-fellow-div">
                <div class="col-lg-6 card-one">
                    <div class="row">
                        <div class="col-lg-8 m-10">
                            <h5>Total Employers</h5>
                            <h4>{{ analyticsList?.totalEmployers }}</h4>
                        </div>
                        <div class="col-lg-4">
                            <img src="../../../../../../assets/images/total-emp.png" />
                            <h6>
                                Last {{ displayedOption }}
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 card-one">
                    <div class="row">
                        <div class="col-lg-8 m-10">
                            <h5>Active Fellows</h5>
                            <h4>{{ analyticsList?.totalActiveFellows }}</h4>
                        </div>
                        <div class="col-lg-4">
                            <img src="../../../../../../assets/images/active-followers.png" />
                            <h6>
                                Last {{ displayedOption }}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Cards for Roles -->
            <div class="row emp-roles">
                <div class="col-lg-3 total-roles">
                    <h6>Total No. of Roles</h6>
                    <h5>{{ analyticsList?.totalRoles }}</h5>
                    <p>Last {{ displayedOption }}</p>
                </div>
                <div class="col-lg-3 open-roles">
                    <h6>No. of Open Roles</h6>
                    <h5>{{ analyticsList?.totalOpenRoles }}</h5>
                    <p>Last {{ displayedOption }}</p>
                </div>
                <div class="col-lg-3 closed-roles">
                    <h6>No. of Closed Roles</h6>
                    <h5>{{ analyticsList?.totalClosedRoles }}</h5>
                    <p>Last {{ displayedOption }}</p>
                </div>
            </div>
        </div>
        <!-- Table for jobs by location -->
        <div class="col-lg-4 job-by-loc">
            <table class="table">
                <thead>
                    <tr>
                        <th>No. of Jobs by Location</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let city of analyticsList?.totalRolesGroupedByLocation">
                        <td>{{ city.value }}</td>
                        <td>{{ city.count }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="analytics-heading">
        <h4>Employers Overview</h4>
    </div>
    <div class="row data-analytics">
        <div class="col-lg-6 table-for-analytics">
            <table class="table table-analytics">
                <thead>
                    <tr>
                        <th>COMPANY NAME</th>
                        <th>TOTAL NO OF ROLES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let city of paginatedDataRoles">
                        <td>{{ city.value }}</td>
                        <td>{{city.count}}</td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation" class="mb-1">
                <ngb-pagination [collectionSize]="analyticsList?.totalRolesGroupedByCompany.length"
                    [(page)]="currentPage" [pageSize]="pageSize" (pageChange)="onPageChanged($event)">
                </ngb-pagination>
            </nav>
        </div>
        <div class="col-lg-6 table-for-analytics">
            <table class="table table-analytics">
                <thead>
                    <tr>
                        <th>COMPANY NAME</th>
                        <th>ACTIVE ROLES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let city of paginatedDataActiveRoles">
                        <td>{{ city.value }}</td>
                        <td>{{city.count}}</td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation" class="mb-1">
                <ngb-pagination [collectionSize]="analyticsList?.totalActiveRolesGroupedByCompany.length"
                    [(page)]="activeCurrentPage" [pageSize]="activePageSize" (pageChange)="onPageChangedActive($event)">
                </ngb-pagination>
            </nav>
        </div>
    </div>
</div>