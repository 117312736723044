import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-job-seeker-want-to-recruiter-side',
  templateUrl: './job-seeker-want-to-recruiter-side.component.html',
  styleUrls: ['./job-seeker-want-to-recruiter-side.component.scss'],
})
export class JobSeekerWantToRecruiterSideComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private empService: EmployerService,
    public dialogRef: MatDialogRef<JobSeekerWantToRecruiterSideComponent>
  ) {}

  ngOnInit(): void {
    // console.log(this.data)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  gotologout(): void {
    this.dialogRef.close();
    localStorage.removeItem('workNigeriaToken');
    sessionStorage.removeItem('workNigeriaToken');
    localStorage.clear();
    window.localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/employer-home']);
    this.empService.setforWSconnect(null);
  }
}
