<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<h4>Report this job !</h4>

<mat-radio-group
  aria-label="Select an option"
  class="example-radio-group"
  [(ngModel)]="chooseReason"
>
  <mat-radio-button
    class="example-radio-button custom-radio-button"
    *ngFor="let row of reasons"
    [value]="row"
  >
    {{ row }}
  </mat-radio-button>
</mat-radio-group>

<div *ngIf="show" class="text-left text-danger pb-1">
  Please select a option
</div>
<p style="font-size: 16px; font-weight: 700">Additional information !</p>
<div class="form-group">
  <mat-form-field class="example-full-width custom-underline-none">
    <textarea
      style="border: 1px solid #000; height: 80px; border-radius: 3px"
      matInput
      [(ngModel)]="additionalWrite"
    ></textarea>
  </mat-form-field>
</div>
<!-- <div>Your favorite season is: {{additionalWrite}}</div> -->
<button style="width: 50%" mat-raised-button color="primary" (click)="submit()">
  Submit
</button>
