<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<h4 style="display: flex; align-items: center; justify-content: space-between">
  Add Skill
</h4>
<input
placeholder="Add Skills..."
#fruitInput
[formControl]="fruitCtrl"
[matAutocomplete]="auto"
[matChipInputFor]="chipList"
[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
(matChipInputTokenEnd)="add($event)"
/>
<div class="custom-skill-list">

  <mat-form-field class="example-chip-list custom-chip-set">
    <mat-label></mat-label>

    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip
        *ngFor="let fruit of fruits"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(fruit)"
      >
        {{ fruit }}
        <button matChipRemove *ngIf="removable">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let fruit of filteredFruits | async"
        [value]="fruit.skillName"
      >
        {{ fruit.skillName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<button
  *ngIf="!show"
  style="float: right"
  mat-raised-button
  color="primary"
  (click)="saveSkills()"
>
  SAVE
</button>
<button
  *ngIf="show"
  style="float: right"
  mat-raised-button
  color="primary"
  (click)="updateSkills()"
>
  UPDATE
</button>
