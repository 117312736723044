<section class="new-banner-style common-padd pt-0 common-padd-lt-rt">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="img">
          <img src="../assets/images/SearchCV'sHeader.png" />
        </div>
      </div>
    </div>
  </div>
</section>
<section class="search-cvs-filter-area common-padd-lt-rt">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4">
        <mat-form-field class="example-full-width searchicon" appearance="fill">
          <mat-label>Search Skills</mat-label>
          <input
            type="text"
            matInput
            placeholder="Search"
            [(ngModel)]="SearchText"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-4">
        <mat-form-field
          class="example-full-width locationicon"
          appearance="fill"
        >
          <mat-label>All Locations</mat-label>
          <input
            type="text"
            matInput
            placeholder="Location"
            [(ngModel)]="lacation"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <button class="btn-submit-find" (click)="findCv()">Find CVs</button>
      </div>
      <div class="col-lg-2">
        <button
          class="btn-submit-find cancle"
          *ngIf="cancelButton"
          (click)="reset()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</section>
<section class="search-cvs-new-area common-padd-lt-rt">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4">
        <div class="search-cvs-new-list">
          <h4>Candidate CVs</h4>
          <div *ngFor="let row of cvSearchList" class="cvs-list-all">
            <div class="cvs-list-all-wrap dr-right">
              <a href="javascript:void(0);" (click)="selectedPerson(row)">
                <div class="cvs-profile-img">
                  <img
                    [src]="
                      row.profileImage
                        ? row.profileImage
                        : '../assets/images/defaultuser.png'
                    "
                    onerror="this.onerror=null;this.src='../assets/images/defaultuser.png'"
                  />
                </div>
                <div class="cvs-profile-info">
                  <h5>
                    {{ row.firstName }} {{ row.lastName }}
                    <span>{{ row.skills ? row.skills[0] : "" }} </span>
                  </h5>
                </div>
                <div class="cvs-profile-cote" (click)="goToMessage(row)">
                  <img src="../assets/images/cv-profile-message.png" />
                </div>
              </a>
            </div>
          </div>
          <p

            *ngIf="this.cvSearchList.length > 9"
            style="
              text-align: right;
              font-size: 20px;
              color: #3eab70;
              cursor: pointer;
            "
            (click)="onScrollDown()"
          >
            load more ...
          </p>
          <div class="wheen-no-content" *ngIf="this.cvSearchList.length == 0">
            <h4>No Cvs Received</h4>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''">
          <button (click)="mobileViewClassClose()" class="mobilejob-back">
            <i class="far fa-times-circle"></i>
          </button>
          <div [ngClass]="showRightSectionInMobile ? 'mobile-view-child' : ''">
            <div class="search-cvs-new-details">
              <div
                *ngIf="this.cvSearchList.length != 0"
                class="profile-middle-sec"
              >
                <div class="container">
                  <div class="card">
                    <h2>
                      Profile
                      <!-- <span>
                                                <a href="javascript:void(0);" (click)="profile()" class="btn-green wh-bg"><i
                                                        class="fas fa-pen"></i>
                                                    EDIT PROFILE</a>
                                            </span> -->
                    </h2>
                    <div class="card-mid pro-img">
                      <figure>
                        <!-- <img src="../assets/images/pro-pic.png" /> -->
                        <img
                          src="{{
                            profileRecords?.profileImage
                              ? profileRecords?.profileImage
                              : '../assets/images/defaultuser.png'
                          }}"
                        />
                      </figure>
                      <figcaption>
                        <strong class="d-block"
                          >{{ profileRecords?.firstName }}
                          {{ profileRecords?.lastName }}</strong
                        >
                      </figcaption>
                    </div>
                  </div>

                  <div class="card" *ngIf="educationAllRecords?.length > '0'">
                    <h2>
                      Education
                      <!-- <span><a href="javascript:void(0);" class="btn-green" (click)="education('NEW')">ADD
                                                    EDUCATION</a></span> -->
                    </h2>
                    <div
                      class="card-mid-description"
                      *ngFor="let row of educationAllRecords; let i = index"
                    >
                      <div class="row">
                        <div class="col-lg-10">
                          <figcaption>
                            <strong class="d-block">{{
                              row.universityName
                            }}</strong>
                            <p class="mb-0">{{ row.courseName }}</p>
                          </figcaption>
                        </div>
                        <!-- <div class="col-lg-2 text-right">
                                                    <a href="javascript:void(0);" class="btn-green wh-bg" (click)="education(row)"><i
                                                            class="fas fa-pen"></i> EDIT</a>
                                                </div> -->
                      </div>
                    </div>
                  </div>

                  <div class="card" *ngIf="experianceAllRecords?.length > '0'">
                    <h2>
                      Experience
                      <!-- <span><a href="javascript:void(0);" class="btn-green" (click)="experiance('NEW')">ADD
                                                    EXPERIENCE</a></span> -->
                    </h2>
                    <div
                      class="card-mid-description"
                      *ngFor="let row of experianceAllRecords; let i = index"
                    >
                      <div class="row">
                        <div class="col-lg-10">
                          <figcaption>
                            <span class="d-block"
                              >{{ row.company }}
                              <span class="verifi-icon"
                                ><img
                                  src="../../../../assets/images/Verification-icon.png" /></span
                            ></span>
                            <strong class="d-block">{{ row.title }}</strong>
                            <p class="mb-0">
                              {{ row.startDate | date }} –
                              {{
                                row.endDate == null
                                  ? "Present "
                                  : (row.endDate | date)
                              }}
                              • {{ yearClculation(row) }} years
                            </p>
                          </figcaption>
                        </div>
                        <!-- <div class="col-lg-2 text-right">
                                                    <a href="javascript:void(0);" class="btn-green wh-bg" (click)="experiance(row)"><i
                                                            class="fas fa-pen"></i> EDIT</a>
                                                </div> -->
                      </div>
                    </div>
                  </div>

                  <div class="card" *ngIf="licenseAllRecords?.length > '0'">
                    <h2>
                      Licenses & certifications
                      <!-- <span><a href="javascript:void(0);" (click)="certifiate('NEW')"
                                                    class="btn-green">ADD LICENSES & CERTIFICATIONS</a></span> -->
                    </h2>
                    <div
                      class="card-mid-description"
                      *ngFor="let row of licenseAllRecords; let i = index"
                    >
                      <div class="row">
                        <div class="col-lg-10">
                          <figcaption>
                            <strong class="d-block">{{ row.name }}</strong>
                            <p class="mb-0">{{ row.issuingOrganization }}</p>
                            <p class="mb-0">
                              Issued - {{ row.issueDate | date }} •
                              {{
                                row.endDate == null
                                  ? "Present"
                                  : (row.endDate | date)
                              }}
                            </p>
                            <p class="mb-0">
                              <small
                                >Credential ID : {{ row.credentialId }}</small
                              >
                            </p>
                          </figcaption>
                        </div>
                        <!-- <div class="col-lg-2 text-right">
                                                    <a href="javascript:void(0);" class="btn-green wh-bg" (click)="certifiate(row)"><i
                                                            class="fas fa-pen"></i> EDIT</a>
                                                </div> -->
                      </div>
                    </div>
                  </div>

                  <div class="card" *ngIf="skillAllRecords?.length > '0'">
                    <h2>Skills</h2>
                    <div class="card-mid-description">
                      <div class="row">
                        <div class="col-lg-12">
                          <figcaption>
                            <mat-form-field
                              class="example-chip-list custom-chip-set"
                              style="border: none"
                            >
                              <mat-chip-list
                                #chipList
                                aria-label="Fruit selection"
                                style="border: none"
                              >
                                <mat-chip
                                  style="border: none"
                                  *ngFor="let fruit of this.skillAllRecords"
                                >
                                  {{ fruit }}
                                </mat-chip>
                              </mat-chip-list>
                            </mat-form-field>
                          </figcaption>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="wheen-no-content"
                *ngIf="this.cvSearchList.length == 0"
              >
                <h4>No preview</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
