import { Component, OnInit } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-emp-campaigns-analytics',
  templateUrl: './emp-campaigns-analytics.component.html',
  styleUrls: ['./emp-campaigns-analytics.component.scss'],
})
export class EmpCampaignsAnalyticsComponent implements OnInit {
  analyticsData = [];
  currentAnalyticsPage = 1;
  analyticsPagination = {
    size: 4,
    start: 0,
    total: 0,
  };

  constructor(private es: EmployerService) {}

  ngOnInit(): void {
    this.getAnalyticsData();
  }

  truncateCampaignName(campaign: string, wordLimit: number): string {
    const words = campaign.split(' ');
    if (words.length > wordLimit) {
      const truncatedWords = words.slice(0, wordLimit);
      return truncatedWords.join(' ') + '...';
    }
    return campaign;
  }

  getAnalyticsData() {
    let params = {
      size: this.analyticsPagination.size,
      start: this.analyticsPagination.start,
      sortParam: 'createdAt',
      sortOrder: 'DESC',
    };
    this.es._getCampaigns(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.analyticsData = res['result'];
        this.analyticsPagination.total = res['pagination']['total'];
        this.analyticsPagination.start = res['pagination']['start'];
      }
    });
  }

  onAnalyticsPageChanged(page: number): void {
    this.analyticsPagination.start = (page - 1) * this.analyticsPagination.size;
    this.currentAnalyticsPage = page;
    this.getAnalyticsData();
  }
}
