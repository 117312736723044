<div class="payment-outer">
  <div class="balance-container">
    <div class="title">Wallet Balance:</div>
    <div class="balance">&nbsp;N{{ this.userAssets?.balance | number : "1.1-1" }}</div>
  </div>
  <input *ngIf="(this.campaignDetails.path !== 'create-campaign' && this.campaignDetails.path !== 'unlock-cv') || this.campaignDetails?.extraAmountRequired > this.auth.getUserBalance()" class="amount-container" type="number" [(ngModel)]="amountToFund" placeholder="Enter amount">
  <div class="actions-container">
    <button
      angular4-paystack
      class="btn btn-primary"
      [key]="this.paystackKey"
      [email]="userDetails?.email"
      [amount]="this.amountToFund*100"
      [ref]="transactionRef"
      (onClose)="paymentCancel()"
      (callback)="paymentDone($event)"
      (paymentInit)="updateTransactionId($event)"
      [disabled]="!this.amountToFund"
      [ngClass]="{
        'btn-disabled': !this.amountToFund
      }"
    >
      Fund Wallet
    </button>
    <button
      *ngIf="this.campaignDetails.path === 'create-campaign' || this.campaignDetails.path === 'unlock-cv'"
      class="btn btn-primary"
      (click)="this.campaignDetails.path === 'create-campaign' ? postCampaign() : unlockCV()"
      [disabled]="this.campaignDetails?.extraAmountRequired > this.auth.getUserBalance()"
      [ngClass]="{
        'btn-disabled': this.campaignDetails?.extraAmountRequired > this.auth.getUserBalance()
      }"
    >
      Pay
    </button>
  </div>
</div>
