<div class="container scrl">
    <div class="close-icon" (click)="onNoClickClose()">
        <span class="close"><a>&#10006;</a></span>
      </div>
    <p class="preview-heading">Preview Jobs <span class="no-of-jobs">{{selectedJobsList.length}} Jobs</span></p>
    <div class="well-views table-responsive">
        <table class="table jobs">
          <tbody>
            <tr *ngFor="let job of selectedJobsList">
              <td class="first-col">{{ formatTitle(job.title) }}</td>
              <td>{{ job.job_location }}</td>
              <td>{{ locationType(job.location_type) }}</td>
              <td>{{ job.minimum_salary }} - {{ job.maximum_salary }}</td>
              <td>
                <button class="btn btn-delete" (click)="deleteAJob(job.id)">
                    <img src="../../../../../../assets/images/delete-icon.png" class="delete-icon"/>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>