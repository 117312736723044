<div class="close-icon">
  <span class="close"><a [mat-dialog-close]="false">&#10006;</a></span>
</div>

<h2 class="modal-heading">{{ data?.message }}</h2>
<p>
  <a href="javascript:void(0);" class="btn-submit" (click)="closeModal()"
    >close</a
  >
</p>
