import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Helper } from '../helper/defult';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(private _authService: AuthService, private _router: Router, private helper: Helper) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let userLocal;
    let userSeeson;
    if (localStorage.getItem('workNigeriaUser')) {
      userLocal = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))));
    }
    if (sessionStorage.getItem('workNigeriaUser')) {
      userSeeson = JSON.parse(this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser'))));
    }
    if (!userLocal && !userSeeson) {
      return true;
    } else {
      if (userLocal) {
        if (userLocal.userType === 'employer' || userSeeson.userType === 'super_admin') {
          this._router.navigate(['/employer-home']);
          return false;
        } else {
          this._router.navigate(['/home']);
          return false;
        }
      }
      if (userSeeson) {
        if (userSeeson.userType === 'employer' || userSeeson.userType === 'super_admin') {
          this._router.navigate(['/employer-home']);
          return false;
        } else {
          this._router.navigate(['/home']);
          return false;
        }
      }

      this._router.navigate(['/home']);
      return false;
    }
    // if(userSeeson) {
    // this._router.navigate([`/${userSeeson.userType}/Dashboard`]);
    //   return false;
    // }
    // if(userLocal) {
    //   this._router.navigate([`/${userLocal.userType}/Dashboard`]);
    //     return false;
    //   }

    // navigate to login page
  }
}
