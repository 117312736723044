<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-main-login">
          <div class="row">
            <div class="col-lg-6 col-12 img-div">
              <img *ngIf="checkUserType !== 'is3MTTFellow' && checkUserType !== 'is3MTTEmployer'"
                [src]= "isSmallScreen ? '../../../../assets/images/login-img-mobile.webp' : '../../../../assets/images/login-img.webp'" alt="Login Employer or Jobseeker" class="login-img" />
              <img *ngIf="checkUserType === 'is3MTTFellow'" [src]="isSmallScreen ? '../../../../assets/images/3mtt-login-img-mobile.webp' : '../../../../assets/images/3mtt-login-img.webp'" alt="3MTT Fellow Login"
                class="mtt-login-img" />
              <img *ngIf="checkUserType === 'is3MTTEmployer'"
                [src]="isSmallScreen ? '../../../../assets/images/3mtt-login-employer-mobile.webp' : '../../../../assets/images/3mtt-login-employer.webp'" alt="3MTT Employer Login" class="mtt-login-img" />
            </div>            
            <!-- <div class="info">
                  <h4>Create your own account</h4>
                </div> -->
            <div class="col-lg-6 col-12 form-section">
              <div class="log-desc">
                <h3 class="heading">Welcome back</h3>
                <p class="sub-heading">
                  Login to your account to get started
                </p>
                <div class="signin-options">
                  <app-google-signin accessedFrom="login" (loginWithGoogle)="googleSignin($event)"></app-google-signin>
                  <!-- <button class="btn-signin ml-3" (click)="signInWithFB()">
                    <img src="../../../../assets/images/fb-circle.png" /> Facebook
                  </button> -->
                  <button class="btn-signin ml-3" (click)="loginWithLinkedin()">
                    <img src="../../../../assets/images/lin1.png" /> LinkedIn
                  </button>
                </div>
                <div class="or-section">
                  <div class="or">or continue with</div>
                </div>
                <div class="signin-options"></div>
                <div class="form-wrap">
                  <form [formGroup]="login" autocomplete="off" class="example-form">
                    <div class="form-group">
                      <mat-form-field class="example-full-width email-fild" appearance="fill">
                        <mat-label>Email</mat-label>
                        <input type="email" matInput placeholder="Email" formControlName="email"
                          [pattern]="emailPattern" />
                      </mat-form-field>
                      <div class="t-left" *ngIf="f.email.touched && f.email.invalid">
                        <span class="text-left text-danger pb-1" *ngIf="f.email.errors.required">Email is
                          required</span>
                        <span class="text-left text-danger pb-1" *ngIf="f.email.errors?.pattern">
                          Email is not valid.
                        </span>
                      </div>
                    </div>
                    <div class="form-group">
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Password</mat-label>
                        <input type="password" matInput placeholder="Password" formControlName="password"
                          [type]="showPassword ? 'text' : 'password'" />
                        <mat-icon matSuffix (click)="togglePasswordVisibility()" style="cursor: pointer">{{
                          showPassword ? "visibility" : "visibility_off"
                          }}</mat-icon>
                      </mat-form-field>
                      <div class="t-left" *ngIf="f.password.touched && f.password.invalid">
                        <span class="text-left text-danger pb-1" *ngIf="f.password.errors.required">Password is required
                        </span>
                      </div>
                    </div>

                    <div class="form-group security-links">
                      <div class="row">
                        <div class="col remember-me-col">
                          <mat-slide-toggle (change)="rememberMe($event)" class="remember-me">
                            Remember Me
                          </mat-slide-toggle>
                        </div>
                        <div class="col forgot-link">
                          <a href="javascript:void(0);" (click)="forgetPassword()" class="forget-password">Forgot
                            password?</a>
                        </div>
                      </div>
                    </div>

                    <div class="form-group mt-4">
                      <input type="submit" value="Log In" class="btn btn-green w-100"  [ngClass]="{'btn-orange' : checkUserType === 'is3MTTEmployer'}" (click)="signin()" />
                    </div>
                  </form>
                  <div class="row signup-option-3mtt" *ngIf="checkUserType === 'is3MTTFellow' || checkUserType === 'is3MTTEmployer'">
                    <p>
                      Don’t have a Worknigeria account?
                      <a href="javascript:void(0);" (click)="signUP()">Sign up!</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row signup-option" *ngIf="checkUserType !== 'is3MTTFellow' && checkUserType !== 'is3MTTEmployer'"> 
      <p>
        Don’t have a Worknigeria account?
        <a href="javascript:void(0);" (click)="signUP()">Sign up!</a>
      </p>
    </div>
  </div>
</div>