<div
  class="container"
  *ngIf="campaignList.length === 0 && !isCampaignBeingAdded"
>
  <div class="top-row">
    <button class="float-right" (click)="startNewCampaign()">
      Start New Campaign
    </button>
  </div>

  <div class="bottom-row">
    <div class="row">
      <img src="assets/images/no-campaign-icon.svg" alt="" />
    </div>
    <div class="row">
      <p class="no-campaign-text">
        You do not have any recent campaign. Click “Start New Campaign” to
        create your first campaign
      </p>
    </div>
  </div>
</div>

<div
  class="container"
  *ngIf="campaignList.length !== 0 && !isCampaignBeingAdded"
>
  <div class="top-row-2">
    <div class="inputs">
      <mat-form-field class="sort-form-field" appearance="fill">
        <mat-label>Sort</mat-label>
        <mat-select>
          <mat-option value="DESC">Descending</mat-option>
          <mat-option value="ASC">Ascending</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="custom-form-field">
        <mat-label>Search Campaign</mat-label>
        <input matInput placeholder="Placeholder" />
      </mat-form-field>
    </div>
    <button class="float-right" (click)="startNewCampaign()">
      Start New Campaign
    </button>
  </div>
  <div class="bottom-row-2">
    <div class="table-heading">
      Below is a list of all your recent campaigns
    </div>
    <div class="well-views table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Campaign Type</th>
            <th>Applicant</th>
            <th>Date Created</th>
            <th>Days Left</th>
            <th>Action</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let campaign of campaignList">
            <td style="padding: 17px 0;">
              <div [ngClass]="getCampaignStatusClass(campaign.status)">
                <span class="pill-text">{{
                  getCampaignStatus(campaign.status)
                }}</span>
              </div>
            </td>
            <td>{{ campaign.type }}</td>
            <td>{{ campaign.applicants }}</td>
            <td>{{ createdAtDate(campaign.createdAt) }}</td>
            <td>{{ daysLeft(campaign.endDate) }}</td>
            <td>
              <label class="toggle">
                <input
                  type="checkbox"
                  [checked]="campaign.status === 'Start'"
                  (change)="toggleCampaignStatus($event, campaign)"
                />
                <span class="slider round"></span>
                <span class="toggle-text"></span>
              </label>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-outline-primary"
                (click)="openExtendPopUp()"
              >
                Extend
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <span class="pagination-text">
          Showing
          {{ campaignsPagination.start + 1 }} to
          {{ campaignsPagination.start + campaignList.length }}
          of {{ campaignsPagination.total }} results
        </span>
        <ngb-pagination
          [page]="currentCampaignsPage"
          (pageChange)="onCampaignsPageChanged($event)"
          [pageSize]="campaignsPagination.size"
          [ellipses]="true"
          [maxSize]="5"
          [rotate]="true"
          [collectionSize]="campaignsPagination.total"
        ></ngb-pagination>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="isCampaignBeingAdded">
  <div class="add-campaign">
    <div class="stepper">
      <div class="step">
        <div class="step-box" [ngClass]="currentPage === 1 ? 'active' : ''">
          1
        </div>
      </div>
      <div class="step-line"></div>
      <div class="step">
        <div class="step-box" [ngClass]="currentPage === 2 ? 'active' : ''">
          2
        </div>
      </div>
      <div class="step-line"></div>
      <div class="step">
        <div class="step-box" [ngClass]="currentPage === 3 ? 'active' : ''">
          3
        </div>
      </div>
    </div>
    <div class="stepper-labels">
      <div class="step-label">Select Job</div>
      <div class="step-label">Set Target Audience</div>
      <div class="step-label">Payment</div>
    </div>
  </div>
  <div class="page-one" *ngIf="currentPage === 1">
    <div class="create-job">
      <div class="create-job-text">
        Select one of your recently posted jobs below, or create a new job to
        run a campaign
      </div>
      <button type="button" class="btn btn-outline-primary">
        Create New Job
      </button>
    </div>
    <div class="well-views table-responsive">
      <table class="table jobs">
        <thead>
          <tr>
            <th></th>
            <th>JOB TITLE</th>
            <th>LOCATION</th>
            <th>TYPE</th>
            <th>SALARY</th>
            <th>DATE POSTED</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of jobsList">
            <td>
              <mat-checkbox
                (change)="updateSelectedJobsList($event, job.id)"
                [checked]="selectedJobsList.indexOf(job.id) !== -1"
              ></mat-checkbox>
            </td>
            <td>{{ formatTitle(job.title) }}</td>
            <td>{{ formatTitle(job.job_location) }}</td>
            <td>{{ locationType(job.location_type) }}</td>
            <td>{{ job.minimum_salary }} - {{ job.maximum_salary }}</td>
            <td>{{ formatDateToDDMMYYYY(job.created_at) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <span class="pagination-text">
          Showing {{ jobsPagination.start + 1 }} to
          {{ jobsPagination.start + jobsList.length }}
          of {{ jobsPagination.total }} results
        </span>
        <ngb-pagination
          [page]="currentJobsPage"
          (pageChange)="onJobsPageChanged($event)"
          [pageSize]="jobsPagination.size"
          [ellipses]="true"
          [maxSize]="5"
          [rotate]="true"
          [collectionSize]="jobsPagination.total"
        ></ngb-pagination>
      </div>
      <div class="bottom-actions">
        <button
          type="button"
          (click)="goBack()"
          class="btn btn-outline-primary"
        >
          Back
        </button>
        <button type="button" (click)="goToNextPage()" class="btn btn-primary">
          Save and Continue
        </button>
      </div>
    </div>
  </div>
  <div class="page-two" *ngIf="currentPage === 2">
    <form [formGroup]="newCampaignForm">
      <div class="top-text">Campaign Schedule and Budget</div>
      <div class="selected-jobs-description">
        <div class="sel-jobs-text">
          you have selected {{ selectedJobsList.length }} Jobs
          <button class="btn btn-preview" (click)="previewJobList()">
            Preview
          </button>
        </div>
      </div>
      <div class="outer-wall">
        <div class="set-heading">Set Campaign Title</div>
        <div class="set-title-input">
          <div class="field-prefix"></div>

          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="title"
              placeholder="Enter text e.g (Product team)"
              type="text"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="outer-wall">
        <div class="set-heading">Set Campaign Budget</div>
        <div class="set-budget-description">
          The minimum campaign budget for a single job is
          <span class="green-desc">N10,000</span>. We only deduct your money
          when a candidate applies for your job. An applicant cost
          <span class="red-desc">N750</span>. when you post up 5 jobs under the
          same campaign title you get
          <span class="green-desc">25% Discount</span>
        </div>
        <div class="set-title-input">
          <div class="field-prefix">How much do you want to spend</div>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="budget"
              type="number"
              [min]="10000"
              placeholder="0.0"
            />
          </mat-form-field>
        </div>
        <div class="approximation">
          above amount will get you
          <span class="green-desc">0.0 applicants</span>
        </div>
      </div>
      <div class="outer-wall">
        <div class="set-heading">Set Campaign Duration</div>
        <div class="set-title-input">
          <div class="field-prefix">Start Date</div>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="startDate"
              type="date"
              [min]="today"
              placeholder=""
            />
          </mat-form-field>
        </div>
        <div class="set-title-input">
          <div class="field-prefix">End Date</div>
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="endDate"
              type="date"
              [min]="newCampaignForm.controls['startDate'].value"
              placeholder=""
            />
          </mat-form-field>
        </div>
      </div>
      <div class="bottom-actions button-resize">
        <button
          type="button"
          (click)="goBack()"
          class="btn btn-outline-primary"
        >
          Back
        </button>
        <button type="submit" (click)="goToNextPage()" class="btn btn-primary">
          Proceed
        </button>
      </div>
    </form>
  </div>

  <div class="page-three" *ngIf="currentPage === 3">
    <div class="payment-outer">
      <div class="balance-container">
        <div class="title">Wallet Balance:</div>
        <div class="balance">N{{ currentBalance | number : "1.1-1" }}</div>
      </div>
      <div class="budget-container">
        Budget for Campaign:
        {{ newCampaignForm.value.budget | number : "1.1-1" }}
      </div>
      <div class="actions-container">
        <!-- <button type="button" class="btn btn-primary" (click)="fundWallet()">
          Fund Wallet
        </button> -->
        <button
          angular4-paystack
          class="btn btn-primary"
          [key]="'pk_test_5c635797c32aee412ccf63d990fd9983b4324b34'"
          [email]="userDetails?.email"
          [amount]="
            (this.newCampaignForm.value.budget - this.currentBalance) * 100
          "
          [ref]="transactionRef"
          (onClose)="paymentCancel()"
          (callback)="paymentDone($event)"
          (paymentInit)="updateTransactionId($event)"
          [disabled]="newCampaignForm.value.budget <= currentBalance"
          [ngClass]="{
            'btn-disabled': newCampaignForm.value.budget <= currentBalance
          }"
        >
          Fund Wallet
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="newCampaignForm.value.budget > currentBalance"
          [ngClass]="{
            'btn-disabled': newCampaignForm.value.budget > currentBalance
          }"
          (click)="postCampaign()"
        >
          Pay
        </button>
      </div>
    </div>
  </div>
</div>
