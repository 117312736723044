<div class="createbookmark">
  <form [formGroup]="saveJob">
    <input
      type="text"
      formControlName="collectionName"
      class="form-control"
      placeholder="New list name"
      required
    />
    <div *ngIf="f.collectionName.touched && f.collectionName.invalid">
      <span
        class="text-left text-danger pb-1"
        *ngIf="f.collectionName.errors.required"
      >
        Collection Name id required
      </span>
    </div>
    <input
      type="submit"
      value="CREATE LIST"
      (click)="createNewCollection()"
      class="sendcreate"
    />
  </form>
</div>
