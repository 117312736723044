import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpIOService } from '../services/http-io.service';

@Injectable({ providedIn: 'root' })
export class Helper {
  constructor(private router: Router, private io: HttpIOService) {}

  // for snackar
  setOnTop = () => {
    let elements = document.getElementsByClassName('ng-star-inserted');
    // let length = elements.length;
    // for (let i = 0; i < length; i++) {
    // elements[0].setAttribute();
    // }
  };

  // MAKE FORMCONTROLE TOUCH //
  public markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  toBinary(string) {
    const codeUnits = Uint16Array.from(
      { length: string.length },
      (element, index) => string.charCodeAt(index)
    );
    const charCodes = new Uint8Array(codeUnits.buffer);

    let result = '';
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  fromBinary(binary) {
    const bytes = Uint8Array.from({ length: binary.length }, (element, index) =>
      binary.charCodeAt(index)
    );
    const charCodes = new Uint16Array(bytes.buffer);

    let result = '';
    charCodes.forEach((char) => {
      result += String.fromCharCode(char);
    });
    return result;
  }

  // Email Verification //
  emailVerify(email: any) {
    let value = this.io
      .apicall2(
        { email: email, path: 'is-email-available' },
        'auth/is-email-available',
        'post'
      )
      .then((res: any) => {
        // console.log(res);
        return { serverResponse: res['serverResponse'] };
        // return;
      })

      .catch((err) => {
        // console.log(err.error);
        let serverResponse;
        if (!err.error.serverResponse) {
          return (serverResponse = {
            serverResponse: {
              code: 0,
              message: 'Please check connectivity',
            },
          });
        } else {
          return (serverResponse = {
            serverResponse: err.error.serverResponse,
          });
        }
      });
    return value;
  }

  // //
  dateFormat(dateValue = '', format = 'dd/MM/yyyy') {
    if (dateValue === '') {
      return dateValue;
    }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format);
    return dateValue;
  }

  // //
  dateFormatUs(dateValue = '') {
    if (dateValue === '') {
      return dateValue;
    }
    const datePipe = new DatePipe('en-US');
    let dtArr = dateValue.split('/');
    let dtStr = `${dtArr[2]}-${dtArr[1]}-${dtArr[0]}`;
    dateValue = datePipe.transform(dtStr);
    return dateValue;
  }

  // //
  removeNullElement(formData: any) {
    const filteredData = {};
    for (const [key, value] of Object.entries(formData)) {
      if (value == null || value === '') {
        continue;
      }
      filteredData[key] = value;
    }
    return filteredData;
  }

  // //
  isEmptyObject(obj = {}) {
    return !Object.keys(obj).length;
  }

  // //
  isObjectEmpty(Obj) {
    for (const key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  // UPEERCASE FIRST //
  ucFirst(inputString: string) {
    // tslint:disable-next-line: max-line-length
    return typeof inputString === 'string' && inputString.length > 0
      ? inputString.charAt(0).toUpperCase() +
          inputString.substr(1).toLowerCase()
      : inputString;
  }

  // CALCULATE DAYS BETWEEN TWO DATES //
  calculateDiff(fromDate: any, dateSent: any) {
    let currentDate = new Date(fromDate);
    dateSent = new Date(dateSent);
    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  // CONVERT TIME AM/PM TO 24 HRS //
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  };

  convertTime24to12(time = '') {
    let hour = parseInt(time.split(':')[0]);
    let newHour = hour % 12 || 12;
    let newHourx = newHour.toString().length == 2 ? newHour : '0' + newHour;
    let ampm = hour < 12 || hour === 24 ? ' AM' : ' PM';
    return newHourx + ':' + time.split(':')[1] + ampm;
  }

  // SUBSTRACT MINUTES FROM DATE //
  dateMinusMinutes(minutes, date = '') {
    let dt: any;
    if (date !== '') {
      dt = new Date(date);
    } else {
      dt = new Date();
    }
    return new Date(dt.setMinutes(dt.getMinutes() - minutes)).toString();
  }

  // HOUR TO MINUTES CONVERT // ex 1:15 HRS => 75 //
  convertHrsToMin(hrs) {
    let hm = hrs.split(':');
    let hours = parseInt(hm[0]);
    let minutes = parseInt(hm[1]);
    let newMin = hours * 60;
    return newMin + minutes;
  }

  // GET VIDEO DURATION //
  getVideoDuration(fileSrc) {
    let vDuration = [];
    const video = document.createElement('video');
    const fileURL = URL.createObjectURL(fileSrc); // files.target.files[i]
    video.src = fileURL;
    video.ondurationchange = function () {
      if (video.duration > 0) {
        const duration: number = video.duration;
        const minutes = duration / 60;
        const seconds = video.duration % 60;
        const vInMin = `${~~minutes}:${~~seconds}`;
        const vInSec = ~~minutes * 60 + ~~seconds;
        vDuration.push({ seconds: vInSec, minutes: vInMin, raw: duration });
      }
    };
    return vDuration;
  }
}
