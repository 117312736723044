import { Component, Inject, OnInit } from '@angular/core';
import { waitForAsync } from '@angular/core/testing';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-savedjob',
  templateUrl: './savedjob.component.html',
  styleUrls: ['./savedjob.component.scss'],
})
export class SavedjobComponent implements OnInit {
  show: boolean = false;
  userDeatils: any;
  companyData: any;
  saveJob: UntypedFormGroup;
  collectionRecords: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SavedjobComponent>
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    // this.getCollection();
  }

  ngOnInit(): void {
    // console.log(this.data);
    // console.log( this.userDeatils);
    // if (this.data['data'].isSaved == true) {}
    this.getCollection();
    this.saveJob = this.fb.group({
      collectionName: ['', Validators.required],
    });
  }
  // Validation Controls //
  get f() {
    return this.saveJob.controls;
  }
  getCollection() {
    let jsonData = {
      id: this.userDeatils.id,
      path: 'collection-list',
    };
    this.io._getCollection(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.collectionRecords = res['result'].collectionList;
        // console.log( this.collectionRecords)
      }
    });
  }
  saveExsitCollection(perticularCollection) {
    let jsonData = {
      path: 'saved-job',
      jobId: this.data['data'].id,
      id: this.userDeatils.id,
      collectionId: perticularCollection.id,
      jobProviderId: this.data['data'].job_provider_id,
      title: this.data['data'].title,
      jobSeekerName: `${this.userDeatils.firstName} ${this.userDeatils.lastName}`,
      companyId: this.data['data'].company_id,
    };
    // console.log(jsonData);
    this.io._saveJob(jsonData).subscribe((res: any) => {
      // console.log(res.serverResponse.message);
      if (res.serverResponse.code == 200) {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('save');
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('save');
      }
    });
  }
  createNewList() {
    this.show = true;
    // console.log("555555555")
  }

  saveNewCollection() {
    if (this.saveJob.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.saveJob);
      return false;
    }
    let jsonData = {
      path: 'saved-job',
      jobId: this.data['data'].id,
      id: this.userDeatils.id,
      collectionName: this.saveJob.get('collectionName').value,
      jobProviderId: this.data['data'].job_provider_id,
      title: this.data['data'].title,
      jobSeekerName: `${this.userDeatils.firstName} ${this.userDeatils.lastName}`,
      companyId: this.data['data'].company_id,
    };
    // console.log(jsonData);
    this.io._saveJob(jsonData).subscribe((res: any) => {
      // console.log(res);
      if (res.serverResponse.code == 200) {
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('save');
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.dialogRef.close('save');
      }
    });
  }
  // ngOnDestroy() {
  //   console.log("+++++++++++++++++++++++++++")
  //   this.dialogRef.close("");
  // }
}
