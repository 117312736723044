<!-- This is for Employer Side Bar -->
<div class="sidebar" *ngIf="userDetails.userType === 'employer'">

  <div class="frame-container" [ngClass]="currentElement === 'overview' ? 'active' : ''" *ngIf="userDetails.isSuperAdmin"
    (click)="selectElement('overview')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/overview-admin.svg" />
        <div class="wallet-label">Overview</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'job-overview' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('job-overview')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/campaigns-icon.svg" />
        <div class="wallet-label">Job Overview</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'job-management' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('job-management')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/management-sidenav.png" />
        <div class="wallet-label">Job Management</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'search-cv' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('search-cv')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/searchCV-sidenav.png" />
        <div class="wallet-label">Search CV</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'promote-job' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('promote-job')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/promote-sidenav.png" />
        <div class="wallet-label">Promote Job</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'payment' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('payment')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/payment-sidenav.png" />
        <div class="wallet-label">Payment</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'help' ? 'active' : ''" (click)="selectElement('help')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/help-sidenav.png" />
        <div class="wallet-label">Help</div>
      </div>
    </div>
  </div>
</div>

<!-- This is for Super Admin -->
<div class="sidebar" *ngIf="userDetails.userType === 'super_admin'">
  <!-- <div class="frame-container" [ngClass]="currentElement === 'overview' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('overview')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/overview-admin-icon.svg" />
        <div class="wallet-label">Overview</div>
      </div>
    </div>
  </div> -->

  <div class="frame-container" [ngClass]="currentElement === 'employers' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('employers')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/employers-icon.svg" />
        <div class="wallet-label">Employers</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'jobseekers' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('jobseekers')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/jobseekers-icon.svg" />
        <div class="wallet-label">Jobseekers</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'jobs' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('jobs')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/jobs-icon.svg" />
        <div class="wallet-label">Jobs</div>
      </div>
    </div>
  </div>

  <div class="frame-container" [ngClass]="currentElement === 'upload-cv' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('upload-cv')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/upload-cv-icon.svg" />
        <div class="wallet-label">Upload CV</div>
      </div>
    </div>
  </div>
<!-- 
  <div class="frame-container" [ngClass]="currentElement === 'settings' ? 'active' : ''" *ngIf="!userDetails.isSuperAdmin"
    (click)="selectElement('settings')">
    <div class="frame-2">
      <div class="frame-3">
        <img class="campaign-icon" src="assets/images/settings-icon.svg" />
        <div class="wallet-label">Settings</div>
      </div>
    </div>
  </div> -->
</div>

<div class="card-container">
  <div class="dashboard-ad" *ngFor="let card of cards; let i = index; trackBy: trackByFn"
    [style.opacity]="currentCardIndex === i ? '1' : '0'">
    <img [src]="card.image" />
    <h1 class={{card.headingClass}}>{{ card.title }}</h1>
    <p *ngIf="card.subtitle && card.subtitle !== ''">{{ card.subtitle }}</p>
    <button class={{card.buttonClass}} (click)="handleButtonClick(card.buttonAction)">{{ card.buttonText }}</button>
  </div>
</div>