<div class="employer-ui">
    <div *ngIf="!selectedEmployer && !detailsOfJob">
        <h2 class="emp-heading">Employers</h2>
        <div class="search-section">
            <div class="search-container">
                <div class="search-bar">
                    <img src="../../../../../../assets/images/search-icon.svg" alt="Search Icon" class="search-icon">
                    <input type="text" placeholder="Search Employer or Company" [(ngModel)]="searchQuery" (ngModelChange)="checkSearchQuery()">
                    <button (click)="onSearch()" class="search-button">Search</button>
                </div>
            </div>
            <div class="date-container">
                <div class="date-input-wrapper">
                    <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" class="date-input" placeholder="Start from" [(ngModel)]="startDate" #dateInput>
                    <img *ngIf="!startDate" 
                        src="../../../../../../assets/images/calendar-icon.svg" 
                        alt="Calendar Icon" 
                        class="calendar-icon" 
                        (click)="dateInput.click()">

                    <img *ngIf="startDate" 
                        src="../../../../../../assets/images/cal-cancel.svg" 
                        alt="Clear Icon" 
                        class="calendar-icon" 
                        (click)="clearStartDate()">
                </div>
                <div class="date-input-wrapper">
                    <input type="text" onfocus="(this.type='date')" onblur="(this.type='text')" class="date-input" placeholder="To" [(ngModel)]="endDate" #dateInput2>
                    <img *ngIf="!endDate" 
                        src="../../../../../../assets/images/calendar-icon.svg" 
                        alt="Calendar Icon" 
                        class="calendar-icon" 
                        (click)="dateInput2.click()">

                    <img *ngIf="endDate" 
                        src="../../../../../../assets/images/cal-cancel.svg" 
                        alt="Clear Icon" 
                        class="calendar-icon" 
                        (click)="clearEndDate()">
                </div>
            </div>
            <div class="btn-set">
                <a href="javascript:void(0);" class="btn border" (click)="exportEmployers()"><img
                        src="../assets/images/el.png" alt="" /></a>
            </div>
        </div>
        <div class="emp-table-section">
            <table class="table table-for-employer">
                <thead>
                    <tr>
                        <th>EMPLOYER</th>
                        <th>EMAIL</th>
                        <th>PLAN</th>
                        <th>ROLES</th>
                        <th>STATUS</th>
                        <th></th>
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let emp of paginatedRoles">
                        <td>{{ truncateString(emp.companyName) }}</td>
                        <td>{{ emp.email }}</td>
                        <td>{{ emp.subscriptionPlan }}</td>
                        <td>{{ emp.totalRoles }}</td>
                        <td>
                            <div class="dropdown-container">
                                <select [disabled]="true" [(ngModel)]="emp.verified" (ngModelChange)="onVerificationChange(emp)"
                                    class="custom-dropdown" [ngClass]="emp.verified ? 'green-back' : 'red-back'">
                                    <option [ngStyle]="{ 'background-color': 'white', 'border': 'none', 'border-radius' : '0' }" value="false">Need Verification
                                    </option>
                                    <option [ngStyle]="{ 'background-color': 'white', 'border': 'none', 'border-radius' : '0' }" value="true">Verified</option>
                                </select>
                            </div>
                        </td>
                        <td><img src="../../../../../../assets/images/green-msg-icon.png" class="msg-icon" (click)="messageOpen(emp.employerDetails)"/></td>
                        <td class="d-flex align-items-center gap-2">
                            <label class="toggle">
                                <input type="checkbox" (change)="activeInactive(emp.primaryContactPersonId)" [checked]="emp.isUserActive" />
                                <span class="slider round"></span>
                                <span class="toggle-text"></span>
                            </label>
                            <button class="btn-view" (click)="navigateToEmployer(emp)">
                                View
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation" class="mb-1">
                <ngb-pagination [collectionSize]="this.searchInitiated ? filteredEmployeeList.length : employersList?.employerData.length" [(page)]="currentPage"
                    [pageSize]="pageSize" (pageChange)="onPageChanged($event)" [maxSize]="10" [ellipses]="false">
                </ngb-pagination>
            </nav>
        </div>
    </div>
    <div *ngIf="selectedEmployer && !detailsOfJob">
        <div class="main-container">
            <h2 class="emp-heading">{{ selectedEmployer.companyName }}</h2>
                <div class="job-search-container">
                    <div>
                        <select class="sort-bar" [(ngModel)]="orderSelected" (change)="onOrderChange()">
                            <option value="" disabled selected>Sort</option>
                            <option value="desc">Newest to Oldest</option>
                            <option value="asc">Oldest to Newest</option>
                        </select>
                    </div>
                    <div class="search-bar">
                        <input type="text" placeholder="Search Jobs" [(ngModel)]="jobSearch">
                        <img src="../../../../../../assets/images/search-icon.svg" alt="Search Icon" class="search-icon" (click)="searchEmployerJob()">
                    </div>
                    <div class="btn-set">
                        <a href="javascript:void(0);" class="btn border" (click)="exportJobs(0, selectedEmployer.employerDetails.id)"><img
                                src="../assets/images/el.png" alt="" /></a>
                    </div>
                </div>
        </div>
        <app-jobs-table [jobProviderId]="selectedEmployer.primaryContactPersonId" (selectedJob)="handlejobSelected($event)" [jobSearch]="jobSearchQuery"></app-jobs-table>
    </div>
    <div *ngIf="detailsOfJob">
        <div class="back-div">
            <button class="back-btn" (click)="backToJobs()">
                <img src="../../../../../../assets/images/back-arrow-btn.svg" class="back-btn-img">
                Return to Jobs
            </button>
        </div>
        <app-job-view [detailsOfJob]="detailsOfJob"></app-job-view>
    </div>
</div>