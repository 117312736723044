import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-emp-job-preview',
  templateUrl: './emp-job-preview.component.html',
  styleUrls: ['./emp-job-preview.component.scss']
})
export class EmpJobPreviewComponent implements OnInit {

  selectedJobsList: any;
  selectedJobsListIds: any;
  constructor(
    public dialogRef: MatDialogRef<EmpJobPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.selectedJobsList = this.data['filteredJobs'];
  }

  onNoClickClose() {
    this.dialogRef.close(this.selectedJobsList.map(job => job.id) );
  }

  truncateTitle(campaign: string, wordLimit: number): string {
    const words = campaign.split(' ');
    if (words.length > wordLimit) {
      const truncatedWords = words.slice(0, wordLimit);
      return truncatedWords.join(' ') + '...';
    }
  }

  locationType(loc: any) {
    switch (loc) {
      case 'REMOTE':
        return 'Remote';
      case 'OUT_OF_COUNTRY':
        return 'Out of Country';
      case 'NIGERA':
        return 'Onsite';
    }
  }

  formatTitle(jobTitle: string) {
    return jobTitle
      .toLowerCase()
      .split(' ') 
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '); 
  }

  deleteAJob(jobId: any) {
    this.selectedJobsList = this.selectedJobsList.filter(job => job.id !== jobId);
  }

}
