// export-emp-jobs.service.ts
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root'
})

// Converts the JsonData for Analytics to an Excel Sheet
export class ExportAnalyticsDataService {

  constructor() { }

  // Gets the data in json form, converts it to excel with the right formatting and column divisions
  genExcelCompanyBasic(data: any): void {
    // Convert the analyticsList to a worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.formatData(data));

    // Create a new workbook and add the worksheet to it
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Analytics Data');

    // Generate a binary string representing the workbook
    const wbout: string = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Convert the binary string to a Blob
    const blob: Blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });

    // Save the Blob as an Excel file
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'analytics_data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  /* Format the data as needed for the Excel sheet
  * Here, we'll flatten the data structure to a single array of objects 
  * @params {array} -> containing the flattened data to be added in the excel sheet
  * @returns -> array of objects
  */
  private formatData(data: any): any[] {
    const formattedData = [];

    // Add summary data
    formattedData.push({
      Metric: 'Total Employers', Value: data.totalEmployers
    });
    formattedData.push({
      Metric: 'Total Active Fellows', Value: data.totalActiveFellows
    });
    formattedData.push({
      Metric: 'Total Roles', Value: data.totalRoles
    });
    formattedData.push({
      Metric: 'Total Open Roles', Value: data.totalOpenRoles
    });
    formattedData.push({
      Metric: 'Total Closed Roles', Value: data.totalClosedRoles
    });

    // Create a map for companies to merge active roles and total roles
    const companyMap: { [key: string]: any } = {};

    // Add active roles grouped by company to the map
    data.totalActiveRolesGroupedByCompany.forEach((item: any) => {
      if (!companyMap[item.value]) {
        companyMap[item.value] = { 'Company Name': item.value };
      }
      companyMap[item.value]['Active Roles'] = item.count;
    });

    // Add total roles grouped by company to the map
    data.totalRolesGroupedByCompany.forEach((item: any) => {
      if (!companyMap[item.value]) {
        companyMap[item.value] = { 'Company Name': item.value };
      }
      companyMap[item.value]['Total No. of Roles'] = item.count;
    });

    // Add roles grouped by location
    data.totalRolesGroupedByLocation.forEach((item: any) => {
      if (!companyMap[item.value]) {
        companyMap[item.value] = { 'Location': item.value };
      }
      companyMap[item.value]['No. of Jobs'] = item.count;
    });

    // Convert the map to an array
    for (const key in companyMap) {
      formattedData.push(companyMap[key]);
    }

    return formattedData;
  }

  /*  converting a string into an ArrayBuffer, helps sving the file as .xlxs
  * @params {string} -> The string containing the analytics data
  * @returns {array} -> An Arraybuffer to save the data in excel
  */
  private s2ab(s: string): ArrayBuffer {
    // Create a new ArrayBuffer with the same length as the string
    const buf = new ArrayBuffer(s.length);

    // Create a Uint8Array view of the buffer
    const view = new Uint8Array(buf);
    
    // Loop through each character in the string
    for (let i = 0; i < s.length; i++) {
      // Get the character code (integer between 0 and 255) of the character at index i
      // Assign it to the corresponding index in the Uint8Array
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }
}
