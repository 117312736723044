import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from '../../../../helper/defult';
import { HttpIOService } from 'src/app/services/http-io.service';
import { SignUpComponent } from '../../sign-up/sign-up.component';

@Component({
  selector: 'app-complete-registration-employer',
  templateUrl: './complete-registration-employer.component.html',
  styleUrls: ['./complete-registration-employer.component.scss'],
})
export class CompleteRegistrationEmployerComponent implements OnInit {
  completeRegiEmplyer: UntypedFormGroup;
  passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  email: any;
  role: any;
  GuestUserjobId: any;
  public showPassword: boolean = false;
  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    let guestUserByselected = sessionStorage.getItem('guestUserSelectedJob');
    if (guestUserByselected) {
      this.GuestUserjobId = JSON.parse(
        atob(sessionStorage.getItem('guestUserSelectedJob'))
      );
    }
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.role = params['role'];
    });
  }

  ngOnInit(): void {
    this.validationForm();
  }

  // Form Insilize //
  validationForm() {
    this.completeRegiEmplyer = this.fb.group({
      email: [this.email, Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordPattern),
        ]),
      ],
      userType: ['employer', Validators.required],
      companyName: ['', Validators.required],
      path: ['register-job-provider', Validators.required],
    });
  }

  // Validation Controls //
  get f() {
    return this.completeRegiEmplyer.controls;
  }

  // Back to Modal //
  signUP() {
    this.router.navigate(['/signup'], {
      queryParams: { _role: 'employer', email: this.email },
    });
  }
  // Show Pasword //
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  // Submit Registrasion //
  completeRegistrasion() {
    if (this.completeRegiEmplyer.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.completeRegiEmplyer);
      return false;
    }
    this.io
      .apicall(this.completeRegiEmplyer.value, 'auth/register-job-provider')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          // if (this.GuestUserjobId) {
          //   this.router.navigate(['/job-search'])
          // }
          // else{
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your account has been successfully created. Please check your email to verify your account',

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/login']);
          // }
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
  }
}
