import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
})
export class CompleteRegistrationComponent implements OnInit {
  selectedTabIndex: number = 0;
  jobseeker: boolean = false;
  employer: boolean = false;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  role: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      this.role = params['role'];
    });
  }

  ngOnInit(): void {
    this.tabArrange();
  }

  // Tap Disablesd by Its Role Name //
  tabArrange() {
    if (this.role == 'jobseeker') {
      this.selectedTabIndex = 0;
      this.employer = true;
    }
    if (this.role == 'employer') {
      this.selectedTabIndex = 1;
      this.jobseeker = true;
    }
  }
}
