<div *ngIf="show">
  <div class="close-icon" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h3>Apply via email or log in</h3>
  <hr />
  <p>
    Already a member of WorkNigeria?
    <a
      (click)="onNoClick()"
      routerLink="/login"
      style="color: #3eab70; text-decoration: none; font-weight: 700"
      >Sign in first</a
    >
  </p>
  <div class="bookmarklisting">
    <div class="profilelogo ng-star-inserted">
      <img src="../../../assets/images/sm-Logo.png" />
      <h4 style="margin-bottom: 0">
        <strong style="color: #000">From: {{ this.guestUser.email }} </strong>
        <span
          style="
            font-size: 14px;
            color: #909090;
            line-height: 16px;
            margin-top: 2px;
          "
          >Subject: Application for {{ jobRecords?.title }},
          {{ jobRecords?.company_name }}</span
        >
      </h4>
    </div>
  </div>
  <hr style="border-color: #3eab70; opacity: 1" />
  <mat-form-field class="example-full-width custom-border-none">
    <mat-label>Write a message (optional)</mat-label>
    <textarea
      type="text"
      matInput
      placeholder="Write a message"
      [(ngModel)]="htmlContent2"
    ></textarea>
  </mat-form-field>
  <br />
  <br />
  <!-- <p class="text-right">
        <button mat-raised-button style="border:1px solid #000;">Attach file</button>&nbsp;
        <button mat-raised-button color="primary" (click)="applyJob()">Apply</button>
    </p> -->
  <!-- <div class="text-right" style="display: flex;
    justify-content: flex-end;">
        <div class="custom-file-upload" style="width:100px;">
            <button mat-raised-button style="border:1px solid #000;">Attach file</button>
            <input type="file" accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="onSelectFile($event)" name="file" style="width: 100%;" />
            <p style="color: #000;" class="uploadText">{{fileName}}</p>
        </div>

        <div class="custom-file-upload" style="width:100px;">
            <button mat-raised-button style="border:1px solid #000;">Attach file cover</button>
            <input type="file" accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="onSelectFileCoverLetter($event)" name="file" style="width: 100%;" />
            <p style="color: #000;" class="uploadText">{{fileNameCoverLetter}}</p>
        </div>


       
        <button mat-raised-button color="primary" (click)="applyJob()" style="margin-left:10px;">Apply</button>
    </div> -->
  <div class="upload-pdf">
    <div class="custom-file-upload">
      <button mat-raised-button class="file-upload-btn">
        <i>+</i> UPLOAD CV
      </button>
      <input
        type="file"
        accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="onSelectFile($event)"
        name="file"
      />
    </div>
    <p style="color: #3eab70">{{ fileName }}</p>
  </div>
  <br />
  <div class="upload-pdf">
    <div class="custom-file-upload">
      <button mat-raised-button class="file-upload-btn">
        <i>+</i> UPLOAD COVER LETTER
      </button>
      <input
        type="file"
        accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        (change)="onSelectFileCoverLetter($event)"
        name="file"
      />
    </div>
    <p style="color: #3eab70">{{ fileNameCoverLetter }}</p>
  </div>
  <p class="text-right cusgap-mobile">
    <button mat-flat-button (click)="onNoClick()" style="color: #3eab70">
      CANCEL</button
    >&nbsp;
    <button mat-raised-button color="primary" (click)="applyJob()">
      APPLY NOW
    </button>
  </p>
</div>

<div *ngIf="!show">
  <div class="close-icon" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <h3>Apply via email</h3>
  <hr />
  <div class="bookmarklisting">
    <div class="profilelogo ng-star-inserted">
      <img src="../../../assets/images/sm-Logo.png" />
      <h4 style="margin-bottom: 0">
        <strong style="color: #000">From: {{ this.userDeatils.email }} </strong>
        <span
          style="
            font-size: 14px;
            color: #909090;
            line-height: 16px;
            margin-top: 2px;
          "
          >Subject: Application for {{ jobRecords?.title }},
          {{ jobRecords?.company_name }}</span
        >
      </h4>
    </div>
  </div>
  <hr style="border-color: #3eab70; opacity: 1" />
  <!-- <P>Select a CV from your profile or upload attachments.</P>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>CV</mat-label>
        <mat-select (selectionChange)="selectByUploadDocs($event)">
            <mat-option value="select CV">select CV</mat-option>
            <mat-option *ngFor="let row of uploadDocsRecords;let i=index" [value]="row">
                {{row?.documentName}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
  <!-- <p><input type="text" placeholder="Write a message (optional)" /></p>accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" -->
  <!-- <hr style="border-color: #3EAB70;opacity: 1;">
    <P>Select a Cover letter from your profile or upload attachments.</P>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Cover letter</mat-label>
        <mat-select (selectionChange)="selectByUploadDocsCoverLetter($event)">
            <mat-option value="select Cover">select Cover</mat-option>
            <mat-option *ngFor="let row of  this.uploadDocsRecordsCoverLetter;let i=index" [value]="row">
                {{row.documentName}}
            </mat-option>
        </mat-select>
    </mat-form-field> -->
  <!-- <p><input type="text" placeholder="Write a message (optional)" /></p> -->
  <!-- <mat-form-field class="example-full-width custom-border-none">
        <mat-label>Write a message (optional)</mat-label>
        <input type="text" matInput placeholder="Write a message" [(ngModel)]="htmlContent2">
    </mat-form-field>
    <br />
    <br />
    <div class="text-right" style="display: flex;
                                  justify-content: flex-end;">
        <div class="custom-file-upload" style="width:100px;">
            <button mat-raised-button style="border:1px solid #000;" [disabled]="applyButton">Attach file</button>
            <input *ngIf="!applyButton" type="file" (change)="onSelectFile($event)" name="file" style="width: 100%;"
                accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
            <p style="color: #000;" class="uploadText">{{fileName}}</p>
        </div>

        <div class="custom-file-upload" style="width:100px;">
            <button mat-raised-button style="border:1px solid #000;" [disabled]="applyButton">Attach file cover</button>
            <input *ngIf="!applyButton" type="file" (change)="onSelectFileCoverLetter($event)" name="file"
                style="width: 100%;"
                accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
            <p style="color: #000;" class="uploadText">{{fileNameCoverLetter}}</p>
        </div> -->

  <!-- <button mat-raised-button style="border:1px solid #000;">Attach file</button>&nbsp; -->
  <!-- <button mat-raised-button color="primary" (click)="applyJob()" style="margin-left:10px;">Apply</button> -->

  <mat-form-field class="example-full-width custom-border-none">
    <mat-label>Write a message (optional)</mat-label>
    <textarea
      type="text"
      matInput
      placeholder="Write a message"
      [(ngModel)]="htmlContent2"
    ></textarea>
  </mat-form-field>

  <div class="row align-items-center">
    <div class="col-lg-12">
      <P>Select a CV from your profile or upload attachments.</P>
    </div>
    <div class="col-lg-6">
      <div class="custom-file-upload">
        <button mat-raised-button class="file-upload-btn w-100">
          <i>+</i> UPLOAD CV
        </button>
        <input
          *ngIf="!applyButton"
          type="file"
          accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          (change)="onSelectFile($event)"
          name="file"
        />
      </div>
      <!-- <p style="color: #3EAB70;" *ngIf="!applyButton">{{fileName}}</p> -->
    </div>
    <div class="col-lg-6">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>CV</mat-label>
        <mat-select
          (selectionChange)="selectByUploadDocs($event)"
          [(ngModel)]="selectedCV"
        >
          <mat-option value="select CV">select CV</mat-option>
          <mat-option
            *ngFor="let row of uploadDocsRecords; let i = index"
            [value]="row?.id"
          >
            {{ row?.documentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <div class="custom-file-upload">
        <button mat-raised-button class="file-upload-btn w-100">
          <i>+</i> UPLOAD COVER LETTER
        </button>
        <input
          type="file"
          *ngIf="!applyButtonCover"
          accept="application/pdf,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          (change)="onSelectFileCoverLetter($event)"
          name="file"
        />
      </div>
      <!-- <p style="color: #3EAB70;" *ngIf="!applyButtonCover">{{fileNameCoverLetter}}</p> -->
    </div>
    <div class="col-lg-6">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>COVER LETTER</mat-label>
        <mat-select
          (selectionChange)="selectByUploadDocsCoverLetter($event)"
          [(ngModel)]="selectedCVcover"
        >
          <mat-option
            *ngFor="let row of uploadDocsRecordsCoverLetter; let i = index"
            [value]="row?.id"
          >
            {{ row?.documentName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-lg-12">
    <p class="text-right">
      <button mat-flat-button (click)="onNoClick()" style="color: #3eab70">
        CANCEL</button
      >&nbsp;
      <button mat-raised-button color="primary" (click)="applyJob()">
        APPLY NOW
      </button>
    </p>
  </div>
</div>
