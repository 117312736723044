import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {
  constructor(private router: Router, private dataService: DataService) {}

  ngOnInit(): void {}
  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  navigateToSearchJob() {
    this.router.navigate(['/job-search']);
  }

  navigateToTraining() {
    this.router.navigate(['/training']);
  }
}
