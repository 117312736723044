<div class="container">
  <p class="campaign-performance-heading">Campaign Performance</p>
  <div class="well-views table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Campaign</th>
          <th class="text-center">Impressions</th>
          <th class="text-center">Clicks</th>
          <th class="text-center">Applicants</th>
          <th class="text-center">Budget</th>
          <th class="text-center">Spend</th>
        </tr>
      </thead>
      <tbody *ngIf="analyticsData.length !== 0">
        <ng-container *ngFor="let data of analyticsData; let isFirst = first">
          <tr>
            <td class="table-cols f-bold">
              <div [style.padding-top.px]="isFirst ? 20 : 0">
                {{ truncateCampaignName(data.type, 3) }}
              </div>
            </td>
            <td class="table-cols text-center">
              <div [style.padding-top.px]="isFirst ? 20 : 0">
                {{ data.impressions }}
              </div>
            </td>
            <td class="table-cols text-center">
              <div [style.padding-top.px]="isFirst ? 20 : 0">
                {{ data.clicks }}
              </div>
            </td>
            <td class="table-cols text-center">
              <div [style.padding-top.px]="isFirst ? 20 : 0">
                {{ data.applicants }}
              </div>
            </td>
            <td class="table-cols text-center">
              <div [style.padding-top.px]="isFirst ? 20 : 0">
                {{ data.budget }}
              </div>
            </td>
            <td class="table-cols text-center text-red">
              <div [style.padding-top.px]="isFirst ? 20 : 0">
                {{ data.utilizedBudget }}
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="pagination" *ngIf="analyticsData.length !== 0">
      <span class="pagination-text">
        Showing
        {{ analyticsPagination.start + 1 }} to
        {{ analyticsPagination.start + analyticsData.length }}
        of {{ analyticsPagination.total }} results
      </span>
      <ngb-pagination
        [page]="currentAnalyticsPage"
        (pageChange)="onAnalyticsPageChanged($event)"
        [pageSize]="analyticsPagination.size"
        [ellipses]="true"
        [maxSize]="5"
        [rotate]="true"
        [collectionSize]="analyticsPagination.total"
      ></ngb-pagination>
    </div>
    <div class="container" *ngIf="analyticsData.length === 0">
      <div class="bottom-row">
        <div class="row mb-3">
          <img src="assets/images/no-campaign-icon.svg" alt="" />
        </div>
        <div class="row">
          <p class="no-campaign-text">
            You do not have not done any campaign at this time. <b>Therefore to analytics to display</b> Go to campaign on the left menu and click <b>“New Campaign”</b> to start your first campaign
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
