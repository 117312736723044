<div class="auth-wrap common-padd">
  <!-- <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="log-lt">
                <h3>Please wait...</h3>
                <div class="form-wrap">
                  <div class="form-group">
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <mat-spinner
                        style="margin: 0 auto"
                        mode="indeterminate"
                      ></mat-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img src="../../../../assets/images/auth-rt.png" alt="" />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>
