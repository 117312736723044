import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-view-profile-data',
  templateUrl: './view-profile-data.component.html',
  styleUrls: ['./view-profile-data.component.scss'],
})
export class ViewProfileDataComponent implements OnInit {
  userDeatils: any;
  companyData: any;
  educationAllRecords: any = [];
  experianceAllRecords: any = [];
  licenseAllRecords: any = [];
  profileRecords: any = [];
  skillAllRecords: any = [];
  fileName: any;
  fileToUpload: any;
  uploadFileResult: any;
  base64File: string | ArrayBuffer;
  show: boolean = false;
  shareProfileId: any;
  constructor(
    private titleService: Title,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    // private dialogRef: MatDialogRef<JobSeekerProfileComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    this.activeRoute.queryParams.subscribe((event) => {
      this.shareProfileId = event['id'];
    });
  }

  ngOnInit(): void {
    // console.log(this.shareProfileId)
    this.getAllDetails();
  }

  getAllDetails() {
    let jsonData = {
      publicId: String(this.shareProfileId),
      path: 'get-job-seeker-public',
    };
    this.io._JSallDetailsByPublickId(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res)
        this.profileRecords = res['result'].userData;
        this.educationAllRecords =
          res['result'].userData.educations == undefined
            ? []
            : res['result'].userData.educations;
        this.experianceAllRecords =
          res['result'].userData.experience == undefined
            ? []
            : res['result'].userData.experience;
        this.licenseAllRecords =
          res['result'].userData.license == undefined
            ? []
            : res['result'].userData.license;
        this.skillAllRecords =
          res['result'].userData.skills == undefined
            ? []
            : res['result'].userData.skills;
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // });
      }
      if (this.userDeatils) {
        if (this.profileRecords.id == this.userDeatils.id) {
          this.show = true;
        }
      }
      this.workNigeriaProfileCompleteORnot();
    });
  }

  workNigeriaProfileCompleteORnot() {
    if (
      this.educationAllRecords.length == 0 &&
      this.experianceAllRecords.length == 0 &&
      this.licenseAllRecords.length == 0 &&
      this.skillAllRecords.length == 0
    ) {
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'User is yet to create profile.',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
    }
  }

  yearClculation(data) {
    // console.log(data)
    if (data.startDate) {
      if (data.endDate === '' || data.endDate === null) {
        let thisYear = new Date().getFullYear();
        let dtArr = data.startDate.split('-');
        let final = thisYear - Number(dtArr[0]);
        // console.log(thisYear,final)
        return final;
      }
    }
    // console.log(data)
    if (data.startDate && data.endDate) {
      let dtstart = data.startDate.split('-');
      let dtend = data.endDate.split('-');
      let final = dtend[0] - dtstart[0];
      return final;
    }
    return '';
  }
}
