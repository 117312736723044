<section class="employer-home mb-2">
  <div class="row align-items-center">
    <div class="col-lg-6 text-col">
      <h1 class="para-1">Find & <span class="hire-text">Hire</span> The <br><span class="right-text">Right
          Professionals</span></h1>
      <h3 class="para-2">From Nigeria’s Largest workforce!</h3>
      <div class="header-btns-div">
        <button href="javascript:void(0);" class="nbtn-orange" (click)="postAjob()" style="cursor: pointer">Post a
          Job</button>
        <button href="javascript:void(0);" class="nbtn-white" (click)="navigateToContactUs('Use our hiring team')"
          style="cursor: pointer">Use our
          Hiring Team</button>
      </div>
      <h5 class="para-3">You’ll get email alerts when candidates apply.</h5>
    </div>
    <!-- <div class="col-lg-2"></div> -->
    <div class="col-lg-6">
      <div class="img"><img class="banner-img" src="../assets/images/employer-header.webp" /></div>
    </div>
  </div>
</section>
<!-- Section for the ticker -->
<section class="slider-container">
  <p class="trust-text">Trusted By: </p>
  <div class="ticker-outer-container">
    <div class="ticker-inner-container">
      <div class="ticker-container">
        <div *ngFor="let logo of logos" class="ticker-item">
          <img [src]="logo" class="ticker-img" alt="Logo" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="cards-section">
  <div class="row">
    <h2 class="services-heading">Services we offer</h2>
  </div>
  <div class="row">
    <div class="card-container">
      <div class="card services-card h-card">
        <div class="div-flex">
          <div>
            <h2 class="card-heading card-1-margin">Promote Your Jobs To Reach Larger Audience</h2>
            <p class="card-para">Promoting your job helps you find the best talent quickly and easily</p>
          </div>
          <button href="javascript:void(0);" class="nbtn-orange mt-2 padding-get-verified" (click)="promoteJob()">Get
            Started</button>
        </div>
        <div class="card-img-div">
          <img class="emp-promote-job" src="../../../../assets/images/emp-promote-job.webp" />
        </div>
      </div>
      <div class="card services-card h-card">
        <div class="div-flex">
          <div>
            <h2 class="card-heading card-1-margin">Verified employers get more applications</h2>
            <p class="card-para">Keeping job seekers safe is our priority. We provide more visibility to employers who
              get verified by WorkNigeria.</p>
          </div>
          <button href="javascript:void(0);" class="nbtn-orange padding-get-verified" (click)="verification()">Verify
            Account</button>
        </div>
        <div class="card-img-div pl-25">
          <img class="emp-promote-job" src="../../../../assets/images/emp-verify-img.webp" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="card-container">
      <div class="card services-card-2 col-2 h-card">
        <div class="div-flex">
          <div>
            <h2 class="card-heading card-1-margin">Seamless Employee&nbsp;Verification and Background Checks</h2>
            <p class="card-para">We carry out thorough verification of employee's certifications, trainings, or
              educational claims.</p>
          </div>
          <button href="javascript:void(0);" class="nbtn-green padding-get-verified"
            (click)="navigateToContactUs('Send A Request')">Send A Request</button>
        </div>
        <div class="card-img-div">
          <img class="emp-verification" src="../../../../assets/images/emp-verification.webp" />
        </div>
      </div>
      <div class="card services-card-2 col-3 h-card">
        <div class="div-flex">
          <div>
            <h2 class="card-heading card-1-margin">Staff Training and Development</h2>
            <p class="card-para">Upskill your staff with our series of online courses or get dedicated training
              workshop from our training experts</p>
          </div>
          <button href="javascript:void(0);" class="nbtn-green padding-get-verified"
            (click)="navigateToTraining()">Learn More</button>
        </div>
        <div class="card-img-div">
          <img class="emp-promote-job" src="../../../../assets/images/emp-staff-training.webp" />
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="or-continue">
      <div class="or" (click)="goToServices()">See All Services</div>
    </div>
  </div>
</section>
<section class="client-section">
  <div class="row emp-client-heading-row">
    <h1 class="emp-client-heading"><b>Why our clients love working with us</b></h1>
  </div>
  <div class="row">
    <div class="col-lg-1">
    </div>
    <div class="col-lg-10">
      <div #widgetsContent class="sayings-card-container">
        <div class="card sayings-card" *ngFor="let card of client_sayings let i = index">
          <img [src]="card.imgSrc" alt="{{ card.heading }}" class="company-icons" />
          <h2 [ngClass]="{'first-heading': i === 0}">{{ card.heading }}</h2>
          <!-- <div class="star-icons">
            <ng-container *ngFor="let star of [1, 2, 3, 4, 5]">
              <i class="fas fa-solid fa-star star-sharp"
                [ngClass]="{ 'golden-star': star <= card.stars, 'white-star': star > card.stars }"></i>
            </ng-container>
          </div> -->
          <p [innerHTML]="card.text" [ngClass]="{'first-text': i === 0}"></p>
        </div>
      </div>
    </div>
    <div class="col-lg-1">
    </div>
  </div>
</section>
<section class="recruitment-section">
  <div class="row">
    <p class="recruitment-heading">Why Choose Us As Your Recruitment Partner?</p>
  </div>
  <div class="row">
    <div class="recruitment-card-container">
      <div class="card recruitment-card" *ngFor="let card of recruitment_cards">
        <img [src]="card.imgSrc" alt="{{ card.heading }}" />
        <h2>{{ card.heading }}</h2>
        <p>{{ card.text }}</p>
      </div>
    </div>
  </div>
</section>
<section class="get-verified-now common-padd longgap">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-1"></div>
      <div class="col-lg-5">
        <div class="get-verified-now-info">
          <h2 class="search-cv-heading">Triumph Amidst Hiring Complexity</h2>
          <p class="search-cv-text">
            Accelerate your recruitment process with our candidate database. Get unlimited profiles that matches your
            preferences
          </p>
          <button href="javascript:void(0);" class="btn nbtn-orange" (click)="searchCV()">Search CV</button>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center">
        <img src="../assets/images/search-cv.webp" class="search-cv-img" />
      </div>
      <div class="col-lg-1"></div>
    </div>
  </div>
</section>