<main>
  <section class="homesearch-area common-padd common-padd-lt-rt white">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <h1>Search for a job</h1>
          <div class="homesearchform">
            <form [formGroup]="homeLogOutSearch">
              <div class="fildwrap">
                <input
                  type="search"
                  formControlName="searchText"
                  class="form-control"
                  id="homesearch"
                  placeholder="Search Keywords"
                />
                <!-- <span *ngIf="g.searchText.touched && g.searchText.invalid">
                                    <span class="text-left text-danger pb-1" *ngIf="g.searchText.errors.required">
                                        Search Text is required
                                    </span>
                                </span> -->
              </div>
              <div class="fildwrap">
                <input
                  type="text"
                  class="form-control"
                  placeholder="All Locations"
                  formControlName="job_location"
                />
                <!-- <span *ngIf="g.job_location.touched && g.job_location.invalid">
                                    <span class="text-left text-danger pb-1" *ngIf="g.job_location.errors.required">
                                        Location is required
                                    </span>
                                </span> -->
              </div>
              <button
                type="submit"
                value="Find Job"
                (click)="search()"
                class="submit"
              >
                Find Job
              </button>
            </form>
          </div>
          <!-- <div class="advancesearch">
                        <h6>Advance Search</h6>
                        <ul>
                            <li><a href="#">Search Keywords</a></li>
                            <li><a href="#">Search Keywords</a></li>
                            <li><a href="#">Search Keywords</a></li>
                            <li><a href="#">Search Keywords</a></li>
                        </ul>
                    </div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="homeBanner-area common-padd-lt-rt common-padd white">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="homeResource-banner">
            <div class="img"><img src="../assets/images/img-1.png" /></div>
            <div class="homeResourceinfo">
              <h2>
                Nigeria’s Largest Work<br />
                Resource
              </h2>
              <div class="btn-wrap">
                <a
                  href="javascript:void(0);"
                  class="btn-bdr-white"
                  routerLink="/job-search"
                  >Browse all Jobs</a
                >
                <a
                  href="javascript:void(0);"
                  *ngIf="this.postButtonShow"
                  (click)="postOpportunities()"
                  class="btn-bdr-white"
                  >Post Opportunity</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="jobsCategories-area common-padd-lt-rt padd-btm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="jobsCategories-info fifty text-center">
            <h2>Jobs Categories</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4" *ngFor="let row of jobCatagoryTypeList">
          <div class="jobsCategories-name">
            <a
              href="javascript:void(0);"
              (click)="categotyWiseJobSearch(row)"
              >{{ row.categoryName }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="featured-jobs-area common-padd-lt-rt">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <div class="featured-jobs-list">
            <h3>Featured Jobs</h3>
            <span *ngFor="let row of featuredJobsList; let i = index">
              <div class="bodyjobsearch-box bgcolor" (click)="jobDetails(row)">
                <div class="bodyjobsearch-img">
                  <img
                    [src]="
                      row?.logo
                        ? row?.logo
                        : '../../../assets/images/sm-Logo.png'
                    "
                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                  />
                  <div class="jobtag">
                    <div *ngIf="row?.job_type == 'hot'" class="jobtag hot">
                      <img src="../../../../assets/images/fire.png" /> Hot
                    </div>
                    <div *ngIf="row?.job_type == 'cold'" class="jobtag cool">
                      <img src="../../../../assets/images/cool.png" /> Cold
                    </div>
                    <div *ngIf="row?.job_type == 'new'" class="jobtag new">
                      <img src="../../../../assets/images/new.png" /> New
                    </div>
                  </div>
                </div>
                <div class="bodyjobsearch-desc">
                  <h5>{{ row.title }}</h5>
                  <h6>
                    {{ row.company_name }}
                    <span class="verifi-icon"
                      ><img
                        src="../../../../assets/images/Verification-icon.png" /></span
                    ><br />
                    <b>{{ row?.job_location }}</b>
                  </h6>
                  <p>
                    {{ row?.employment_type }} |
                    {{ eventExpireIn(row?.created_at) }} |
                    <span *ngIf="row?.minimum_salary != '0'"
                      >{{ row?.currency }} {{ row?.minimum_salary | number }}</span
                    >
                    <span *ngIf="row?.maximum_salary != '0'">
                      - {{ row?.currency }} {{ row?.maximum_salary | number }}</span
                    >
                    <span *ngIf="row?.minimum_salary == '0'">
                      Salary - Not specified or see the job description</span
                    >
                  </p>
                </div>
                <div class="bodyjobsearch-share">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" (click)="shareJob(row)"
                        ><i class="fas fa-share-alt"></i> SHARE</a
                      >
                    </li>
                    <!-- <li><a href="javascript:void(0);" (click)="savedJob(row)"><i
                                                    class="far fa-heart"></i> SAVE</a></li> -->
                    <li *ngIf="!row?.isSaved">
                      <a href="javascript:void(0);" (click)="savedJob(row)"
                        ><i class="far fa-heart"></i> SAVE</a
                      >
                    </li>
                    <li *ngIf="row?.isSaved">
                      <a
                        class="active"
                        href="javascript:void(0);"
                        (click)="savedJob(row)"
                        ><i class="far fa-heart"></i> SAVED</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="featured-jobs-list hotjobs">
            <h3>Hot Jobs</h3>
            <span *ngFor="let row of hotJobsList; let i = index">
              <div class="bodyjobsearch-box bgcolor" (click)="jobDetails(row)">
                <div class="bodyjobsearch-img">
                  <img
                    [src]="
                      row?.logo
                        ? row?.logo
                        : '../../../assets/images/sm-Logo.png'
                    "
                    onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                  />
                  <div class="jobtag">
                    <div *ngIf="row?.job_type == 'hot'" class="jobtag hot">
                      <img src="../../../../assets/images/fire.png" /> Hot
                    </div>
                    <div *ngIf="row?.job_type == 'cold'" class="jobtag cool">
                      <img src="../../../../assets/images/cool.png" /> Cold
                    </div>
                    <div *ngIf="row?.job_type == 'new'" class="jobtag new">
                      <img src="../../../../assets/images/new.png" /> New
                    </div>
                  </div>
                </div>
                <div class="bodyjobsearch-desc">
                  <h5>{{ row.title }}</h5>
                  <h6>
                    {{ row.company_name }}
                    <span class="verifi-icon"
                      ><img
                        src="../../../../assets/images/Verification-icon.png" /></span
                    ><br />
                    <b>{{ row?.job_location }}</b>
                  </h6>
                  <p>
                    {{ row?.employment_type }} |
                    {{ eventExpireIn(row?.created_at) }} |
                    <span *ngIf="row?.minimum_salary != '0'"
                      >{{ row?.currency }} {{ row?.minimum_salary | number }}</span
                    >
                    <span *ngIf="row?.maximum_salary != '0'">
                      - {{ row?.currency }} {{ row?.maximum_salary | number }}</span
                    >
                    <span *ngIf="row?.minimum_salary == '0'">
                      Salary - Not specified or see the job description</span
                    >
                  </p>
                </div>
                <div class="bodyjobsearch-share">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" (click)="shareJob(row)"
                        ><i class="fas fa-share-alt"></i> SHARE</a
                      >
                    </li>
                    <!-- <li><a href="javascript:void(0);" (click)="savedJob(row)"><i
                                                    class="far fa-heart"></i> SAVE</a></li> -->
                    <li *ngIf="!row?.isSaved">
                      <a href="javascript:void(0);" (click)="savedJob(row)"
                        ><i class="far fa-heart"></i> SAVE</a
                      >
                    </li>
                    <li *ngIf="row?.isSaved">
                      <a
                        class="active"
                        href="javascript:void(0);"
                        (click)="savedJob(row)"
                        ><i class="far fa-heart"></i> SAVED</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="builder-area" *ngIf="buildSectionShow">
    <div class="img"><img src="../assets/images/img-2.png" alt="" /></div>
    <div class="builder-info">
      <a
        href="javascript:void(0);"
        (click)="buildCVsection()"
        class="btn btn-red"
        >Build Your Resume</a
      >
    </div>
  </section>
  <section class="verifyemployers-area common-padd-lt-rt common-padd">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="verifyemployers-info">
            <div class="img">
              <img
                src="../assets/images/Landing-Page-Verification-Section.png"
                alt="We Verify Our Employers"
              />
            </div>
            <!--<div class="verifyemployers-desc">
                            <h3>We Verify Our Employers</h3>
                            <h5>If you want to verify your account then please Lets us know.</h5>
                        </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="jobAlertFeature-area common-padd-lt-rt padd-btm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="hdr fifty text-center">
            <h2>Job Alert Feature</h2>
            <p>
              Are you looking for the perfect job ? Browse our weekly blog to
              make sure you have all the necessary resoures to prepare for
              interviews and get ready for the future .
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 p-0">
          <div class="alert-bell" (click)="afterLoginAlertPage()">
            <img src="../assets/images/Landing-Page-job-Alerts.png" />
          </div>
        </div>
        <div class="col-lg-4 p-0">
          <div class="alertFeature">
            <h5>Email Frequency</h5>
            <div class="addspace no-select">
              <select class="select-option">
                <option>Daily</option>
              </select>
              <select class="select-option">
                <option>Weekly</option>
              </select>
              <select class="select-option">
                <option>Never</option>
              </select>
              <a href="#" class="btn-home">home</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 p-0">
          <div class="alertFeature">
            <h5>Alert Name</h5>
            <div class="addspace">
              <ul>
                <li>
                  Executive Officer Graduate School
                  <span
                    ><a href="#"
                      ><img src="../assets/images/bell-sm.png" alt="" /></a
                  ></span>
                </li>
                <li>
                  UX Designer
                  <span
                    ><a href="#"
                      ><img src="../assets/images/bell-sm.png" alt="" /></a
                  ></span>
                </li>
                <li>
                  Product Manager
                  <span
                    ><a href="#"
                      ><img src="../assets/images/bell-sm.png" alt="" /></a
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
