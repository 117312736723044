<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row">
            <div class="col-lg-6">
              <div class="log-lt">
                <h3>Sign Up</h3>
                <p class="sub-heading">
                  By continuing, you agree to the WorkNigeria <br /><a
                    href="javascript:void(0);"
                    routerLink="/terms-and-conditions"
                    >Terms and Conditions</a
                  >
                  and
                  <a href="javascript:void(0);" routerLink="/privacy-policy"
                    >Privacy Policy.</a
                  >
                </p>
                <mat-tab-group
                  #tabs
                  [(selectedIndex)]="selectedTabIndex"
                  mat-align-tabs="center"
                >
                  <mat-tab label="JOBSEEKER">
                    <app-sign-up-jon-seeker></app-sign-up-jon-seeker>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img src="../../../../assets/images/signup.png" alt="" />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
