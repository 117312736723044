import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpIOService } from 'src/app/services/http-io.service';
import Observable from 'zen-observable-ts';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  value: any;
  private data = new BehaviorSubject<any>(null);
  sharedData = this.data.asObservable();
  private userAssetsSubject = new BehaviorSubject<any>(null);
  userAssets = this.userAssetsSubject.asObservable();
  // constructor() { }
  // private value = new BehaviorSubject({value:'',title:''});
  // getData = this.value.asObservable();

  // setData(data:any){
  //   this.value.next(data);
  // }

  constructor(private io: HttpIOService) { }


  nextData(data: any) {
    this.data.next(data)
  }

  setData(data: any) {
    this.value = data;
  }

  getData() {
    return new Observable((observer) => {
      observer.next(this.value);
    });
  }

  nextUserData(data: any) {
    this.data.next(data)
  }

  setUserData(data: any) {
    this.value = data;
  }

  getUserData() {
    return new Observable((observer) => {
      observer.next(this.value);
    });
  }
  
  loadUpdatedAssets() {
    return this.io._getUserAssets().pipe(
      tap((res: any) => {
        this.userAssetsSubject.next(res.result);
        this.setUserData(res.result);
      })
    );
  }
}
