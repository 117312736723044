import { Component, Input } from '@angular/core';
import { SharejobComponent } from 'src/app/views/job-search/sharejob/sharejob.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HttpIOService } from 'src/app/services/http-io.service';


@Component({
  selector: 'app-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.scss']
})
export class JobViewComponent {
  @Input() detailsOfJob: any;
  clickSection: any = '';
  jobDesc: any = '';
  qualifications: any = '';

  constructor(
    private dialog: MatDialog,
    private io: HttpIOService,
  ) { }

  scrollToElement(id: any) {
    this.clickSection = id;
    setTimeout(() => {
      const element = <HTMLElement>document.querySelector(`#${id}`);
      element.scrollIntoView({ behavior: 'smooth' });
    });
  }

  splitDescription() {
    const description = this.detailsOfJob?.description || '';

    const keyword = this.detectKeyword(description);

    if (keyword) {
      const parts = description.split(keyword);
      this.jobDesc = parts[0];
      this.qualifications = parts[1];
    } else {
      // Handle the case where the keyword is not found
      this.jobDesc = description;
      this.qualifications = '';
    }
  }

  detectKeyword(description: string): string | null {
    const possibleKeywords = ["Requirements:", "Requirement:", "Requirement", "Requirements", "Qualifications", "Qualifications:"];
    for (const keyword of possibleKeywords) {
      if (description.includes(keyword)) {
        return keyword;
      }
    }
    return '';
  }

  shareJob() {
    const dialogRef = this.dialog.open(SharejobComponent, {
      height: 'auto',
      width: '450px',
      data: { data: this.detailsOfJob },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === true) {
        return;
      }
    });
  }

  toggleJobStatus() {
    let jobStatus = this.detailsOfJob.job_status;
    if (jobStatus === 'active') {
      let jsonData = {
        path: 'inactive-job',
        id: this.detailsOfJob.id,
      };
      this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => {});
    } else {
      let jsonData = {
        path: 'active-job',
        id: this.detailsOfJob.id,
      };
      this.io._activeForEmpManage(jsonData).subscribe((res: any) => {});
    }
  }

}
