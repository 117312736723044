import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-emp-campaign-management',
  templateUrl: './emp-campaign-management.component.html',
  styleUrls: ['./emp-campaign-management.component.scss'],
})
export class EmpCampaignManagementComponent implements OnInit {
  selectedElement: string = 'campaigns';
  isCampaignBeingAdded: boolean = false;
  userDetails: any;
  companyData: any;

  constructor(
    private helper: Helper,
    private auth: AuthService,
    private employerService: EmployerService
  ) {
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyData();
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
        this.companyData = this.auth.getCurrentCompanyDataInSessoin();
      }
    });
  }

  ngOnInit(): void {
    let jsonData = {
      path: 'get-job-seeker',
      id: this.userDetails.id,
    };
    this.employerService._getCurrentUser(jsonData).subscribe((res) => {
      if (res['serverResponse'].code === 200) {
        this.auth.setCurrentUserDetails(res['result']['userData']);
        this.auth.setCurrentUserDetailsInSessoin(res['result']['userData']);
      }
    });
  }

  setIsCampaignBeingAdded(value: boolean): void {
    this.isCampaignBeingAdded = value;
  }
}
