import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SNSClient, PublishCommand } from '@aws-sdk/client-sns';
import { AuthService } from './services/auth.service';
import { EmployerService } from './services/employer.service';
import { Helper } from './helper/defult';
import { Meta } from '@angular/platform-browser';

const websocket = new WebSocket(
  'wss://1a9upprxv0.execute-api.us-east-1.amazonaws.com/production'
);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  dynamicClass: boolean = false;
  creds = {
    accessKeyId: 'AKIAYWWKI6TGOE7DIR7M',
    secretAccessKey: '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',
  };
  config = { region: 'us-east-1', credentials: this.creds };
  client = new SNSClient(this.config);
  userDeatils: any;
  companyData: any;

  constructor(
    private empService: EmployerService,
    private auth: AuthService,
    private router: Router,
    private helper: Helper,
    private meta: Meta
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    this.empService.getforWSconnect().subscribe((res) => {
      if (res) {
        console.log(res);
        this.WSconnect();
      }
    });
    this.meta.addTags([
      {
        name: 'description',
        content:
          'WorkNigeria was established with a Singular Vision. To help the vast Nigerian workforce find meaningful jobs. We are also on a mission to upskill them in the best ways possible and help them grow with and within their careers.',
      },

      {
        name: 'keywords',
        content:
          'Jobs in Nigeria, Nigerian job vacancies, Employment opportunities Nigeria, Nigerian job search, Nigerian job portal, Careers in Nigeria, Nigerian job listings, Job vacancies in Lagos, Latest Nigerian jobs, Nigerian job websites',
      },
      {
        name: 'keywords',
        content:
          'Nigerian recruitment agencies, Nigerian government jobs, Graduate jobs in Nigeria, Oil and gas jobs in Nigeria, IT jobs in Nigeria, Banking jobs in Nigeria, Engineering jobs in Nigeria, Teaching jobs in Nigeria, Healthcare jobs in Nigeria, Remote jobs in Nigeria',
      },
    ]);
  }

  ngOnInit(): void {
    if (this.userDeatils) {
      this.WSconnect();
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  async WSconnect() {
    websocket.onopen = (data) =>
      this.onWSOpen(data).then((res: any) => {
        if (res) {
          this.WSsubscribe('chat_room');
          websocket.onmessage = (data) => this.onWNMessageReceived(data);
          websocket.onclose = (data) => this.onWSClose(data);
          websocket.onerror = (data) => this.onWSError(data);
          this.empService.setforWSconnect(null);
        }
      });
  }

  async WSdiconnect() {
    websocket.close();
  }

  async onWSOpen(event) {
    // console.log('Connection open', event);
    return new Promise((resolve, reject) => {
      // console.log('Connection open', event);
      resolve(event);
    });
  }

  async onWSClose(event) {
    console.log('Connection close', event);
  }
  async onWSError(event) {
    console.log('Connection close', event);
  }

  async onWNMessageReceived(data) {
    console.log('here2');
    console.log(data);
    this.empService.setforMessageWSconnect(data);
  }

  async WSsubscribe(topic) {
    // console.log('topic', topic);
    websocket &&
      websocket.send(
        JSON.stringify({
          message: 'subscribe',
          topic: topic,
          userId: this.userDeatils.id,
        })
      );
  }

  title = 'Worknigeria';
}
