import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';

@Component({
  selector: 'app-first-profile-option',
  templateUrl: './first-profile-option.component.html',
  styleUrls: ['./first-profile-option.component.scss'],
})
export class FirstProfileOptionComponent implements OnInit {
  userDeatils: any;
  companyData: any;
  fileName: any;
  fileToUpload: any;
  CVpurserData: any;
  base64Data: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FirstProfileOptionComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {}
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelectFile(event) {
    this.fileName = event.target.files[0].name;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUpload = event.target.files[i];
        const reader = new FileReader();

        reader.onloadend = () => {
          let fileNameExtention = this.fileName.split('.');

          const formData = new FormData();
          formData.append('path', 'parse-resume');
          formData.append('userId', this.userDeatils.id);
          formData.append('file', this.fileToUpload); // Updated line
          formData.append('purpose', 'parseResumeUpload');

          this.io._CVpurse(formData).subscribe(
            (res: any) => {
              if (res.serverResponse.code == 200) {
                this.CVpurserData = res;
                this.io.setforCVpurserData(this.CVpurserData);
                this.dialogRef.close();
                this.router.navigate(['/jobseeker-profile-confirm']);
              }
              if (res.serverResponse.code == 404) {
                this.snackBar
                  .openFromComponent(DynamicSnacbarErrorComponent, {
                    data: res.serverResponse.message,
                    verticalPosition: 'top', // 'top' | 'bottom'
                    horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
                  })
                  .afterOpened()
                  .subscribe(this.helper.setOnTop);
              }
            },
            (err) => console.log(err)
          );
        };

        reader.readAsDataURL(this.fileToUpload);
      }
    }
  }
}
