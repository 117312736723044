import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  verifyemail: UntypedFormGroup;
  text: any;

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService
  ) {
    this.route.queryParams.subscribe((param) => {
      this.text = param['utm_source'];
    });
    // console.log(this.text);
  }

  ngOnInit(): void {
    if (!this.text) {
      // this.snackBar.open('You have come unauthorized','OK',{});
      this.snackBar
        .openFromComponent(DynamicSnacbarSkipComponent, {
          data: 'You have come unauthorized',
          verticalPosition: 'top', // 'top' | 'bottom'
          horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
        })
        .afterOpened()
        .subscribe(this.helper.setOnTop);
      this.router.navigate(['/home']);
    }
    this.formInsli();
    if (this.text) {
      this.veriFyEmail();
    }
  }

  // Create Modal Driven Form //
  formInsli() {
    this.verifyemail = this.fb.group({
      text: [this.text, Validators.compose([Validators.required])],
      path: ['verify-email', Validators.required],
    });

    // this.verifyemail.patchValue({
    //   text:this.text
    // })
  }

  // Validation Controls //
  get f() {
    return this.verifyemail.controls;
  }

  // VERIFY EMAI //
  veriFyEmail() {
    let jsonData = {
      text: this.text,
      path: 'verify-email',
    };

    this.io.apicall(jsonData, 'auth/verify-email').subscribe((res: any) => {
      // console.log(res)
      if (res.serverResponse.code == 200) {
        // let userdata = res['result'].userData

        // this._authService.sendToken('token');
        // this._authService.setCurrentUserDetails(userdata);
        const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
          height: 'auto',
          width: '450px',
          data: { message: res.serverResponse.message },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res === true) {
          }
        });

        this.router.navigate(['/home']);
        // window.location.reload();
      } else {
        const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
          height: 'auto',
          width: '450px',
          data: { message: res.serverResponse.message },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res === true) {
          }
        });
      }
    });
    // .catch(err=>{
    //   if (!err.error.serverResponse) {
    //     const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
    //       height: 'auto',
    //       width: '450px',
    //       data: { message: 'Please check connectivity' }
    //     });
    //     dialogRef.afterClosed().subscribe(res => {
    //       if (res === true) {
    //       }
    //     });
    //   }
    //   else {
    //     const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
    //       height: 'auto',
    //       width: '450px',
    //       data: { message: err.error.serverResponse.message }
    //     });
    //     dialogRef.afterClosed().subscribe(res => {
    //       if (res === true) {
    //       }
    //     });
    //   }
    // })
  }

  // Resend Email //
  // resendEmail(){
  //   if (this.verifyemail.status === 'INVALID') {
  //     this.helper.markFormGroupTouched(this.verifyemail);
  //     return false;
  //   }

  //   this.io.apicall(this.verifyemail.value,'auth/resend-email','post').then((res:any)=>{
  //     if (res.serverResponse.code == 200) {
  //       const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
  //         height: 'auto',
  //         width: '450px',
  //         data: { message: res.serverResponse.message }
  //       });
  //       dialogRef.afterClosed().subscribe(res => {
  //         if (res === true) {
  //         }
  //       });
  //     }
  //     else {
  //         const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
  //           height: 'auto',
  //           width: '450px',
  //           data: { message: res.serverResponse.message }
  //         });
  //         dialogRef.afterClosed().subscribe(res => {
  //           if (res === true) {
  //           }
  //         });

  //     }
  //   }).catch(err=>{
  //     if (!err.error.serverResponse) {
  //       const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
  //         height: 'auto',
  //         width: '450px',
  //         data: { message: 'Please check connectivity' }
  //       });
  //       dialogRef.afterClosed().subscribe(res => {
  //         if (res === true) {
  //         }
  //       });
  //     }
  //     else {
  //       const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
  //         height: 'auto',
  //         width: '450px',
  //         data: { message: err.error.serverResponse.message }
  //       });
  //       dialogRef.afterClosed().subscribe(res => {
  //         if (res === true) {
  //         }
  //       });
  //     }
  //   })
  // }
}
