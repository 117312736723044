import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-filter-description',
  templateUrl: './job-filter-description.component.html',
  styleUrls: ['./job-filter-description.component.scss'],
})
export class JobFilterDescriptionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  someAdditionFunction (a,b){
    return a+b;
  }

}
