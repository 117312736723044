<div class="notifications-bar testbar succes whitebg">
  <div class="tickctn">
    <div class="tickctn-icon"><i class="fas fa-check"></i></div>
    <div class="tickctn-info">
      <p class="wb"><strong>Great!</strong> {{ this.data }}</p>
    </div>
  </div>
  <div class="tickctn-right">
    <p><a href="javascript:void(0);" (click)="close()">
        <i class="fas fa-times"></i
      ></a>
    </p>
  </div>
</div>
