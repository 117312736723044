import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EmpDashPreviewComponent } from '../../employer/emp-dashboard/emp-dashboard-dash/emp-dash-preview/emp-dash-preview.component';
import { EmployerService } from 'src/app/services/employer.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-employer-bundles',
  templateUrl: './employer-bundles.component.html',
  styleUrls: ['./employer-bundles.component.scss']
})
export class EmployerBundlesComponent {
  transactionRef = `BUNDLE_${uuidv4()}`;
  userDetails: any;
  goldPlan: any;
  silverPlan: any;
  userPlan: any;
  bundleId: string;
  paystackKey: string;
  campaignDetails: any;

  constructor(
    private helper: Helper,
    private auth: AuthService,
    private io: HttpIOService,
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private es: EmployerService,
    private dataService: DataService
  ) {
    this.paystackKey = environment.paystack_key
    this.userPlan = {subscriptionPlan: ''}
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
      }
    });
    this.campaignDetails = this.location.getState()
    console.log(this.campaignDetails)
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.bundleId = id
    });
    if(!['cv','campaign', 'pay'].includes(this.bundleId)){
      this.router.navigate(['/employer-home'])
    }
  }

  paymentCancel(): void {
    console.log('Payment Cancelled');
  }

  paymentDone(event: any): void {
    console.log(event)
    if(event.status === 'success'){
      this.io._purchaseBundle({
        bundleId: environment.bundles[this.bundleId],
        transactionId: event.transaction
      }).subscribe(
          (res: any) => {
            if (res.serverResponse.code === 200) {
              // this.loadUpdatedAssets()
              if(this.campaignDetails.path === 'create-campaign'){
                const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                  height: 'auto',
                  width: 'auto',
                  autoFocus: false,
                  data: {
                    data: 'Campaign Payment Successful',
                    from: 'bundle',
                    campaignDetails: {...this.campaignDetails, deductFromWallet: true}
                  },
                })
                dialogRef.afterClosed().subscribe(result => {
                  if(result === 'job-overview'){
                    this.es._getCreditUtilization({numOfJobs: this.campaignDetails?.jobIds?.length}).subscribe((res) => {
                      const dialogRef = this.dialog.open(EmpDashPreviewComponent, {
                        width: '880px',
                        height: '850px',
                        autoFocus: false,
                        data: {
                          ...this.campaignDetails,
                          jobOverview: true, 
                          filteredJobs: this.campaignDetails?.jobs, 
                          creditBreakup: res['result']?.creditBreakup,
                          subscriptionPlan: res['result']?.subscriptionPlan,
                          jobIds: this.campaignDetails?.jobIds
                          },
                      });
                      dialogRef.afterClosed().subscribe(result => {
                        if(result === 'success'){
                          const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                            height: 'auto',
                            width: 'auto',
                            autoFocus: false,
                            data: {
                              data: 'Campaign Successful',
                              campaignDetails: this.campaignDetails
                            },
                          })
                          dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
                        }
                      });
                    });
                  }
                });
              }else if(this.campaignDetails.path === 'unlock-cv'){
                const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                  height: 'auto',
                  width: 'auto',
                  autoFocus: false,
                  data: {
                    data: 'Campaign Payment Successful',
                    from: 'cv-bundle',
                    campaignDetails: this.campaignDetails
                  },
                })
                dialogRef.afterClosed().subscribe(result => {
                  if(result === 'success'){
                    const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                      height: 'auto',
                      width: 'auto',
                      autoFocus: false,
                      data: {
                        data: 'CV Unlocked Successful',
                        campaignDetails: this.campaignDetails
                      },
                    })
                    dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
                  }
                });
              }else{
                const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                  height: 'auto',
                  width: 'auto',
                  autoFocus: false,
                  data: {
                    data: this.bundleId,
                  },
                })
                dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
              }
            } else {
              console.log(res);
            }
          },
          (error: any) => {
            console.error('An error occurred:', error);
          }
        );
    }
  }

  updateTransactionId(event: any): void {
    this.transactionRef = `BUNDLE_${uuidv4()}`;
  }

  goBack() {
    this.location.back();
  }
  navigateToFundWallet(){
    this.router.navigate(['fund-wallet'])
  }
  loadUpdatedAssets() {
    // this.dataService.loadUpdatedAssets().subscribe(() => {});
    this.dataService.setUserData(undefined)
  }
}
