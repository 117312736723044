import { CommonModule } from '@angular/common';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { GeneralRoutingModule } from './general-routing.module';
import { ViewProfileDataComponent } from './view-profile-data/view-profile-data.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { MaterialModule } from '../../modules/material/material.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { MessagingComponent } from './messaging/messaging.component';
import { JobSeekerWantToRecruiterSideComponent } from './job-seeker-want-to-recruiter-side/job-seeker-want-to-recruiter-side.component';
import { RecruiterWantToJobSeekerSideComponent } from './recruiter-want-to-job-seeker-side/recruiter-want-to-job-seeker-side.component';

@NgModule({
  declarations: [
    ViewProfileDataComponent,
    MessagingComponent,
    JobSeekerWantToRecruiterSideComponent,
    RecruiterWantToJobSeekerSideComponent,
  ],
  imports: [
    CommonModule,
    GeneralRoutingModule,
    MatChipsModule,
    SharedModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GeneralModule {}
