import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { CompleteRegistrationComponent } from './complete-registration/complete-registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EmailedCodeComponent } from './emailed-code/emailed-code.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { AuthProcessComponent } from './auth-process/auth-process.component';
import { LoginGuard } from 'src/app/gaurd/login.guard';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServiceComponent } from './service/service.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { CompanyApprovalActionComponent } from './company-approval-action/company-approval-action.component';
import { SuperAdminGuard } from 'src/app/gaurd/super-admin.guard';
import { EmployerHomeComponent } from './employer-home/employer-home.component';
import { EmpHomeGuard } from 'src/app/gaurd/emp-home.guard';
import { EmployerSignUpComponent } from './employer-sign-up/employer-sign-up.component';
import { EmployerSubscriptionsComponent } from './employer-subscriptions/employer-subscriptions.component';
import { EmployerBundlesComponent } from './employer-bundles/employer-bundles.component';

const routes: Routes = [
  { path: '', component: AuthComponent },
  {
    path: 'employer-home',
    component: EmployerHomeComponent,
    data: { title: 'Employer Home' },
    canActivate: [EmpHomeGuard],
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    data: { title: 'Verify Email' },
    canActivate: [],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
    canActivate: [LoginGuard],
  },
  {
    path: 'signup',
    component: SignUpComponent,
    data: { title: 'SignUp' },
    canActivate: [LoginGuard],
  },
  {
    path: 'complete-registration',
    component: CompleteRegistrationComponent,
    data: { title: 'Complete Registration' },
    canActivate: [LoginGuard],
  },
  {
    path: 'choose-employer-subscription',
    component: EmployerSubscriptionsComponent,
    canActivate: [EmpHomeGuard],
  },
  {
    path: 'bundles/:id',
    component: EmployerBundlesComponent,
    canActivate: [EmpHomeGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password' },
  },
  {
    path: 'code-verify',
    component: EmailedCodeComponent,
    data: { title: 'Code Verify' },
    canActivate: [LoginGuard],
  },
  {
    path: 'reset-password',
    component: NewPasswordComponent,
    data: { title: 'Reset Password' },
    canActivate: [LoginGuard],
  },
  {
    path: 'password-changed',
    component: ChangePasswordComponent,
    data: { title: 'Password Changed' },
    canActivate: [LoginGuard],
  },
  {
    path: 'auth-process',
    component: AuthProcessComponent,
    data: { title: 'Auth Process' },
    canActivate: [LoginGuard],
  },
  {
    path: 'app-settings',
    component: AppSettingsComponent,
    data: { title: 'App Settings' },
    canActivate: [],
  },
  {
    path: 'aboutUs',
    component: AboutUsComponent,
    data: { title: 'About Us' },
    canActivate: [],
  },
  {
    path: 'contactUs',
    component: ContactUsComponent,
    data: { title: 'Contact Us' },
    canActivate: [],
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionComponent,
    data: { title: 'Trem & Condition' },
    canActivate: [],
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Privacy-Policy' },
    canActivate: [],
  },
  {
    path: 'services',
    component: ServiceComponent,
    data: { title: 'Services' },
    canActivate: [],
  },

  {
    path: 'companyApprovalAction',
    component: CompanyApprovalActionComponent,
    data: { title: 'Company Approval Action' },
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'signupemployer',
    component: EmployerSignUpComponent,
    data: { title: 'SignUpEmployer' },
    canActivate: [LoginGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
