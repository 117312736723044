<div
  class="outer-frame"
  *ngIf="selectedElement === 'campaigns' && !isCampaignBeingAdded"
>
  <div class="inner-frame">
    <div class="balance-frame">
      <div class="wallet-label">Wallet Balance:</div>
      <div class="amount-value">
        N{{ userDetails.balance | number : "1.1-1" }}
      </div>
    </div>
    <div class="action-frame">
      <div class="fund-button">Fund Wallet</div>
    </div>
  </div>
</div>

<div
  class="spacer"
  *ngIf="
    selectedElement !== 'campaigns' ||
    (selectedElement === 'campaigns' && isCampaignBeingAdded)
  "
></div>

<div class="container-main">
  <div class="sidebar-container">
    <app-emp-campaigns-sidenav
      (elementSelected)="selectedElement = $event; isCampaignBeingAdded = false"
    ></app-emp-campaigns-sidenav>
  </div>

  <div class="content-container">
    <div class="content">
      <ng-container [ngSwitch]="selectedElement">
        <app-emp-campaigns
          (dataEvent)="setIsCampaignBeingAdded($event)"
          *ngSwitchCase="'campaigns'"
        ></app-emp-campaigns>
        <app-emp-campaigns-analytics
          *ngSwitchCase="'analytics'"
        ></app-emp-campaigns-analytics>
        <app-emp-campaigns-wallet
          *ngSwitchCase="'wallet'"
        ></app-emp-campaigns-wallet>
        <app-emp-campaigns-help *ngSwitchCase="'help'"></app-emp-campaigns-help>
      </ng-container>
    </div>
  </div>
</div>
