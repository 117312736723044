import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SuccecErrorModalComponent } from 'src/app/alert-message/succec-error-modal/succec-error-modal.component';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarSkipComponent } from 'src/app/container/elements/dynamic-snacbar-skip/dynamic-snacbar-skip.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from '../../../helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { EmailNotificationComponent } from '../email-notification/email-notification.component';

@Component({
  selector: 'app-auth-process',
  templateUrl: './auth-process.component.html',
  styleUrls: ['./auth-process.component.scss'],
})
export class AuthProcessComponent implements OnInit {
  linkedInToken: any;
  status: any;
  error: any;
  linkdinUserDetails: any;
  linkdeValue: any;
  linkdesignupValue: any;
  processFollowedss: string;
  getUrlRedirection: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    private _authService: AuthService,
    private empService: EmployerService,
  ) {
    this.linkedInToken = this.route.snapshot.queryParams['code'];
    this.status = this.route.snapshot.queryParams['state'];
    this.error = this.route.snapshot.queryParams['error'] || null;

    if (this.status == 'signup') {
      this.processFollowedss = 'signup';
    } else {
      this.processFollowedss = 'login';
    }
    this.getUrlRedirection = sessionStorage.getItem('setUrlRedirection');
  }

  ngOnInit(): void {
    if (this.linkedInToken) {
      this.getLinkedinUseDetails();
    } else {
      // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
      //   height: 'auto',
      //   width: '450px',
      //   data: { message: 'You have not generated Token' }
      // });
      // dialogRef.afterClosed().subscribe(res => {
      //   if (res === true) {
      //   }
      // });
      // this.snackBar.open('You have not generated Token', "OK", {
      // })

      if(this.error && this.error !== 'user_cancelled_login'){
        this.snackBar
          .openFromComponent(DynamicSnacbarSkipComponent, {
            data: 'You have not generated Token',
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
      this.router.navigate(['/login']);
    }
  }

  // Get LinkeDin User Details //
  getLinkedinUseDetails() {
    let payload = {
      code: this.linkedInToken,
      path: 'user-data-from-linked-in',
      processFollowed: this.status,
    };
    this.io
      .apicall(payload, 'auth/user-data-from-linked-in')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.linkdinUserDetails = res['result'];
          // console.log(this.linkedInToken,this.status)
          if (this.status == 'signup') {
            this.signUpwithLinkin();
          } else {
            this.signInWithLinkdin();
          }
        } else {
          // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
          //   height: 'auto',
          //   width: '450px',
          //   data: { message: res.serverResponse.message }
          // });
          // dialogRef.afterClosed().subscribe(res => {
          //   if (res === true) {
          //   }
          // })
          // this.snackBar.open(res.serverResponse.message, "OK", {
          // })
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          if (this.status == 'signup') {
            this.router.navigate(['/signup']);
          } else {
            this.router.navigate(['/login']);
          }
        }
      });
    // .catch(err => {
    //   if (!err.error.serverResponse) {
    //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
    //     //   height: 'auto',
    //     //   width: '450px',
    //     //   data: { message: 'Please check connectivity' }
    //     // });
    //     // dialogRef.afterClosed().subscribe(res => {
    //     //   if (res === true) {
    //     //   }
    //     // })
    //     if (this.status == 'login') {
    //       this.router.navigate(['/login']);
    //     }
    //     else {
    //       this.router.navigate(['/signup']);

    //     }
    //     this.snackBar.open('Please check connectivity', "OK", {
    //     })
    //   }
    //   else {
    //     // const dialogRef = this.dialog.open(SuccecErrorModalComponent, {
    //     //   height: 'auto',
    //     //   width: '450px',
    //     //   data: { message: err.error.serverResponse.message }
    //     // });
    //     // dialogRef.afterClosed().subscribe(res => {
    //     //   if (res === true) {
    //     //   }
    //     // });
    //     this.snackBar.open(err.error.serverResponse.message, "OK", {
    //     })
    //     if (this.status == 'login') {
    //       this.router.navigate(['/login']);
    //     }
    //     else {
    //       this.router.navigate(['/signup']);

    //     }
    //   }
    // })
  }
  // Sign In with Linkedin //
  signInWithLinkdin() {
    let userSocialLogin = {
      socialId: this.linkdinUserDetails.userData.id,
      email: this.linkdinUserDetails.userData.email
        ? this.linkdinUserDetails.userData.email
        : '',
      socialType: 'linkedIn',
      path: 'social-login',
    };
    this.io
      .apicall(userSocialLogin, 'auth/social-login')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.empService.setforWSconnect(res);
          let userdata = res['result'].userData;
          let token = res['result'].token;
          let companyData = res['result'].companyData;

          this._authService.sendToken(token);
          this._authService.setCurrentUserDetails(userdata);
          this._authService.setCurrentCompanyData(companyData);

          if (this.getUrlRedirection) {
            this.router.navigateByUrl(`/${this.getUrlRedirection}`);
            sessionStorage.removeItem('setUrlRedirection');
          }
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'You have been successfully logged in.',
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/home']);
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/login']);
        }
      });
  }
  // Sign Up with Limkedin //
  signUpwithLinkin() {
    if (!this.linkdinUserDetails.userData.email) {
      const dialogRef = this.dialog.open(EmailNotificationComponent, {
        height: 'auto',
        width: '450px',
        hasBackdrop: false,
        data: {},
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // console.log(res);
          this.linkdesignupValue = res;
          // this.loginWithSocialTwitter();
        }
      });
      return false;
    }
    let userSocialLogin = {
      socialId: this.linkdinUserDetails.userData.id,
      email: this.linkdinUserDetails.userData.email
        ? this.linkdinUserDetails.userData.email
        : this.linkdesignupValue,
      socialType: 'linkedIn',
      firstName: this.linkdinUserDetails.userData.firstName,
      lastName: this.linkdinUserDetails.userData.lastName,
      profileImage: this.linkdinUserDetails.userData.img,
      userType: 'jobseeker',
      path: 'social-signup',
    };

    this.io
      .apicall(userSocialLogin, 'auth/social-signup')
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: 'Your account has successfully been created.',
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.signInWithLinkdin()
        } else {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/signup']);
        }
      });
  }
}
