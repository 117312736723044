import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { DownloadBrochurePopupComponent } from 'src/app/container/elements/download-brochure-popup/download-brochure-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-training-development',
  templateUrl: './training-development.component.html',
  styleUrls: ['./training-development.component.scss']
})
export class TrainingDevelopmentComponent implements OnInit {
  upcoming_trainings = [];
  // array for the training solutions cards section
  training_solution_cards = [
    {
      imgSrc: '../../../../assets/images/inplant-icon.svg',
      heading: 'Personalized In-Plant Training',
      text: 'This type of training is customized to meet the specific needs of an organization and is conducted on-site at the company\'\s facility. The training content is tailored to address the unique challenges and requirements of the company, ensuring that employees gain relevant skills and knowledge directly applicable to their work environment.'
    },
    {
      imgSrc: '../../../../assets/images/masterclass-icon.svg',
      heading: 'Masterclasses',
      text: 'Masterclasses are advanced training sessions led by experts in a specific field. These sessions are typically designed for individuals who already have a significant level of expertise and are looking to deepen their knowledge and skills. Masterclasses often involve in-depth exploration of complex topics and may include hands-on activities, case studies, and interactive discussions.'
    },
    {
      imgSrc: '../../../../assets/images/courses-icon.svg',
      heading: 'Open Courses (Physical and Virtual)',
      text: 'Open courses are educational programs available to the general public. They cover a wide range of topics and are designed to accommodate learners from various backgrounds and skill levels. Open courses can be offered physically or via electronics platform, and provide a flexible learning option for individuals seeking to gain new knowledge or improve their skills.'
    },
    {
      imgSrc: '../../../../assets/images/bootcamp-icon.svg',
      heading: 'Bootcamps',
      text: 'Bootcamps are intensive, short-term training programs focused on teaching practical skills, often in a specific field or industry. These programs are designed to rapidly equip participants with the necessary competencies to start a new career or advance in their current job. Bootcamps emphasize hands-on learning, real-world projects, and collaboration, making them highly immersive and demanding.'
    }
  ];

  // List of key and development items
  items = [
    'Human Resources Management',
    'Management and Leadership',
    'Finance and Accounting',
    'Customer Service and Public Relations',
    'Procurement, Inventory and Warehouse Management',
    'Internal Audit and Risk Management',
    'Sales and Marketing',
  ];

  // testimonials array
  testimonials = [
    {
      imgSrc: '../../../../assets/images/ke-icon.png',
      quote: '“I am happy to be part of this training. The facilitator did a great job and I learnt so much from him. A big thank you to Worknigeria for this opportunity”',
      stars: 4,
      companyName: 'Kelechi Eke (The Tonic Technologies)',
    },
    {
      imgSrc: '../../../../assets/images/od-icon.png',
      quote: '“WorkNigeria lives up to their name in the HR industry”',
      stars: 4,
      companyName: 'Obirai Dumebi',
    },
    {
      imgSrc: '../../../../assets/images/ao-icon.png',
      quote: '“The engagement sessions with the pool of professional facilitators were exceptional. The sessions were interactive and learner-centered.”',
      stars: 4,
      companyName: ' Adedayo Ogunmowo (PWAN Group)',
    },
    {
      imgSrc: '../../../../assets/images/ke-icon.png',
      quote: '“Worknigeria’s facilitator\'\s approach to breaking down complex terminologies, coupled with the empowering atmosphere that allowed for free participant input, made the experience incredibly enriching and engaging.”',
      stars: 4,
      companyName: 'Peace Nwachukwu',
    },
    {
      imgSrc: '../../../../assets/images/od-icon.png',
      quote: '“Worknigeria Training sessions were exceptional and highly interactive”',
      stars: 4,
      companyName: 'Michael Ezemba (Landnest Homes and Properties)',
    },
    {
      imgSrc: '../../../../assets/images/ao-icon.png',
      quote: '“Training with Worknigeria was awesome! The facilitators\'\ approach to conducting the program was commendable, especially their dedication to creating a dynamic question and answer avenue that enhanced our learning experience.”',
      stars: 4,
      companyName: 'Oshilaja Olatunji',
    },
    {
      imgSrc: '../../../../assets/images/ao-icon.png',
      quote: '“Worknigeria training was very effective and enlightening.  Your expertise and engaging approach provided valuable insights, enhancing our skills and confidence.”',
      stars: 4,
      companyName: 'Lawal Idris (Yum-Yum Cafe)',
    },
  ];

  colorClasses = ['color1', 'color2', 'color3'];
  onReadMore = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    public dialog: MatDialog,
    private io: HttpIOService,
  ) { }

  ngOnInit(): void {
    this.getUpcomingData()
    this.updateCardsPerPage();
    window.addEventListener('resize', this.updateCardsPerPage.bind(this));
  }

  sendARequest() {
    window.location.href = 'https://bit.ly/wn-training';
  }

  navigateToContactUs(title: any) {
    this.dataService.setData({ value: title, label: title });
    this.router.navigate(['/contactUs']);
  }

  readMore() {
    this.onReadMore = !this.onReadMore;
  }

  // to get the data for the upcoming trainings section
  getUpcomingData() {
    this.io._getUpcomingTrainingData().subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.upcoming_trainings = res['result'];
      }
    })
  }

  checkPhysicalVirtual(is_physical: boolean, is_virtual: boolean) {
    if (is_physical && is_virtual) {
      return 'Physical & Virtual';
    } else if (is_physical && !is_virtual) {
      return 'Physical Only';
    } else if (!is_physical && is_virtual) {
      return 'Virtual Only';
    } else {
      return '';
    }
  }

  downloadBrochure() {
    const dialogRef = this.dialog.open(DownloadBrochurePopupComponent, {
      height: 'auto',
      width: '761px',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe();
  }

  cardsPerPage = window.innerWidth < 768 ? 1 : 3; // 1 card for mobile, 3 for desktop

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateCardsPerPage.bind(this));
  }

  updateCardsPerPage() {
    this.cardsPerPage = window.innerWidth < 768 ? 1 : 3; // Update based on screen width
  }

  /* Displayes distinct groups of cards on each swipe
  * returns {array} -> cardGroups array, which contains non-overlapping groups of cards
  */
  groupedCards() {
    let cardGroups = [];
    for (let i = 0; i < this.testimonials.length; i += this.cardsPerPage) {
      cardGroups.push(this.testimonials.slice(i, i + this.cardsPerPage));
    }
    return cardGroups;
  }

  /* correctly reflect the number of distinct sets of cards to be displayed, including any partial set at the end
  * @returns {number} -> Dot indicators displayed 
  */
  get totalPages() {
    return Math.ceil(this.testimonials.length / this.cardsPerPage);
  }

  /* Takes the name of the company and returns the first two initials
  * @params {string} -> The string containing the company name
  * @returns {string} -> The string of initials
  */
  getInitials(name: string): string {
    const words = name.trim().split(/\s+/);
    if (words.length >= 2) {
      const initials = words.slice(0, 2).map(word => word.charAt(0).toUpperCase()).join('');
      return initials;
    }
    return words.length > 0 ? words[0].charAt(0).toUpperCase() : '';
  }

  /*Takes the index number of the testimonials array to return the color to add behind the initials
  * @params {string} -> The string containing index number
  * @returns {string} -> The string of color to be added
  */
  getColorClass(index: number): string {
    return this.colorClasses[index % this.colorClasses.length];
  }

}