import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from '../../../helper/defult';
import { EmployerService } from 'src/app/services/employer.service';

@Component({
  selector: 'app-company-approval-action',
  templateUrl: './company-approval-action.component.html',
  styleUrls: ['./company-approval-action.component.scss'],
})
export class CompanyApprovalActionComponent implements OnInit {
  company_id: any;
  status: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employerService: EmployerService,
    private snackBar: MatSnackBar,
    public loaderService: NgxSpinnerService,
    private helper: Helper
  ) {
    this.company_id = this.route.snapshot.queryParams['id'];
    this.status = this.route.snapshot.queryParams['approvedStatus'];
  }

  ngOnInit(): void {
    this.loaderService.show();
    let jsonData = {
      path: 'change-company-status',
      company_id: this.company_id,
      status: String(this.status),
    };
    this.employerService
      ._changeCompanyStatus(jsonData)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.loaderService.hide();
          this.router.navigate(['/Dashboard']);
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              // data: "Company Verified successfully"
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
        if (res.serverResponse.code == 400) {
          this.snackBar
            .openFromComponent(DynamicSnacbarErrorComponent, {
              // data: "Company Verified successfully"
              data: res.serverResponse.message,
              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
          this.router.navigate(['/Dashboard']);
        }
      });
    // sessionStorage.removeItem('companyVerificaton')
  }
}
