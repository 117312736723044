<div class="close-icon" (click)="onNoClick()">
  <span class="close" style="cursor: pointer"><a>&#10006;</a></span>
</div>
<div class="new-screen sharedjobmodal">
  <h5 class="share-heading">{{ title }}</h5>
  <div class="googlelogo">
    <div class="img">
      <img class="job-img" [src]="this.pic ? this.pic : '../../../assets/images/sm-Logo.png'"
        onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
    </div>
    <h4 class="m-0 job-title">
      {{ record }}<span>{{ record?.company_name }} - {{jobLocation}}</span>
    </h4>
  </div>

  <hr class="hr-share" />

  <div class="medialink">
    <h6 class="mt-3 share-subheadings">Social media</h6>
    <ul class="d-flex">
      <li>
        <div class="sameline">
          <a href="javascript:void(0);" (click)="openSocialMedia('facebook')" class="linkedin-text">
            <img src="../../../../assets/images/facebook-icon.png" alt="" width="19" height="19" />&nbsp;
            Facebook
          </a>
        </div>
      </li>
      <!-- <li>
                <div class="sameline">
                    <share-buttons [theme]="'modern-light'" [include]="['twitter']" [showText]="true" [show]="5"
                        [url]="url">
                    </share-buttons>
                </div>
            </li> -->
      <li>
        <div class="sameline">
          <a href="javascript:void(0);" (click)="openSocialMedia('whatsapp')" class="email-text d-flex align-items-center">
            <img src="../../../../assets/images/whatsapp-icon.png" alt="" width="30" height="30" />
            Whatsapp
          </a>
        </div>
      </li>
    </ul>
  </div>

  <div class="medialink">
    <h6 class="mt-3 share-subheadings">Messaging & email</h6>
    <ul class="d-flex">
      <li class="">
        <div class="sameline li-linkedin">
          <a href="javascript:void(0);" (click)="openSocialMedia('linkedin')" class="linkedin-text">
            <img src="../../../../assets/images/linkedin.png" alt="" width="18" height="18" />&nbsp;
            linkedin
          </a>
        </div>
      </li>
      <!-- <li>
                <div class="sameline">
                    <share-buttons [theme]="'modern-light'" [include]="['whatsapp']" [showText]="true" [show]="5"
                        [url]="url"></share-buttons>
                </div>
            </li> -->
      <!-- <li *ngIf="detectMob()">
        <div class="sameline">
          <share-buttons
            [theme]="'modern-light'"
            [include]="['messenger']"
            [showText]="true"
            [show]="5"
            [url]="url"
          ></share-buttons>
        </div>
      </li> -->
      <li>
        <div class="sameline">
          <a [href]="'mailto:' +'?subject=' + email_title + '&body=' + url" class="email-text d-flex">
            <img src="../../../../assets/images/email-icon.png" alt="" width="20" height="16" />&nbsp;
            Email
          </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="copylink">
    <h6 class="mt-3 share-subheadings">Copy link</h6>
    <div class="copylinkbx">
      <img src="../../../../assets/images/copy-hook.png" width="20" height="20"/>
      <share-buttons [theme]="'modern-light'" [include]="['copy']" [show]="5" [url]="url"></share-buttons>
      <p class="url-text">{{ url }}</p>
      <img src="../../../../assets/images/copy_icon.png" width="20" height="20" class="copy-icon" (click)="copyUrl()" />
    </div>
  </div>
</div>

<!-- <share-buttons theme="modern-dark"
    [include]="['copy', 'facebook', 'email', 'messenger', 'mix', 'line', 'linkedin', 'pinterest', 'print', 'reddit', 'sms', 'telegram', 'tumblr', 'twitter', 'viber', 'vk', 'xing', 'whatsapp']"
    [showIcon]="true"
    [showText]="true"
    url="https://rodrigo.kamada.com.br/"
    description="Angular Share Buttons"
    twitterAccount="rodrigokamada"
    class="pt-5">
  </share-buttons> -->