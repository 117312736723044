import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-status-reject',
  templateUrl: './change-status-reject.component.html',
  styleUrls: ['./change-status-reject.component.scss'],
})
export class ChangeStatusRejectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
