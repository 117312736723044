import { Component } from '@angular/core';

@Component({
  selector: 'app-emp-dash-help',
  templateUrl: './emp-dash-help.component.html',
  styleUrls: ['./emp-dash-help.component.scss']
})
export class EmpDashHelpComponent {

}
