import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
})
export class AppSettingsComponent implements OnInit {
  appSettingsEmailFrom: UntypedFormGroup;
  appSettingsPasswordFrom: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  userDeatils: any;
  companyData: any;
  public showPassword: boolean = false;
  public showPassword2: boolean = false;
  public showPassword3: boolean = false;
  constructor(
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.emailValidationForm();
    this.passwordValidationForm();
  }
  // Validation Controls //
  get f() {
    return this.appSettingsEmailFrom.controls;
  }
  get g() {
    return this.appSettingsPasswordFrom.controls;
  }

  emailValidationForm() {
    this.appSettingsEmailFrom = this.fb.group({
      oldEmail: [this.userDeatils.email, Validators.required],
      newEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  passwordValidationForm() {
    this.appSettingsPasswordFrom = this.fb.group(
      {
        oldPassword: ['', Validators.required],
        NewPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(this.passwordPattern),
          ]),
        ],
        ConfirmPassword: ['', [Validators.required]],
      },
      {
        validator: this.ConfirmedValidator('NewPassword', 'ConfirmPassword'),
      }
    );
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  // Show Pasword //
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  // Show Pasword //
  public togglePasswordVisibility2(): void {
    this.showPassword2 = !this.showPassword2;
  }
  // Show Pasword //
  public togglePasswordVisibility3(): void {
    this.showPassword3 = !this.showPassword3;
  }
  changeEmail() {
    if (this.appSettingsEmailFrom.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.appSettingsEmailFrom);
      return false;
    }
    let jsonData = {
      path: 'change-email',
      id: this.userDeatils.id,
      email: this.appSettingsEmailFrom.get('newEmail').value,
    };
    this.io._changeEmail(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // });
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.getAllDetails();
      }
      if (res.serverResponse.code == 400) {
        // console.log(res);
        // this.snackBar.open(res.serverResponse.message, "OK", {
        // });
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  changePassWord() {
    if (this.appSettingsPasswordFrom.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.appSettingsPasswordFrom);
      return false;
    }
    let jsonData = {
      path: 'change-password',
      id: this.userDeatils.id,
      old_password: this.appSettingsPasswordFrom.get('oldPassword').value,
      new_password: this.appSettingsPasswordFrom.get('NewPassword').value,
    };
    this.io._changePassword(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.appSettingsPasswordFrom.reset();
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
      if (res.serverResponse.code == 400) {
        // console.log(res);
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }

  getAllDetails() {
    let jsonData = {
      id: this.userDeatils.id,
      path: 'get-job-seeker',
    };
    this.io._JSallDetails(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res)
        if (localStorage.getItem('workNigeriaUser') === null) {
          this.auth.setCurrentUserDetailsInSessoin(res['result'].userData);
        } else {
          this.auth.setCurrentUserDetails(res['result'].userData);
        }
        window.location.reload();
      }
    });
  }
}
