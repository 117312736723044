<div class="row">
    <!-- Payment Table Side -->
    <div class="col-9">
        <div class="container">
            <div class="glow-card">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-2 my-auto" *ngIf="['Gold', 'Silver', 'Bronze'].includes(this.userAssets.subscription.subscriptionPlan)">
                                <img class="wallet-image" *ngIf="this.userAssets.subscription.subscriptionPlan === 'Bronze'" src="assets/images/bronze_badge.png" width="40px" />
                                <img class="wallet-image" *ngIf="this.userAssets.subscription.subscriptionPlan === 'Silver'" src="assets/images/silver_badge.png" width="40px" />
                                <img class="wallet-image" *ngIf="this.userAssets.subscription.subscriptionPlan === 'Gold'" src="assets/images/gold_badge.png" width="40px" />
                            </div>
                            <div class="col-lg-9 my-auto" *ngIf="['Gold', 'Silver', 'Bronze'].includes(this.userAssets.subscription.subscriptionPlan)">
                                <div class="plan-title">You are on {{this.userAssets.subscription.subscriptionPlan}} Plan</div>
                                <div class="plan-duration" *ngIf="['Gold', 'Silver'].includes(this.userAssets.subscription.subscriptionPlan)">you have <b>{{remainingDays(this.userAssets.subscription.lastRenewalDate)}} days</b> left</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 my-auto" *ngIf="['None', 'Bronze', 'Silver'].includes(this.userAssets.subscription.subscriptionPlan) || !this.userAssets.subscription.subscriptionPlan">
                        <div class="top-row">
                            <button class="upgrade-plan-btn" (click)="navigateToPlans()">Upgrade Plan</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container" *ngIf="transactionHistory.length !== 0">
            <div class="glow-card-transactions">
                <div class="top-row-2">
                    <div class="float-left">Payment History</div>
                </div>
                <div class="outline-border"></div>
                <div class="bottom-row-3">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" matSortDirection="desc">
                        <!-- CARD NAME -->
                        <ng-container matColumnDef="cardName">
                            <th mat-header-cell *matHeaderCellDef>
                                Name
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <img *ngIf="element.cardType.toLowerCase().trim() === 'visa'"
                                    src="../../../../../../assets/images/visa-icon.png" class="card-type" />
                                <img *ngIf="element.cardType === 'mastercard'"
                                    src="../../../../../../assets/images/mastercard-icon.png" class="card-type" />
                                {{element.cardName}}
                            </td>
                        </ng-container>

                        <!-- CREATED AT -->
                        <ng-container matColumnDef="createdAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header
                                sortActionDescription="Sort by date">
                                Date
                            </th>
                            <td mat-cell *matCellDef="let element"> {{correctDayDate(element.createdAt)}} </td>
                        </ng-container>

                        <!-- PAYMENT -->
                        <ng-container matColumnDef="currency">
                            <th mat-header-cell *matHeaderCellDef>
                                Price
                            </th>
                            <td mat-cell *matCellDef="let element">  {{correctAmountFormat(element.paymentAmount, element.currency)}}
                            </td>
                        </ng-container>

                        <!-- STATUS -->
                        <ng-container matColumnDef="purpose">
                            <th mat-header-cell *matHeaderCellDef>
                                Purpose
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="curved-pill">
                                    <span class="pill-text">{{this.paymentTypes[element.paymentType]}}</span>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                    <mat-paginator 
                        *ngIf="transactionHistory.length > 0" 
                        [length]="this.walletPagination.total"
                        (page)="onWalletPageChanged($event)"
                        [pageIndex]="this.currentWalletPage"
                        [pageSize]="4"
                        showFirstLastButtons>
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
    <!-- Promotion and CV Credit Side -->
    <div class="col-3 p-10">
        <div class="promo-cv-credit">
            <div class="dropdown-months">
                <mat-form-field>
                    <mat-label></mat-label>
                    <mat-select [(ngModel)]="selectedMonth" name="month" class="select-month" [disabled]="true">
                        <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="promo-credit">
                <p class="p-heading">
                    Promotion Credit
                </p>
                <table class="table-promo">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Plan</th>
                            <th>Pack</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Used</td>
                            <td class="pl-20">
                                <div class="c-1">
                                    {{this.userAssets.subscription.usedCampaignCredits || 0}}
                                </div>
                            </td>
                            <td class="pl-20">
                                <div class="c-2">
                                    {{this.userAssets.bundle.campaign.used}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Balance</td>
                            <td class="pl-20">
                                <div class="c-1">
                                    {{this.userAssets.subscription.campaignCredits ? this.userAssets.subscription.campaignCredits - this.userAssets.subscription.usedCampaignCredits : 0}}
                                </div>
                            </td>
                            <td class="pl-20">
                                <div class="c-2">
                                    {{this.userAssets.bundle.campaign.total - this.userAssets.bundle.campaign.used ?? 0}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="buy-pack" (click)="navigateToBundle('campaign')">
                    <a class="buy-pack-btn">
                        Buy A Pack of 5
                    </a>
                </p>
                <hr class="dotted-line">
            </div>
            <div class="promo-credit">
                <p class="c-heading">
                    CV Credit
                </p>
                <table class="table-promo">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Plan</th>
                            <th>Pack</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Used</td>
                            <td class="pl-20">
                                <div class="c-1">
                                    {{this.userAssets.usedCvCredits ?? 0}}
                                </div>
                            </td>
                            <td class="pl-20">
                                <div class="c-3">
                                    {{this.userAssets.bundle.cv.used ?? 0}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Balance</td>
                            <td class="pl-20">
                                <div class="c-1">
                                    {{this.userAssets.subscription.cvCredits ? this.userAssets.subscription.cvCredits - this.userAssets.subscription.usedCvCredits : 0}}
                                </div>
                            </td>
                            <td class="pl-20">
                                <div class="c-3">
                                    {{this.userAssets.bundle.cv.total - this.userAssets.bundle.cv.used ?? 0}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="buy-pack" (click)="navigateToBundle('cv')">
                    <a class="buy-pack-btn-2">
                        Buy A Pack of 25
                    </a>
                </p>
                <hr class="dotted-line">
            </div>
        </div>
    </div>
</div>