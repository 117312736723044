<div class="main">
  <div class="main-row">
    <div class="sidebar">
      <div class="back" (click)="goBack()">
        <div class="icon-wrapper" [ngClass]="{'green': this.bundleId === 'campaign', 'orange': this.bundleId === 'cv', 'blue': this.bundleId === 'pay'}">
          <img src="assets/images/back.svg">
        </div>
        <p class="mb-0" [ngClass]="{'green': this.bundleId === 'campaign', 'orange': this.bundleId === 'cv', 'blue': this.bundleId === 'pay'}">Back</p>
      </div>
    </div>
    <div class="box">
      <div class="inner-box">
        <div class="row" [ngClass]="{'green-bg': this.bundleId === 'campaign', 'orange-bg': this.bundleId === 'cv', 'blue-bg': this.bundleId === 'pay'}">
          <div class="col-md-8">
            <h5 *ngIf="this.bundleId === 'campaign'">Pack of 5 <span class="orange">Campaign Bundle</span></h5>
            <h5 *ngIf="this.bundleId === 'cv'">Pack of 25 <span class="orange">CV Unlock</span></h5>
            <h5 *ngIf="this.bundleId === 'pay'">Pay As You Go</h5>
            <p *ngIf="this.bundleId === 'campaign'" class="desc-text">Introducing our exclusive "Pack of 5" campaign bundle!<br> Elevate your hiring game by promoting 5 unique job listings for an entire month. Whether you're on the bronze plan or have utilized your campaign quota on other plans, this package is tailored to meet your needs. Maximize your recruitment efforts and reach the best candidates effortlessly. Your next great hire is just a click away with our unbeatable "Pack of 5" campaign bundle.</p>
            <p *ngIf="this.bundleId === 'cv'" class="desc-text">Discover the power of our "Pack of 25" CV bundle! Seamlessly explore a pool of candidates and access a collection of up to 25 CVs from the expansive WorkNigeria unlimited CV bank. Crafted for users on the bronze plan and those who've utilized their plan's allocation on other tiers, this package offers unparalleled value. Unleash your recruitment potential with the "Pack of 25" CV bundle and unlock the ideal candidate for your next role, right from the convenience of your screen.</p>
            <p *ngIf="this.bundleId === 'pay'" class="desc-text">Introducing our "Pay As You Use" package—an innovative solution that empowers you to fund your wallet and exclusively pay for services as you utilize them. Whether you're launching a new campaign or downloading CV on the CV bank. This package is suitable for small business owner trying to recruit for only few roles<br><strong>Campaign - N3000/Job for one Month</strong><br><strong>Search - N1500/ Profile</strong></p>
          </div>
          <div class="col-md-4 img">
            <div class="image-container">
              <img *ngIf="this.bundleId === 'campaign'" src="../assets/images/pack-of-5.png"/>
              <img *ngIf="this.bundleId === 'cv'" src="../assets/images/cv-bundle.png"/>
              <img *ngIf="this.bundleId === 'pay'" src="../assets/images/pay-a-y-go.png"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="bottom-div">
              <img *ngIf="this.bundleId === 'campaign'" src="../assets/images/old-price.png"/>
              <img *ngIf="this.bundleId === 'cv'" src="../assets/images/old-price-cv.png"/>
              <div>
                <h6 *ngIf="this.bundleId === 'campaign'">N25,000</h6>
                <p *ngIf="this.bundleId === 'campaign'" class="discount">-33% Discount</p>
                <h6 *ngIf="this.bundleId === 'cv'">N25,000</h6>
                <p *ngIf="this.bundleId === 'cv'" class="discount">-50% Discount</p>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div *ngIf="this.bundleId === 'campaign' || this.bundleId === 'cv'" class="bottom-div">
              <div class="sub-div">
                <h6 class="gray">30 Days</h6>
                <p class="discount">Duration</p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="bottom-div p0">
              <!-- <button class="purchase" *ngIf="this.bundleId !== 'pay'" [ngClass]="{'green': this.bundleId === 'campaign', 'orange': this.bundleId === 'cv'}">Purchase</button> -->
              <button
                *ngIf="this.bundleId !== 'pay'" [ngClass]="{'green': this.bundleId === 'campaign', 'orange': this.bundleId === 'cv'}"
                angular4-paystack
                class="purchase"
                [key]="this.paystackKey"
                [email]="userDetails?.email"
                [amount]="2500000"
                [ref]="transactionRef"
                (onClose)="paymentCancel()"
                (callback)="paymentDone($event)"
                (paymentInit)="updateTransactionId($event)"
              >
                Purchase
              </button>
              <button
                *ngIf="this.bundleId === 'pay'"
                class="purchase blue"
                (click)="navigateToFundWallet()"
              >
                Fund Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>