import { Component } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { ExportAdminJobsService } from 'src/app/services/export-admin-jobs.service';
@Component({
  selector: 'app-job-super-admin',
  templateUrl: './job-super-admin.component.html',
  styleUrls: ['./job-super-admin.component.scss']
})
export class JobSuperAdminComponent {
  startDate: string = '';
  endDate: string = '';
  searchQuery: string = '';
  jobSearchQuery: any = {};
  detailsOfJob: any;
  selectedEmployer: any = null;
  jobLists: any;
  pagination: any;

  constructor(
    private exportJobsService: ExportAdminJobsService,
    private empService: EmployerService,
  ) {}

  onSearch(): void {
    this.jobSearchQuery = {
      start: 0,
      searchText: this.searchQuery,
      startDate: this.startDate,
      endDate: this.endDate
    }
  }

  // converts the jobs list to excel sheet
  async exportJobs(start) {
    let payLoad = {
      ...this.jobSearchQuery,
      path: 'all-jobs-data',
      size: 250,
      start: 0,
      sortParam: 'created_at',
    };
    await this.empService._getJobsList(payLoad).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.jobLists = res['result'];
      }
      this.exportJobsService.genExcel(this.jobLists);
    });
  }

  /**
 * Navigates the user to the employer jobs.
 * @param {Object} emp - selected employer object.
 */
  navigateToEmployer(emp) {
    this.selectedEmployer = emp;
  }

  handlejobSelected(job: any) {
    this.detailsOfJob = job;
  }

  backToJobs() {
    this.detailsOfJob = null
  }

  clearEndDate(): void {
    this.endDate = null
  }

  clearStartDate(): void {
    this.startDate = null
  }
}
