<div class="overview-job-row">
  <div class="return-to-job-overview">
    <a class="return-click" (click)="openJobOverview()">
      <img class="left-arrow" src="../../../../../../assets/images/left-arrow.png" />
      Return To Jobs
    </a>
  </div>
  <div class="form-and-div">
    <form [formGroup]="appliedDashBoardDetails">
      <div class="select-wrap">
        <div class="select-row">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label class="search-jobs">Search Jobs <i class="fas fa-search"></i></mat-label>
            <input type="text" matInput placeholder="Search text" formControlName="firstName" (keyup)="onKey($event)" />
          </mat-form-field>
        </div>
        <div class="btn-set">
          <a href="javascript:void(0);" *ngIf="clearButton" class="gray-btn position-button"
            (click)="resetFrom()">Clear</a>&nbsp;
          <a href="javascript:void(0);" class="btn border position-button-export" (click)="exportexcel()"><img
              src="../../../../../../assets/images/el.png" alt="" /></a>&nbsp;
        </div>
      </div>
    </form>
  </div>
</div>

<div class="well-views dashboardtab table-responsive">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8">

    <!-- VIEWS -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>
        APPLICATION DATE
      </th>
      <td mat-cell *matCellDef="let element"> {{formatDateString(element.createdAt)}} </td>
    </ng-container>

    <!-- FIRST NAME -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
        FIRST NAME
      </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
    </ng-container>

    <!-- LAST NAME -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
        LAST NAME
      </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>

    <!-- LAST NAME -->
    <ng-container matColumnDef="is_3mtt">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.is_3mtt" class="tag-3mtt">
          <p class="txt-3mtt">3MTT Fellow</p>
        </div>
      </td>
    </ng-container>

    <!-- EMAIL -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        EMAIL
      </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- ACTIONS -->
    <ng-container matColumnDef="appliedData.appliedStatus">
      <th mat-header-cell *matHeaderCellDef>
        ACTION
      </th>
      <td mat-cell *matCellDef="let element">
        <ul>
          <li>
            <a class="action-clicks" matTooltip="CV" matTooltipPosition="above" matTooltipShowDelay="500" (click)="cvOpen(element.appliedData.documents)"><img
                class="action-icons" src="../../../../../../../assets/images/cv.svg" /></a>
          </li>
          <li>
            <a class="action-clicks" 
            [style.pointer-events]="hasCoverLetter(element.appliedData.documents) ? 'auto' : 'none'" matTooltip="Cover Letter" matTooltipPosition="above" matTooltipShowDelay="500" (click)="coverOpen(element.appliedData.documents)"><img
                class="action-icons" [ngClass]="{'disabled': !hasCoverLetter(element.appliedData.documents)}" src="../../../../../../../assets/images/cover-letter.svg" /></a>
          </li>
          <li>
            <a class="action-clicks" matTooltip="Message" matTooltipPosition="above" matTooltipShowDelay="500" *ngIf="element.appliedData.userId" (click)="messageOpen(element)"><img
                class="action-icons" src="../../../../../../../assets/images/message-icon.png" /></a>
          </li>
        </ul>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
  <div class="no-job-found" *ngIf="appliedJobseeker.length == '0'">
    <h4>No job found</h4>
  </div>
</div>