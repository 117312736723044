import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-emp-campaigns-sidenav',
  templateUrl: './emp-campaigns-sidenav.component.html',
  styleUrls: ['./emp-campaigns-sidenav.component.scss'],
})
export class EmpCampaignsSidenavComponent implements OnInit {
  constructor() {}

  currentElement: string = 'campaigns';

  ngOnInit(): void {}

  @Output() elementSelected = new EventEmitter<string>();

  selectElement(element: string): void {
    this.currentElement = element;
    this.elementSelected.emit(element);
  }
}
