import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss'],
})
export class TermsConditionComponent implements OnInit, AfterViewInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  scrollToId(id: string) {
    console.log('element id : ', id);
    const element = <HTMLElement>document.querySelector(`#${id}`);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  // private __getElementById(id: string): HTMLElement {
  //   console.log("element id : ", id);
  //   const element = <HTMLElement>document.querySelector(`#${id}`);
  //   return element;
  // }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
