<p>new-message works!</p>
<div class="messagepopupdas">
  <button class="close">X</button>
  <div class="messagepopupdas-profile">
    <div class="messagepopupdas-img">
      <img src="../../../../assets/images/avatar-img-ramse.png" />
    </div>
    <div class="messagepopupdas-pro-info">
      <h4>Ramsey Nouah</h4>
      <p>Producer</p>
    </div>
  </div>
  <hr class="grayline" />
  <div class="messagepopupdas-profilesm">
    <div class="messagepopupdassm-img">
      <img src="../../../../assets/images/avatar-img-adeola.png" />
    </div>
    <div class="messagepopupdassm-pro-info">
      <h4>Adeola Ariyo <span>• Jun 25, 5:38 PM</span></h4>
      <p>Hi Ramsey,</p>

      <p>
        Can we schedule an interview for some time next week? You look like a
        great match for the Higher Executive Officer position at our
        Postgraduate School. Here are some times the hiring manager is
        available. Please let me know what works for you:
      </p>

      <p>
        Tuesday, July 6 at 10:00 or 11:00 AM Wednesday, July 7 at 1:30, 3:00 or
        5:15 PM
      </p>

      <p>
        Best,<br />
        Adeola Ariyo<br />
        Recruiting Manager at Jobot<br />
        949-688-2006
      </p>
    </div>
  </div>
  <hr class="greenline" />
  <textarea placeholder="Write a message..."></textarea>
  <div class="btnrightwrap">
    <button class="btn-bdr">Attach file</button>
    <button class="green-btn">Send message</button>
  </div>
</div>
