import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXT = '.xlsx';

const font = { name: 'sans-serif', family: 2, size: 9, bold: true };
const analysisFont = {
  name: 'sans-serif',
  family: 4,
  size: 10,
  bold: true,
  underline: true,
};
const headerFont = { name: 'sans-serif', family: 4, size: 12, bold: true };
const nonBoldfont = { name: 'sans-serif', family: 2, size: 9, bold: false };
const centeralign = {
  vertical: 'middle',
  horizontal: 'center',
  wrapText: true,
};
const leftalign = { vertical: 'top', horizontal: 'left', wrapText: false };
const all_border = {
  top: { style: 'thin', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FF000000' } },
};
const no_border = {
  top: { style: 'thin', color: { argb: 'FF000000' } },
  left: { style: 'thin', color: { argb: 'FF000000' } },
  bottom: { style: 'thin', color: { argb: 'FF000000' } },
  right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
};
const totalBorderLess = {
  top: { style: 'hair', color: { argb: 'FFFFFFFF' } },
  left: { style: 'hair', color: { argb: 'FFFFFFFF' } },
  bottom: { style: 'hair', color: { argb: 'FFFFFFFF' } },
  right: { style: 'hair', color: { argb: 'FFFFFFFF' } },
};
const fill_color1 = {
  type: 'pattern',
  pattern: 'darkTrellis',
  fgColor: { argb: 'FFFFFFFF' },
  bgColor: { argb: 'FFFFFFFF' },
};
const fill_color2 = {
  type: 'pattern',
  pattern: 'darkTrellis',
  fgColor: { argb: 'FFFFFFFF' },
  bgColor: { argb: 'FFFFFFFF' },
};
const DynamiRowStart = 3;

@Injectable({
  providedIn: 'root',
})
export class ExportAdminJobseekerService {
  constructor() {}
  //create range
  to_a(c1, c2) {
    let a = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    return a.slice(a.indexOf(c1), a.indexOf(c2) + 1);
  }

  //creating header for excel
  excelHeader(headerData) {
    return [
      {
        MERGE_CELL: 'A1:F1',
        CELL_NO: 'F1',
        CELL_VALUE: 'ALL JOBSEEKERS LIST ',
        CELL_FONT: analysisFont,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: '',
        CELL_WIDTH: 0,
      },

      {
        MERGE_CELL: '',
        CELL_NO: 'A2',
        CELL_VALUE: 'S NO.',
        CELL_FONT: font,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: '',
        CELL_WIDTH: 6,
      },
      {
        MERGE_CELL: '',
        CELL_NO: 'B2',
        CELL_VALUE: 'FIRST NAME',
        CELL_FONT: font,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: '',
        CELL_WIDTH: 15,
      },
      {
        MERGE_CELL: '',
        CELL_NO: 'C2',
        CELL_VALUE: 'LAST NAME',
        CELL_FONT: font,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: '',
        CELL_WIDTH: 15,
      },
      {
        MERGE_CELL: '',
        CELL_NO: 'D2',
        CELL_VALUE: 'EMAIL',
        CELL_FONT: font,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: '',
        CELL_WIDTH: 25,
      },
      {
        MERGE_CELL: '',
        CELL_NO: 'E2',
        CELL_VALUE: 'STATUS',
        CELL_FONT: font,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: fill_color1,
        CELL_WIDTH: 15,
      },
      {
        MERGE_CELL: '',
        CELL_NO: 'F2',
        CELL_VALUE: 'LAST SEEN',
        CELL_FONT: font,
        CELL_ALIGN: centeralign,
        CELL_BORDER: all_border,
        CELL_FILL: fill_color1,
        CELL_WIDTH: 15,
      },
    ];
  }
  genExcel(data: any) {
    console.log(data);
    var workbook = new ExcelJS.Workbook();

    //generating range
    let fillColor1Cell = this.to_a('E', 'N');
    let fillColor2Cell = this.to_a('O', 'S');
    let fillColor3Cell = this.to_a('A', 'F');

    //get total number of record set
    let totalRecord = data.length;
    let headerColumns = this.excelHeader(data);
    let headerColumnsWidth = [];
    let rowDynamicData = [];

    //Set Workbook Properties
    // workbook.creator = 'Laboratory Information Management System - System Generated';
    workbook.lastModifiedBy = 'LIMS - System Generated';
    workbook.created = new Date();
    workbook.modified = new Date();

    // Force workbook calculation on load
    workbook.calcProperties.fullCalcOnLoad = true;

    // create a sheet with red tab colour
    var worksheet = workbook.addWorksheet('TOTAL JOBSEEKERS - ', {
      properties: { tabColor: { argb: 'FF8B008B' } },
    });
    worksheet.properties.defaultRowHeight = 25;

    headerColumns.forEach((item, index) => {
      //push all the widths for all column header cell
      if (item.CELL_WIDTH > 0) {
        headerColumnsWidth.push({ width: item.CELL_WIDTH });
      }

      //cell merge for column headers
      if (item.MERGE_CELL != '') {
        worksheet.mergeCells(item.MERGE_CELL);
      }
      // set the value for column headers
      worksheet.getCell(item.CELL_NO).value = item.CELL_VALUE;

      // set the alignment property for all column headers
      worksheet.getCell(item.CELL_NO).alignment = item.CELL_ALIGN;

      // set the fonts for all column headers
      worksheet.getCell(item.CELL_NO).font = item.CELL_FONT;

      // draw borders for for column headers
      worksheet.getCell(item.CELL_NO).border = item.CELL_BORDER;

      // fill the column headers by background color
      if (item.CELL_FILL != '') {
        worksheet.getCell(item.CELL_NO).fill = item.CELL_FILL;
      }
    });

    //set width for column headers
    worksheet.columns = headerColumnsWidth;

    data.forEach((rotate, index) => {
      rowDynamicData.push([
        index + 1,
        rotate.firstName,
        rotate.lastName,
        rotate.email,
        (rotate.isEmailVerified === true ? 'Verified' : 'Need Verification'),
        
      ]);
    });

    //set dynamic records
    worksheet.addRows(rowDynamicData);

    data.forEach((k, ind) => {
      // as initial 14 records are hide and for them no spl font, border or alignment is mentioned the styling is started from DynamiRowStart(14) no of rows
      worksheet.getRow(ind + DynamiRowStart).font = font;
      worksheet.getRow(ind + DynamiRowStart).alignment = centeralign;
      fillColor3Cell.forEach((c3, i) => {
        worksheet.getCell(c3 + (ind + DynamiRowStart)).border = all_border;
      });
    });

    let currentTime = new Date().getTime();

    workbook.xlsx.writeBuffer().then((data) => {
      var blob = new Blob([data], { type: EXCEL_TYPE });
      FileSaver.saveAs(blob, 'Total Jobseekers' + EXCEL_EXT, true);
    });
  }

  getPhoneNumber(data: any) {
    if (data) {
      return data;
    } else {
      return '-';
    }
  }
}
