// class AppMessage {
// 	// static _appLanguages = ['en'];
// 	static _messages = {
// 		// "en": {

// 			"M_902": (params) => `CONGRATS ${params?.restaurantName} has been approved and is now part of our MyEatPal family. We are thrilled to have you with us and we wholeheartedly welcome you on board.`,
// 			"M_903": (params) => `Thank you for your interest in becoming a partner with MyEatPal. We appreciate the time taken to apply and the effort put in the application. After careful consideration, unfortunately, ${params?.restaurantName} application has been disapproved. We wish you all the best.`,
// 		// }
// 	}

// 	// static getAppLanguages() {
// 	// 	return AppMessage._appLanguages;
// 	// }

// 	static getMessage(code, params = {}) {
// 		// return AppMessage._messages[language] && AppMessage._messages[language][code] ? AppMessage._messages[language][code](params) : ""
// 		return AppMessage._messages[code]  ? AppMessage._messages[code](params) : ""
// 	}
// 	// AppMessage.getMessage("107", req.headers.language)
// }

// module.exports = AppMessage
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpIOService } from '../services/http-io.service';
import { waitForAsync } from '@angular/core/testing';

@Injectable({ providedIn: 'root' })
export class AppMessage {
  constructor(private router: Router, private io: HttpIOService) {}

  static _messages = {
    'Lack of Work Authorization': (
      params
    ) => `Hi ${params?.wholeData.firstName}, 

            Thank you for your interest in our ${params?.selectedJob.title} role at ${params?.selectedJob.company_name}.  At this time, we have identified more suitable candidates to move forward in the recruitment process who are more qualificationed for this role.  Your application and interest in this opportunity is greatly appreciated. 
            
            If there is a role of interest at our organization in the future, we would encourage you to apply online again. 
            
            Thank you again for your interest in ${params?.selectedJob.company_name}. We wish you the best of luck with your job search.
            
            Talent Acquisition Team 
            ${params?.selectedJob.company_name}`,

    'Not able to relocate': (params) => `Hi ${params?.wholeData.firstName}, 

            Thank you for your interest in our ${params?.selectedJob.title} role at ${params?.selectedJob.company_name}. At this time, we have identified more suitable candidates to move forward in the recruitment process who are more willing to relocate. Your application and interest in this opportunity is greatly appreciated. 
            
            If there is a role of interest at our organization in the future, we would encourage you to apply online again. 
            
            Thank you again for your interest in ${params?.selectedJob.company_name}. We wish you the best of luck with your job search.
            
            Talent Acquisition Team 
            ${params?.selectedJob.company_name}
            `,

    'Not Qualified': (params) => `Hi ${params?.wholeData.firstName}, 

            Thank you for your interest in our ${params?.selectedJob.title} role at ${params?.selectedJob.company_name}. At this time, we have identified more suitable candidates to move forward in the recruitment process. Your application and interest in this opportunity is greatly appreciated. 
            
            If there is a role of interest at our organization in the future, we would encourage you to apply online again. 
            
            Thank you again for your interest in ${params?.selectedJob.company_name}. We wish you the best of luck with your job search.
            
            Talent Acquisition Team 
            ${params?.selectedJob.company_name}`,

    'Unfavorable Interview': (params) => `Hi ${params?.wholeData.firstName}, 

            Thank you for your interest in our ${params?.selectedJob.title} role at ${params?.selectedJob.company_name}.  Based on our interviews, we feel we have identified more suitable candidates to move forward in the recruitment process. Your application and interest in this opportunity is greatly appreciated. 
            
            If there is a role of interest at our organization in the future, we would encourage you to apply online again. 
            
            Thank you again for your interest in ${params?.selectedJob.company_name}. We wish you the best of luck with your job search.
            
            Talent Acquisition Team 
            ${params?.selectedJob.company_name}`,
  };

  getMessage(cause, params = {}) {
    return AppMessage._messages[cause]
      ? AppMessage._messages[cause](params)
      : '';
  }
}
