import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Helper } from '../helper/defult';

@Injectable({
  providedIn: 'root',
})
export class SuperAdminGuard  {
  userDeatils: any;
  companyData: any;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private auth: AuthService,
    private helper: Helper
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // let userLocal;
    // let userSeeson;
    // if ((localStorage.getItem('workNigeriaUser'))) {
    //   userLocal = JSON.parse(this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser'))))
    // }
    // if ((sessionStorage.getItem('workNigeriaUser'))) {
    //   userSeeson = JSON.parse(this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser'))))
    // }
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    // console.log(this.userDeatils)
    if (this.userDeatils && this.userDeatils.userType == 'super_admin') {
      return true;
    } else {
      if (!this.userDeatils) {
        sessionStorage.setItem(
          'companyVerificaton',
          btoa(JSON.stringify(window.location.href))
        );
      }
      if (this.userDeatils) {
        if (this.userDeatils.userType == 'super_admin') {
          this._router.navigate(['/companyApprovalAction']);
          return false;
        }
      }
      if (this.userDeatils) {
        if (this.userDeatils.userType == 'super_admin') {
          this._router.navigate(['/companyApprovalAction']);
          return false;
        }
      }
      this._router.navigate(['/login']);
      return false;
    }
    // if(userSeeson) {
    // this._router.navigate([`/${userSeeson.userType}/Dashboard`]);
    //   return false;
    // }
    // if(userLocal) {
    //   this._router.navigate([`/${userLocal.userType}/Dashboard`]);
    //     return false;
    //   }

    // navigate to login page
  }
}
