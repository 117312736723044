<!--header sction-->
<header
  class="main-header"
  [ngClass]="
    !userDtls && !userDtlssessionStorage
      ? ''
      : userDtls.isEmailVerified == false
      ? 'loggedinn e-notification'
      : 'loggedinn'
  "
>
  <!-- header without login jov seeker  -->

  <div *ngIf="userDeatils == '' && jobSeekerHeader" class="new-hdr flex">
    <div class="logo">
      <a href="javascript:void(0);" routerLink="/home"
        ><img src="../assets/images/logo.webp"
      /></a>
    </div>
    <div class="flex">
      <div class="hdr-nav">
        <ul>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/job-search' }">
            <a href="javascript:void(0);" (click)="jobSearch()"
              ><i class="fas fa-search"></i> <span> Search Jobs</span></a
            >
          </li>
          <li *ngIf="currentPath != '/employer-home'">
            <a
              href="javascript:void(0);"
              (click)="services()"
              (mouseenter)="isHovered = true"
              (mouseleave)="isHovered = false"
            >
              <img
                [src]="
                  isHovered
                    ? '../../../../assets/images/service_icon_green.png'
                    : '../../../../assets/images/service_icon.png'
                "
                class="service-icon"
              />
              <span> Services</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              (click)="buildCVsection()"
              (mouseenter)="isHoveredCV = true"
              (mouseleave)="isHoveredCV = false"
            >
              <img
                [src]="
                  isHoveredCV
                    ? '../../../../assets/images/build_cv_green.png'
                    : '../../../../assets/images/build_cv.png'
                "
                class="build-cv-icon"
              />
              <span> Build CV</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="job_alert()"
              ><i class="fas fa-bell"></i> <span> Job Alerts</span></a
            >
          </li>
        </ul>
      </div>
      <div class="hdr-log">
        <a
          href="javascript:void(0);"
          (click)="!clickable ? login() : null"
          class="login"
          >Log In</a
        >
        <a
          href="javascript:void(0);"
          (click)="!clickable ? Sign_up() : null"
          class="nbtn dark"
          >Sign Up</a
        >
      </div>
      <div class="hdr-emp-jb">
        <a
          href="javascript:void(0);"
          class="emp-jb-btn"
          (click)="goToEmployerHome()"
          >Employer? Post Job &nbsp; <i class="fas fa-angle-right"></i
        ></a>
      </div>
    </div>
    <div class="mobileview">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div class="hdr-nav">
          <ul>
            <li>
              <a href="javascript:void(0);" (click)="jobSearch()"
                ><i class="fas fa-search"></i>
                <span> &nbsp;Search Jobs</span></a
              >
            </li>
            <li *ngIf="currentPath != '/employer-home'">
              <a
                href="javascript:void(0);"
                (click)="services()"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
              >
                <img
                  [src]="
                    isHovered
                      ? '../../../../assets/images/service_icon_green.png'
                      : '../../../../assets/images/service_icon.png'
                  "
                  class="service-icon"
                />
                <span> &nbsp;Services</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" (click)="buildCVsection()"
                ><i class="fas fa-bell"></i> <span> &nbsp;Build CV</span></a
              >
            </li>
            <li>
              <a href="javascript:void(0);" (click)="job_alert()"
                ><i class="fas fa-file"></i> <span> &nbsp;Jobs Alerts</span></a
              >
            </li>
          </ul>
        </div>
        <div class="hdr-log">
          <a
            href="javascript:void(0);"
            (click)="!clickable ? login() : null"
            class="login"
            >Log In</a
          >
          <a
            href="javascript:void(0);"
            (click)="!clickable ? Sign_up() : null"
            class="nbtn dark"
            >Sign Up</a
          >
        </div>
        <div class="hdr-emp-jb">
          <a
            href="javascript:void(0);"
            class="emp-jb-btn"
            (click)="goToEmployerHome()"
            >Employer? Post Job &nbsp; <i class="fas fa-angle-right"></i
          ></a>
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- header without login employer  -->

  <div
    *ngIf="userDeatils == '' && jobProviderHeader"
    class="new-hdr flex withoutLogin"
  >
    <div class="logo">
      <a href="javascript:void(0);" routerLink="/home"
        ><img src="../assets/images/logo.webp"
      /></a>
    </div>
    <div class="flex">
      <div class="hdr-nav">
        <ul>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/services' }">
            <a
              href="javascript:void(0);"
              (click)="services()"
            >
              <img
                src="../../../../assets/images/services-svg.svg"
                class="service-icon-emp"
                [ngClass]="{
                  'current-employer-item':
                    currentPath == '/services'
                }"
              />
              <span>Services</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="postAjob()"
              ><svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="post-a-job mb-2"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3337 4.00008H15.667C16.592 4.00008 17.3337 4.74175 17.3337 5.66675V14.8334C17.3337 15.7584 16.592 16.5001 15.667 16.5001H2.33366C1.40866 16.5001 0.666992 15.7584 0.666992 14.8334L0.675326 5.66675C0.675326 4.74175 1.40866 4.00008 2.33366 4.00008H5.66699V2.33341C5.66699 1.40841 6.40866 0.666748 7.33366 0.666748H10.667C11.592 0.666748 12.3337 1.40841 12.3337 2.33341V4.00008ZM7.33366 4.00008H10.667V2.33341H7.33366V4.00008Z"
                  fill="#666666"
                />
              </svg>
              <span>Jobs</span></a
            >
          </li>
          <li>
            <a href="javascript:void(0);" (click)="searchCV()"
              ><i class="fas fa-search"></i> <span> Search CV</span></a
            >
          </li>
        </ul>
      </div>
      <div class="hdr-log">
        <a
          href="javascript:void(0);"
          (click)="!clickable ? login('employer') : null"
          class="login"
          >Log In</a
        >
        <a
          href="javascript:void(0);"
          (click)="!clickable ? signUpEmployer() : null"
          class="nbtn dark"
          >Sign Up</a
        >
      </div>
      <div class="hdr-emp-jb">
        <a
          href="javascript:void(0);"
          class="emp-jb-btn"
          (click)="goTojobSeekerrHome()"
          >Job Seeker? Search Jobs &nbsp; <i class="fas fa-angle-right"></i
        ></a>
      </div>
    </div>
    <div class="mobileview">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div class="hdr-nav">
          <ul>
            <li>
              <a
                href="javascript:void(0);"
                (click)="goToServices()"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
              >
                <img
                  [src]="
                    isHovered
                      ? '../../../../assets/images/service_icon_green.png'
                      : '../../../../assets/images/service_icon.png'
                  "
                  class="service-icon mb-1"
                />
                <span> Services</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" (click)="postAjob()"
                ><i class="fas fa-file mb-2"></i> <span>Jobs</span></a
              >
            </li>
            <li>
              <a href="javascript:void(0);" (click)="searchCV()"
                ><i class="fas fa-search"></i> <span> Search CV</span></a
              >
            </li>
          </ul>
        </div>
        <div class="hdr-log">
          <a
            href="javascript:void(0);"
            (click)="!clickable ? login() : null"
            class="login"
            >Login</a
          >
          <a
            href="javascript:void(0);"
            (click)="!clickable ? signUpEmployer() : null"
            class="nbtn dark"
            >Sign Up</a
          >
        </div>
        <div class="hdr-emp-jb">
          <a
            href="javascript:void(0);"
            class="emp-jb-btn"
            (click)="goTojobSeekerrHome()"
            >Job Seeker? Search Jobs &nbsp; <i class="fas fa-angle-right"></i
          ></a>
        </div>
      </mat-menu>
    </div>
  </div>

  <!-- header start jov seeker  -->
  <div
    *ngIf="userDeatils.userType == 'jobseeker'"
    class="new-hdr hdr-logged flex"
  >
    <div class="logo">
      <a href="javascript:void(0);" routerLink="/home"
        ><img src="../assets/images/logo.webp"
      /></a>
    </div>
    <div class="flex">
      <div class="hdr-nav">
        <ul>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/job-search' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? jobSearch() : null"
              ><i class="fas fa-search"></i><span> Search Jobs</span></a
            >
          </li>
          <li>
            <a
              href="javascript:void(0);"
              (click)="services()"
              (mouseenter)="isHovered = true"
              (mouseleave)="isHovered = false"
            >
              <img
                [src]="
                  isHovered
                    ? '../../../../assets/images/service_icon_green.png'
                    : '../../../../assets/images/service_icon.png'
                "
                class="service-icon mb-1"
              />
              <span>Services</span>
            </a>
          </li>
          <li
            [ngClass]="{
              'current-menu-item': currentPath == '/jobseeker-profile'
            }"
          >
            <a
              href="javascript:void(0);"
              (click)="buildCV()"
              (mouseenter)="isHoveredCV = true"
              (mouseleave)="isHoveredCV = false"
            >
              <img
                [src]="
                  isHoveredCV
                    ? '../../../../assets/images/build_cv_green.png'
                    : '../../../../assets/images/build_cv.png'
                "
                class="build-cv-icon"
              />
              <span> Build CV</span>
            </a>
          </li>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/job-save' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? saveJob() : null"
              ><i class="far fa-heart"></i><span> Saved </span></a
            >
          </li>
          <li
            [ngClass]="{ 'current-menu-item': currentPath == '/applied-job' }"
          >
            <a
              href="javascript:void(0);"
              (click)="!clickable ? appiledJob() : null"
              ><i class="fas fa-suitcase"></i><span> Applied </span></a
            >
          </li>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/message' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? messaging() : null"
              ><i class="fas fa-comment-alt"></i> <span> Messaging</span
              ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
            ></a>
          </li>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/job-alert' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? JobAlert() : null"
              ><i class="fas fa-file"></i><span> Job Alerts</span></a
            >
          </li>
        </ul>
      </div>
      <div class="logged">
        <div class="logg">
          <a class="flex" href="javascript:void(0);">
            <div class="pimg">
              <img src="{{ displayPicture }}" />
            </div>
            <span>{{ this.userDeatils.firstName }}</span>
            <ul class="sub-menu">
              <!-- <li><a class="" href="javascript:void(0);">{{this.userDeatils.firstName}} </a></li> -->

              <li class="active">
                <a href="javascript:void(0);" (click)="gotoJobSeekerProfile()">
                  Profile
                </a>
              </li>
              <li class="active" *ngIf="this.userDeatils.isEmail">
                <a href="javascript:void(0);" (click)="gotoAppSettings()">
                  Settings
                </a>
              </li>
              <li class="active">
                <a href="javascript:void(0);" (click)="gotoLogIn()">
                  Log out
                </a>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </div>
    <div class="mobileview">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div class="hdr-nav">
          <ul>
            <li
              [ngClass]="{ 'current-menu-item': currentPath == '/job-search' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? jobSearch() : null"
                ><i class="fas fa-search"></i><span> &nbsp;Search Jobs</span></a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                (click)="services()"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
              >
                <img
                  [src]="
                    isHovered
                      ? '../../../../assets/images/service_icon_green.png'
                      : '../../../../assets/images/service_icon.png'
                  "
                  class="service-icon"
                />
                <span> &nbsp;Services</span>
              </a>
            </li>
            <li
              [ngClass]="{
                'current-menu-item': currentPath == '/jobseeker-profile'
              }"
            >
              <a
                href="javascript:void(0);"
                (click)="buildCV()"
                (mouseenter)="isHoveredCV = true"
                (mouseleave)="isHoveredCV = false"
              >
                <img
                  [src]="
                    isHoveredCV
                      ? '../../../../assets/images/build_cv_green.png'
                      : '../../../../assets/images/build_cv.png'
                  "
                  class="build-cv-icon"
                />
                <span> &nbsp;Build CV</span>
              </a>
            </li>
            <li [ngClass]="{ 'current-menu-item': currentPath == '/job-save' }">
              <a
                href="javascript:void(0);"
                (click)="!clickable ? saveJob() : null"
                ><i class="far fa-heart"></i><span> &nbsp;Saved </span></a
              >
            </li>
            <li
              [ngClass]="{ 'current-menu-item': currentPath == '/applied-job' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? appiledJob() : null"
                ><i class="fas fa-suitcase"></i><span> &nbsp;Applied </span></a
              >
            </li>
            <li [ngClass]="{ 'current-menu-item': currentPath == '/message' }">
              <a
                href="javascript:void(0);"
                (click)="!clickable ? messaging() : null"
                ><i class="fas fa-comment-alt"></i> <span> &nbsp;Messaging</span
                ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
              ></a>
            </li>
            <li
              [ngClass]="{ 'current-menu-item': currentPath == '/job-alert' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? JobAlert() : null"
                ><i class="fas fa-file"></i><span> &nbsp;Job Alerts</span></a
              >
            </li>
          </ul>
        </div>
        <div class="logged">
          <div class="logg">
            <a class="flex" href="javascript:void(0);">
              <div class="pimg">
                <img src="{{ displayPicture }}" />
              </div>
              <span>{{ this.userDeatils.firstName }}</span>
              <ul class="sub-menu">
                <!-- <li><a class="" href="javascript:void(0);">{{this.userDeatils.firstName}} </a></li> -->

                <li class="active">
                  <a
                    href="javascript:void(0);"
                    (click)="gotoJobSeekerProfile()"
                  >
                    Profile
                  </a>
                </li>
                <li class="active" *ngIf="this.userDeatils.isEmail">
                  <a href="javascript:void(0);" (click)="gotoAppSettings()">
                    Settings
                  </a>
                </li>
                <li class="active">
                  <a href="javascript:void(0);" (click)="gotoLogIn()">
                    Log out
                  </a>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </mat-menu>
    </div>
    <div
      class="email-notification"
      *ngIf="userDeatils.isEmailVerified == false"
    >
      <div class="down-notify">
        <p>
          <b>Confirm your email address</b>
          <a
            href="javascript:void(0);"
            (click)="emailVarification()"
            style="color: #3eab70"
            >Re-send Email</a
          >
        </p>
      </div>
    </div>
  </div>

  <!-- header start employer *ngIf="userDeatils.isEmailVerified == false" -->
  <div
    *ngIf="userDeatils.userType == 'employer' "
    class="new-hdr hdr-logged withoutLogin flex"
  >
    <div class="logo">
      <a href="javascript:void(0);" (click)="goToEmployerHome2()"
        ><img src="../assets/images/logo.webp"
      /></a>
    </div>

    <div class="flex">
      <div class="hdr-nav">
        <ul>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/services' }">
            <a
              href="javascript:void(0);"
              (click)="services()"
            >
              <img
                src="../../../../assets/images/services-svg.svg"
                class="service-icon-emp"
                [ngClass]="{
                  'current-employer-item':
                    currentPath == '/services'
                }"
              />
              <span>Services</span>
            </a>
          </li>
          <!-- <li [ngClass]="{ 'current-menu-item': currentPath == '/cv-search' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? cvSearch() : null"
              ><i class="fas fa-search"></i> <span> CV search </span></a
            >
          </li>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/job-search' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? jobSearch() : null"
              ><i class="fas fa-search"></i> <span> Jobs Search</span></a
            >
          </li>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/manage-job' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? manageJobs() : null"
              ><i class="fas fa-suitcase"></i> <span> Jobs</span></a
            >
          </li> -->
          <li [ngClass]="{ 'current-menu-item': currentPath == '/Dashboard' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? dashboard() : null"
              >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="post-a-job mb-2"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3337 4.00008H15.667C16.592 4.00008 17.3337 4.74175 17.3337 5.66675V14.8334C17.3337 15.7584 16.592 16.5001 15.667 16.5001H2.33366C1.40866 16.5001 0.666992 15.7584 0.666992 14.8334L0.675326 5.66675C0.675326 4.74175 1.40866 4.00008 2.33366 4.00008H5.66699V2.33341C5.66699 1.40841 6.40866 0.666748 7.33366 0.666748H10.667C11.592 0.666748 12.3337 1.40841 12.3337 2.33341V4.00008ZM7.33366 4.00008H10.667V2.33341H7.33366V4.00008Z"
                  fill="#666666"
                />
              </svg>
              <span> Jobs</span></a
            >
          </li>
          <!-- <li [ngClass]="{ 'current-menu-item': currentPath == '/message' }">
            <a
              href="javascript:void(0);"
              (click)="!clickable ? messaging() : null"
              ><i class="fa fa-envelope"></i> <span> Messaging</span
              ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
            ></a>
          </li> -->
          <li
            [ngClass]="{
              'current-menu-item':
                currentPath == '/choose-employer-subscription'
            }"
            *ngIf="!userDeatils.isSuperAdmin"
          >
            <a href="javascript:void(0);" (click)="goToPlans()"
              ><img src="../../../../assets/images/award-icon.svg" class="award-icon" [ngClass]="{
                  'current-employer-item':
                    currentPath == '/choose-employer-subscription'
                }"/>
              <span> Plans</span></a
            >
          </li>
        </ul>
      </div>

      <div class="hdr-log">
        <a
        *ngIf="!userDeatils.isSuperAdmin"
        [ngClass]="{
          'current-employer-message': 
          currentPath == '/message'
        }"
        href="javascript:void(0);"
        (click)="!clickable ? messaging() : null"
        ><i class="fa fa-envelope"></i> <span> Messaging</span
        ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
      ></a>
        <a
          href="javascript:void(0);"
          (click)="!clickable ? gotoLogInEmployer() : null"
          class="nbtn dark"
          >Sign Out</a
        >
      </div>

      <div class="logged">
        <div class="logg">
          <a class="flex" href="javascript:void(0);">
            <div class="pimg">
              <img src="{{ displayPicture }}" />
            </div>
            <span>{{ this.userDeatils.firstName }}</span>
            <ul class="sub-menu">
              <!-- *ngIf="userDtlssessionStorage.isEmailVerified == false" <li><a class="" href="javascript:void(0);">{{this.userDeatils.firstName}} </a></li> -->

              <li class="active">
                <a
                  href="javascript:void(0);"
                  (click)="gotoJobProviderProfile()"
                >
                  Profile
                </a>
              </li>
              <li class="active" *ngIf="this.userDeatils.isEmail">
                <a href="javascript:void(0);" (click)="gotoAppSettings()">
                  Settings
                </a>
              </li>
              <!-- <li class="active">
                <a href="javascript:void(0);" (click)="gotoLogInEmployer()">
                  Log out
                </a>
              </li> -->
            </ul>
          </a>
        </div>
      </div>
    </div>
    <div class="mobileview">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div class="hdr-nav">
          <ul>
            <!-- <li [ngClass]="activeClassCvSearch ? 'current-menu-item' : '' "><a href="javascript:void(0);"
                (click)='!clickable ? cvSearch():null'><img src="../../../../assets/images/Favorite_filled.png">
                <span>CV Search</span></a></li> -->
            <li [ngClass]="{ 'current-menu-item': currentPath == '/services' }">
              <a
                href="javascript:void(0);"
                (click)="services()"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
              >
                <img
                  [src]="
                    isHovered
                      ? '../../../../assets/images/service_icon_green.png'
                      : '../../../../assets/images/service_icon.png'
                  "
                  class="service-icon"
                />
                <span> &nbsp;Services</span>
              </a>
            </li>
            <!-- <li
              [ngClass]="{ 'current-menu-item': currentPath == '/job-search' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? jobSearch() : null"
                ><img src="../../../../assets/images/Favorite_filled.png" />
                <span> &nbsp;Jobs Search</span></a
              >
            </li> -->
            <li
              [ngClass]="{ 'current-menu-item': currentPath == '/Dashboard' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? dashboard() : null"
                ><img src="../../../../assets/images/work_outline_24px.png" />
                <span> &nbsp;Jobs</span></a
              >
            </li>
            <li
            [ngClass]="{
              'current-menu-item':
                currentPath == '/choose-employer-subscription'
            }"
            *ngIf="!userDeatils.isSuperAdmin"
          >
            <a href="javascript:void(0);" (click)="goToPlans()"
              ><img src="../../../../assets/images/award-icon.svg" [ngClass]="{
              'current-employer-item':
                currentPath == '/choose-employer-subscription'
            }"
          />
              <span> Plans</span></a
            >
          </li>
            <!-- <li
              [ngClass]="{ 'current-menu-item': currentPath == '/Dashboard' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? dashboard() : null"
                ><i class="fas fa-list"> </i> <span> &nbsp;Dashboard</span></a
              >
            </li> -->
            <li [ngClass]="{ 'current-menu-item': currentPath == '/message' }" *ngIf="!userDeatils.isSuperAdmin">
              <a
                href="javascript:void(0);"
                (click)="!clickable ? messaging() : null"
                ><img src="../../../../assets/images/mail_24px.png" />
                <span> &nbsp;Messaging</span
                ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
              ></a>
            </li>
          </ul>
        </div>
        <div class="logged">
          <div class="logg">
            <a class="flex" href="javascript:void(0);">
              <div class="pimg">
                <img src="{{ displayPicture }}" />
              </div>
              <span>{{ this.userDeatils.firstName }}</span>
              <ul class="sub-menu">
                <!-- <li><a class="" href="javascript:void(0);">{{this.userDeatils.firstName}} </a></li> -->

                <li class="active">
                  <a
                    href="javascript:void(0);"
                    (click)="gotoJobProviderProfile()"
                  >
                    Profile
                  </a>
                </li>
                <li class="active" *ngIf="this.userDeatils.isEmail">
                  <a href="javascript:void(0);" (click)="gotoAppSettings()">
                    Settings
                  </a>
                </li>
                <li class="active">
                  <a href="javascript:void(0);" (click)="gotoLogInEmployer()">
                    Log out
                  </a>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </mat-menu>
    </div>

    <div
      class="email-notification"
      *ngIf="userDeatils.isEmailVerified == false"
    >
      <div class="down-notify">
        <p>
          <b>Confirm your email address</b>
          <a
            href="javascript:void(0);"
            (click)="emailVarification()"
            style="color: #3eab70"
            >Re-send Email</a
          >
        </p>
      </div>
    </div>
  </div>

  <!-- header start super Admin  -->
  <div
    *ngIf="userDeatils.userType == 'super_admin'"
    class="new-hdr superadmin hdr-logged flex"
  >
    <div class="logo">
      <a href="javascript:void(0);" (click)="goToEmployerHome2()"
        ><img src="../assets/images/logo.webp"
      /></a>
    </div>

    <div class="flex">
      <div class="hdr-nav">
        <ul>
          <li [ngClass]="{ 'current-menu-item': currentPath == '/services' }">
            <a
              href="javascript:void(0);"
              (click)="services()"
            >
              <img
                src="../../../../assets/images/services-svg.svg"
                class="service-icon-emp"
                [ngClass]="{
                  'current-employer-item':
                    currentPath == '/services'
                }"
              />
              <span>Services</span>
            </a>
          </li>
          <li
              [ngClass]="{ 'current-menu-item': currentPath == '/Dashboard' }"
            >
              <a
                href="javascript:void(0);"
                (click)="!clickable ? dashboard() : null"
                ><img src="../../../../assets/images/work_outline_24px.png" />
                <span> &nbsp;Jobs</span></a
              >
            </li>
            <li
            [ngClass]="{
              'current-menu-item':
                currentPath == '/choose-employer-subscription'
            }"
            *ngIf="!userDeatils.isSuperAdmin"
          >
            <a href="javascript:void(0);" (click)="goToPlans()"
              ><img src="../../../../assets/images/award-icon.svg" [ngClass]="{
              'current-employer-item':
                currentPath == '/choose-employer-subscription'
            }"
          />
              <span> Plans</span></a
            >
          </li>
        </ul>
      </div>

      <div class="hdr-log">
        <a
        *ngIf="!userDeatils.isSuperAdmin"
        [ngClass]="{
          'current-employer-message': 
          currentPath == '/message'
        }"
        href="javascript:void(0);"
        (click)="!clickable ? messaging() : null"
        ><i class="fa fa-envelope"></i> <span> Messaging</span
        ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
      ></a>
        <a
          href="javascript:void(0);"
          (click)="!clickable ? gotoLogInEmployer() : null"
          class="nbtn dark"
          >Sign Out</a
        >
      </div>

      <div class="logged">
        <div class="logg">
          <a class="flex" href="javascript:void(0);">
            <div class="pimg">
              <img src="{{ displayPicture }}" />
            </div>
            <span>{{ this.userDeatils.firstName }}</span>
            <ul class="sub-menu">
              <!-- *ngIf="userDtlssessionStorage.isEmailVerified == false" <li><a class="" href="javascript:void(0);">{{this.userDeatils.firstName}} </a></li> -->

              <li class="active">
                <a
                  href="javascript:void(0);"
                  (click)="gotoJobProviderProfile()"
                >
                  Profile
                </a>
              </li>
              <li class="active" *ngIf="this.userDeatils.isEmail">
                <a href="javascript:void(0);" (click)="gotoAppSettings()">
                  Settings
                </a>
              </li>
            </ul>
          </a>
        </div>
      </div>
    </div>
    <div class="mobileview">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <div class="hdr-nav">
          <ul>
            <li [ngClass]="{ 'current-menu-item': currentPath == '/services' }">
              <a
                href="javascript:void(0);"
                (click)="services()"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
              >
                <img
                  [src]="
                    isHovered
                      ? '../../../../assets/images/service_icon_green.png'
                      : '../../../../assets/images/service_icon.png'
                  "
                  class="service-icon"
                />
                <span> &nbsp;Services</span>
              </a>
            </li>
            <li [ngClass]="{ 'current-menu-item': currentPath == '/Dashboard' }">
              <a
                href="javascript:void(0);"
                (click)="!clickable ? dashboard() : null"
                ><i class="fas fa-suitcase"></i> <span> &nbsp;Jobs</span></a
              >
            </li>
            <li [ngClass]="{ 'current-menu-item': currentPath == '/message' }" *ngIf="!userDeatils.isSuperAdmin">
              <a
                href="javascript:void(0);"
                (click)="!clickable ? messaging() : null"
                ><img src="../../../../assets/images/mail_24px.png" />
                <span> &nbsp;Messaging</span
                ><span [ngClass]="messageCount > 0 ? 'custom-dot' : ''"></span
              ></a>
            </li>
          </ul>
        </div>
        <div class="logged">
          <div class="logg">
            <a class="flex" href="javascript:void(0);">
              <div class="pimg">
                <img src="{{ displayPicture }}" />
              </div>
              <span>{{ this.userDeatils.firstName }}</span>
              <ul class="sub-menu">
                <!-- <li><a class="" href="javascript:void(0);">{{this.userDeatils.firstName}} </a></li> -->

                <li class="active">
                  <a
                    href="javascript:void(0);"
                    (click)="gotoJobProviderProfile()"
                  >
                    Profile
                  </a>
                </li>
                <li class="active" *ngIf="this.userDeatils.isEmail">
                  <a href="javascript:void(0);" (click)="gotoAppSettings()">
                    Settings
                  </a>
                </li>
                <li class="active">
                  <a href="javascript:void(0);" (click)="gotoLogInEmployer()">
                    Log out
                  </a>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </mat-menu>
    </div>

    <div
      class="email-notification"
      *ngIf="userDeatils.isEmailVerified == false"
    >
      <div class="down-notify">
        <p>
          <b>Confirm your email address</b>
          <a
            href="javascript:void(0);"
            (click)="emailVarification()"
            style="color: #3eab70"
            >Re-send Email</a
          >
        </p>
      </div>
    </div>
  </div>
</header>
<!--header sction-->
