<div class="extend-container">
  <div class="close-icon" (click)="onNoClick()">
    <span class="close"><a>&#10006;</a></span>
  </div>
  <p class="extend-campaign-text">
    Extend Campaign
    <img
      src="../../../../../../assets/images/double-arrow-right.png"
      class="extend-arrow-right"
    />
  </p>
  <hr class="green-dash" />
  <div class="row">
    <p class="campaign-name-heading">CAMPAIGN NAME</p>
  </div>
  <div class="row">
    <p class="campaign-name">Senior Frontend Developer</p>
  </div>
  <div class="row row-3">
    <div class="col-lg-3 p-0">
      <span class="col-text-1">Status: </span>
      <span class="curved-pill">
        <span class="pill-text">ACTIVE</span>
      </span>
    </div>
    <div class="col-lg-3 p-0">
      <span class="col-text-1">Date Created: </span>
      <span class="pill-text-2">12-05-23</span>
    </div>
    <div class="col-lg-3 p-0">
      <span class="col-text-1">End Date: </span>
      <span class="pill-text-2">20-12-23</span>
    </div>
    <div class="col-lg-3 p-0">
      <span class="col-text-1">Days Left: </span>
      <span class="pill-text-3">21</span>
    </div>
  </div>
  <div class="row row-4 mt-3 inputs-2">
    <div class="col-lg-3 form-group">
      <p class="col-text-2">Extend by how many days</p>
      <mat-form-field appearance="fill" class="custom-input">
        <input matInput placeholder="01" />
      </mat-form-field>
    </div>
    <div class="col-lg-3 form-group">
      <p class="col-text-2">Start Date</p>
      <mat-form-field appearance="fill" class="custom-input p-0">
        <input matInput [matDatepicker]="picker" placeholder="" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-lg-3 form-group">
      <p class="col-text-2">Campaign Budget</p>
      <mat-form-field appearance="fill" class="custom-input">
        <input matInput placeholder="00" />
      </mat-form-field>
    </div>
  </div>
  <div class="row row-5 mt-3">
    <button class="btn btn-continue">Continue</button>
  </div>
</div>
