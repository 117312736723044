<div class="call-to-action">
  <img src="./assets/images/3mtt-small-logo.webp" />
  <p>
    We are Partnering with 3MTT To Match Tech Talents To Partnering Employers
  </p>
  <button class="call-to-action-button" (click)="navigateTo3MTT()">
    Click here to get started
  </button>
</div>
<section class="homebanner">
  <div class="container-fluid pb-0 bg-banner pr-0">
    <div class="row align-items-center">
      <div class="col-lg-1"></div>
      <div class="col-lg-5">
        <div class="homebannerinfo white">
          <h1 class="banner-heading">
            Let's Connect you to your
            <span class="dream-job-color"> Dream Job </span>
          </h1>
          <form class="search-form" [formGroup]="homeNew">
            <div class="search-tag-home">
              <input type="search" id="homesearch" class="form-control input-sm" formControlName="searchText"
                placeholder="Search jobs"/>
              <!-- location auto complete -->
              <mat-form-field class="form-control input-sm form-field-area" appearance="fill">
                  <mat-label>States</mat-label>
                  <mat-select formControlName="cityName"
                  (selectionChange)="changeLocation($event.value)">
                    <mat-option *ngFor="let option of cityList; let i = index" [value]="option">
                      {{ option }}
                    </mat-option>
                </mat-select>
              </mat-form-field>
              <button value="Find Job" class="nbtn input-sm" (click)="search()" [disabled]="homeNew.status === 'INVALID'">
                Search Jobs
              </button>
            </div>
            <div class="warning-div">
              <span *ngIf="f.searchText.touched && f.searchText.invalid">
                <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors.required">Search Title is required
                </span>
                <span class="text-left text-danger pb-1" *ngIf="f.searchText.errors?.pattern">
                  Search Title is not valid.
                </span>
              </span>
            </div>
            <!-- <ul>
              <li>
                <a href="javascript:void(0);" (click)="setSearchText('Client Relations Officer')">Client Relations
                  Officer</a>
              </li>
              <li>
                <a href="javascript:void(0);" (click)="setSearchText('Front-Desk Officer')">Front-Desk Officer</a>
              </li>
              <li>
                <a href="javascript:void(0);" (click)="setSearchText('Receptionist')">Receptionist</a>
              </li>
              <li><a href="javascript:void(0);" (click)="setSearchText('Lab Assistant')">Lab Assistant</a>
              </li>
            </ul> -->
          </form>
          <a href="javascript:void(0);" routerLink="/job-search">Browse all jobs <i
              class="fas fa-chevron-right"></i></a>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-5 pb-0">
        <div class="img">
          <img src="../assets/images/home-search-banner.webp" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <button  (click)="ccjcujcj()" >Find Job</button> -->
<!-- SECTION FOR THE CARDS -->
<section>
  <div class="card-container" id="jobAlertCard">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="card">
          <!-- <img
            class="card-img-element"
            src="../assets/images/build-cv-element.png"
            alt="Image 1"
          /> -->
          <div class="row mt-3">
            <div class="col-lg-6 col-6 d-flex flex-column align-items-start">
              <h2 class="card-para-1">Build CV</h2>
              <p class="card-para-2">
                Take advantage of our CV builder to make your CV stand out.
              </p>
              <div *ngIf="!userDeatils">
                <button href="javascript:void(0);" class="btn btn-get-started" (click)="buildCVsection()">Get
                  Started</button>
              </div>
              <div *ngIf="userDeatils">
                <a href="javascript:void(0);" class="btn btn-get-started" (click)="BuildCV()">Get Started</a>
              </div>
            </div>
            <div class="col-lg-6 col-6">
              <img class="build-cv-lady" src="../assets/images/build-cv-lady.webp" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="card job-alert-card">
          <div class="row">
            <div class="col-lg-6 col-6 order-lg-2 job-alert-desc">
              <h2 class="card-para-4">Set Job Alert</h2>
              <p class="card-para-5">
                Receive daily, weekly or monthly alert about your preferred jobs
              </p>
              <form [formGroup]="jobAlertForm">
                <input placeholder="enter email" class="form-control input-email" formControlName="email" />
                <button type="submit" class="btn-subscribe" (click)="createAlert()">
                  Subscribe
                </button>
                <div *ngIf="errorCheck.email.touched && errorCheck.email.invalid" class="invaild-div">
                  <span class="text-left text-danger pb-1" *ngIf="errorCheck.email.errors.required">Email is required
                  </span>
                  <span class="text-left text-danger pb-1" *ngIf="errorCheck.email.errors?.pattern">
                    Enter valid email .
                  </span>
                </div>
              </form>
            </div>
            <div class="col-lg-6 col-6 order-lg-1">
              <img *ngIf="!isMobileScreen" class="job-alert-img" src="../assets/images/job-alert.webp" alt="Job Alert Card" />
              <img *ngIf="isMobileScreen" class="job-alert-img" src="../assets/images/job-alert-mobile.png"
                alt="Job Alert Card Mobile" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="row hire-row">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 hire-col-2">
      <h3 class="hire-heading">Increase Your Chance Of Getting Hired</h3>
      <p class="hire-text">Request A CV or Cover Letter Review</p>
      <button class="btn btn-rqst-review" (click)="navigateToCvReview()">
        Request a Review
      </button>
    </div>
    <div class="col-lg-3">

    </div>
    <div class="col-lg-3 hire-2-img">
      <img class="" src="../../../../assets/images/cv-review.webp" />
    </div>
    <div class="col-lg-1"></div>
  </div>
</section>
<section class="newjobs-boxes sky2 common-padd">
  <div class="container">
    <div class="row" style="justify-content: center;">
      <div class="col-lg-4">
        <div class="newjobs-boxes-wrap">
          <h4>Hot Jobs</h4>
          <span *ngFor="let row of hotJob; let i = index">
            <div class="newjobs-boxes-inner">
              <div class="newjobs-boxes-inner-left" (click)="jobDetails(row)" style="cursor: pointer">
                <h5>
                  <a href="javascript:void(0);">{{ row.title }}</a>
                </h5>
                <p>
                  <span *ngIf="row?.minimum_salary != '0'">
                    {{ row?.currency }} {{ row?.minimum_salary | number }}
                  </span>
                  <span *ngIf="row?.maximum_salary != '0'">
                    - {{ row?.currency }} {{ row?.maximum_salary | number }}
                  </span>
                  <span *ngIf="row?.minimum_salary == '0'">
                    Salary - Not specified or see the job description
                  </span>
                </p>
                <ul>
                  <li>{{ row.company_name }}</li>
                  <li>{{ row.location_type }}, {{ row.job_location }}</li>
                </ul>
              </div>
              <div class="newjobs-boxes-inner-right">
                <ul>
                  <li *ngIf="!row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)"><i class="far fa-heart"></i></a>
                  </li>
                  <li *ngIf="row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)" class="active"><i
                        class="far fa-heart"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="shareJob(row)"><i class="fas fa-share-alt"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </span>
          <span *ngIf="hotJob?.length > 5">
            <a href="javascript:void(0);">See all Hot Jobs <i class="fas fa-chevron-right"></i></a>
          </span>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="newjobs-boxes-wrap">
          <h4>Recently Posted</h4>
          <span *ngFor="let row of recentJob; let i = index">
            <div class="newjobs-boxes-inner">
              <div class="newjobs-boxes-inner-left" (click)="jobDetails(row)" style="cursor: pointer">
                <h5>
                  <a href="javascript:void(0);">{{ row.title }}</a>
                </h5>
                <p>
                  <span *ngIf="row?.minimum_salary != '0'">{{ row?.currency }}
                    {{ row?.minimum_salary | number }}</span>
                  <span *ngIf="row?.maximum_salary != '0'">
                    - {{ row?.currency }}
                    {{ row?.maximum_salary | number }}</span>
                  <span *ngIf="row?.minimum_salary == '0'">
                    Salary - Not specified or see the job description</span>
                </p>
                <ul>
                  <li>{{ row.company_name }}</li>
                  <li>{{ row.location_type }}, {{ row.job_location }}</li>
                </ul>
              </div>
              <div class="newjobs-boxes-inner-right">
                <ul>
                  <li *ngIf="!row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)"><i class="far fa-heart"></i></a>
                  </li>
                  <li *ngIf="row?.isSaved">
                    <a href="javascript:void(0);" (click)="savedJob(row)" class="active"><i
                        class="far fa-heart"></i></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" (click)="shareJob(row)"><i class="fas fa-share-alt"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </span>
          <span *ngIf="recentJob?.length > 5">
            <a href="javascript:void(0);">See all Recently Posted Jobs <i class="fas fa-chevron-right"></i></a>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="job-categories common-padd pb-0">
  <div class="container">
    <div class="row text-center">
      <div class="col-lg-12">
        <div class="hdr gray">
          <h2>Job Categories</h2>
          <h5>Select from below to discover jobs based on a category</h5>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4" *ngFor="let column of getColumns(jobCatagoryTypeList)">
        <div class="newcatlist">
          <ul>
            <li *ngFor="let row of column">
              <a href="javascript:void(0);" (click)="categotyWiseJobSearch(row)">{{ row.categoryName }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row text-center align-items-center">
      <div class="col-lg-12">
        <div class="hdr mb-5">
          <h5>Did not find the right category?</h5>
          <a href="javascript:void(0);" routerLink="/job-search">Browse all jobs</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="employer pb-0 bg-dark-green">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="image-container">
          <img src="../assets/images/new-job-element.webp" class="new-job-element" />
          <div class="text-container">
            <h2 class="para-6">Are you an employer?</h2>
            <h5 class="para-7">
              Discover the right professional from Nigeria’s largest work
              resource!
            </h5>
            <a href="javascript:void(0);" (click)="postAjob()" class="nbtn-white m20">Post Job Opportunities</a>
            <h5 class="para-7">
              We verify our employers. <br />
              Keeping job seekers safe is our priority.
            </h5>
            <a href="javascript:void(0);" (click)="Dashboard()" class="nbtn-white">Get Verified</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 align-items-center justify-content-center">
        <div class="img">
          <img class="new-job-posting" src="../assets/images/employers-img.webp" />
        </div>
      </div>
    </div>
  </div>
</section>