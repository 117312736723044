import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { JsEditProfileComponent } from '../js-edit-profile/js-edit-profile.component';
import { JsEducationComponent } from '../js-education/js-education.component';
import { JsExperianceComponent } from '../js-experiance/js-experiance.component';
import { JsLicenseOrCertificateComponent } from '../js-license-or-certificate/js-license-or-certificate.component';
import { JsSkillComponent } from '../js-skill/js-skill.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from '../../../../environments/environment';
import { FirstProfileOptionComponent } from '../first-profile-option/first-profile-option.component';
import { SharejobComponent } from '../../job-search/sharejob/sharejob.component';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import { JobseekerShareProfileComponent } from '../jobseeker-share-profile/jobseeker-share-profile.component';
import { Observable, Observer } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';

@Component({
  selector: 'app-cv-purser-view-profile',
  templateUrl: './cv-purser-view-profile.component.html',
  styleUrls: ['./cv-purser-view-profile.component.scss'],
})
export class CvPurserViewProfileComponent implements OnInit {
  isDisplay: boolean = false;
  userDeatils: any;
  companyData: any;
  educationAllRecords: any;
  experianceAllRecords: any;
  licenseAllRecords: any;
  profileRecords: any;
  skillAllRecords: any;
  fileName: any;
  fileToUpload: any;
  uploadFileResult: any;
  base64File: string | ArrayBuffer;
  show: boolean;
  cv: boolean = false;
  base64Image: string;
  v: any =
    'https://worknigeria-file-storage.s3.amazonaws.com/user_profile_image/f3594771-acf6-45c8-a548-2e202889430f_bad.jpg';
  // skillShow = [];
  // allFruits: any[] = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    public datepipe: DatePipe,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    // private dialogRef: MatDialogRef<JobSeekerProfileComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private _location: Location
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    // this.getAllDetails();
    this.io.getforCVpurserData().subscribe((res) => {
      if (res != null) {
        // console.log(res);
        this.profileRecords = res['result'].userData;
        this.educationAllRecords =
          res['result'].userData.educations == undefined
            ? []
            : res['result'].userData.educations;
        this.experianceAllRecords =
          res['result'].userData.experience == undefined
            ? []
            : res['result'].userData.experience;
        this.licenseAllRecords =
          res['result'].userData.license == undefined
            ? []
            : res['result'].userData.license;
        this.skillAllRecords =
          res['result'].userData.skills == undefined
            ? []
            : res['result'].userData.skills;
      }
    });
  }

  transformTreeDateTimeUrl(value = '', format = 'yyyy-MM-dd') {
    var datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, format);
    return value;
  }

  yearClculation(data) {
    // console.log(data)
    if (data.startDate) {
      if (data.endDate === '' || data.endDate === null) {
        let thisYear = new Date().getFullYear();
        let dtArr = data.startDate.split('-');
        let final = thisYear - Number(dtArr[0]);
        // console.log(thisYear,final)
        return final;
      }
    }
    // console.log(data)
    if (data.startDate && data.endDate) {
      let dtstart = data.startDate.split('-');
      let dtend = data.endDate.split('-');
      let final = dtend[0] - dtstart[0];
      return final;
    }
    return '';
  }

  education(data) {
    // console.log(data)
    const dialogRef = this.dialog.open(JsEducationComponent, {
      height: '350px',
      width: '550px',
      data: {
        data,
        wholeData: Object.assign([], this.educationAllRecords),
        confirm: 'confirmEducation',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // console.log(res)
        this.educationAllRecords = res;
      }
    });
  }

  cancel(){
    this._location.back();
  }

  experiance(data) {
    // console.log(data)
    const dialogRef = this.dialog.open(JsExperianceComponent, {
      height: '500px',
      width: '550px',
      data: {
        data,
        wholeData: Object.assign([], this.experianceAllRecords),
        confirm: 'confirmExperiance',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // console.log("++++++++++++")
        this.experianceAllRecords = res;
      }
    });
  }

  profile() {
    // console.log(this.profileRecords)
    const dialogRef = this.dialog.open(JsEditProfileComponent, {
      height: '450px',
      width: '650px',
      data: { data: this.profileRecords, confirm: 'confirmProfile' },
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        // console.log(res)
        this.profileRecords = res.data;
      }
    });
  }

  certifiate(data) {
    console.log(data);
    const dialogRef = this.dialog.open(JsLicenseOrCertificateComponent, {
      height: '450px',
      width: '550px',
      data: {
        data,
        wholeData: Object.assign([], this.licenseAllRecords),
        confirm: 'confirmLicense',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // console.log("++++++++++++")
        this.licenseAllRecords = res;
      }
    });
  }

  skill() {
    // console.log(this.skillAllRecords)
    const dialogRef = this.dialog.open(JsSkillComponent, {
      height: '450px',
      width: '550px',
      data: {
        data: Object.assign([], this.skillAllRecords),
        confirm: 'confirmSkill',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // console.log(res)
        this.skillAllRecords = res;
      }
    });
  }

  onSelectFile(event) {
    this.fileName = event.target.files[0].name;
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUpload = event.target.files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          // console.log(reader.result.toString().replace("data:application/pdf;base64,",""));
          let base64Data = reader.result
            .toString()
            .replace('data:application/pdf;base64,', '');
          let jsonData = {
            filedata: base64Data,
            filename: this.fileName,
            userkey: environment.CVpurse_userkey,
            version: environment.CVpurse_version,
            subuserid: environment.CVpurse_subuserid,
          };
          // console.log(jsonData)
          this.io._CVpurse(jsonData).subscribe(
            (res: any) => {
              // console.log(res)
            },
            (err) => console.log(err)
          );
        };
        reader.readAsDataURL(this.fileToUpload);
      }
    }
  }

  submit() {
    let jsonData = {
      path: 'add-parsed-resume-data-to-user',
      id: this.userDeatils.id,
      userData: {
        firstName: this.profileRecords.firstName,
        middleName: this.profileRecords.middleName || '',
        lastName: this.profileRecords.lastName,
        website: this.profileRecords.website,
        educations: this.educationAllRecords,
        license: this.licenseAllRecords,
        skills: this.skillAllRecords,
        experience: this.experianceAllRecords,
        about: this.profileRecords.about,
        profileImage: this.profileRecords.profileImage,
        userLocation: this.profileRecords.userLocation,
        contactNumber: this.profileRecords.contactNumber,
      },
    };
    // console.log(jsonData)
    this.io._submitAllDataByCvPurser(jsonData).subscribe((res: any) => {
      // console.log(res)
      if (res.serverResponse.code == 200) {
        let userdata = res['result'].user.Attributes;
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        if (localStorage.getItem('workNigeriaUser') === null) {
          this.auth.setCurrentUserDetailsInSessoin(userdata);
        } else {
          this.auth.setCurrentUserDetails(userdata);
        }
        this.router.navigate(['/jobseeker-profile']);
      }
    });
  }
  //  // this.dialogRef.close();
  //  let userdata = res['result'].userData
  //  let token = res['result'].token
  //  let companyData = res['result'].companyData

  //  if (this.remeber) {

  //    this._authService.sendToken(token);
  //    this._authService.setCurrentUserDetails(userdata);
  //    this._authService.setCurrentCompanyData(companyData);

  //  }
  //  else {
  //    this._authService.sendTokenInsession(token);
  //    this._authService.setCurrentUserDetailsInSessoin(userdata);
  //    this._authService.setCurrentCompanyDataInSessoin(companyData);

  //    // localStorage.setItem('companyDetails',btoa(JSON.stringify(res['result'].companyData)))
  //  }
}
