<div class="tab-content py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active" id="jobs">
    <div class="menu-padding">
      <div class="btnrightadd container-fluid remove-padding">
        <div class="row justify-content-between align-items-center g-0">
          <div class="col-sm-3 text-left">
            <div class="brd-crmb">
              <div class="select-wrap">
                <div class="select-row">
                  <mat-form-field class="selectbox" appearance="fill">
                    <mat-select
                      [(value)]="selectedUserType"
                      (selectionChange)="changeUserType($event)"
                    >
                      <mat-option
                        value="{{ type }}"
                        *ngFor="let type of userTypeList"
                        >{{ type }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 text-right">
            <div class="btn-set">
              <a
                href="javascript:void(0);"
                class="btn border"
                (click)="downloadUsers()"
                ><img
                  class="csv-icon"
                  src="../assets/images/csv-icon.png"
                  alt=""
                />&nbsp;&nbsp;Download</a
              >
            </div>
          </div>
        </div>
      </div>

      <form [formGroup]="dashboardDetails">
        <div class="filter-options-row">
          <div class="select-wrap">
            <div class="separator">
              <div class="select-row">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Start Date</mat-label>
                  <input
                    matInput
                    disabled
                    [matDatepicker]="startPicker"
                    formControlName="start_date"
                    (click)="startPicker.open()"
                    (dateChange)="filterStartDate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="startPicker"
                    disabled="false"
                  >
                    <img
                      matDatepickerToggleIcon
                      src="../../../../assets/images/calendar.svg"
                    />
                  </mat-datepicker-toggle>
                  <mat-datepicker
                    #startPicker
                    [disabled]="false"
                  ></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="select-row">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label class="custom-mat-label">End Date</mat-label>
                  <input
                    matInput
                    disabled
                    [matDatepicker]="endPicker"
                    formControlName="end_date"
                    (click)="endPicker.open()"
                    (dateChange)="filterEndDate($event)"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endPicker"
                    disabled="false"
                  >
                    <img
                      matDatepickerToggleIcon
                      src="../../../../assets/images/calendar.svg"
                    />
                  </mat-datepicker-toggle>
                  <mat-datepicker
                    #endPicker
                    [disabled]="false"
                  ></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="select-row wide-row">
                <mat-form-field appearance="fill">
                  <mat-label *ngIf="selectedUserType === 'Employer'"
                    ><i class="fas fa-search"></i> Search with name, company or
                    location</mat-label
                  >
                  <mat-label *ngIf="selectedUserType === 'Jobseeker'"
                    ><i class="fas fa-search"></i> Search with email, name or
                    location</mat-label
                  >
                  <input
                    type="text"
                    matInput
                    placeholder="Search text"
                    formControlName="search_text"
                  />
                </mat-form-field>
              </div>
            </div>

            <div class="select-row-dropdown">
              <mat-form-field class="selectbox" appearance="fill">
                <mat-select [(value)]="selectedActionType">
                  <mat-option
                    value="{{ type }}"
                    *ngFor="let type of actionTypeList"
                    disabled="{{ type === 'Action' ? true : false }}"
                    >{{ type }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="well-views table-responsive">
      <table class="table table-hover">
        <thead>
          <tr *ngIf="selectedUserType === 'Employer'">
            <th><input class="form-check-input" type="checkbox" value="" /></th>
            <th>Name</th>
            <th>Company</th>
            <th>Email</th>
            <th>Location</th>
            <th>Phone</th>
            <th>Status</th>
          </tr>
          <tr *ngIf="selectedUserType === 'Jobseeker'">
            <th><input class="form-check-input" type="checkbox" value="" /></th>

            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Location</th>
            <th>Last</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody *ngIf="selectedUserType === 'Employer'">
          <tr *ngFor="let user of usersList; let i = index">
            <td><input class="form-check-input" type="checkbox" value="" /></td>

            <td style="cursor: pointer">
              {{ user.firstName + " " + user.lastName }}
            </td>
            <td style="cursor: pointer">
              {{ user.company.companyName }}
            </td>
            <td style="cursor: pointer">
              {{ user.email }}
            </td>
            <td style="cursor: pointer">
              {{ user.location }}
            </td>
            <td style="cursor: pointer">
              {{ user.phone }}
            </td>
            <td style="cursor: pointer">
              <div
                class="{{
                  user.isEmailVerified === true
                    ? 'status-pill-verified'
                    : 'status-pill'
                }}"
              >
                <p>
                  {{
                    user.isEmailVerified === true ? "Verified" : "Not Verified"
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="selectedUserType === 'Jobseeker'">
          <tr *ngFor="let user of usersList; let i = index">
            <td><input class="form-check-input" type="checkbox" value="" /></td>

            <td style="cursor: pointer">
              {{ user.firstName }}
            </td>
            <td style="cursor: pointer">
              {{ user.lastName }}
            </td>
            <td style="cursor: pointer">
              {{ user.email }}
            </td>
            <td style="cursor: pointer">
              {{ user.location }}
            </td>
            <td style="cursor: pointer">
              {{ user.createdAt }}
            </td>
            <td style="cursor: pointer">
              <div
                class="{{
                  user.isEmailVerified === true
                    ? 'status-pill-verified'
                    : 'status-pill'
                }}"
              >
                <p>
                  {{
                    user.isEmailVerified === true ? "Verified" : "Not Verified"
                  }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="no-job-found" *ngIf="usersList.length == '0'">
      <h4>No Users Found</h4>
    </div>
    <ngb-pagination
      *ngIf="usersList?.length > 0"
      [page]="page"
      [pageSize]="pageSize"
      [maxSize]="10"
      (pageChange)="onPageChanged($event)"
      [ellipses]="false"
      [rotate]="true"
      [collectionSize]="collectionSize"
    >
      <ng-template ngbPaginationPrevious
        ><img src="../../../../assets/images/previous-button.svg" />&nbsp;
        Prev.</ng-template
      >
      <ng-template ngbPaginationNext
        ><img src="../../../../assets/images/next-button.svg" />&nbsp;
        Next</ng-template
      >
    </ngb-pagination>
  </div>
</div>
