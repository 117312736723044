import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CvSearchRoutingModule } from './cv-search-routing.module';
import { CvSearchComponent } from './cv-search.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { MaterialModule } from '../../modules/material/material.module';

import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';

@NgModule({
  declarations: [CvSearchComponent],
  imports: [
    CommonModule,
    CvSearchRoutingModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MaterialModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CvSearchModule {}
