<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>
<div
  class="mobile-first-profile-option-modal"
  style="
    height: 450px;
    overflow-x: scroll;
    margin-top: 30px;
    padding-right: 15px;
  "
>
  <h4
    style="display: flex; align-items: center; justify-content: space-between"
  >
    Edit Profile
  </h4>
  <div>
    <div>
      <div>
        <div class="card-mid pro-img">
          <div class="profile-withPic">
            <figure>
              <img src="{{ imgURL == '' ? profileImage : imgURL }}" />
            </figure>
            <div class="upload-cv-btn-wrap">
              <a href="javascript:void(0);" class="btn-green">
                Upload Picture</a
              >
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/webp"
                (change)="onSelectFile($event)"
              />
              <button
                style="float: right"
                mat-raised-button
                color="primary"
                (click)="updateProfile()"
              >
                UPDATE
              </button>
            </div>
          </div>
          <figcaption class="mb-3">
            <strong class="d-block">{{ Name }}</strong>
          </figcaption>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3"></div>
  <!-- <img [src]="imgURL" *ngIf="imgURL" class="imgPlaceholder"> -->
  <div class="col-lg-3"></div>
  <form class="example-form modal-dropdown" [formGroup]="profileForm">
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>First Name</mat-label>
            <input
              type="text"
              matInput
              placeholder="First Name"
              required="required"
              formControlName="firstName"
            />
          </mat-form-field>
        </div>
        <div *ngIf="f.firstName.touched && f.firstName.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.firstName.errors.required"
            >First Name is required
          </span>
        </div>
      </div>

      <div class="col-lg-4">
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Middle Name</mat-label>
              <input
                type="text"
                matInput
                placeholder="Middle Name"
                formControlName="middleName"
              />
            </mat-form-field>
          </div>
        </div>

      <div class="col-lg-4">
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Last Name</mat-label>
            <input
              type="text"
              matInput
              placeholder="Last Name"
              required="required"
              formControlName="lastName"
            />
          </mat-form-field>
        </div>
        <div *ngIf="f.lastName.touched && f.lastName.invalid">
          <span
            class="text-left text-danger pb-1"
            *ngIf="f.lastName.errors.required"
            >Last Name is required
          </span>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Location</mat-label>
            <input
              type="text"
              matInput
              placeholder="Location"
              formControlName="Location"
            />
          </mat-form-field>
        </div>
        <!-- <div *ngIf="f.Location.touched && f.Location.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.Location.errors.required">Location is required
                    </span>
                </div> -->
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Contact Number</mat-label>
            <input
              type="text"
              matInput
              placeholder="Contact Number"
              formControlName="conatctNumber"
            />
          </mat-form-field>
        </div>
        <!-- <div *ngIf="f.conatctNumber.touched && f.conatctNumber.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.conatctNumber.errors.required">Contact Number is
                        required
                    </span>
                </div> -->
      </div>

      <div class="col-lg-12">
        <div class="form-group mb-3">
          <angular-editor
            [(ngModel)]="description"
            [config]="editorConfig"
            formControlName="description"
          >
          </angular-editor>
        </div>
        <!-- <div *ngIf="f.description.touched && f.description.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.description.errors.required">Description is
                        required
                    </span>
                </div> -->
      </div>
      <div class="col-lg-12"></div>
      <div class="col-lg-12">
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Website</mat-label>
            <input
              type="text"
              matInput
              placeholder="Website"
              formControlName="Website"
            />
          </mat-form-field>
        </div>
        <!-- <div *ngIf="f.Website.touched && f.Website.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.Website.errors.required">Website Number is
                        required
                    </span>
                </div> -->
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Title</mat-label>
            <input
              type="text"
              matInput
              placeholder="Title"
              formControlName="Title"
            />
          </mat-form-field>
        </div>
        <!-- <div *ngIf="f.Title.touched && f.Title.invalid">
                    <span class="text-left text-danger pb-1" *ngIf="f.Title.errors.required">Title Number is
                        required
                    </span>
                </div> -->
      </div>
    </div>
  </form>
  <p>
    <button
      style="float: right"
      mat-raised-button
      color="primary"
      (click)="updateProfile()"
    >
      UPDATE
    </button>
  </p>
</div>
