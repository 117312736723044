<div
  property="schema:text"
  class="trmsncnd clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item common-padd"
>
  <div class="container">
    <h2>About Us</h2>

    <p>
      <span
        ><span
          ><span
            >WorkNigeria was established with a Singular Vision. To help the
            vast Nigerian workforce find meaningful jobs. We are also on a
            mission to upskill them in the best ways possible and help them grow
            with and within their careers.
          </span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span>We have lofty goals - To put Nigeria back to work</span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >We understand that these goals may require a lot of work in
            achieving, but we have a leadership team that leverages extensive
            experience in this space to form a solid core foundation. With the
            help of recruiters and leading companies willing to come on board,
            we’re certain that we can make WorkNigeria a community of
            like-minded individuals looking to grow and the #1 Job Board in
            Nigeria.
          </span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            ><strong style="font-size: 26px">Our Values </strong></span
          ></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >WorkNigeria is the brainchild of Innovate Africa, an organization
            dedicated to solving African society’s most pressing problems. Our
            biggest motivation across our subsidiaries revolves around honesty,
            customer empathy, encouraging diversity, winning as a team, and
            fun.</span
          ></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >Honesty &amp; Transparency are the driving forces behind our
            organization because we truly believe it is the only way of building
            loyalty and trust with our partners in the long run.
          </span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >Customer Empathy is another aspect that is paramount to our
            success. We understand the difficulties our customers might face and
            being able to resonate with their needs helps us go a long way in
            providing them with appropriate solutions.
          </span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >Diversity in the workplace is a topic of great discussion in the
            world today, and we intend to do our bit to ensure that we are a
            forward-thinking organization that prides itself on its diverse
            practices.
          </span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >Having a winning team and winning as a team are 2 different things.
            We’re a highly experienced set of professionals who work in tandem
            to move ahead despite all obstacles. We celebrate our wins and stick
            together during difficult moments.
          </span></span
        ></span
      >
    </p>

    <p>
      <span
        ><span
          ><span
            >But at the end of the day, we don’t want to be too self-serious, we
            want our journey to be fun and full of new experiences. We look
            forward to having you onboard our exciting journey.</span
          ></span
        ></span
      >
    </p>
  </div>
</div>
