<div *ngIf="detailsOfJob">
    <div class="col-lg-8 p-0 job-desc">
    <span>
      <div class="hsc-top-hd">
        <div class="row p-4 job-desc-row-1">
          <div class="col-lg-1 p-0 d-flex">
            <div class="company-img" *ngIf="detailsOfJob.is_3mtt">
              <img [src]="'../../../assets/images/3mtt.svg'" onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
            </div>
            <div class="company" *ngIf="!detailsOfJob.is_3mtt">
              <img [src]="detailsOfJob.company_behalf_logo
                              ? detailsOfJob.company_behalf_logo
                              : detailsOfJob.logo
                              ? detailsOfJob.logo
                              : '../../../assets/images/sm-Logo.png'" onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'" />
            </div>
          </div>
          <div class="col-lg-8">
            <h3 class="job-desc-title">
              {{ detailsOfJob.title }}
            </h3>
            <p class="job-desc-city-salary">
              <span class="job-desc-company-name">
                {{
                  detailsOfJob?.company_behalf_name
                    ? detailsOfJob?.company_behalf_name
                    : detailsOfJob?.company_name
                }}
              </span>
              <span class="job-desc-location">
                -
                {{ detailsOfJob?.city }}, {{ detailsOfJob?.job_location }}
              </span>
              <span> | </span>
              <span
                class="job-desc-currency"
                *ngIf="
                  detailsOfJob?.minimum_salary != '0' &&
                  detailsOfJob?.minimum_salary != ''
                "
                >{{ detailsOfJob?.currency }}
                {{ detailsOfJob?.minimum_salary | number }}
              </span>
              <span
                class="job-desc-currency"
                *ngIf="
                  detailsOfJob?.maximum_salary != '0' &&
                  detailsOfJob?.maximum_salary != ''
                "
              >
                - {{ detailsOfJob?.currency }}
                {{ detailsOfJob?.maximum_salary | number }}
              </span>
              <span
                class="job-desc-currency"
                *ngIf="
                  detailsOfJob?.minimum_salary == '0' ||
                  detailsOfJob?.minimum_salary == ''
                "
              >
                Salary - Not specified or see the job description
              </span>
            </p>
            <div class="tag-hot-list">
              <ul>
                <li [style.display]="(detailsOfJob?.employment_type === 'None' || detailsOfJob?.employment_type === '') ? 'none' : 'relative'">
                  <a href="javascript:void(0);" class="employment-type">{{
                    detailsOfJob.employment_type
                  }}</a>
                </li>
                <li>
                  <a href="javascript:void(0);" class="employment-years"
                    >{{ detailsOfJob.minimum_experience }}+ years</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-2 share-btn">
            <ul class="d-flex flex-column">
              <li class="mb-3">
                <a
                  class="job-desc-share"
                  href="javascript:void(0);"
                  (click)="shareJob()"
                >
                  <img src="../../../../assets/images/share-new.png" />
                  Share</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="row job-desc-row-2">
            <div class="test">
                <ul>
                  <li
                    [ngClass]="clickSection == 'jobDescription' ? 'active' : ''"
                  >
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('jobDescription-large')"
                      >Job Description</a
                    >
                  </li>
                  <li
                    [ngClass]="clickSection == 'qualifications' ? 'active' : ''"
                  >
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('qualifications-large')"
                      >Qualification</a
                    >
                  </li>
                  <li [ngClass]="clickSection == 'company' ? 'active' : ''">
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('company-large')"
                      >Company Info</a
                    >
                  </li>
                  <li [ngClass]="clickSection == 'related' ? 'active' : ''">
                    <a
                      href="javascript:void(0);"
                      (click)="scrollToElement('related')"
                      >Related Jobs</a
                    >
                  </li>
                </ul>
                <label class="toggle">
                    <input type="checkbox" (change)="toggleJobStatus()" [checked]="detailsOfJob.job_status === 'active'" />
                    <span class="slider round"></span>
                    <span class="toggle-text"></span>
                </label>
            </div>
        </div>
      </div>
      <div *ngIf="detailsOfJob" class="search-filter-right">
        <div class="test-info" id="topOfDesc">
          <div
            class="step-1 jobDescription disc-list"
            id="jobDescription-large"
            [ngClass]="
              clickSection == 'jobDescription'
                ? 'padding-jobDescription'
                : ''
            "
          >
            <h4>Job Description</h4>
            <div [innerHTML]="detailsOfJob.description"></div>
          </div>
          <div
            class="step-1 qualifications disc-list"
            id="qualifications-large"
            [ngClass]="
              clickSection == 'qualifications'
                ? 'padding-qualifications'
                : ''
            "
          >
            <h4>Qualifications</h4>

            <p [innerHTML]="qualifications"></p>
          </div>
          <div
            class="step-1 company disc-list"
            id="company-large"
            [ngClass]="clickSection == 'company' ? 'padding-company' : ''"
          >
            <h4>Company Info</h4>
            <h4 class="company-name">
              {{
                detailsOfJob?.company_behalf_name
                  ? detailsOfJob?.company_behalf_name
                  : detailsOfJob?.company_name
              }}
            </h4>
          </div>
        </div>
      </div>
    </span>
  </div>
</div>