<div class="messagepopupdas change-status">
  <div class="modal-header">
    <h2>Change status</h2>
    <button class="close" (click)="onNoClickClose()">
      <img src="../../../../assets/images/menu_close.png" alt="" />
    </button>
  </div>

  <div class="messagepopupdas-profile">
    <div class="messagepopupdas-img">
      <!-- <img *ngIf="this.data.wholeData.profileImage && this.data.wholeData.profileImage != '' " [src]="this.data.wholeData.profileImage " alt=""> -->
      <img
        [src]="
          this.data.wholeData.profileImage != ''
            ? this.data.wholeData.profileImage
            : '../../../assets/images/sm-Logo.png'
        "
        onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
      />
    </div>
    <div class="messagepopupdas-pro-info">
      <h4>
        {{ this.data.wholeData.firstName }} {{ this.data.wholeData.lastName }}
      </h4>
      <!-- <p>Producer</p> -->
    </div>
  </div>

  <div class="mb-4">
    <div class="select-row">
      <mat-form-field class="example-full-width selectbox" appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select
          [(ngModel)]="selected"
          (selectionChange)="changeStatusInDropdown($event)"
        >
          <mat-option
            value="{{ row }}"
            *ngFor="let row of statusList; let i = index"
            [disabled]="this.index > i"
            >{{ row[0].toUpperCase() + row.slice(1) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="mb-4">
    <div class="select-row">
      <mat-form-field
        class="example-full-width selectbox"
        appearance="fill"
        *ngIf="this.dropDownValue == 'rejected'"
      >
        <mat-label>status</mat-label>
        <mat-select
          [(ngModel)]="cause"
          (selectionChange)="changeStatusInCause($event)"
        >
          <mat-option value="Lack of Work Authorization"
            >Lack of Work Authorization</mat-option
          >
          <mat-option value="Not able to relocate"
            >Not able to relocate</mat-option
          >
          <mat-option value="Not Qualified">Not Qualified</mat-option>
          <mat-option value="Unfavorable Interview"
            >Unfavorable Interview</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- <div class="message-info">
        <span *ngIf="showSection() == 'rejected'">
            <p>We will notify {{this.data.wholeData.firstName}} that they are no longer being considered for this
                position.
                Would you like to provide a
                reason for this decision? You can select from our list of pre-written notes, edit a pre-written note, or
                write your own custom note.</p>

            <p>This note will be sent as an email from donotreply@worknigeria.com. Applicants will not be able to
                respond.
                If you wish to communicate directly, please send a direct message instead.</p>
        </span>
        <span *ngIf="showSection() == 'interviewing'">interviewing</span>
        <span *ngIf="showSection() == 'in-progress'">in-progress</span>
        <span *ngIf="showSection() == 'applied'">applied</span>
        <span *ngIf="showSection() == 'withdrawn'">withdrawn</span>
        <span *ngIf="showSection() == 'hired'">hired</span>
        <span *ngIf="showSection() == 'hold for future openings'">hold for future openings</span>
    </div> -->
  <!-- <textarea *ngIf="mailContext != '' " [(ngModel)]="mailContext"
        placeholder="Thank you for your application. Unfortunately, 
        you do not have all the qualifications we are seeking for this position."></textarea> -->
  <angular-editor
    [(ngModel)]="mailContext"
    [config]="editorConfig"
  ></angular-editor>

  <div class="btnrightwrap">
    <button class="btn btn-green" (click)="onNoClickClose()">Cancel</button>
    <button class="btn-green btn" (click)="changeStatus()">
      Change status
    </button>
  </div>
</div>
