import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ExportAnalyticsDataService } from 'src/app/services/export-analytics-data.service';

@Component({
  selector: 'app-emp-dash-admin',
  templateUrl: './emp-dash-admin.component.html',
  styleUrls: ['./emp-dash-admin.component.scss']
})
export class EmpDashAdminComponent implements OnInit {

  analyticsList: any;
  pagination = {
    size: 10,
    start: 0,
    total: 0,
  };
  currentPage: number = 1;
  pageSize: number = 7;
  activeCurrentPage: number = 1;
  activePageSize: number = 7;
  sortOrder: string = 'DESC';

  selectedOption: string;
  displayedOption: string;
  options: string[] = ['15 Days', '30 Days', '60 Days'];
  startDate: string;

  constructor(
    private empService: EmployerService,
    private exportAnalyticsDataService: ExportAnalyticsDataService,
  ) {}

  ngOnInit(): void {
    this.selectedOption = '';
    this.displayedOption = '30 Days';
    this.calculateDate();
    this.getFellowAnalytics();
  }

  calculateDate() {
    const daysMatch = this.displayedOption.match(/(\d+)\s+Days/);
    if (daysMatch) {
      const days = parseInt(daysMatch[1], 10);
      const today = new Date();
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - days);

      this.startDate = this.formatDate(startDate);
    } else {
      console.error('Invalid displayedOption format');
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  updateSelectedOption(value: string) {
    this.displayedOption = value;
    this.calculateDate();
    this.getFellowAnalytics();
  }

  exportAllJobs() {
    this.exportAnalyticsDataService.genExcelCompanyBasic(this.analyticsList);
  }

  get paginatedDataRoles() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.analyticsList?.totalRolesGroupedByCompany.slice(startIndex, endIndex);
  }

  get paginatedDataActiveRoles() {
    const startIndex = (this.activeCurrentPage - 1) * this.activePageSize;
    const endIndex = startIndex + this.activePageSize;
    return this.analyticsList?.totalActiveRolesGroupedByCompany.slice(startIndex, endIndex);
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
  }

  onPageChangedActive(page: number): void {
    this.activeCurrentPage = page;
  }

  async getFellowAnalytics() {
    let params = new HttpParams();
    params = params.append('startDate', this.startDate);
    await this.empService._getAnalytics(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.analyticsList = res['result'];
      }
    });
  }
}
