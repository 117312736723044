<div class="close-icon" (click)="onNoClick()">
  <span class="close"><a>&#10006;</a></span>
</div>

<h4
  *ngIf="!show"
  style="display: flex; align-items: center; justify-content: space-between"
>
  Add license or certification
</h4>
<h4
  *ngIf="show"
  style="display: flex; align-items: center; justify-content: space-between"
>
  Edit license or certification
</h4>
<form
  class="example-form modal-dropdown"
  [formGroup]="certificateORlisenceForm"
>
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Name"
            required="required"
            formControlName="Name"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.Name.touched && f.Name.invalid">
        <span class="text-left text-danger pb-1" *ngIf="f.Name.errors.required"
          >Name is required
        </span>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Issuing organization</mat-label>
          <input
            type="text"
            matInput
            placeholder="Issuing organization"
            required="required"
            formControlName="IssuingOrganization"
          />
        </mat-form-field>
      </div>
      <div
        *ngIf="f.IssuingOrganization.touched && f.IssuingOrganization.invalid"
      >
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.IssuingOrganization.errors.required"
          >Issuing organization is required
        </span>
      </div>
    </div>

    <div class="col-lg-12 mb-2">
      <div class="form-group">
        <mat-checkbox
          class="example-margin"
          [checked]="checked"
          [disabled]="disabled"
          (change)="checkDeclaration($event.checked)"
        >
          This credential does not expire
        </mat-checkbox>
      </div>
    </div>

    <div class="col-lg-5">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Issue Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="issueDate"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="col-lg-3"></div>
    <div class="col-lg-5">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker11"
            formControlName="endDate"
            [min]="certificateORlisenceForm.controls.issueDate.value"
          />
          <mat-datepicker-toggle matSuffix [for]="picker11">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker11></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="col-lg-3"></div>

    <!-- <div class="col-lg-5">
                <div class="form-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>End month</mat-label>
                        <input type="number" matInput placeholder="End month" 
                            formControlName="Endmonth" />
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4 pl-0">
                <div class="form-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>End year</mat-label>
                        <input type="number" matInput placeholder="End year" 
                            formControlName="Endyear" />
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-3"></div> -->

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Credential ID</mat-label>
          <input
            type="text"
            matInput
            placeholder="Credential ID"
            required="required"
            formControlName="CredentialID"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.CredentialID.touched && f.CredentialID.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.CredentialID.errors.required"
          >Credential ID is required
        </span>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Credential URL</mat-label>
          <input
            type="text"
            matInput
            placeholder="Credential URL"
            required="required"
            formControlName="CredentialURL"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.CredentialURL.touched && f.CredentialURL.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.CredentialURL.errors.required"
          >Credential URL is required
        </span>
      </div>
    </div>
  </div>
</form>
<button
  *ngIf="show"
  mat-raised-button
  color="primary"
  (click)="removeLicense()"
>
  Delete Certification
</button>
<button
  *ngIf="!show"
  style="float: right"
  mat-raised-button
  color="primary"
  (click)="saveCertificate()"
>
  SAVE
</button>
<button
  *ngIf="show"
  style="float: right"
  mat-raised-button
  color="primary"
  (click)="updateLicense()"
>
  UPDATE
</button>
