import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Helper } from '../../../helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { LoginComponent } from 'src/app/views/auth/login/login.component';
import { DynamicSnacbarComponent } from '../dynamic-snacbar/dynamic-snacbar.component';
import { CompanyVerificationComponent } from 'src/app/views/employer/company-verification/company-verification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  token: any;
  userDtls: any;
  tokensessionStorage: any;
  userDtlssessionStorage: any;
  clickable: boolean;
  searchAble: boolean;
  currentPath: any;
  //++++++++++++++++++++//
  selectedValue: string;

  foods: any[];
  titleOfPages: any;
  displayPicture: any;
  messageCount: number = 0;

  //-------- JOB SEEKER --------------//
  activeClassJob: boolean = false;
  activeClassSaveJob: boolean = false;
  activeClassAppliedJob: boolean = false;
  activeClassmessaging: boolean = false;
  activeClassJobAlert: boolean = false;

  //-------- JOB PROVIDER --------------//
  activeClassCvSearch: boolean = false;
  activeClassManageJob: boolean = false;
  activeClassDashboard: boolean = false;
  userDeatils: any = '';
  companyData: any = '';
  activeClassBuildCV: boolean = false;
  //++++++++++++++++++++//
  jobProviderHeader: boolean = false;
  jobSeekerHeader: boolean = true;
  isHovered: boolean = false;
  isHoveredCV: boolean = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private io: HttpIOService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private dialogRef: MatDialog,
    private empService: EmployerService,
    private helper: Helper
  ) {
    this.io.getfordisplayPicture().subscribe((res) => {
      if(res) this.displayPicture = res;
    });

    router.events.subscribe((val) => {
      // Get loggedin User data
      //uncomment below one if api get implement
      if (val instanceof NavigationEnd) {
        let localUser = localStorage.getItem('workNigeriaUser');
        let sessionUser = sessionStorage.getItem('workNigeriaUser');

        this.auth.userUpdated.subscribe((res) => {
          if (res) {
            this.userDeatils = JSON.parse(
              this.helper.fromBinary(
                atob(sessionStorage.getItem('workNigeriaUser'))
              )
            );
          }
        });

        if (localUser) {
          this.userDeatils = JSON.parse(
            this.helper.fromBinary(
              atob(localStorage.getItem('workNigeriaUser'))
            )
          );
          this.companyData = this.auth.getCurrentCompanyData();
        } else if (sessionUser) {
          this.userDeatils = JSON.parse(
            this.helper.fromBinary(
              atob(sessionStorage.getItem('workNigeriaUser'))
            )
          );
          this.companyData = this.auth.getCurrentCompanyDataInSessoin();
        } else {
          this.userDeatils = '';
        }
        this.empService.getMessageCountForOnSeenApi().subscribe((res) => {
          // if (res) {
          // console.log(res)
          this.messageCount = res;
          // }
        });
        this.empService.setMessageCountForOnSeenApi(null);
        this.empService.getforMessageWSconnect().subscribe((res) => {
          if (res) {
            let responseMessage = JSON.parse(res.data);
            this.messageCount =
              responseMessage.notificationCount.userRespectiveCount;
          }
        });

        // --------------------- -------------------------   ---------------//

        if (this.route.snapshot.children[0]?.data?.title) {
          this.setTitles(this.route.snapshot.children[0]);
        } else if (this.route.snapshot.children[0]?.children[0]?.data?.title) {
          this.setTitles(this.route.snapshot.children[0].children[0]);
        } else {
          this.setTitles(
            this.route.snapshot.children[0].children[0]?.children[0]
          );
        }
      }
    });
  }

  ngOnInit(): void {
    // console.log(this.router.url)
    if (this.userDeatils.profileImage) {
      this.displayPicture = this.userDeatils.profileImage;
    } else {
      this.displayPicture = 'assets/images/defaultuser.png';
    }
    if (this.router.url == '/employer-home' || this.router.url == '/signupemployer' || this.router.url == '/login?userType=employer') {
      this.jobSeekerHeader = false;
      this.jobProviderHeader = true;
    }
    if (this.userDeatils) {
      this.getAllDetails();
      this.getUserSubscription();
      this._messageCount();
    }
    let headerLocation = window.location.pathname;
    this.currentPath = headerLocation;
  }

  getAllDetails() {
    if (this.userDeatils) {
      let jsonData = {
        id: this.userDeatils.id,
        path: 'get-user',
      };
      this.io._JSallDetails(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.auth.setCurrentUserDetailsInSessoin(res['result'].userData);
          this.auth.setCurrentUserDetails(res['result'].userData);
        }
      });
    }
  }
  getUserSubscription() {
    this.io._getUserPlan().subscribe((res: any) =>{
      this.auth.setUserPlan(res.result)
    })
  }

  //===============================  NEW METGODS =============================
  goToEmployerHome2() {
    this.router.navigate(['/employer-home']);
  }

  goToEmployerHome() {
    this.router.navigate(['/employer-home']);
  }

  goTojobSeekerrHome() {
    this.jobSeekerHeader = true;
    this.jobProviderHeader = false;
    this.router.navigate(['/home']);
  }

  goToServices() {
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['/services']);
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'services');
    }
  }

  postAjob() {
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['/post-job']);
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'post-job');
    }
  }

  searchCV() {
    if (this.userDeatils.userType == 'employer') {
      this.router.navigate(['Dashboard'], {
          queryParams: ['search-cv']
        });
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'cv-search');
    }
  }
  //===============================  NEW METGODS =============================
  _messageCount() {
    let jsonData = {
      path: 'get-message-count',
      user_id: this.userDeatils.id,
    };

    // else if (this.userDtlssessionStorage) {
    //   jsonData.user_id = this.userDtlssessionStorage.id;
    // }
    this.empService._getMessageCount(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        //  console.log(res)
        this.messageCount = res.result;
      }
    });
  }

  gotoLogIn() {
    if (this.titleOfPages == 'HOME') {
      localStorage.removeItem('workNigeriaToken');
      sessionStorage.removeItem('workNigeriaToken');
      localStorage.clear();
      sessionStorage.clear();
      window.localStorage.clear();
      window.location.reload();
      this.empService.setforWSconnect(null);
    } else {
      this.auth.allLogout();
      this.empService.setforWSconnect(null);
    }
  }

  gotoLogInEmployer() {
    if (this.titleOfPages == 'Employer Home') {
      localStorage.removeItem('workNigeriaToken');
      sessionStorage.removeItem('workNigeriaToken');
      localStorage.clear();
      sessionStorage.clear();
      this.empService.setforWSconnect(null);
      window.localStorage.clear();
      window.location.reload();
      this.jobSeekerHeader = false;
      this.jobProviderHeader = true;
    } else {
      localStorage.removeItem('workNigeriaToken');
      sessionStorage.removeItem('workNigeriaToken');
      localStorage.clear();
      sessionStorage.clear();
      this.empService.setforWSconnect(null);
      this.dialogRef.closeAll();
      window.localStorage.clear();
      this.jobSeekerHeader = false;
      this.jobProviderHeader = true;
      // window.location.reload();
      this.router.navigate(['/employer-home']);
    }
  }

  gotoJobSeekerProfile() {
    this.router.navigate(['/jobseeker-profile']);
  }

  gotoJobProviderProfile() {
    this.router.navigate(['/jobProvider-profile']);
  }
  gotoAppSettings() {
    this.router.navigate(['/app-settings']);
  }

  // Get Title //
  public setTitles(newTitle: any) {
    this.titleOfPages = newTitle?.data?.title;
    if (newTitle?.data?.title == 'Auth Process') {
      this.clickable = true;
    } else {
      this.clickable = false;
    }

    if (newTitle?.data?.title == 'Job Search') {
      this.searchAble = true;
    } else {
      this.searchAble = false;
    }
  }

  logout() {
    if (this.titleOfPages == 'HOME') {
      window.location.reload();
    }
    this.router.navigate(['/home']);
    localStorage.clear();
    sessionStorage.clear();
  }

  // Redirect to job-search //
  jobSearch() {
    // this.selectedValue = this.foods[0].viewValue;
    // if (this.searchAble) {
    //   return false;
    // } else {
    this.router.navigate(['/job-search']);
    // }
  }
  // Redirect to services //
  services() {
    this.router.navigate(['/services']);
  }
  // Redirect to cv-search //
  cvSearch() {
    this.router.navigate(['/cv-search']);
  }
  // Redirect to job-search //
  saveJob() {
    this.router.navigate(['/job-save']);
  }
  // Redirect to job-search //
  appiledJob() {
    this.router.navigate(['/applied-job']);
  }

  goToPlans(){
    this.router.navigate(['/choose-employer-subscription']);
  }

  // Redirect to Dashboard //
  dashboard() {
    this.router.navigate(['/Dashboard']);
  }
  emailAlert() {
    this.router.navigate(['/email-alerts']);
  }
  messaging() {
    this.router.navigate(['/message']);
  }

  buildCV() {
    if (this.userDeatils) {
      this.router.navigate(['/jobseeker-profile']);
    }
  }

  manageJobs() {
    this.router.navigate(['/manage-job']);
  }
  JobAlert() {
    this.router.navigate(['/job-alert']);
  }
  // Login Page //
  login(userType: string = 'jobseeker') {
    // const dialogRef = this.dialog.open(LoginComponent, {
    //   height: '600px',
    //   width: '600px',
    //   data: {}
    // });
    // dialogRef.afterClosed().subscribe(res => {
    //   if (res === true) {
    //   }
    // });
    this.router.navigate(['/login'],{ queryParams: { userType } });
  }
  Sign_up() {
    this.router.navigate(['/signup']);
  }

  signUpEmployer() {
    this.router.navigate(['/signupemployer']);
  }
  emailVarification() {
    // console.log("+++++++++")
    if (this.userDeatils) {
      let jsonData = {
        email: this.userDeatils.email,
        path: 'resend-email',
      };
      this.io._resendEmail(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          // this.snackBar.open(res.serverResponse.message, "OK", {
          // })
          this.snackBar
            .openFromComponent(DynamicSnacbarComponent, {
              data: res.serverResponse.message,

              verticalPosition: 'top', // 'top' | 'bottom'
              horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
            })
            .afterOpened()
            .subscribe(this.helper.setOnTop);
        }
      });
    }
  }

  verification() {
    const dialogRef = this.dialog.open(CompanyVerificationComponent, {
      height: 'auto',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        //  console.log(res)
        // if (localStorage.getItem("workNigeriacompanyData") === null) {
        //   this.auth.setCurrentCompanyDataInSessoin(res);
        // } else {
        //   this.auth.setCurrentCompanyData(res);
        // }
      }
    });
  }
  buildCVsection() {
    if (this.userDeatils) {
      if (this.userDeatils.isResumeParsed) {
        this.router.navigate(['/jobseeker-profile']);
      }
    } else {
      this.router.navigate(['/login']);
      sessionStorage.setItem('setUrlRedirection', 'jobseeker-profile');
    }
  }

  job_alert() {
    const dataToSend = {
      data: 'job alert set'
    }
    this.router.navigate(['/home'], {
      queryParams: dataToSend
    });
  }
}