<div class="spacer"></div>

<div class="container-main" *ngIf="!showSavedCV">
  <div class="sidebar-container">
    <app-emp-dashboard-sidenav
      (elementSelected)="selectedElement = $event; isCampaignBeingAdded = false"
    ></app-emp-dashboard-sidenav>
  </div>

  <div class="content-container">
    <div class="content" *ngIf="userDetails.userType === 'employer'">
      <ng-container [ngSwitch]="selectedElement">
        <app-emp-dash-admin *ngSwitchCase="'overview'"></app-emp-dash-admin>
        <app-emp-dash-job-overview
          *ngSwitchCase="'job-overview'"
        ></app-emp-dash-job-overview>
        <app-emp-dash-promote-jobs *ngSwitchCase="'promote-job'">
        </app-emp-dash-promote-jobs>
        <app-emp-dash-management *ngSwitchCase="'job-management'">
        </app-emp-dash-management>
        <app-emp-dash-payment *ngSwitchCase="'payment'"></app-emp-dash-payment>
        <app-emp-dash-cv *ngSwitchCase="'search-cv'"></app-emp-dash-cv>
        <app-emp-campaigns-help *ngSwitchCase="'help'"></app-emp-campaigns-help>
      </ng-container>
    </div>
    <div class="content" *ngIf="userDetails.userType === 'super_admin'">
      <ng-container [ngSwitch]="selectedElement">
        <app-overview-super-admin *ngSwitchCase="'overview'"></app-overview-super-admin>
        <app-employer-super-admin *ngSwitchCase="'employers'"></app-employer-super-admin>
        <app-jobseeker-super-admin *ngSwitchCase="'jobseekers'"></app-jobseeker-super-admin>
        <app-job-super-admin *ngSwitchCase="'jobs'"></app-job-super-admin>
        <app-upload-cv *ngSwitchCase="'upload-cv'"></app-upload-cv>
        <app-setting-super-admin *ngSwitchCase="'settings'"></app-setting-super-admin>
      </ng-container>
    </div>
  </div>
</div>
<div class="container-main" *ngIf="showSavedCV">
  <div class="content-container">
    <div class="content">
          <app-emp-dash-saved-cv></app-emp-dash-saved-cv> 
    </div>
  </div>
</div>
