import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
import { ViaEmailComponent } from '../via-email/via-email.component';
import { ViaExternalUrlComponent } from '../via-external-url/via-external-url.component';
import { ViaWorkNigeriaComponent } from '../via-work-nigeria/via-work-nigeria.component';

@Component({
  selector: 'app-anonymous-user',
  templateUrl: './anonymous-user.component.html',
  styleUrls: ['./anonymous-user.component.scss'],
})
export class AnonymousUserComponent implements OnInit {
  anonymousForm: UntypedFormGroup;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  checked: boolean = false;
  disabled = false;
  declrationCheckBox: any = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AnonymousUserComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {}

 

  applyWithoutLogin = false;
  loginToApply = false;
  selectedOption = 'loginToApply';

  getContainerClass(): string {
    if (this.selectedOption === 'applyWithoutLogin') {
      return 'radio-row';
    } else {
      return 'login-to-apply';
    }
  }  
  
  

  ngOnInit(): void {
    this.validationForm();
    // console.log(this.data);
  }
  // Validation Controls //
  get f() {
    return this.anonymousForm.controls;
  }

  validationForm() {
    this.anonymousForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/login'], {
      queryParams: { GuestUser: 'Guest-User' },
    });
    sessionStorage.setItem(
      'guestUserSelectedJob',
      btoa(JSON.stringify(this.data['Perticularjob'].id))
    );
  }
  onNoClickClose() {
    this.dialogRef.close();
  }
  checkDeclaration(event) {
    // console.log(event)
    this.declrationCheckBox = event;
  }

  guestUserCreat() {
    if (this.anonymousForm.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.anonymousForm);
      return false;
    }
    let jsonData = {
      path: 'create-guest-user',
      firstName: this.anonymousForm.get('firstName').value,
      lastName: this.anonymousForm.get('lastName').value,
      email: this.anonymousForm.get('email').value,
    };
    if (this.declrationCheckBox == true) {
      this.io._guestUser(jsonData).subscribe((res: any) => {
        if (res.serverResponse.code == 200) {
          this.dialogRef.close();
          // console.log(res);
          sessionStorage.setItem(
            'guestUser',
            btoa(JSON.stringify(res['result'].userData))
          );
          this.modelOpen();
        }
      });
    }
  }

  modelOpen() {
    if (this.data['Perticularjob'].apply_via == '3') {
      const dialogRef = this.dialog.open(ViaEmailComponent, {
        //height: '450px',
        width: '450px',
        data: {
          Perticularjob: this.data['Perticularjob'],
          user: 'WithoutSignIn',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else if (this.data['Perticularjob'].apply_via == '2') {
      const dialogRef = this.dialog.open(ViaExternalUrlComponent, {
        //height: '450px',
        width: '450px',
        data: {
          Perticularjob: this.data['Perticularjob'],
          user: 'WithoutSignIn',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    } else if (this.data['Perticularjob'].apply_via == '1') {
      const dialogRef = this.dialog.open(ViaWorkNigeriaComponent, {
        //height: '450px',
        width: '450px',
        data: {
          Perticularjob: this.data['Perticularjob'],
          user: 'WithoutSignIn',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res == 'save') {
          // console.log("++++++++++++")
        }
      });
    }
  }
}
