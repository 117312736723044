<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="log-lt">
                <h3>Please Enter Your Email</h3>
                <div class="form-wrap">
                  <form [formGroup]="loginNotification" autocomplete="off">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Enter email"
                          formControlName="email_noti"
                          required="required"
                          [pattern]="emailPattern"
                        />
                        <div
                          *ngIf="f.email_noti.touched && f.email_noti.invalid"
                        >
                          <span
                            class="text-left text-danger pb-1"
                            *ngIf="f.email_noti.errors.required"
                            >Email is required
                          </span>
                          <span
                            class="text-left text-danger pb-1"
                            *ngIf="f.email_noti.errors?.pattern"
                          >
                            Email is not valid.
                          </span>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div class="col-md-12">
                      <div class="form-group">
                        <input
                          type="submit"
                          value="Submit"
                          class="btn btn-green"
                          (click)="closeDilog()"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img
                    src="../../../../assets/images/Forgot-password.png"
                    alt=""
                  />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
