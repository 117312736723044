import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JobAlertsComponent } from './job-alerts/job-alerts.component';
import { JobSeekerProfileComponent } from './job-seeker-profile/job-seeker-profile.component';
import { JobseekerAppliedComponent } from './jobseeker-applied/jobseeker-applied.component';
import { JobseekerSaveJobComponent } from './jobseeker-save-job/jobseeker-save-job.component';
import { AuthGuard } from 'src/app/gaurd/auth.guard';
import { CvPurserViewProfileComponent } from './cv-purser-view-profile/cv-purser-view-profile.component';
import { MessageComponent } from './../message/message.component';
import { AuthJobseekerGuard } from 'src/app/gaurd/auth-jobseeker.guard';

const routes: Routes = [
  {
    path: 'applied-job',
    component: JobseekerAppliedComponent,
    data: {
      title: 'APPLIED JOB',
    },
    canActivate: [AuthJobseekerGuard],
  },
  {
    path: 'job-save',
    component: JobseekerSaveJobComponent,
    data: {
      title: 'JOB SAVED',
    },
    canActivate: [AuthJobseekerGuard],
  },
  {
    path: 'job-alert',
    component: JobAlertsComponent,
    data: {
      title: 'JOB ALERT',
    },
    canActivate: [AuthJobseekerGuard],
  },
  {
    path: 'jobseeker-profile',
    component: JobSeekerProfileComponent,
    data: {
      title: 'JOBSEEKER PROFILE',
    },
    canActivate: [AuthJobseekerGuard],
  },
  {
    path: 'jobseeker-profile-confirm',
    component: CvPurserViewProfileComponent,
    data: {
      title: 'JOBSEEKER PROFILE CV PURSER',
    },
    canActivate: [AuthJobseekerGuard],
  },
  //   {
  //   path: 'message',
  //   component: MessageComponent,
  //   data: {
  //     title: 'message',
  //   },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobseekerRoutingModule {}
