import { Component, ViewChild } from '@angular/core';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { HttpIOService } from 'src/app/services/http-io.service';
import { DataService } from 'src/app/services/data.service';
import { switchMap } from 'rxjs/operators';


import { Router } from '@angular/router';
@Component({
  selector: 'app-emp-dash-payment',
  templateUrl: './emp-dash-payment.component.html',
  styleUrls: ['./emp-dash-payment.component.scss']
})
export class EmpDashPaymentComponent {
  transactionHistory = [];
  toDay: any = new Date();
  currentWalletPage = 0;
  walletPagination = {
    size: 4,
    start: 0,
    total: 0,
  };
  userDetails: any;
  companyData: any;
  userAssets: any;
  currentBalance = 0;

  displayedColumns: string[];
  dataSource: any;

  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  paymentTypes = {fundWallet: "Fund Wallet", 'subscription': "Subscription", 'bundle': "Bundle"}
  selectedMonth: string = 'January';

  constructor(
    private es: EmployerService,
    private helper: Helper,
    public auth: AuthService,
    private router: Router,
    private io: HttpIOService,
    private dataService: DataService,
  ) {
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
        this.companyData = this.auth.getCurrentCompanyData();
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
        this.companyData = this.auth.getCurrentCompanyDataInSessoin();
      }
    });
    this.currentBalance = this.userDetails.balance ?? 0;
  }

  ngOnInit(): void {
    let date = new Date()
    this.getPaymentHistory();
    this.loadUpdatedAssets()
    this.selectedMonth = this.months[date.getMonth()]
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.walletPagination.start = 0;
    this.currentWalletPage = 0
    if (sortState.direction) {
      this.getPaymentHistory(sortState.direction.toUpperCase());
    } else {
      this.getPaymentHistory();
    }
  }

  getPaymentHistory(sort: string = null) {
    let params = {
      size: this.walletPagination.size,
      start: this.walletPagination.start,
      sortOrder: sort ?? 'DESC',
    };
    this.es._paymentHistory(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.transactionHistory = res['result'];
        console.log("transaction history: ", this.transactionHistory)
        this.displayedColumns = ['cardName', 'createdAt', 'currency', 'purpose'];
        this.dataSource = new MatTableDataSource(this.transactionHistory);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.walletPagination.total = res['pagination']['total'];
        this.walletPagination.start = res['pagination']['start'];
      }
    });
  }

  correctDayDate(datetime: string) {
    const dateObj = new Date(datetime);

    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-US', options);

    return formattedDate;
  }

  correctAmountFormat(amount: number, currency: string) {
    return amount.toLocaleString('en-US', { 
      style: 'currency', 
      currency, 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 2
    });
  }

  onWalletPageChanged(event: any): void {
    this.walletPagination.start = event.pageIndex * this.walletPagination.size;
    this.currentWalletPage = event.pageIndex;
    this.getPaymentHistory();
  }

  navigateToPlans(): void {
    this.router.navigate(['choose-employer-subscription'])
  }
  navigateToBundle(bundleID: string): void {
    this.router.navigate([`bundles/${bundleID}`])
  }

  remainingDays(subscriptionDate: string):number {
    const startDate = new Date(subscriptionDate);
    const currentDate:any = new Date();
    const monthsDiff = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth());
    let nextRenewalDate:any = new Date(startDate);
    for (let i = 0; i < monthsDiff + 1; i++) {
      nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);
      const lastDayOfMonth = new Date(nextRenewalDate.getFullYear(), nextRenewalDate.getMonth() + 1, 0).getDate();
      if (nextRenewalDate.getDate() > lastDayOfMonth) {
        nextRenewalDate.setDate(lastDayOfMonth);
      }
    const remainingDays = Math.ceil((nextRenewalDate - currentDate) / (1000 * 60 * 60 * 24));
    return remainingDays;
    }
  }

  handlePageEvent(event: any): void {
    console.log(event)
  }
  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets()
    .subscribe(() => {
      this.dataService.getUserData().subscribe((data: any) => {
        this.userAssets = data;
        if(!this.userAssets.subscription.subscriptionPlan){
          this.userAssets['subscription'] = {
            subscriptionPlan: "Bronze"
          }
        }
      });
    });
  }
}
