<div class="container" *ngIf="campaignList.length === 0 && !isCampaignBeingAdded">
  <div class="top-row">
    <button class="float-right" (click)="startNewCampaign()">
      Start New Campaign
    </button>
  </div>

  <div class="bottom-row">
    <div class="row">
      <img src="../../../../../../assets/images/no-campaign-icon.svg" alt="" />
    </div>
    <div class="row">
      <p class="no-campaign-text">
        You do not have any recent campaign. Click “Start New Campaign” to
        create your first campaign
      </p>
    </div>
  </div>
</div>

<div class="container" *ngIf="campaignList.length !== 0 && !isCampaignBeingAdded">
  <div class="top-row-2">
    <div class="inputs">
      <mat-form-field appearance="outline" class="custom-form-field" id="searchCampaign">
        <mat-label>Search Campaign</mat-label>
        <input matInput [(ngModel)]="search" placeholder="Search" />
      </mat-form-field>
      <button class="search-btn" (click)="getAllCampaigns()">
        <img src="../../../../../../assets/images/search.svg">
      </button>
      <button class="btn-analytics">
        Campaign Analytics
      </button>
    </div>
    <button class="float-right" (click)="startNewCampaign()">
      Start New Campaign
    </button>
  </div>
  <div class="bottom-row-2">
    <div class="table-heading">
      Below is a list of all your recent campaigns
    </div>
    <div class="job-overview-div">
      <table mat-table [dataSource]="this.campaignList" matSort (matSortChange)="announceSortChange($event)">

        <!-- STATUS -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            <div [ngClass]="getCampaignStatusClass(element.status, element.endDate)">
              <span class="pill-text">
                {{getCampaignStatus(element.status, element.endDate)}}
              </span>
            </div>
          </td>
        </ng-container>

        <!-- CAMPAIGN TYPE -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef style="text-align: left; padding-right: 120px;">
            Campaign Type
          </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

        <!-- APPLICANT -->
        <ng-container matColumnDef="applicants">
          <th mat-header-cell *matHeaderCellDef style="text-align: left;">
            Applicant
          </th>
          <td mat-cell *matCellDef="let element"> {{element.applicants}} </td>
        </ng-container>

        <!-- DATE CREATED -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date"
            style="text-align: left;">
            Date Created
          </th>
          <td mat-cell *matCellDef="let element"> {{createdAtDate(element.createdAt)}} </td>
        </ng-container>

        <!-- DAYS LEFT -->
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef style="text-align: left;">
            Days Left
          </th>
          <td mat-cell *matCellDef="let element"> {{daysLeft(element.endDate)}} Days </td>
        </ng-container>

        <!-- ACTIONS -->
        <ng-container matColumnDef="paymentStatus">
          <th mat-header-cell *matHeaderCellDef>
            Action
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: center; padding-right: 0;">
            <label class="toggle">
              <input [disabled]="!daysLeft(element.endDate)" type="checkbox" [checked]="element.status === 'Start'"
                (change)="toggleCampaignStatus($event, element)" />
              <span class="slider round" [ngClass]="{'off': !daysLeft(element.endDate)}"></span>
              <span class="toggle-text"></span>
            </label>
          </td>
        </ng-container>

        <!-- EXTEND -->
        <!-- <ng-container matColumnDef="clicks">
                    <th mat-header-cell *matHeaderCellDef>

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button class="btn-extend">Extend</button>
                    </td>
                </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <mat-paginator *ngIf="campaignList?.length > 0" [length]="this.campaignsPagination?.total"
        (page)="onCampaignsPageChanged($event)" [pageIndex]="this.currentCampaignsPage" [pageSize]="4"
        showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</div>

<div class="container" *ngIf="isCampaignBeingAdded">
  <div class="add-campaign">
  </div>
  <div class="page-one" *ngIf="currentPage === 1">
    <div class="create-job">
      <div class="create-job-text">
        Select one of your recently posted jobs below, or create a new job to
        run a campaign
      </div>
      <button type="button" class="btn btn-outline-primary" (click)="postAjob()">
        Create New Job
      </button>
    </div>
    <div class="well-views table-responsive">
      <table class="table jobs">
        <thead>
          <tr>
            <th></th>
            <th>JOB TITLE</th>
            <th>LOCATION</th>
            <th>TYPE</th>
            <th>SALARY</th>
            <th>DATE POSTED</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of jobsList">
            <td>
              <mat-checkbox (change)="updateSelectedJobsList($event, job.id)"
                [checked]="selectedJobsList.indexOf(job.id) !== -1"></mat-checkbox>
            </td>
            <td [ngClass]="{'row-color': selectedJobsList.indexOf(job.id) >= 0}">{{ formatTitle(job.title) }}</td>
            <td [ngClass]="{'row-color': selectedJobsList.indexOf(job.id) >= 0}">{{ formatTitle(job.job_location) }}
            </td>
            <td [ngClass]="{'row-color': selectedJobsList.indexOf(job.id) >= 0}">{{ locationType(job.location_type) }}
            </td>
            <td [ngClass]="{'row-color': selectedJobsList.indexOf(job.id) >= 0}">{{ job.minimum_salary }} - {{
              job.maximum_salary }}</td>
            <td [ngClass]="{'row-color': selectedJobsList.indexOf(job.id) >= 0}">{{ formatDateToDDMMYYYY(job.created_at)
              }}</td>
          </tr>
        </tbody>
      </table>
      <mat-paginator *ngIf="jobsList.length > 0" [length]="this.jobsPagination?.total"
        (page)="onJobsPageChanged($event)" [pageIndex]="this.currentJobsPage" [pageSize]="10" showFirstLastButtons>
      </mat-paginator>
      <!-- <div class="pagination">
          <span class="pagination-text">
            Showing {{ jobsPagination.start + 1 }} to
            {{ jobsPagination.start + jobsList.length }}
            of {{ jobsPagination.total }} results
          </span>
          <ngb-pagination
            [page]="currentJobsPage"
            (pageChange)="onJobsPageChanged($event)"
            [pageSize]="jobsPagination.size"
            [ellipses]="true"
            [maxSize]="5"
            [rotate]="true"
            [collectionSize]="jobsPagination.total"
          ></ngb-pagination>
        </div> -->
      <div class="bottom-actions">
        <button type="button" (click)="goBack()" class="btn btn-outline-primary">
          Back
        </button>
        <button type="button" (click)="goToNextPage()" class="btn btn-primary">
          Save and Continue
        </button>
      </div>
    </div>
  </div>
  <div class="page-two" *ngIf="currentPage === 2">
    <form [formGroup]="newCampaignForm">
      <div class="top-text">Set Campaign Title and Duration</div>
      <div class="selected-jobs-description">
        <div class="sel-jobs-text">
          you have selected {{ selectedJobsList.length }}
          <span *ngIf="selectedJobsList.length < 2">Job</span>
          <span *ngIf="selectedJobsList.length >= 2">Jobs</span>
          <button class="btn btn-preview" (click)="previewJobList()">
            Preview
          </button>
        </div>
      </div>
      <div class="outer-wall">
        <div class="set-heading">Set Promotion Title</div>
        <div class="set-title-input">
          <div class="field-prefix"></div>

          <mat-form-field appearance="outline" id="campaignTitle">
            <input matInput formControlName="title" placeholder="Enter text e.g (Product team)" type="text" />
          </mat-form-field>
        </div>
      </div>
      <div class="outer-wall">
        <div class="set-heading">Set Campaign Duration</div>
        <div class="set-title-input">
          <div class="field-prefix">Start Date</div>
          <div class="group">
            <mat-form-field appearance="outline" id="campaignDuration">
              <input matInput formControlName="startDate" type="date" [min]="today" placeholder="" appPreventManualInput/>
            </mat-form-field>
            <mat-error *ngIf="newCampaignForm.get('startDate').hasError('dateError')">
              Please select a correct date
            </mat-error>
            <p class="campaign-end-text"> Your campaign ends in 30 Days </p>
          </div>
        </div>
      </div>
      <div class="bottom-actions button-resize">
        <button type="button" (click)="goBack()" class="btn btn-outline-primary">
          Back
        </button>
        <button type="submit" (click)="postCampaign()" class="btn btn-primary" [disabled]="newCampaignForm.invalid">
          Done
        </button>
      </div>
    </form>
  </div>

  <div class="page-three" *ngIf="currentPage === 3">
    <div class="payment-outer">
      <div class="balance-container">
        <div class="title">Wallet Balance:</div>
        <div class="balance">N{{ currentBalance | number : "1.1-1" }}</div>
      </div>
      <div class="budget-container">
        Budget for Campaign:
        {{ newCampaignForm.value.budget | number : "1.1-1" }}
      </div>
      <div class="actions-container">
        <!-- <button type="button" class="btn btn-primary" (click)="fundWallet()">
            Fund Wallet
          </button> -->
        <button angular4-paystack class="btn btn-primary" [key]="'pk_test_5c635797c32aee412ccf63d990fd9983b4324b34'"
          [email]="userDetails?.email" [amount]="
              (this.newCampaignForm.value.budget - this.currentBalance) * 100
            " [ref]="transactionRef" (onClose)="paymentCancel()" (callback)="paymentDone($event)"
          (paymentInit)="updateTransactionId($event)" [disabled]="newCampaignForm.value.budget <= currentBalance"
          [ngClass]="{
              'btn-disabled': newCampaignForm.value.budget <= currentBalance
            }">
          Fund Wallet
        </button>
        <button type="button" class="btn btn-primary" [disabled]="newCampaignForm.value.budget > currentBalance"
          [ngClass]="{
              'btn-disabled': newCampaignForm.value.budget > currentBalance
            }" (click)="postCampaign()">
          Pay
        </button>
      </div>
    </div>
  </div>
</div>