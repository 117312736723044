<section class="common-padd min-height-blank-page">
  <div class="pass-notifctn-box">
    <h4 class="modal-heading">Your password has been changed</h4>
    <a
      href="javascript:void(0);"
      class="btn btn-green w-100"
      (click)="homePage()"
      >Go to Worknigeria</a
    >
  </div>
</section>
