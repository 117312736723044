<div class="alertwrap">
  <h4>Add New Alert</h4>
  <p>
    <button
      mat-flat-button
      style="color: #000; background: #e2e2e2"
      (click)="onNoClick()"
    >
      CANCEL</button
    >&nbsp;
    <button
      class="graybtn"
      *ngIf="!buttonVisible"
      mat-raised-button
      color="primary"
      (click)="createAlert()"
    >
      SAVE
    </button>
    <button
      class="greenbtn"
      *ngIf="buttonVisible"
      mat-raised-button
      color="primary"
      (click)="updateAlert()"
    >
      UPDATE
    </button>
  </p>
</div>
<form
  class="example-form addalert modal-dropdown"
  [formGroup]="createAlartForm"
>
  <div class="row">
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Alert Name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Alert Name"
            required="required"
            formControlName="alertName"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.alertName.touched && f.alertName.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.alertName.errors.required"
          >Alert Name is required
        </span>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Keyword</mat-label>
          <input
            type="text"
            matInput
            placeholder="Keyword"
            required="required"
            formControlName="Keyword"
          />
        </mat-form-field>
      </div>
      <div *ngIf="f.Keyword.touched && f.Keyword.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.Keyword.errors.required"
          >Keyword is required
        </span>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Location</mat-label>
          <mat-select
            formControlName="location"
            (selectionChange)="joblocationName($event.value)"
          >
            <mat-option value="">Any</mat-option>
            <mat-option
              value="{{ row?.id }}"
              *ngFor="let row of cityRecords; let i = index"
            >
              {{ row?.city }} ,{{ row?.state }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Job Category</mat-label>
          <mat-select
            formControlName="category_id"
            (selectionChange)="changeCategoty($event)"
          >
            <mat-option value="">Any</mat-option>
            <mat-option
              value="{{ jobCategoryType?.id }}"
              *ngFor="let jobCategoryType of jobCatagoryTypeList; let i = index"
            >
              {{ jobCategoryType?.categoryName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-6" style="padding-right: 7px">
      <div class="form-group">
        <label style="margin-bottom: 1px">Salary Range</label>
        <!-- <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>From</mat-label>
                        <input type="number" matInput placeholder="Salary From" required="required"
                            formControlName="salaryForm" />
                    </mat-form-field> -->
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>From</mat-label>
          <mat-select formControlName="salaryForm">
            <mat-option value="">Any</mat-option>
            <!-- <mat-option value="{{min}}" *ngFor="let min of Salary">{{min}} NGN</mat-option> -->
            <mat-option value="{{ min }}" *ngFor="let min of Salary"
              >{{ min | currency : " ₦ " }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-6" style="padding-left: 7px">
      <div class="form-group">
        <br />
        <!-- <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>To</mat-label>
                        <input type="number" matInput placeholder="Salary To" required="required"
                            formControlName="salaryTo" />
                    </mat-form-field> -->
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>To</mat-label>
          <mat-select formControlName="salaryTo">
            <mat-option value="">Any</mat-option>
            <mat-option value="{{ max }}" *ngFor="let max of Salary">{{
              max | currency : " ₦ "
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Employment Type</mat-label>
          <mat-select
            formControlName="employment_type_id"
            (selectionChange)="changeEmploment($event)"
          >
            <mat-option value="">Any</mat-option>
            <mat-option
              value="{{ emplomentType?.id }}"
              *ngFor="let emplomentType of employemntTypeList; let i = index"
            >
              {{ emplomentType?.employmentType }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email frequency</mat-label>
          <mat-select formControlName="email_frequency">
            <mat-option [value]="row" *ngFor="let row of emailFrequency">
              {{ row[0].toUpperCase() + row.slice(1) }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="f.email_frequency.touched && f.email_frequency.invalid">
        <span
          class="text-left text-danger pb-1"
          *ngIf="f.email_frequency.errors.required"
          >Email frequency is required
        </span>
      </div>
    </div>
  </div>
</form>
