import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactUsFrom: UntypedFormGroup;
  userDeatils: any;
  companyData: any;
  emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9_.+-]{2,}$/;
  namePattern = /^[a-zA-Z]{2,}$/;
  numberPattern = /^[0-9]{8,14}$/;
  title: any;
  dropDownData = [
    {
      value: 'General Inquiry',
      label: 'General Inquiry',
    },
    {
      value: 'Feedback',
      label: 'Feedback',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ];
  constructor(
    private dataService: DataService,
    private titleService: Title,
    private router: Router,
    private io: HttpIOService,
    private helper: Helper,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    // public dialogRef: MatDialogRef<JobAlertsComponent>,
    private fb: UntypedFormBuilder,
    private auth: AuthService
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.validationForm();
    this.dataService
      .getData()
      .subscribe((data: any) =>
        typeof data === 'object' && data !== null ? this.pushData(data) : null
      );

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  // Validation Controls //
  get f() {
    console.log(this.contactUsFrom.controls)
    return this.contactUsFrom.controls;
  }

  validationForm() {
    this.contactUsFrom = this.fb.group({
      Name: ['', [Validators.required, Validators.pattern(this.namePattern), this.noWhitespaceValidator]],
      Message: ['', this.noWhitespaceValidator],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
      number: ['', [Validators.required, Validators.pattern(this.numberPattern)]],
      selectedOption: [''],
    });
  }
  pushData(data) {
    this.dropDownData.push(data);
    this.contactUsFrom.controls['selectedOption'].patchValue(data.value);
  }
  submitContactFrom() {
    if (this.contactUsFrom.status === 'INVALID') {
      this.helper.markFormGroupTouched(this.contactUsFrom);
      return false;
    }
    let jsonData = {
      path: 'send-contact-form',
      name: this.contactUsFrom.get('Name').value,
      email: this.contactUsFrom.get('email').value,
      subject: this.contactUsFrom.get('selectedOption').value,
      message: this.contactUsFrom.get('Message').value,
    };
    this.io._contactUs(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,

            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        this.contactUsFrom.reset();
      }
    });
  }

  noWhitespaceValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value || '';
    if (value.trim().length === 0 && value.length !== 0) {
      return { 'whitespace': true };
    }
    return null;
  }
}
