<!-- <div class="text-center border border-light p-5">
  <p class="h4 mb-4">
    {{userDetails.type == 'edit' ? 'Update User details' : userDetails.type ==
    'view'?'View Details':'Create User'}}
  </p>

  <div class="row mb-4">
    <div class="col-6">
   
      <input
        [(ngModel)]="firstName"
        type="text"
        class="form-control"
        placeholder="First name"
        [disabled]="isView"
      />
    </div>
    <br />
    <div class="col-6">
    
      <input
        [(ngModel)]="lastName"
        type="text"
        class="form-control"
        placeholder="Last name"
        [disabled]="isView"
      />
    </div>
  </div>
 
  <input
    [(ngModel)]="email"
    type="email"
    class="form-control mb-4"
    placeholder="E-mail"
    [disabled]="isView"
  />
  
  <input
    type="password"
    [(ngModel)]="password"
    class="form-control"
    placeholder="Password"
    *ngIf="data['type'] == 'add'"
  />
  <br />
  <mat-form-field class="form-control" appearance="">
    <mat-label>Select a role</mat-label>
    <mat-select [(value)]="role" [disabled]="isView">
      <mat-option value="company_admin">Admin</mat-option>
      <mat-option value="company_basic">Basic</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="isValid==false" class="text-danger p-3">
    enter valid details / all fields required
  </div>
  <div *ngIf="emailExist==false" class="text-danger p-3">
    Email alredy exist
  </div>
  <button
    *ngIf="isView!=true"
    class="green-btn"
    (click)="onFormSubmit()"
    type="submit"
  >
    {{userDetails.type == 'edit' ? 'Edit User' : 'Create User'}}
  </button>
</div> -->
<p class="h4 mb-4">
  {{userDetails.type == 'edit' ? 'Update User details' : userDetails.type ==
  'view'?'View Details':'Create User'}}
</p>
<div class="example-form dashnewuser addalert modal-dropdown">
  <div class="row">
    <div class="col-lg-6">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>First name</mat-label>
          <input
            type="text"
            matInput
            placeholder="First name"
            required="required"
            [disabled]="isView"
            [(ngModel)]="firstName"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Last name</mat-label>
          <input
            type="text"
            matInput
            placeholder="Last name"
            required="required"
            [disabled]="isView"
            [(ngModel)]="lastName"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email</mat-label>
          <input
            type="email"
            matInput
            placeholder="Email"
            required="required"
            [disabled]="isView"
            [(ngModel)]="email"
          />
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="col-lg-12" *ngIf="data['type'] == 'add'">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Password</mat-label>
          <input type="password" matInput placeholder="Password" required="required" [(ngModel)]="password" />
        </mat-form-field>
      </div>
    </div> -->
    <div class="col-lg-12">
      <div class="form-group">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Select a role</mat-label>
          <mat-select [(value)]="role" [disabled]="isView">
            <mat-option value="company_admin">Admin</mat-option>
            <mat-option value="company_basic">Basic</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="isValid==false" class="text-danger p-3">
      enter valid details / all fields required
    </div>
    <div *ngIf="emailExist==false" class="text-danger p-3">
      Email already exists
    </div>
    <div class="col-lg-12">
      <button class="btn-green btn" type="submit" (click)="onFormSubmit()">
        Submit
      </button>
    </div>
  </div>
</div>
