<div class="verification-wrap common-padd">
  <div class="row auth-main-verify">
    <div class="col-lg-5 col-12 img-div order-lg-1">
      <img src="../../../../assets/images/company-verification.webp" alt="company verification" />
    </div>
    <div class="col-lg-7 col-12 order-lg-2 d-flex justify-content-center align-items-center">
      <div class="form-wrap d-flex justify-content-center align-items-center">
        <form class="" [formGroup]="VerificationForm">
          <!-- Company Name -->
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Company Name</mat-label>
              <input type="text" matInput placeholder="" formControlName="CompanyName" />
            </mat-form-field>
            <div *ngIf="f.CompanyName.touched && f.CompanyName.invalid">
              <span class="text-left text-danger pb-1" *ngIf="f.CompanyName.errors.required">Company Name is required
              </span>
              <span class="text-left text-danger pb-1" *ngIf="f.CompanyName.errors.hasSpecialCharacters">
                Special characters are not allowed in Company Name
              </span>
            </div>
          </div>
          <!-- Company Address -->
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Company Address</mat-label>
              <input type="text" matInput placeholder="" formControlName="CompanyAddress" />
            </mat-form-field>
            <div *ngIf="f.CompanyAddress.touched && f.CompanyAddress.invalid">
              <span class="text-left text-danger" *ngIf="f.CompanyAddress.errors.required">Company Address is required
              </span>
            </div>
          </div>
          <!-- Company Website -->
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Company Website (Optional)</mat-label>
              <input type="text" matInput placeholder="" formControlName="CompanyWebsite" />
            </mat-form-field>
          </div>
          <!-- Registration Number -->
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Enter CAC Registration Number</mat-label>
              <input type="text" matInput placeholder="" formControlName="registrationNumber" />
            </mat-form-field>
            <div *ngIf="f.registrationNumber.touched && f.registrationNumber.invalid">
              <span class="text-left text-danger pb-1" *ngIf="f.registrationNumber.errors.required">
                CAC Number is required
              </span>
              <span class="text-left text-danger pb-1" *ngIf="f.registrationNumber.errors?.pattern">
                Invalid CAC Number
              </span>
            </div>
          </div>
          <!-- Upload CAC Form -->
          <div class="upload-cac">
            <p class="upload-text">Upload CAC Certificate </p>
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-lg-6">
                <label class="custom-file-upload">
                  <input type="file" (change)="onFileSelected($event)" accept=".pdf" />
                  <img src="../../../../assets/images/browse-arrow.png" /> Browse computer
                </label>
              </div>
              <div class="col-lg-6 d-flex align-items-center justify-content-center p-0">
                <mat-progress-bar mode="indeterminate" *ngIf="uploadProgress == 0"></mat-progress-bar>
                <mat-progress-bar mode="determinate" [value]="uploadProgress"
                  *ngIf="selectedFile && uploadProgress > 0"></mat-progress-bar>
                <p class="uploaded-file-name" *ngIf="fileName">{{ fileName }}</p>

              </div>
            </div>
          </div>
          <button class="verify-btn" (click)="submit()">
            Verify My Company
          </button>
        </form>
      </div>
    </div>
  </div>
</div>