import { Component, EventEmitter, Input, Output } from '@angular/core';

declare global {
  interface Window {
    google: any;
  }
}

@Component({
  selector: 'app-google-signin',
  templateUrl: './google-signin.component.html',
  styleUrls: ['./google-signin.component.scss'],
})
export class GoogleSigninComponent {
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>();
  @Output() signupWithGoogle: EventEmitter<any> = new EventEmitter<any>();
  @Input() accessedFrom: string = "";

  createGoogleWrapper = () => {
  const googleLoginWrapper = document.createElement('div');
  googleLoginWrapper.style.display = 'none';
  googleLoginWrapper.classList.add('custom-google-button');
  document.body.appendChild(googleLoginWrapper);
  window.google.accounts.id.renderButton(googleLoginWrapper, {
    type: 'icon',
    width: '200',
  });

  const googleLoginWrapperButton = googleLoginWrapper.querySelector(
    'div[role=button]'
  ) as HTMLElement;

  return {
    click: () => {
      googleLoginWrapperButton?.click();
    },
  };
};
  handleGoogleLogin() {
    this.loginWithGoogle.emit(this.createGoogleWrapper());
  }
  handleGoogleSignup() {
    this.signupWithGoogle.emit(this.createGoogleWrapper());
  }
}
