<section class="common-padd common-padd-lt-rt pt-0 job-management">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="img">
          <img src="../../../../assets/images/JobManagementHeader.png" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="addjob-sec common-padd-lt-rt">
  <div class="row">
    <div class="col-lg-12">
      <div class="mystatistics-info">
        <div class="row">
          <div class="col-lg-9">
            <p *ngIf="this.countData != ''" class="job-mgnt-line">
              My statistics: Active Jobs:
              <span>{{ this.countData.activeJob }}</span> | Total views:
              <span>{{ this.countData.viewJob }}</span> | Total applications:
              <span>{{ this.countData.appliedJob }}</span>
            </p>
          </div>
          <div class="col-lg-3 text-right">
            <a
              class="btn job-mgnt-btn-green"
              href="javascript:void(0);"
              (click)="routeToAddJob()"
              >Add new Job</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bodyjobsearch-area job-manage-main padd-btm common-padd-lt-rt">
  <div class="row">
    <div class="col-lg-5">
      <div class="bodyjobsearch-left jobsearch-height">
        <h4>Job List</h4>
        <ng-container *ngFor="let job of jobLists; let i = index">
          <!-- class="bodyjobsearch-box active-edit bgcolor" -->
          <div
            [ngClass]="
              status == job?.id
                ? 'bodyjobsearch-box bgcolor success'
                : 'bodyjobsearch-box bgcolor'
            "
          >
            <ng-container>
              <!-- <div class="bodyjobsearch-img" *ngIf="job.company_behalf_logo">
                <img
                  [src]="
                    job.company_behalf_logo
                      ? job.company_behalf_logo
                      : '../../../assets/images/sm-Logo.png'
                  "
                  onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                />
              </div>
              <div class="bodyjobsearch-img" *ngIf="!job.company_behalf_logo">
                <img
                  [src]="
                    this.companyData.companyLogo
                      ? this.companyData.companyLogo
                      : '../../../assets/images/sm-Logo.png'
                  "
                  onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
                />
              </div> -->
              <div class="bodyjobsearch-desc" (click)="jobDetails(i, job)">
                <!-- <h5>Product Manager, Google Cloud</h5> -->
                <h5>{{ job?.title }}</h5>
                <h6>
                  {{ job?.viewed }} viewed<br />
                  <b>{{ job?.applied }} applied</b>
                </h6>
                <p>{{ eventExpireIn(job.created_at) }}</p>
              </div>
              <div class="bodyjobsearch-share smalltgl">
                <ul>
                  <li>
                    <a
                      href="javascript:void(0);"
                      (click)="routeToEditJob(job.id)"
                      ><i style="color: green" class="fas fa-edit"></i> Edit</a
                    >
                  </li>
                  <li>
                    <mat-slide-toggle
                      [checked]="job.job_status == 'active' ? true : false"
                      (click)="activeInactive(job, i)"
                      [disabled]="!userDeatils.isEmailVerified"
                    >
                      {{ job.job_status == "active" ? "Active" : "Deactive" }}
                    </mat-slide-toggle>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <p
          style="
            text-align: right;
            font-size: 20px;
            color: #3eab70;
            cursor: pointer;
          "
          *ngIf="loadMoreButtonVisible"
          (click)="onScrollDown()"
        >
          load more ...
        </p>

        <div class="no-post-yet" *ngIf="detailsOfJob == ''">
          <h4>No jobs posted yet</h4>
          <!-- <button class="green-btn">Add your first job</button> -->
        </div>
      </div>
    </div>
    <div class="col-lg-7">
      <div
        class="bodyjobsearch-right bodyjobsearch-right-shadow"
        [ngClass]="showRightSectionInMobile ? 'mobile-view-parent' : ''"
        *ngIf="detailsOfJob"
      >
        <button (click)="mobileViewClassClose()" class="mobilejob-back">
          <i class="far fa-times-circle"></i>
        </button>
        <div [ngClass]="showRightSectionInMobile ? 'mobile-view-child' : ''">
          <h4>Preview</h4>

          <div class="title-wrap">
            <div class="profilelogo" *ngIf="!detailsOfJob.company_behalf_logo">
              <img
                [src]="
                  detailsOfJob.logo
                    ? detailsOfJob?.logo
                    : '../../../assets/images/sm-Logo.png'
                "
                onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
              />
              <h4>{{ detailsOfJob?.title }}</h4>
            </div>

            <div class="profilelogo" *ngIf="detailsOfJob.company_behalf_logo">
              <img
                [src]="
                  detailsOfJob.company_behalf_logo
                    ? detailsOfJob?.company_behalf_logo
                    : '../../../assets/images/sm-Logo.png'
                "
                onerror="this.onerror=null;this.src='../../../assets/images/sm-Logo.png'"
              />
              <h4>{{ detailsOfJob?.title }}</h4>
            </div>

            <div class="btn-wrap edit-deact">
              <button
                href="javascript:void(0);"
                class="btn-bdr-green"
                (click)="routeToEditJob(detailsOfJob.id)"
              >
                Edit
              </button>
              <button
                href="javascript:void(0);"
                class="btn red"
                (click)="activeInactive(detailsOfJob, currentIndex)"
              >
                {{
                  detailsOfJob.job_status == "active" ? "Deactive" : "Active"
                }}
              </button>
            </div>
          </div>
          <hr />
          <div class="moreinfo-wrap">
            <h6>Job info</h6>
          </div>
          <!-- <div class="desc-half">
                        <p><span>Job title</span> {{detailsOfJob.title}}</p>
                        <p *ngIf="detailsOfJob.job_location"><span>Job location</span>
                            {{detailsOfJob.job_location}}</p>
                        <p><span>Job type</span> {{detailsOfJob.employment_type}}</p>
                        <p><span>Minimum experience</span> {{detailsOfJob.minimum_experience}}</p>
                        <div class="moreinfo-wrap">
                            <p><span>Salary</span> ₦ {{detailsOfJob.minimum_salary}}  </p>

                        </div>
                    </div> -->
          <div class="desc-half">
            <p>
              <span class="plft">Job title</span>
              <span class="prit">{{ detailsOfJob.title }}</span>
            </p>
            <p *ngIf="detailsOfJob.job_location">
              <span class="plft">Job location</span>
              <span class="prit"
                >{{ detailsOfJob.job_location
                }}<span *ngIf="detailsOfJob?.city"
                  >/{{ detailsOfJob?.city }}</span
                ></span
              >
            </p>
            <p>
              <span class="plft">Job type</span>
              <span class="prit">{{ detailsOfJob.employment_type }}</span>
            </p>
            <p>
              <span class="plft">Minimum experience</span>
              <span class="prit">{{ detailsOfJob.minimum_experience }}</span>
            </p>
            <div class="moreinfo-wrap">
              <p>
                <span class="plft">Salary</span>
                <span class="prit"
                  >{{ detailsOfJob.currency }} {{ detailsOfJob.minimum_salary | number }}
                </span>
              </p>
            </div>
          </div>
          <hr />
          <h6>Job Description</h6>
          <p class="disc-list" [innerHTML]="detailsOfJob?.description"></p>

          <hr />
          <h6>Job About</h6>
          <p class="disc-list" [innerHTML]="detailsOfJob?.about"></p>
        </div>
      </div>
      <!-- <ng-container *ngIf="jobLists">
                <div class="no-post-here text-center" *ngIf="jobLists.length == 0">
                    <h4 class="hdttl">No jobs posted yet</h4>
                    <a href="javascript:void(0);" class="green-btn" (click)="routeToAddJob()">Add your first
                        job</a>
                </div>
            </ng-container> -->
      <div class="no-post-yet" *ngIf="detailsOfJob == ''">
        <h4>No preview</h4>
        <button class="btn btn-green" (click)="routeToAddJob()">
          + Add your first job
        </button>
      </div>
    </div>
  </div>
</section>

<!-- <div *ngIf="jobLists?.length == '0'" class="min-height">
    <section class="nosavedjob-found new-screen">
        <div class="no-job-posted-yet">
            <h4>No jobs posted yet</h4>
            <button class="green-btn" (click)="routeToAddJob()">Add your first job</button>
        </div>
    </section>
</div> -->
