import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployerRoutingModule } from './employer-routing.module';
import { EmployerComponent } from './employer.component';
import { EmployerAddjobComponent } from './employer-addjob/employer-addjob.component';
import { EmpDashboardComponent } from './emp-dashboard/emp-dashboard.component';
import { EmpJobsComponent } from './emp-dashboard/emp-jobs/emp-jobs.component';
import { EmpCompanyComponent } from './emp-dashboard/emp-company/emp-company.component';
import { EmpUserManagementComponent } from './emp-dashboard/emp-user-management/emp-user-management.component';
import { EmpBillingComponent } from './emp-dashboard/emp-billing/emp-billing.component';
import { MaterialModule } from '../../modules/material/material.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { EmpManageJobsComponent } from './emp-manage-jobs/emp-manage-jobs.component';
import { EmpEditjobComponent } from './emp-editjob/emp-editjob.component';
import { FormsModule } from '@angular/forms';
import { NewUserDialog } from './emp-dashboard/emp-user-management/new-user-dialog';
import { JobProviderProfileComponent } from './job-provider-profile/job-provider-profile.component';
import { AppliedJobseekerComponent } from './emp-dashboard/emp-jobs/applied-jobseeker/applied-jobseeker.component';
import { NewMessageComponent } from './emp-dashboard/models/new-message/new-message.component';
import { ChangeStatusComponent } from './emp-dashboard/models/change-status/change-status.component';
import { ChangeStatusRejectComponent } from './emp-dashboard/models/change-status-reject/change-status-reject.component';
import { GenPasswordComponent } from './gen-password/gen-password.component';
import { CompaniesForSuperAdminComponent } from './emp-dashboard/companies-for-super-admin/companies-for-super-admin.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReportsComponent } from './emp-dashboard/reports/reports.component';
import { CompanyVerificationComponent } from './company-verification/company-verification.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EmpCampaignManagementComponent } from './emp-dashboard/emp-campaign-management/emp-campaign-management.component';
import { EmpCampaignsSidenavComponent } from './emp-dashboard/emp-campaign-management/emp-campaigns-sidenav/emp-campaigns-sidenav.component';
import { EmpCampaignsComponent } from './emp-dashboard/emp-campaign-management/emp-campaigns/emp-campaigns.component';
import { EmpCampaignsAnalyticsComponent } from './emp-dashboard/emp-campaign-management/emp-campaigns-analytics/emp-campaigns-analytics.component';
import { EmpCampaignsWalletComponent } from './emp-dashboard/emp-campaign-management/emp-campaigns-wallet/emp-campaigns-wallet.component';
import { EmpCampaignsHelpComponent } from './emp-dashboard/emp-campaign-management/emp-campaigns-help/emp-campaigns-help.component';
import { EmpExtendPopupComponent } from './emp-dashboard/emp-campaign-management/emp-extend-popup/emp-extend-popup.component';
import { EmpJobPreviewComponent } from './emp-dashboard/emp-campaign-management/emp-job-preview/emp-job-preview.component';
import { Angular4PaystackModule } from 'angular4-paystack';
import { environment } from 'src/environments/environment';
import { EmpDashboardDashComponent } from './emp-dashboard/emp-dashboard-dash/emp-dashboard-dash.component';
import { EmpDashboardSidenavComponent } from './emp-dashboard/emp-dashboard-dash/emp-dashboard-sidenav/emp-dashboard-sidenav.component';
import { EmpDashJobOverviewComponent } from './emp-dashboard/emp-dashboard-dash/dashboard-elements/emp-dash-job-overview/emp-dash-job-overview.component';
import { EmpDashPromoteJobsComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-promote-jobs/emp-dash-promote-jobs.component';
import { EmpDashPreviewComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-preview/emp-dash-preview.component';
import { EmpDashManagementComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-management/emp-dash-management.component';
import { EmpDashHelpComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-help/emp-dash-help.component';
import { EmpDashPaymentComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-payment/emp-dash-payment.component';
import { EmpDashCvComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-cv/emp-dash-cv.component';
import { EmpDashSavedCvComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-saved-cv/emp-dash-saved-cv.component';
import { EmpFundWalletComponent } from './emp-dashboard/emp-fund-wallet/emp-fund-wallet.component';
import { PreventManualInputDirective } from 'src/app/directives/prevent-manual-input/prevent-manual-input.directive';
import { EmpDashAdminComponent } from './emp-dashboard/emp-dashboard-dash/emp-dash-admin/emp-dash-admin.component';
import { EmployerSuperAdminComponent } from './emp-dashboard/emp-dashboard-dash/employer-super-admin/employer-super-admin.component';
import { OverviewSuperAdminComponent } from './emp-dashboard/emp-dashboard-dash/overview-super-admin/overview-super-admin.component';
import { JobseekerSuperAdminComponent } from './emp-dashboard/emp-dashboard-dash/jobseeker-super-admin/jobseeker-super-admin.component';
import { SettingSuperAdminComponent } from './emp-dashboard/emp-dashboard-dash/setting-super-admin/setting-super-admin.component';
import { JobsTableComponent } from './emp-dashboard/emp-dashboard-dash/jobs-table/jobs-table.component';
import { JobViewComponent } from './emp-dashboard/emp-dashboard-dash/job-view/job-view.component';
import { JobSuperAdminComponent } from './emp-dashboard/emp-dashboard-dash/job-super-admin/job-super-admin.component';
import { UploadCvComponent } from './emp-dashboard/emp-dashboard-dash/upload-cv/upload-cv.component';

export const options: Partial<IConfig> = {
  thousandSeparator: "'",
};

@NgModule({
  declarations: [
    EmployerComponent,
    EmployerAddjobComponent,
    EmpDashboardComponent,
    EmpJobsComponent,
    EmpCompanyComponent,
    EmpUserManagementComponent,
    EmpBillingComponent,
    EmpManageJobsComponent,
    EmpEditjobComponent,
    JobProviderProfileComponent,
    NewUserDialog,
    AppliedJobseekerComponent,
    NewMessageComponent,
    ChangeStatusComponent,
    ChangeStatusRejectComponent,
    GenPasswordComponent,
    CompaniesForSuperAdminComponent,
    ReportsComponent,
    CompanyVerificationComponent,
    SuperAdminComponent,
    EmpCampaignManagementComponent,
    EmpCampaignsSidenavComponent,
    EmpCampaignsComponent,
    EmpCampaignsAnalyticsComponent,
    EmpCampaignsWalletComponent,
    EmpCampaignsHelpComponent,
    EmpExtendPopupComponent,
    EmpJobPreviewComponent,
    EmpDashboardDashComponent,
    EmpDashboardSidenavComponent,
    EmpDashJobOverviewComponent,
    EmpDashPromoteJobsComponent,
    EmpDashPreviewComponent,
    EmpDashManagementComponent,
    EmpDashHelpComponent,
    EmpDashPaymentComponent,
    EmpDashCvComponent,
    EmpDashSavedCvComponent,
    EmpFundWalletComponent,
    PreventManualInputDirective,
    EmpDashAdminComponent,
    EmployerSuperAdminComponent,
    OverviewSuperAdminComponent,
    JobseekerSuperAdminComponent,
    SettingSuperAdminComponent,
    JobsTableComponent,
    JobViewComponent,
    JobSuperAdminComponent,
    UploadCvComponent
  ],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(options),
    EmployerRoutingModule,
    MaterialModule,
    SharedModule,
    FormsModule,
    NgbPaginationModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'This item is actually loading...',
    }),
    Angular4PaystackModule.forRoot(environment.paystack_key),
  ],
})
export class EmployerModule {}
