import { Component, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

declare var gtag: Function;

@Component({
  selector: 'app-cv-review-landing',
  templateUrl: './cv-review-landing.component.html',
  styleUrls: ['./cv-review-landing.component.scss'],
})
export class CvReviewLandingComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    gtag('event', 'page_view', { page_path: '/cv-review' });
  }

  cardClick(type: String) {
    gtag('event', 'click', {
      event_category: 'review_type',
      event_label: type,
      value: 1,
    });
    this.document.location.href =
      'https://docs.google.com/forms/d/e/1FAIpQLSdMyGXszTmsmtk5imRxQGC6wlnblC6EwBXFeYZdwLLzm3RYOA/viewform';
  }
}
