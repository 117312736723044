<div class="emp-table-section">
    <table class="table table-for-employer">
        <thead>
            <tr>
                <th>APPLY</th>
                <th class="title-width">JOB TITLE</th>
                <th *ngIf="jobProviderId === undefined">COMPANY</th>
                <th>POSTED BY</th>
                <th>PROMOTION</th>
                <th>DATE POSTED</th>
                <th>ACTION</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let job of jobLists">
                <td>{{ job.jobViewData.applied }}</td>
                <td class="title-width">{{ truncateString(job.title) }}</td>
                <td *ngIf="jobProviderId === undefined"> {{ job.company_name }}</td>
                <td>{{ job.providerName }}</td>
                <td>{{ isPromoted(job.isPromoted) }}</td>
                <td>{{ formatDateToDDMMYYYY(job.created_at) }}</td>
                <td class="d-flex align-items-center gap-2">
                    <label class="toggle">
                        <input type="checkbox" (change)="toggleJobStatus(job.id)" [checked]="job.job_status === 'active'" />
                        <span class="slider round"></span>
                        <span class="toggle-text"></span>
                    </label>
                    <button class="btn-view" (click)="viewJob(job)">
                        View Job
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <nav aria-label="Page navigation" class="mb-1">
        <ngb-pagination [collectionSize]="jobsPagination.total" [(page)]="currentPage"
            [pageSize]="jobsPagination.size" (pageChange)="onPageChanged($event)" [maxSize]="10" [ellipses]="false" [rotate]="true">
        </ngb-pagination>
    </nav>
</div>