<section class="common-banner new-screen">
  <div class="common-banner-info">
    <h5>OVERLINE</h5>
    <h1>Headline</h1>
    <h6>Description & Instructions</h6>
  </div>
</section>

<section class="py-4 bg-light" style="margin-top: 10px">
  <div class="container shadow-md bg-white" style="padding: 30px">
    <div style="display: flex; padding-bottom: 20px">
      <div style="flex: 1"><h2 class="title">Alerts</h2></div>
      <div style="text-align: right">
        <a class="green-btn" href="job-added"> Add new alert</a>
      </div>
    </div>

    <div class="well-views">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 230px">Email frequency</th>
            <th></th>
            <th>Alert name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select class="select-box">
                <option>Daily</option>
              </select>
            </td>
            <td>
              <button class="green-btn">Edit</button>
            </td>
            <td>Executive Officer Postgraduate School</td>
          </tr>
          <tr>
            <td>
              <select class="select-box">
                <option>Weekly</option>
              </select>
            </td>
            <td>
              <button class="green-btn">Edit</button>
            </td>
            <td>Product Manager</td>
          </tr>
          <tr>
            <td>
              <select class="select-box">
                <option>Never</option>
              </select>
            </td>
            <td>
              <button class="green-btn">Edit</button>
            </td>
            <td>Assistant Director - MIS Unit</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
