<div class="tab-content select-wrap py-3 px-3 px-sm-0">
  <div class="tab-pane fade show active" id="nav-contact">
    <div class="flexbtn invite-top">
      <!-- <div class="flexbtnleft">
        <a href="#" class="btn bdr-gray">Activate</a>
        <a href="#" class="btn bdr-pink">Deactivate</a>
        <a href="#" class="green-btn">Change role</a>
      </div> -->

      <!-- <div class="select-row" *ngIf=" this.loggedInUser.userType == 'super_admin'">
        <mat-form-field class="example-full-width selectbox" appearance="fill">
          <mat-label>Companies</mat-label>
          <mat-select  (selectionChange)="changeCompany($event)">
            <mat-option value="{{row.id}}" *ngFor="let row of CompaniesResult">{{row.companyName[0].toUpperCase()+
              row.companyName.slice(1)}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <form
        [formGroup]="userManagement"
        *ngIf="this.loggedInUser.userType == 'super_admin'"
      >
        <div class="select-row">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label style="color: black"
              ><i class="fas fa-search"></i> Companies</mat-label
            >
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="companies"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="changeCompany($event.option.value)"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option.companyName"
              >
                {{ option.companyName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>

      <div *ngIf="viewOnly != true">
        <button (click)="openDialog('', 'add')" href="#" class="btn btn-green">
          <img src="../../../assets/images/add_circle_outline_24px.png" />
          Invite new users
        </button>
      </div>
    </div>

    <div class="well-views table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>
              Name
              <i
                *ngIf="this.sortfuction"
                class="fas fa-arrow-up"
                (click)="sortName()"
              ></i
              ><i
                *ngIf="!this.sortfuction"
                class="fas fa-arrow-down"
                (click)="sortName()"
              ></i>
            </th>
            <th>Email</th>
            <th>Status</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let emp of empList">
            <td (click)="viewUserDetails(emp)" class="tneme">
              {{ emp.firstName }}
            </td>
            <td (click)="viewUserDetails(emp)" class="temail">
              {{ emp.email }}
            </td>
            <td (click)="viewUserDetails(emp)">
              {{ emp.isUserActive == true ? "Activate" : "Deactivate" }}
            </td>
            <td (click)="viewUserDetails(emp)">
              {{ emp.userRole == "company_basic" ? "Basic" : "Administrator" }}
            </td>
            <td *ngIf="viewOnly !== true; else view" class="flex">
              <button class="edit" (click)="openDialog(emp, 'edit')">
                Edit
              </button>
              <button
                class="btn bdr-pink"
                *ngIf="loggedInUser.id != emp.id && emp.isUserActive"
                (click)="deactiveUser(emp)"
              >
                Deactivate
              </button>
              <button
                class="btn bdr-gray"
                *ngIf="loggedInUser.id != emp.id && !emp.isUserActive"
                (click)="activeUser(emp)"
              >
                Activate
              </button>
            </td>
            <ng-template #view>
              <td>
                <button class="edit" (click)="viewUserDetails(emp)">
                  View
                </button>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
