import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/services/auth.service';
import { EmployerService } from 'src/app/services/employer.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { Helper } from 'src/app/helper/defult';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss'],
})
export class SuperAdminComponent implements OnInit {
  usersList: any = [];
  public selectedUserType: any;
  public selectedActionType: any;
  dashboardDetails: UntypedFormGroup;
  searchString = '';
  startDate: any;
  endDate: any;
  collectionSize: any;
  pageSize: any = 10;
  start: any = 0;
  page: any = 1;
  userToken: string;
  statusList: any = ['Active', 'Inactive'];
  userDeatils: any;
  companyData: any;
  searchText: any = '';
  userTypeList: any = ['Jobseeker', 'Employer'];
  actionTypeList: any = ['Action', 'Block', 'Suspend', 'Release'];

  constructor(
    // private jobService: JobServices,
    public dialog: MatDialog,
    private helper: Helper,
    private auth: AuthService,
    public datepipe: DatePipe,
    private employerService: EmployerService,
    private fb: UntypedFormBuilder
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');
    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
  }

  ngOnInit(): void {
    this.validationForm();
    this.selectedUserType = this.userTypeList[0];
    this.selectedActionType = this.actionTypeList[0];
    this.fetchUsers();

    this.dashboardDetails.controls.search_text.valueChanges
      .pipe(debounceTime(500)) // Add debounceTime here with the desired debounce time in milliseconds
      .subscribe((searchText) => {
        this.searchText = searchText;
        this.resetPagination();
        this.fetchUsers();
      });
  }

  validationForm() {
    this.dashboardDetails = this.fb.group({
      start_date: [''],
      end_date: [''],
      search_text: [''],
    });
  }

  changeUserType(event) {
    this.selectedUserType = event.value;
    this.resetPagination();
    this.fetchUsers();
  }

  fetchAgain() {
    this.fetchUsers();
  }

  async fetchUsers() {
    this.usersList = [];

    let params = new HttpParams();
    params = params.append('path', '/admin/users');
    params = params.append('start', this.start);
    params = params.append('size', this.pageSize);
    params = params.append('userType', this.selectedUserType.toLowerCase());

    if (this.searchText != '') {
      params = params.append('search', this.searchText);
    }

    if (this.startDate) {
      params = params.append('startDate', this.startDate.toISOString());
    }

    if (this.endDate) {
      params = params.append('endDate', this.endDate.toISOString());
    }

    await this.employerService._fetchUsersList(params).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        this.usersList = res['result'];
        this.collectionSize = res['pagination'].total;
      }
    });
  }

  onPageChanged(pageNumber) {
    this.start = (pageNumber - 1) * this.pageSize;
    this.page = pageNumber;
    this.fetchUsers();
  }

  resetPagination() {
    this.start = 0;
    this.page = 1;
  }

  async downloadUsers() {
    if (this.selectedUserType == 'Jobseeker') {
      await this.employerService
        ._downloadJobSeekersCsv()
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.downloadS3File(res['result'].fileUrl);
          }
        });
    } else {
      await this.employerService
        ._downloadEmployersCsv()
        .subscribe((res: any) => {
          if (res.serverResponse.code == 200) {
            this.downloadS3File(res['result'].fileUrl);
          }
        });
    }
  }

  filterStartDate(e: MatDatepickerInputEvent<Date>) {
    this.startDate = e.value;
    this.resetPagination();

    this.fetchUsers();
  }
  filterEndDate(e: MatDatepickerInputEvent<Date>) {
    this.endDate = e.value;
    this.resetPagination();

    this.fetchUsers();
  }

  downloadS3File(s3Link) {
    const link = document.createElement('a');
    link.href = s3Link;
    link.setAttribute('download', '');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }
}
