import { Component } from '@angular/core';

@Component({
  selector: 'app-overview-super-admin',
  templateUrl: './overview-super-admin.component.html',
  styleUrls: ['./overview-super-admin.component.scss']
})
export class OverviewSuperAdminComponent {
 
}
