import { Injectable } from '@angular/core';
// import { AngularFire, AuthProviders, AuthMethods } from '@angular/fire/auth-guard';
// for auth
// import { AngularFireAuth } from 'angularfire2/auth';
// for database
// import { AngularFireDatabase } from 'angularfire2/database';
// for Observables
// import {FirebaseListObservable, FirebaseObjectObservable } from 'angularfire2/database-deprecated';
// import firebase from 'firebase';
@Injectable()
export class AF {
  constructor() {}

  socialLogin(loginProvider) {
    // var provider = new firebase.auth.TwitterAuthProvider();
    // console.log('provider',provider);
    //   if (loginProvider === 'google') {
    //     provider = AngularFireDatabase.Google;
    //   }
    //   else if (loginProvider === 'facebook') {
    //     provider = AngularFireDatabase.Facebook;
    //   }
    //   else if (loginProvider === 'github') {
    //     provider = AngularFireDatabase.Github;
    //   }
    //   else if (loginProvider === 'twitter') {
    //     provider = AngularFireDatabase.Twitter;
    //   }

    //   return this.af.auth.login({
    //      provider: provider,
    //      method: FirebaseListObservable.Popup,
    //   });

    // firebase
    //   .auth()
    //   .signInWithPopup(provider)
    //   .then((result) => {
    //     /** @type {firebase.auth.OAuthCredential} */
    //     var credential = result.credential;
    //     // console.log('result',result);
    //     // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    //     // You can use these server side with your app's credentials to access the Twitter API.
    //     // var token = credential.accessToken;
    //     // var secret = credential.secret;

    //     // The signed-in user info.
    //     var user = result.user;
    //     // ...
    //   })
    //   .catch((error) => {
    //     // Handle Errors here.
    //     // console.log('error',error);
    //     var errorCode = error.code;
    //     var errorMessage = error.message;
    //     // The email of the user's account used.
    //     var email = error.email;
    //     // The firebase.auth.AuthCredential type that was used.
    //     var credential = error.credential;
    //     // ...
    //   });
  }

  // Logs out the current user
  logout() {
    // firebase
    //   .auth()
    //   .signOut()
    //   .then(() => {
    //     // Sign-out successful.
    //   })
    //   .catch((error) => {
    //     // An error happened.
    //   });
  }
}
