import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { HttpIOService } from 'src/app/services/http-io.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Helper } from 'src/app/helper/defult';
import { EmployerService } from 'src/app/services/employer.service';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Location } from '@angular/common';
import { PopupConfirmationComponent } from 'src/app/container/elements/popup-confirmation/popup-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { EmpDashPreviewComponent } from '../emp-dashboard-dash/emp-dash-preview/emp-dash-preview.component';
@Component({
  selector: 'app-emp-fund-wallet',
  templateUrl: './emp-fund-wallet.component.html',
  styleUrls: ['./emp-fund-wallet.component.scss'],
})
export class EmpFundWalletComponent implements OnInit {
  amountToFund: number = 0;
  transactionRef = `FUND_${uuidv4()}`;
  userDetails: any;
  paystackKey: string;
  campaignDetails: any = null;
  userAssets: any;

  constructor(
    public auth: AuthService,
    private helper: Helper,
    private employerService: EmployerService,
    private location: Location,
    private dialog: MatDialog,
    private io: HttpIOService,
    private router: Router,
    private dataService: DataService,
  ) {
    this.paystackKey = environment.paystack_key
    this.campaignDetails = this.location.getState()
    if(this.campaignDetails?.extraAmountRequired && this.auth.getUserBalance() - this.campaignDetails?.extraAmountRequired < 0){
      this.amountToFund = this.campaignDetails?.extraAmountRequired - this.auth.getUserBalance()
    }
  }
  
  ngOnInit(): void {
    this.fetchUserDetails()
    this.dataService.getUserData().subscribe((data: any) => {
      if(data){
        this.userAssets = data;
      }else{
        this.loadUpdatedAssets()
      }
    });
  }

  paymentCancel(): void {
    console.log('Payment Cancelled');
  }

  paymentDone(event: any): void {
    if(event.status === 'success'){
      // this.auth.setUserBalance(this.auth.getUserBalance() + this.amountToFund)
      this.amountToFund = null
      if(this.campaignDetails.path === 'create-campaign'){
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Campaign Payment Successful',
            from: 'wallet',
            campaignDetails: {...this.campaignDetails, deductFromWallet: true}
          },
        })
        dialogRef.afterClosed().subscribe(result => {
          if(result === 'job-overview'){
            const dialogRef = this.dialog.open(EmpDashPreviewComponent, {
              width: '880px',
              height: '850px',
              autoFocus: false,
              data: {
                ...this.campaignDetails,
                jobOverview: true, 
                filteredJobs: this.campaignDetails?.jobs, 
                creditBreakup: this.campaignDetails?.creditBreakup,
                jobIds: this.campaignDetails?.jobIds
                }, // Pass a copy of the filtered job list
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result === 'success'){
                const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                  height: 'auto',
                  width: 'auto',
                  autoFocus: false,
                  data: {
                    data: 'Campaign Successful',
                    campaignDetails: this.campaignDetails
                  },
                })
                dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
              }
            })
          }else{
            this.loadUpdatedAssets()
          }
        });
      }else if(this.campaignDetails.path === 'unlock-cv'){
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Campaign Payment Successful',
            from: 'wallet-cv',
            campaignDetails: {...this.campaignDetails, deductFromWallet: true}
          },
        });
        dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
      }else{
        this.loadUpdatedAssets()
      }
    }
  }

  updateTransactionId(event: any): void {
    this.transactionRef = `FUND_${uuidv4()}`;
  }

  fetchUserDetails(): void {
    this.auth.userUpdated.subscribe((res) => {
      let localUser = localStorage.getItem('workNigeriaUser');
      let sessionUser = sessionStorage.getItem('workNigeriaUser');
      if (localUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
        );
      }
      if (sessionUser) {
        this.userDetails = JSON.parse(
          this.helper.fromBinary(
            atob(sessionStorage.getItem('workNigeriaUser'))
          )
        );
      }
    });
  }

  postCampaign(): void {
    const dialogRef = this.dialog.open(EmpDashPreviewComponent, {
      width: '880px',
      height: '850px',
      autoFocus: false,
      data: { 
        ...this.campaignDetails,
        jobOverview: true, 
        filteredJobs: this.campaignDetails?.jobs, 
        creditBreakup: this.campaignDetails?.creditBreakup,
        jobIds: this.campaignDetails?.jobIds
        }, // Pass a copy of the filtered job list
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
          height: 'auto',
          width: 'auto',
          autoFocus: false,
          data: {
            data: 'Campaign Successful',
            campaignDetails: this.campaignDetails
          },
        })
        dialogRef.afterClosed().subscribe(() => { this.loadUpdatedAssets() })
      }
    })
  }

  unlockCV(): void {
    this.io._unlockCV({candidateId: this.campaignDetails.candidateId, payFromWallet: true}).subscribe((res) => {
      console.log(res['serverResponse'])
      if (res['serverResponse']['code'] == 200) {
        this.loadUpdatedAssets()
        this.router.navigate(['Dashboard']);
      }else{
        console.log('failed')
      }
    });
  }

  loadUpdatedAssets() {
    this.dataService.loadUpdatedAssets()
    .subscribe(() => {
      this.dataService.getUserData().subscribe((data: any) => {
        this.userAssets = data;
      });
    });
  }
}
