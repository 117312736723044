import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { DynamicSnacbarErrorComponent } from 'src/app/container/elements/dynamic-snacbar-error/dynamic-snacbar-error.component';
import { DynamicSnacbarComponent } from 'src/app/container/elements/dynamic-snacbar/dynamic-snacbar.component';
import { Helper } from 'src/app/helper/defult';
import { AuthService } from 'src/app/services/auth.service';
import { HttpIOService } from 'src/app/services/http-io.service';
@Component({
  selector: 'app-via-external-url',
  templateUrl: './via-external-url.component.html',
  styleUrls: ['./via-external-url.component.scss'],
})
export class ViaExternalUrlComponent implements OnInit {
  show: boolean = false;
  userDeatils: any;
  companyData: any;
  guestUser: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private titleService: Title,
    public dialogRef: MatDialogRef<ViaExternalUrlComponent>,
    private snackBar: MatSnackBar,
    private io: HttpIOService,
    private auth: AuthService,
    private helper: Helper
  ) {
    let localUser = localStorage.getItem('workNigeriaUser');
    let sessionUser = sessionStorage.getItem('workNigeriaUser');

    if (localUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(localStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyData();
    }
    if (sessionUser) {
      this.userDeatils = JSON.parse(
        this.helper.fromBinary(atob(sessionStorage.getItem('workNigeriaUser')))
      );
      this.companyData = this.auth.getCurrentCompanyDataInSessoin();
    }
    let guestUser = sessionStorage.getItem('guestUser');
    if (guestUser) {
      this.guestUser = JSON.parse(atob(sessionStorage.getItem('guestUser')));
    }
  }

  ngOnInit(): void {
    // console.log(this.data);
    if (this.data['user'] == 'WithoutSignIn') {
      this.show = true;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  applyJob() {
    let jsonData = {
      path: 'apply-job-via-external-site',
      jobId: this.data['Perticularjob'].id,
      userId:
        this.userDeatils != undefined ? this.userDeatils.id : this.guestUser.id,
      documents: [],
      siteUrl: this.data['Perticularjob'].external_url,
      title: this.data['Perticularjob'].title,
      from:
        this.userDeatils != undefined
          ? this.userDeatils.email
          : this.guestUser.email,
      name:
        this.userDeatils != undefined
          ? `${this.userDeatils.firstName} ${this.userDeatils.lastName}`
          : `${this.guestUser.firstName} ${this.guestUser.lastName}`,
      job_provider_id: this.data['Perticularjob'].job_provider_id,
      company_id: this.data['Perticularjob'].company_id,
    };
    // console.log(jsonData);
    this.io._applyViaExtralnamUrl(jsonData).subscribe((res: any) => {
      if (res.serverResponse.code == 200) {
        // console.log(res);
        this.dialogRef.close('save');
        this.snackBar
          .openFromComponent(DynamicSnacbarComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
        window.open(this.data['Perticularjob'].external_url);
        sessionStorage.removeItem('guestUser');
      } else {
        this.snackBar
          .openFromComponent(DynamicSnacbarErrorComponent, {
            data: res.serverResponse.message,
            verticalPosition: 'top', // 'top' | 'bottom'
            horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
          })
          .afterOpened()
          .subscribe(this.helper.setOnTop);
      }
    });
  }
}
