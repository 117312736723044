import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { EmployerService } from 'src/app/services/employer.service';
import { HttpIOService } from 'src/app/services/http-io.service';

@Component({
  selector: 'app-jobs-table',
  templateUrl: './jobs-table.component.html',
  styleUrls: ['./jobs-table.component.scss']
})
export class JobsTableComponent implements OnInit, OnChanges {
  @Input() jobProviderId: string;
  @Input() jobSearch: any = {};
  @Output() selectedJob: EventEmitter<any> = new EventEmitter<any>();
  jobLists: any;
  detailsOfJob: any;
  currentPage: number = 1;
  jobsPagination = {
    size: 8,
    start: 0,
    total: 0,
  };

  constructor(
    private employerService: EmployerService,
    private io: HttpIOService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.jobSearch = changes.jobSearch?.currentValue ?? {}
    console.log(changes.jobSearch)
    if(this.jobSearch.start == 0) this.currentPage = 1
    if (this.jobProviderId !== undefined) {
      this.fetchJobList('desc', 0);
    } else {
      this.fetchAllJobs(0);
    }
  }

  ngOnInit(): void {
    if (this.jobProviderId !== undefined) {
      this.fetchJobList('desc', 0);
    } else {
      this.fetchAllJobs(0);
    }
  }

  // Cuts the string short to display 
  // @param {string} str: takes the whole string
  // @return {string}: returns the string cut to only 20 letters including spaces
  truncateString(str: string): string {
    if (str.length <= 10) {
      return str;
    } else {
      return str.slice(0, 20) + "...";
    }
  }

  // Fetches ALL the jobs 
  // @param {number} start: takes the start of the pagination 
  // @returns {object}: the list of the jobs list 
  async fetchAllJobs(start: number): Promise<void> {
    let payLoad = {
      ...this.jobSearch,
      path: 'all-jobs-data',
      size: this.jobsPagination.size,
      start: start,
      sortParam: 'created_at'
    };
    await this.employerService._getJobsList(payLoad).subscribe((res: any) => {
      if (res.serverResponse.code == 200 && res['pagination']) {
        this.jobLists = res['result'];
        this.jobsPagination = res['pagination'];
        if (this.jobLists.length > 0) {
          this.detailsOfJob = this.jobLists[0];
        }
      }else {
        this.jobLists = []
        this.jobsPagination = {
          size: 8,
          start: 0,
          total: 0,
        }
      }
    });
  }

  // Fetches the jobs with an id
  // @param {string, number} sortOrder, start: takes the start of the pagination 
  // @returns {object}: the list of the jobs list 
  async fetchJobList(sortOrder: string, start: number): Promise<void> {
    let payLoad = {
      sortOrder,
      ...this.jobSearch,
      path: 'get-job-by-provider-or-company',
      provider_id: this.jobProviderId,
      size: this.jobsPagination.size,
      start: start,
      sortParam: 'created_at'
    };
    this.employerService
      ._getJobByproviderAndCompany(payLoad)
      .subscribe((res: any) => {
        if (res.serverResponse.code == 200  && res['pagination']) {
          this.jobLists = res['result'].job;
          this.jobsPagination = res['pagination'];
          if (this.jobLists.length > 0) {
            this.detailsOfJob = this.jobLists[0];
          }
        }else {
          this.jobLists = []
          this.jobsPagination = {
            size: 8,
            start: 0,
            total: 0,
          }
        }
      });
  }

  toggleJobStatus(jobId: string) {
    let jobStatus = this.detailsOfJob.job_status;
    if (jobStatus === 'active') {
      let jsonData = {
        path: 'inactive-job',
        id: jobId,
      };
      this.io._inactiveForEmpManage(jsonData).subscribe((res: any) => { });
    } else {
      let jsonData = {
        path: 'active-job',
        id: jobId,
      };
      this.io._activeForEmpManage(jsonData).subscribe((res: any) => { });
    }
  }

  // To move to the next page through pagination
  onPageChanged(page: number): void {
    this.currentPage = page;
    if (this.jobProviderId !== undefined) {
      this.fetchJobList('desc', (page - 1) * this.jobsPagination.size);
    } else {
      this.fetchAllJobs((page - 1) * this.jobsPagination.size);
    }
  }

  viewJob(job: any): void {
    this.selectedJob.emit(job)
  }

  formatDateToDDMMYYYY(timestamp: number): string {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day} - ${month} - ${year}`;
  }

  isPromoted(promoted: any) {
    if (promoted === false) {
      return 'Not Promoted'
    } else {
      return 'Promoted'
    }
  }
}
