<div class="custom-page-height">
  <div>
    <div
      class="pro-top-hd"
      *ngIf="profileRecords?.userType == 'employer' && show"
    >
      <p>
        &nbsp;
        <a href="javascript:void(0);" class="btn-green"
          ><i class="fas fa-comment-dots"></i> Message</a
        >
      </p>
    </div>

    <div class="profile-middle-sec">
      <div class="container">
        <div class="card">
          <h2>
            Profile
            <!-- <span>
                            <a href="javascript:void(0);" (click)="profile()" class="btn-green wh-bg"><i
                                    class="fas fa-pen"></i>
                                EDIT PROFILE</a>
                        </span> -->
          </h2>
          <div class="card-mid pro-img">
            <figure>
              <!-- <img src="../assets/images/pro-pic.png" /> -->
              <img
                src="{{
                  profileRecords?.profileImage
                    ? profileRecords?.profileImage
                    : '../assets/images/defaultuser.png'
                }}"
              />
            </figure>
            <figcaption>
              <strong class="d-block"
                >{{ profileRecords?.firstName }}
                {{ profileRecords?.lastName }}</strong
              >
            </figcaption>
          </div>
        </div>

        <div class="card" *ngIf="educationAllRecords?.length > '0'">
          <h2>
            Education
            <!-- <span><a href="javascript:void(0);" class="btn-green" (click)="education('NEW')">ADD
                                EDUCATION</a></span> -->
          </h2>
          <div
            class="card-mid-description"
            *ngFor="let row of educationAllRecords; let i = index"
          >
            <div class="row">
              <div class="col-lg-10">
                <figcaption>
                  <strong class="d-block">{{ row.universityName }}</strong>
                  <p class="mb-0">{{ row.courseName }}</p>
                </figcaption>
              </div>
              <!-- <div class="col-lg-2 text-right">
                                <a href="javascript:void(0);" class="btn-green wh-bg" (click)="education(row)"><i
                                        class="fas fa-pen"></i> EDIT</a>
                            </div> -->
            </div>
          </div>
        </div>

        <div class="card" *ngIf="experianceAllRecords?.length > '0'">
          <h2>
            Experience
            <!-- <span><a href="javascript:void(0);" class="btn-green" (click)="experiance('NEW')">ADD
                                EXPERIENCE</a></span> -->
          </h2>
          <div
            class="card-mid-description"
            *ngFor="let row of experianceAllRecords; let i = index"
          >
            <div class="row">
              <div class="col-lg-10">
                <figcaption>
                  <span class="d-block">{{ row.company }}</span>
                  <strong class="d-block">{{ row.title }}</strong>
                  <p class="mb-0">
                    {{ row.startDate | date }} –
                    {{
                      row.endDate == null ? "Present " : (row.endDate | date)
                    }}
                    • {{ yearClculation(row) }} years
                  </p>
                </figcaption>
              </div>
              <!-- <div class="col-lg-2 text-right">
                                <a href="javascript:void(0);" class="btn-green wh-bg" (click)="experiance(row)"><i
                                        class="fas fa-pen"></i> EDIT</a>
                            </div> -->
            </div>
          </div>
        </div>

        <div class="card" *ngIf="licenseAllRecords?.length > '0'">
          <h2>
            Licenses & certifications
            <!-- <span><a href="javascript:void(0);" (click)="certifiate('NEW')"
                                class="btn-green">ADD LICENSES & CERTIFICATIONS</a></span> -->
          </h2>
          <div
            class="card-mid-description"
            *ngFor="let row of licenseAllRecords; let i = index"
          >
            <div class="row">
              <div class="col-lg-10">
                <figcaption>
                  <strong class="d-block">{{ row.name }}</strong>
                  <p class="mb-0">{{ row.issuingOrganization }}</p>
                  <p class="mb-0">
                    Issued - {{ row.issueDate | date }} •
                    {{ row.endDate == null ? "Present" : (row.endDate | date) }}
                  </p>
                  <p class="mb-0">
                    <small>Credential ID : {{ row.credentialId }}</small>
                  </p>
                </figcaption>
              </div>
              <!-- <div class="col-lg-2 text-right">
                                <a href="javascript:void(0);" class="btn-green wh-bg" (click)="certifiate(row)"><i
                                        class="fas fa-pen"></i> EDIT</a>
                            </div> -->
            </div>
          </div>
        </div>

        <div class="card" *ngIf="skillAllRecords?.length > '0'">
          <h2>Skills</h2>
          <div class="card-mid-description">
            <div class="row">
              <div class="col-lg-12">
                <figcaption>
                  <mat-form-field
                    class="example-chip-list custom-chip-set"
                    style="border: none"
                  >
                    <mat-chip-list
                      #chipList
                      aria-label="Fruit selection"
                      style="border: none"
                    >
                      <mat-chip
                        style="border: none"
                        *ngFor="let fruit of this.skillAllRecords"
                      >
                        {{ fruit }}
                      </mat-chip>
                    </mat-chip-list>
                  </mat-form-field>
                </figcaption>
              </div>
            </div>
          </div>

          <!-- <div class="card-mid-description">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="javascript:void(0);" class="show-more-exp-btn">Show more <i
                                        class="fas fa-angle-down"></i> </a>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
