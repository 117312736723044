<div class="auth-wrap common-padd">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="auth-mainbox">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="log-lt choose-new-password">
                <h3>Choose new password</h3>
                <p class="sub-heading">
                  Create a new password that is atleast 8 characters long
                </p>
                <div class="form-wrap">
                  <form [formGroup]="reset">
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="New password"
                        required="required"
                        formControlName="password"
                        [type]="showPassword ? 'text' : 'password'"
                      />
                      <mat-icon
                        matSuffix
                        (click)="togglePasswordVisibility()"
                        style="cursor: pointer"
                        >{{
                          showPassword ? "visibility_off" : "visibility"
                        }}</mat-icon
                      >
                      <div *ngIf="f.password.touched && f.password.invalid">
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.password.errors.required"
                          >Password is required
                        </span>
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.password.errors?.pattern"
                          >You must use at least one upper case letter, one lower case letter, one number, and one special character. The password should be at least 8 characters long
                        </span>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Re type new password"
                        required="required"
                        formControlName="newPassword"
                        [type]="showPassword2 ? 'text' : 'password'"
                      />
                      <mat-icon
                        matSuffix
                        (click)="togglePasswordVisibility2()"
                        style="cursor: pointer"
                        >{{
                          showPassword2 ? "visibility_off" : "visibility"
                        }}</mat-icon
                      >
                      <div
                        *ngIf="f.newPassword.touched && f.newPassword.invalid"
                      >
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.newPassword.errors.required"
                          >New Password is required
                        </span>
                        <span class="text-left text-danger pb-1">
                          <span *ngIf="f.password.value != f.newPassword.value && !f.newPassword.errors.required">
                            New Password and Confirm Password does not match
                          </span>
                        </span>
                        <span
                          class="text-left text-danger pb-1"
                          *ngIf="f.newPassword.errors?.pattern && !f.newPassword.errors.required && f.password.value == f.newPassword.value"
                          >You must use at least one upper case letter, one lower case letter, one number, and one special character. The password should be at least 8 characters long
                        </span>
                      </div>
                    </div>

                    <div class="form-group">
                      <input
                        type="submit"
                        value="Submit"
                        class="btn btn-green w-100"
                        (click)="changedPassword()"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="log-sldr-wrap">
                <div class="imgbox">
                  <img
                    src="../../../../assets/images/Forgot-password.png"
                    alt=""
                  />
                </div>
                <div class="info">
                  <h4>Create your own account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
